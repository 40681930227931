import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { SurveyRoutingModule } from './survey-routing.module';
import { UtilityModule } from 'app/utility';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { SurveyAddComponent } from './components/survey-add/survey-add.component';
import { SurveyFilterComponent, SurveyListComponent, SurveyListTeamAddComponent, SurveyListTeamListComponent,SurveyListTeamAddMemberComponent } from './components';

@NgModule({
  declarations: [
      SurveyListComponent,
      SurveyAddComponent,
      SurveyFilterComponent,
      SurveyListTeamListComponent,
      SurveyListTeamAddComponent,
      SurveyListTeamAddMemberComponent
  ],
  imports: [
    CommonModule,
    UtilityModule,
    SurveyRoutingModule,
    InfiniteScrollModule,
    TranslateModule.forChild(),
  ],
  providers: [
    NgDialogAnimationService,
  ]
})
export class SurveyModule { }
