import {Injectable} from '@angular/core';
import {EncryptionFunctions} from '../shared-functions';
import {AppApi, APPStorage, HttpMethodsTypeEnum} from '../shared-constants';
import {FileResponseModel} from '../shared-model';
import { Observable } from 'rxjs';
import { DocumentViewTypeEnum } from '../shared-component';
import { APIManager } from './apimanager.service';


@Injectable({
    providedIn: 'root'
})
export class SharedFileService {
    private _file: FileResponseModel;

    constructor(private _apiManager: APIManager) {
    }

    uploadFile(params, files): Observable<any> {
        return this._apiManager.httpHelperMethod(
          HttpMethodsTypeEnum.POST_MULTIPART, `${AppApi.UPLOAD_FILE}?storageProvider=S3`, params,
          this._apiManager.Authorised_HttpOptions, false, true, undefined, files);
      }
    
    getAllFilesByServiceId(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, `${AppApi.GET_ALL_FILES_BY_SERVICE_ID}`, { ServiceId: id },
        this._apiManager.Authorised_HttpOptions, false, true);
    }
    
    archiveFile(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.DELETE, `${AppApi.ARCHIVE_FILE_REQUEST}`, { id: id },
        this._apiManager.Authorised_HttpOptions, true, true);
    }

    deleteFile(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.DELETE, `${AppApi.DELETE_FILE}`, { id: id },
        this._apiManager.Authorised_HttpOptions, true, true);
    }

    download(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, `${AppApi.DOWNLOAD_FILE_REQUEST}`, { id: id },
        this._apiManager.Blob_HttpOptions, true, true);
    }
    
    getPreviewUrl(fileId: string): string {
    var qs = {
        fileId: fileId,
        access_token: this._apiManager.Auth_Token
    }
    return AppApi.PREVIEW_FILE_REQUEST
        + "?" + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
    }

    getPreviewType(type: string) {
    if (type.match(/.(jpg|jpeg|png|gif)$/i)) return DocumentViewTypeEnum.IMG;
    else if (type.match(/pdf$/i)) return DocumentViewTypeEnum.PDF;
    else if (type.match(/mp4$/i)) return DocumentViewTypeEnum.VIDEO;
    else return DocumentViewTypeEnum.DOC;
    }

    getThumbnailUrl(serviceId: string): string {
        const qs = {
            serviceId: serviceId,
            access_token: this._apiManager.Auth_Token,
            // v: Math.random(),
        }
        return AppApi.PREVIEW_THUMBNAIL_REQUEST
            + '?' + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
    }
}
