import { Component, OnInit } from '@angular/core';
import { LANGUAGES } from 'app/utility';
import { SharedService } from 'app/utility/shared-services';

@Component({
  selector: 'app-terms-services',
  templateUrl: './terms-services.component.html',
  styleUrls: ['./terms-services.component.scss']
})
export class TermsServicesComponent implements OnInit {

  // Constant variables
  languages = LANGUAGES;

  selectedLanguage: string = LANGUAGES.English;

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  setLanguage(lang: string): void {
    this.selectedLanguage = lang;
    this.sharedService.setLanguageChange(lang);
  }

  isLangActive = (lang: string) => {
    return lang === this.selectedLanguage;
  };
}
