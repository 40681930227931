import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { concatAll, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { Reminder, ReminderResponse, RemindersService } from './reminders.service';
import * as moment from "moment";

class RemindersDataSource extends DataSource<Reminder> {

  private cached: Reminder[] = [];
  private readonly data$: Observable<Reminder[]>;
  private finished = false;
  static readonly pageSize = 7;

  readonly searching = {} as Reminder;
  readonly nothing = {} as Reminder;
  readonly first$: Observable<Reminder>;

  constructor(
    private readonly reminderService: RemindersService,
    private readonly since: Date
  ) {
    super();

    this.data$ = this.rangeToItems(of({ start: 0, end: 0 })).pipe(shareReplay(1));
    this.first$ = this.data$
      .pipe(
        map((list: Reminder[]) => list.find(_ => true)),
        map((a: Reminder) => a ? a : this.nothing),
        startWith(this.searching)
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<Reminder[]> {

    return merge(
      this.data$,
      this.rangeToItems(collectionViewer.viewChange)
    );
  }

  disconnect(/*collectionViewer: CollectionViewer*/): void {
  }

  private rangeToItems(ranges: Observable<ListRange>): Observable<Reminder[]> {
    return ranges
      .pipe(
        map(r => {
          return ({ start: this.cached.length, end: r.end + RemindersDataSource.pageSize } as ListRange)
        }),
        map((range: ListRange) => {
          return range.end > this.cached.length && !this.finished
            ? this.getItems(range)
              .pipe(
                map((items: Reminder[]) => {
                  if (items) this.cached.splice(range.start, range.end, ...items);
                  return this.cached;
                })
              )
            : of(this.cached)
        }
        ),
        concatAll()
      );
  }

  private getItems(range: ListRange): Observable<Reminder[]> {
    if (this.finished) return of([]);
    return this.reminderService.getReminders({
      since: this.since,
      start: range.start,
      end: range.end
    })
      .pipe(
        tap((result: ReminderResponse) => this.finished = result.end === result.start),
        map((result: ReminderResponse) => result.reminders),
        // Transform dates
        tap((reminders: Reminder[]) => reminders.map(reminder => reminder.date_trigger = moment.utc(reminder.date_trigger).toDate()))
      );
  }
}

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  readonly remindersDataSource$: Observable<RemindersDataSource>;
  allRead = false;
  constructor(public reminderService: RemindersService) {

    this.remindersDataSource$ = of(new RemindersDataSource(reminderService, new Date()));
  }

  ngOnInit() {

  }
  async markRead(reminder: Reminder) {
    if (reminder.read) {
      return;
    }
    await this.reminderService.mark(reminder.id);
    reminder.read = true;
  }
  async markAllRead() {
    await this.reminderService.mark(null, true);
    this.allRead = true;
  }
  async delete(reminder: Reminder) {
    await this.reminderService.delete(reminder.id, reminder.read);
    reminder.deleted = true;
  }
}
