import { NgModule } from '@angular/core';
import { HasRoleDirective } from './hasRole.directive';

@NgModule({
    declarations: [
        HasRoleDirective,
    ],
    imports     : [],
    exports     : [
        HasRoleDirective,
    ]
})
export class DirectivesModule
{
}
