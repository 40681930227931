import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { FileQueueObject } from "app/private-modules/modules/my-file-module/services/file-uploader.service";
import { FormBaseComponent } from "../form-base/form-base.component";
import { SharedFileUploaderService } from "app/utility/shared-services/shared-file-uploader.service";
import { appLogger } from "app/utility/shared-functions";

@Component({
    selector: "app-files-upload",
    templateUrl: "./files-upload.component.html",
    styleUrls: ["./files-upload.component.scss"],
})
export class UploadFilesComponent
    extends FormBaseComponent
    implements OnInit, OnDestroy {

    uploadFilesForm: FormGroup;
    @Input() parentFolderId: string;
    @Input() serviceId: string;
    @Input() appId: string;

    @ViewChild('upload') fileInput;
    queue: Observable<FileQueueObject[]>;
    refresh = false;
    refreshSubscription: Subscription;
    constructor(
        public dialogRef: MatDialogRef<UploadFilesComponent>,
        fb: FormBuilder,
        public uploader: SharedFileUploaderService
    ) {
        super(fb);
    }

    ngOnInit(): void {
        this.queue = this.uploader.queue;
        this.refreshSubscription = this.uploader.refresh$$.subscribe(refresh => 
            this.refresh = refresh);
        this.initialize();

        appLogger("ServiceId: " + this.serviceId);
        appLogger("AppId: " + this.appId);
    }
    ngOnDestroy() {
        this.refreshSubscription?.unsubscribe();
    }
    initialize = () => {
        this.createUploadFileDetailsForm();
    };

    createUploadFileDetailsForm = () => {
        this.uploadFilesForm = this.createForm({
            attachment: ["", []],
        });
    };

    onUploadFileDetailsSubmit = async (form: FormGroup) => {
        await this.uploader.uploadAll();
        this.onCloseDialog();
    };

    onCloseDialog = () => {
        this.uploader.clearQueue();
        this.dialogRef.close(this.refresh);
    };

    onBrowseFile() {
        this.fileInput.nativeElement.click();
    }

    addToQueue() {
        const fileBrowser = this.fileInput.nativeElement;
        this.uploader.addToQueue(fileBrowser.files, this.parentFolderId, this.serviceId, this.appId);
    }

}
