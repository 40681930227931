<div class="idea-box-white attachment-box">
    <div *ngFor="let i of [1,2,3]">
        <img src="assets/images/attachment-idea.png" alt="">

        <a (click)="onDeleteComment()" matTooltip='{{ "BOTTON.DELETE" | translate }}'>
            <span class="material-icons-outlined">
                delete
            </span>
        </a>
    </div>
</div>