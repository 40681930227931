<div class="modal-view upload-file file-detail-container">
    <div class="row">
        <div class="col-md-12 col-xs-12 PLR-0">
            <h2>{{ "MY_FILE.UPLOAD_FILE" | translate }}</h2>
        </div>
    </div>

    <form [formGroup]="uploadFilesForm" (submit)="onUploadFileDetailsSubmit(uploadFilesForm)">
        <div class="row">
            <div class="col-md-12 col-xs-12 PLR-0">
                <div class="form-control attachment-form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{"IDEA.SELECT_ATTACHMENT" | translate}}</mat-label>
                        <input matInput formControlName="attachment" readonly (click)="onBrowseFile()"
                            placeholder='{{"IDEA.SELECT_ATTACHMENT" | translate}}'
                            autocomplete="off" />
                        <input multiple type="file" hidden #upload (change)="addToQueue()" />

                        <span class="material-icons"> attachment </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let item of queue | async" class="file-progress single-file">
                <div class="file-icon" style="width: 50px">
                    <mat-icon>description</mat-icon>
                </div>
                <div class="info">
                    <h4 class="name">
                        {{ item?.file?.name }}
                    </h4>
                    <p class="size">
                        {{ item?.file?.size | filesize }}
                    </p>
                    <div class="progress-cont">
                        <div class="progress" [style.width]="item.progress + '%'">
                        </div>
                    </div>
                </div>
                <div class="cancel">
                    <a matTooltip="Upload file" (click)="item.upload()" *ngIf="item.isUploadable()">
                        <mat-icon>upload</mat-icon>
                    </a>
                    <a matTooltip="Cancel upload" (click)="item.cancel()" *ngIf="item.inProgress()">
                        <mat-icon>cancel</mat-icon>
                    </a>
                    <a matTooltip="Remove from queue" (click)="item.remove()" *ngIf="!item.inProgress()">
                        <mat-icon>delete</mat-icon>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-xs-12 PLR-0">
                <button type="submit" mat-flat-button color="primary">
                    {{ "BOTTON.UPLOAD" | translate }}
                </button>
                <button (click)="onCloseDialog()" type="button" mat-flat-button color="secondary" class="ML-2">
                    {{ "BOTTON.CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>