<div class="auth">
    <div class="auth__left">
        <img *ngIf="isLangActive(languages.English)" src="../../../../../assets/images/auth/logo-en.png" />
        <img *ngIf="isLangActive(languages.German)" src="../../../../../assets/images/auth/logo-de.png" />
        <img *ngIf="isLangActive(languages.Turkish)" src="../../../../../assets/images/auth/logo-tr.png" />
    </div>
    <div class="auth__right">
        <div class="auth__right-top-text">
            <button 
                type="button"
                (click)="onRegisterCompany()"
                mat-raised-button
                color="accent"
                style="margin-top: 5px !important"
            > 
                <!-- <img src="assets/images/google.png" />  -->
                {{ "LOGIN.REGISTERCOMPANY" | translate}}
            </button>
        </div>

        <h2>{{ "LOGIN.SIGNIN" | translate }}</h2>
        <p>
            {{ "LOGIN.ALREADY_ACCOUNT" | translate }}. {{ "LOGIN.DONTHAVEACCOUNT" | translate }}
                <a (click)="onSignUp()">{{ "LOGIN.SIGNUP" | translate }}</a>
        </p>

        <form name="loginForm" [formGroup]="loginForm" (submit)="onSubmitLoginForm(loginForm)" novalidate>
            <div class="row">
                <div class="col-md-12 PLR-0">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
                        <input matInput placeholder="{{'COMMON.EMAIL_PLACEHOLDER' | translate}}" formControlName="email"
                            autocomplete="off" />
                        <span matSuffix class="material-icons"> person </span>
                        <mat-error *ngIf="loginForm.get('email').hasError('required')">
                            {{"VALIDATION.EMAIL_REQUIRED" | translate}}
                        </mat-error>
                        <mat-error *ngIf="
                                !loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')
                            ">
                            {{"VALIDATION.EMAIL_REQUIRED" | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 PLR-0">
                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "LOGIN.PASSWORD" | translate
                            }}</mat-label>
                        <input matInput placeholder="{{
                                'COMMON.PASSWORD_PLACEHOLDER'
                                    | translate
                            }}" type="password" formControlName="password" autocomplete="off" />
                        <mat-icon matSuffix class="material-icons">vpn_key
                        </mat-icon>
                        <mat-error>{{"VALIDATION.PASSWORD_REQUIRED" | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row remember-forgot-password">
                <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                    <mat-checkbox class="remember-me" aria-label="Remember Me" [formControl]="rememberMeFormControl">
                        {{ "LOGIN.REMEMBERME" | translate }}
                    </mat-checkbox>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 text-right PR-0">
                    <a class="forgot-password" (click)="onForgotPassword()">
                        {{ "LOGIN.FORGOTPASSWORD" | translate }}
                    </a>
                </div>
            </div>

            <button mat-raised-button 
                color="accent" 
                class="submit-button" 
                aria-label="LOGIN"
                style="margin-top: 15px !important"
                [disabled]="loginForm.invalid">
                {{ "LOGIN.SIGN_IN" | translate }}
            </button>

            <div class="auth__another-way">
                <div class="signin-border">
                    <span class="sign-in">{{ "LOGIN.OR_SIGN_IN_WITH" | translate }}</span>
                </div>
                <button type="button" 
                    (click)="$event.preventDefault(); loginWithGoogle()" 
                    mat-stroked-button
                    color="primary"
                    style="margin-top: 5px !important"
                    > 
                    <img src="assets/images/google.png" /> 
                    {{ "LOGIN.SIGN_IN_WITH_GOOGLE" | translate}}
                </button>
            </div>
        </form>



        <ul class="auth__language">
            <li>
                <span class="material-icons"> language </span>
                <label>{{ "LOGIN.LANGUAGE" | translate }}:</label>
            </li>
            <li (click)="setLanguage(languages.English)">
                <a [ngClass]="{'active' : isLangActive(languages.English)}">
                    <img src="assets/icons/flags/us.png" /><span>English</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.Turkish)">
                <a [ngClass]="{'active' : isLangActive(languages.Turkish)}">
                    <img src="assets/icons/flags/tr.png" /><span>Türkçe</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.German)">
                <a [ngClass]="{'active' : isLangActive(languages.German)}">
                    <img src="assets/icons/flags/de.png" /><span>Deutsch</span>
                </a>
            </li>
        </ul>
    </div>
</div>