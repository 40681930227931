<form [formGroup]="surveyFilterForm" (ngSubmit)="onSurveyFilterSubmit(surveyFilterForm)">
    <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="form-control">
              <mat-form-field appearance="outline" class="w-full">
                  <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.ENTER_SURVEY_NAME" | translate }}</mat-label>
                  <input
                      matInput
                      autocomplete="off"
                      formControlName="survey_name"
                  />
              </mat-form-field>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-control">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.SELECT_SURVEY_TYPE" | translate }}</mat-label>
                  <mat-select formControlName="srv_type">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let item of surveyTypes" value="{{item.id}}">{{item.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-control">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.SELECT_ACCOUNT" | translate }}</mat-label>
                  <mat-select formControlName="account">
                      <mat-option value="">All</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-control">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.SELECT_ORGANIZATION" | translate }}</mat-label>
                  <mat-select formControlName="organization" (selectionChange)="onChangeOrganization($event.value)">
                      <mat-option value="">All</mat-option>
                      <mat-option value="{{item.id}}" *ngFor="let item of organizations">{{item.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-control">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.SELECT_LOCATION" | translate }}</mat-label>
                    <mat-select formControlName="location">
                        <mat-option value="">All</mat-option>
                        <mat-option value="{{item.id}}" *ngFor="let item of locations">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            
        </div>
        <div class="col-md-6 col-sm-6">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ 'SURVEY_MODULES.PLACEHOLDER.START_DATE' | translate }}</mat-label>
                <input
                    matInput
                    autocomplete="off"
                    [matDatepicker]="dateStartPicker"
                    formControlName="start_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dateStartPicker">
                </mat-datepicker-toggle>
                <mat-datepicker #dateStartPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-6">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>{{ 'SURVEY_MODULES.PLACEHOLDER.END_DATE' | translate }}</mat-label>
                <input
                    matInput
                    autocomplete="off"
                    [matDatepicker]="dateEndPicker"
                    formControlName="end_date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dateEndPicker">
                </mat-datepicker-toggle>
                <mat-datepicker #dateEndPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-control">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.CREATED_BY" | translate }}</mat-label>
                    <mat-select formControlName="createdby">
                        <mat-option value="">All</mat-option>
                        <mat-option value="{{item.id}}" *ngFor="let item of users">{{item.first_name}} {{item.last_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-control">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.MODIFIED_BY" | translate }}</mat-label>
                      <mat-select formControlName="modifiedby">
                          <mat-option value="">All</mat-option>
                          
                      </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-control">
                <mat-checkbox>
                    {{"SURVEY_MODULES.ONLY_MY_SURVEY" | translate | uppercase}}
                </mat-checkbox>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            
        </div>
        <div class="col-md-12 col-sm-12 mt-24">
            <button type="submit" mat-flat-button color="primary" translate>
                {{"BOTTON.APPLY" | translate | uppercase}}
            </button>
            <button type="button" mat-flat-button color="secondary" translate>
                {{"BOTTON.RESET" | translate | uppercase}}
            </button>
        </div>
    </div>

</form>