import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {locale as english} from '../i18n/en';
import {locale as turkish} from '../i18n/tr';
import {locale as german} from '../i18n/de';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {fuseAnimations} from '@fuse/animations';
import {FormBaseComponent, LANGUAGES, MY_FORMATS, removeEmptyFields, RouteConstants} from 'app/utility';
import {SharedService} from '../../../../../utility/shared-services';
import {RegisterService} from '../../services';
import {CountryLanguagesModel, CountryModel, StateModel, TimezoneModel} from '../../models';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';

export enum RegisterStepsViewEnum {
    LOGIN_INFORMATION = 'login-information',
    PROFILE_INFORMATION = 'profile-information',
    CONTACT_INFORMATION = 'contact-information',
    ADDRESS_INFORMATION = 'address-information',
    SIGN_UP_SUCCESSFULLY = 'signup-successfully'
}

export enum ShowVerifyButtonEnum {
    SUCCESS,
    UNSUCCESSFUL,
    DO_NOT_SHOW
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ],
})
export class RegisterComponent extends FormBaseComponent implements OnInit {
    // Constant variables
    languagesArray = LANGUAGES;
    registerStepsViewEnum = RegisterStepsViewEnum;
    isShowView = this.registerStepsViewEnum.LOGIN_INFORMATION;
    isSuccessVerify = ShowVerifyButtonEnum.DO_NOT_SHOW;
    showVerifyButtonEnum = ShowVerifyButtonEnum;

    // Form variables
    termsFormControl: FormControl = new FormControl(false);
    loginFormGroup: FormGroup;
    profileFormGroup: FormGroup;
    contactFormGroup: FormGroup;
    addressFormGroup: FormGroup;

    // Data variables
    selectedLanguage: string = LANGUAGES.English;
    countryLanguagesList: CountryLanguagesModel[] = [];
    searchCountryLanguageList: CountryLanguagesModel[] = [];
    timezoneList: TimezoneModel[] = [];
    searchTimezoneList: TimezoneModel[] = [];
    countryList: CountryModel[] = [];
    searchCountryList: CountryModel[] = [];
    stateList: StateModel[] = [];
    searchStateList: StateModel[] = [];
    otpTimeOut: number;
    duration: number;
    interval: any;
    clockDisplay: string;
    paramCode = '';
    paramEmail = '';
    paramReferrer = '';

    // State variables
    showResendOTPButton = true;

    constructor(
        fb: FormBuilder,
        private router: Router,
        private sharedService: SharedService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private registerService: RegisterService,
        private route: ActivatedRoute
    ) {
        super(fb);

        // Set the language defaults
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, german);
    }

    ngOnInit(): void {
        this.selectedLanguage = this.sharedService.getLanguageValue() ? this.sharedService.getLanguageValue() : LANGUAGES.English;
        this.route.queryParams.subscribe(params => {
            this.paramCode = params['code'];
            this.paramEmail = params['email'];
            this.paramReferrer = params['referrer'];
        });
        this.createLoginForm();
        this.createProfileForm();
        this.createContactForm();
        this.createAddressForm();
        this.getInitialData();
        this.getSearchLanguages();
        this.getSearchTimeZone();
        this.getSearchCountry();
        this.getSearchState();
        this.getTenantCodeChanges();
        if (this.paramCode) {
            this.onClickVerify();
        }
    }

    // Initialisation methods
    createLoginForm = () => {
        this.loginFormGroup = this.createForm({
                email: [this.paramEmail, Validators.email],
                password: ['', [
                    Validators.required
                    , Validators.minLength(8)
                ]],
                passwordConfirm: ['', [Validators.required]],
                tenant: [this.paramCode, Validators.required],
                tenant_id: ['', Validators.required]
            }
            , {
                validator: this.passwordValidator
            }
        );
    };

    createProfileForm = () => {
        this.profileFormGroup = this.createForm({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            date_birth: ['1991-01-01', Validators.required],
            gender: ['M']
        });
    };

    createContactForm = () => {
        this.contactFormGroup = this.createForm({
            phoneNumber: [''],
            language: [''],
            languageInput: [''],
            lang: [''],
            timezoneInput: [''],
            timezone: [''],
            timezone_id: ['']
        });
    };

    createAddressForm = () => {
        this.addressFormGroup = this.createForm({
            address_street: [''],
            state_province_id: [''],
            stateInput: [''],
            state: [''],
            address_city: [''],
            postal_code: [''],
            country: [''],
            country_id: [''],
            countryInput: [''],
        });
    };

    getInitialData = () => {
        forkJoin(
            this.getLanguagesListApiCall(),
            this.getTimeZoneListApiCall(),
            this.getCountryListApiCall()
        ).subscribe(allResponse => {
            this.countryLanguagesList = allResponse[0]['data'];
            this.searchCountryLanguageList = this.countryLanguagesList;
            this.timezoneList = allResponse[1]['data'];
            this.searchTimezoneList = this.timezoneList;
            this.countryList = allResponse[2]['data'];
            this.searchCountryList = this.countryList;
        });
    };

    getSearchLanguages = () => {
        this.contactFormControls['languageInput']
            .valueChanges.subscribe((value) => {
            if (value === '' || this.contactFormControls['language'].value !== value) {
                this.contactFormControls['lang'].reset();
            }
            if (value !== '' && this.contactFormControls['language'].value !== value) {
                this.contactFormControls['languageInput'].setErrors({notValidName: true});
            } else {
                this.contactFormControls['languageInput'].setErrors(null);
            }
            if (value === '') {
                this.searchCountryLanguageList = this.countryLanguagesList;
            } else {
                this.getFilterLanguageData(value);
            }
        });
    };

    getSearchTimeZone = () => {
        this.contactFormControls['timezoneInput']
            .valueChanges.subscribe((value) => {
            if (value === '' || this.contactFormControls['timezone'].value !== value) {
                this.contactFormControls['timezone_id'].reset();
            }
            if (value !== '' && this.contactFormControls['timezone'].value !== value) {
                this.contactFormControls['timezoneInput'].setErrors({notValidName: true});
            } else {
                this.contactFormControls['timezoneInput'].setErrors(null);
            }
            if (value === '') {
                this.searchTimezoneList = this.timezoneList;
            } else {
                this.getFilterTimeZoneData(value);
            }
        });
    };

    getSearchCountry = () => {
        this.addressFormControls['countryInput']
            .valueChanges.subscribe((value) => {
            if (value === '' || this.addressFormControls['country'].value !== value) {
                this.addressFormControls['country_id'].reset();
            }
            if (value !== '' && this.addressFormControls['country'].value !== value) {
                this.addressFormControls['countryInput'].setErrors({notValidName: true});
            } else {
                this.addressFormControls['countryInput'].setErrors(null);
            }
            if (value === '') {
                this.searchCountryList = this.countryList;
            } else {
                this.getFilterCountryData(value);
            }
        });
    };

    getSearchState = () => {
        this.addressFormControls['stateInput']
            .valueChanges.subscribe((value) => {
            if (value === '' || this.addressFormControls['state'].value !== value) {
                this.addressFormControls['state_province_id'].reset();
            }
            if (value !== '' && this.addressFormControls['state'].value !== value) {
                this.addressFormControls['stateInput'].setErrors({notValidName: true});
            } else {
                this.addressFormControls['stateInput'].setErrors(null);
            }
            if (value === '') {
                this.searchStateList = this.stateList;
            } else {
                this.getFilterStateData(value);
            }
        });
    };

    getTenantCodeChanges = () => {
        this.loginFormControls['tenant'].valueChanges.subscribe(() => {
            if (this.isSuccessVerify === ShowVerifyButtonEnum.UNSUCCESSFUL) {
                this.isSuccessVerify = ShowVerifyButtonEnum.DO_NOT_SHOW;
            }
        });
    };

    getFilterLanguageData = (value: string) => {
        this.searchCountryLanguageList = this.countryLanguagesList.filter(lang => lang.description.toLowerCase().includes(value.toLowerCase()));
    };

    getFilterTimeZoneData = (value: string) => {
        this.searchTimezoneList = this.timezoneList.filter(zone => zone.name.toLowerCase().includes(value.toLowerCase()));
    };

    getFilterCountryData = (value: string) => {
        this.searchCountryList = this.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
    };

    getFilterStateData = (value: string) => {
        this.searchStateList = this.stateList.filter(state => state.name.toLowerCase().includes(value.toLowerCase()));
    };

    passwordValidator(loginFormGroup: FormGroup): any {
        const password = loginFormGroup.controls.password;
        const repeatPassword = loginFormGroup.controls.passwordConfirm;
        if (repeatPassword.value.length <= 0) {
            return null;
        }
        if (password.value.length === 0) {
            return null;
        }
        if (repeatPassword.value !== password.value) {
            repeatPassword.setErrors({incorrect: true});
            return {
                doesMatchPassword: true
            };
        } else {
            repeatPassword.setErrors(null);
        }
        return null;
    }

    // Api calls
    getTenantDetailsByDomainApiCall = (params: unknown) => {
        return this.registerService.getTenantDetailsByDomain(params);
    };

    verifyTenantApiCall = (params: unknown) => {
        return this.registerService.verifyTenant(params);
    };

    getLanguagesListApiCall = () => {
        return this.registerService.getLanguageList();
    };

    getTimeZoneListApiCall = () => {
        return this.registerService.getTimeZoneList();
    };

    getCountryListApiCall = () => {
        return this.registerService.getCountryList();
    };

    getStateListApiCall = () => {
        return this.registerService.getStateList({countryId: this.addressFormControls['country_id'].value});
    };

    registerUserApiCall = (params: unknown) => {
        return this.registerService.registerUser(params);
    };

    resendEmailConfirmationApiCall = (params: unknown) => {
        return this.registerService.resendEmailConfirmation(params);
    };

    // Page events
    onEmailFocusOut = () => {
        const domain = this.loginFormControls['email'].value.split('@').pop();
        if (domain) {
            this.getTenantDetailsByDomainApiCall({name: domain}).subscribe((response) => {
                if (response && response['data']['id']) {
                    this.isSuccessVerify = ShowVerifyButtonEnum.SUCCESS;
                    this.loginFormControls['tenant'].setValue(response['data']['name']);
                    this.loginFormControls['tenant_id'].setValue(response['data']['id']);
                    this.loginFormControls['tenant'].disable();
                    this.loginFormControls['tenant'].updateValueAndValidity();
                } else {
                    this.isSuccessVerify = ShowVerifyButtonEnum.DO_NOT_SHOW;
                    this.loginFormControls['tenant'].setValue('');
                    this.loginFormControls['tenant_id'].setValue('');
                    this.loginFormControls['tenant'].enable();
                    this.loginFormControls['tenant'].updateValueAndValidity();
                }
            });
        } else {
            this.loginFormControls['tenant'].enable();
            this.loginFormControls['tenant'].updateValueAndValidity();
        }
    };

    onLogin = () => {
        this.router.navigate(['/' + RouteConstants.LOGIN]);
    };

    onRegisterViewChange = (view: RegisterStepsViewEnum) => {
        switch (view) {
            case this.registerStepsViewEnum.LOGIN_INFORMATION:
                this.isShowView = view;
                break;
            case this.registerStepsViewEnum.PROFILE_INFORMATION:
                if (this.loginFormGroup.valid) {
                    this.isShowView = view;
                }
                break;
            case this.registerStepsViewEnum.CONTACT_INFORMATION:
                if (this.loginFormGroup.valid && this.profileFormGroup.valid) {
                    this.isShowView = view;
                }
                break;
            case this.registerStepsViewEnum.ADDRESS_INFORMATION:
                if (this.loginFormGroup.valid && this.profileFormGroup.valid && this.contactFormGroup.valid) {
                    this.isShowView = view;
                }
                break;
        }
    };

    onClickVerify = () => {
        this.verifyTenantApiCall({code: this.loginFormControls['tenant'].value}).subscribe((response) => {
            if (response && response['data']) {
                this.isSuccessVerify = ShowVerifyButtonEnum.SUCCESS;
                this.loginFormControls['tenant_id'].setValue(response['data']['id']);
                this.loginFormControls['tenant'].setValue(response['data']['name']);
                this.loginFormControls['tenant'].disable();
                this.loginFormControls['tenant'].updateValueAndValidity();
            } else {
                this.isSuccessVerify = ShowVerifyButtonEnum.UNSUCCESSFUL;
            }
        }, () => {
            this.isSuccessVerify = ShowVerifyButtonEnum.UNSUCCESSFUL;
        });
    };

    changeLanguage(lang: CountryLanguagesModel, event: any) {
        if (lang instanceof Object && lang.code && event.isUserInput) {
            this.contactFormControls['language'].setValue(lang.description);
            this.contactFormControls['lang'].setValue(lang.code);
        }
    }

    changeTimeZone(zone: TimezoneModel, event: any) {
        if (zone instanceof Object && zone.id && event.isUserInput) {
            this.contactFormControls['timezone'].setValue(zone.name);
            this.contactFormControls['timezone_id'].setValue(zone.id);
        }
    }

    changeCountry(country: CountryModel, event: any) {
        if (country instanceof Object && country.id && event.isUserInput) {
            this.addressFormControls['country'].setValue(country.name);
            this.addressFormControls['country_id'].setValue(country.id);
            this.getStateList();
        }
    }

    changeState(state: StateModel, event: any) {
        if (state instanceof Object && state.id && event.isUserInput) {
            this.addressFormControls['state'].setValue(state.name);
            this.addressFormControls['state_province_id'].setValue(state.id);
        }
    }

    getStateList = () => {
        this.getStateListApiCall().subscribe((response) => {
            this.stateList = response['data'];
            this.searchStateList = this.stateList;
        });
    };

    onCLickRegistration = (form: FormGroup) => {
        if (this.onSubmit(form) && this.termsFormControl.value) {
            const combineValues = {
                ...this.addressFormGroup.value, ...this.contactFormGroup.value,
                ...this.profileFormGroup.value, ...this.loginFormGroup.getRawValue()
            };
            const dateOfBirth = new Date(combineValues['date_birth']);
            combineValues['date_birth'] = moment(dateOfBirth).format('YYYY-MM-DD');
            const params = removeEmptyFields(combineValues);
            params['assigned_user_id'] = this.paramReferrer;
            this.registerUserApiCall(params).subscribe((response) => {
                this.isShowView = this.registerStepsViewEnum.SIGN_UP_SUCCESSFULLY;
                this.setTimerValue();
            });
        }
    };

    onResendOTP() {
        const params = {email: encodeURIComponent(this.loginFormControls['email'].value), lang: this.selectedLanguage};
        this.resendEmailConfirmationApiCall(params).subscribe(res => {
            this.showResendOTPButton = false;
            this.setTimerValue();
        });
    }

    setTimerValue = () => {
        this.otpTimeOut = 3; // set 3 minute timeout
        if (this.otpTimeOut > 0) {
            this.duration = 60 * +this.otpTimeOut;
            this.showResendOTPButton = false;
            this.startTimer(this.duration);
        } else {
            this.showResendOTPButton = true;
        }
    };

    startTimer(duration: number) {
        if (duration > 0) {
            let timer: number = +duration;
            let minutes: any;
            let seconds: any;
            const self = this;
            self.interval = setInterval(function () {
                if (timer < 0) {
                    clearInterval(self.interval);
                    self.showResendOTPButton = true;
                    self.clockDisplay = '';
                }
                minutes = Math.floor(timer / 60);
                seconds = Math.floor(timer % 60);
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                self.clockDisplay = minutes + ':' + seconds;
                if (--timer < 0) {
                    self.showResendOTPButton = true;
                    self.clockDisplay = '';
                    clearInterval(self.interval);
                }
            }, 1000);
        }
    }

    setLanguage(lang): void {
        this.selectedLanguage = lang;
        this.sharedService.setLanguageChange(lang);
    }

    // Helper methods
    isLangActive = (lang: string) => {
        return lang === this.selectedLanguage;
    };

    get loginFormControls() {
        return this.loginFormGroup.controls;
    }

    get contactFormControls() {
        return this.contactFormGroup.controls;
    }

    get addressFormControls() {
        return this.addressFormGroup.controls;
    }

    showVerifyButton = () => {
        let showButton = false;
        if (this.loginFormControls['email'].value && this.loginFormControls['tenant'].value && !this.loginFormControls['tenant_id'].value &&
            this.isSuccessVerify === ShowVerifyButtonEnum.DO_NOT_SHOW) {
            showButton = true;
        }
        return showButton;
    };
}
