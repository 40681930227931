<div class="modal confirmation-dialog">
    <div class="modal__header">
        <div class="confirmation-dialog__icon">
      <span class="material-icons">
        cancel
      </span>
        </div>
        <h3>{{title}}</h3>
    </div>

    <div class="modal__body">
        <p>{{message}}</p>
        <p>{{actionMessage}}</p>
    </div>

    <div class="modal__footer">
        <button type="submit" (click)="onCloseDialog()" mat-flat-button color="primary">Yes</button>
        <button (click)="onCloseDialog(false)" type="button" mat-flat-button color="secondary">No</button>
    </div>
</div>