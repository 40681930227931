import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssignUsersDialogComponent } from 'app/private-modules/modules/task-management-module/components';
import { ProjectDotCoreService } from 'app/private-modules/modules/task-management-module/services/project.dotcore.service';
import { TeamService } from 'app/shared/services/team.service';
import { ConfirmationDialogComponent } from 'app/utility';
import { HttpStatus, RouteConstants } from '../../shared-constants';

enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc'
}
@Component({
  selector: 'app-general-team-build',
  templateUrl: './general-team-build.component.html',
  styleUrls: ['./general-team-build.component.scss']
})
export class GeneralTeamBuildComponent implements OnInit {
  @Input() userLookups;
  @Input() colorCodeList;
  @Input() teamLookups;
  @Input() isAddTaskMember;
  @Input() taskCardData;

  @Output() onClose = new EventEmitter<any>();

  nameCtrl: FormControl = new FormControl("");
  colorCtrl: FormControl = new FormControl("");
  descriptionCtrl: FormControl = new FormControl("");
  memberNameCtrl: FormControl = new FormControl("");

  userSearchCtrl: FormControl = new FormControl('');
  teamSearchCtrl: FormControl = new FormControl('');

  filteredUserLookups = [];
  filteredTeamLookups = [];

  teamId = null;
  isAllSeleted = false;

  teamMembers = [];
  teamDetail = null;

  isShowUserList = true;
  isShowTeamList = true;
  isEditUserName = false;
  mediaQuery = window.matchMedia("(min-width: 320px) and (max-width: 767px)");
  userSortOrder = SORT_ORDER.ASC;
  selectedIndex = null;

  constructor(
    private _teamService: TeamService,
    public dialog: MatDialog,
    private _router: Router,
    private _projectService: ProjectDotCoreService,
    private _location: Location) { }

  ngOnInit(): void {
    this.initialize();
    this.teamDetail = this._teamService.getTeamDetail();
    if (this.teamDetail) {
      this.setDataToEditTeam(this.teamDetail);
    }
    if (this.mediaQuery.matches) {
      this.isShowUserList = false;
      this.isShowTeamList = false;
    }
  }

  setDataToEditTeam = (teamData) => {
    this.teamId = teamData.id;
    this.nameCtrl.setValue(teamData.name);
    this.descriptionCtrl.setValue(teamData.description);
    this.colorCtrl.setValue(teamData.color);
    this.getAllTeamMembers();
  }

  initialize = () => {
    this.userLookups.forEach(user => user['checked'] = false);
    this.filteredTeamLookups = this.teamLookups;
    this.filteredUserLookups = this.userLookups;
    this.userSearchCtrl.valueChanges.subscribe(() => {
      this.searchUser();
    })
    this.teamSearchCtrl.valueChanges.subscribe(() => {
      this.searchTeam();
    })
  }

  searchUser = () => {
    if (this.userSearchCtrl.value && this.userSearchCtrl.value != '') {
      this.filteredUserLookups = this.userLookups.filter(elem => {
        if ((elem.name.toLowerCase()).includes(this.userSearchCtrl.value.toLowerCase())) {
          return elem;
        }
      })
    } else {
      this.filteredUserLookups = this.userLookups;
    }
  }

  searchTeam = () => {
    if (this.teamSearchCtrl.value && this.teamSearchCtrl.value != '') {
      this.filteredTeamLookups = this.teamLookups.filter(elem => {
        if ((elem.name.toLowerCase()).includes(this.teamSearchCtrl.value.toLowerCase())) {
          return elem;
        }
      })
    } else {
      this.filteredTeamLookups = this.teamLookups;
    }
  }

  onSubmitTeamBuild = () => {
    if (this.nameCtrl.value) {
      const params = this.prepareRequestParams();
      if (this.teamId) {
        params['id'] = this.teamId;
      }
      this._teamService.addUpdateTeam(params, this.teamId).subscribe(response => {
        if (response && response.status === HttpStatus.SUCCESS) {
          if (!this.teamId) {
            this.teamId = response.data;
          }
          this.getAllTeamMembers();
          this.getTeamLookups();
        }
      })
    }
  }

  prepareRequestParams = () => {
    const projectData = this._projectService.getProjectData();
    return {
      name: this.nameCtrl.value,
      userId: null,
      app_id: "8008",
      service_id: projectData ? projectData['id'] : null,
      assigned_user_id: null,
      description: this.descriptionCtrl.value,
      type: null,
      code: null,
      icon: null,
      color: this.colorCtrl.value ? this.colorCtrl.value : null,
      sequence: 0,
      priority: 0,
      status: 1,
      access_modifier: 0
    }
  }

  addAsMemberToTeam = (user) => {
    let params = this.prepareRequestParamsForTeamMember([user]);
    params = params[0];
    this._teamService.addTeamMember(params).subscribe(response => {
      if (response && response.status === HttpStatus.SUCCESS) {
        this.getAllTeamMembers();
      }
    })
  }

  addMembersToTeam = () => {
    const selectedMember = this.userLookups.filter(user => user.checked);
    const params = this.prepareRequestParamsForTeamMember(selectedMember);
    this._teamService.addTeamMemberList(params).subscribe(response => {
      if (response && response.status === HttpStatus.SUCCESS) {
        this.getAllTeamMembers();
      }
    })
  }

  prepareRequestParamsForTeamMember = (selectedMember) => {
    selectedMember.forEach(user => {
      user['team_id'] = this.teamId;
      user['assigned_user_id'] = null;
      user['description'] = '';
      user['type'] = null;
      user['code'] = null;
      user['icon'] = user?.profile_image_url;
      user['color'] = null;
      user['sequence'] = 0;
      user['priority'] = 0;
      user['status'] = 1;
      user['access_modifier'] = 1;

      delete (user['checked']);
      delete (user['email']);
      delete (user['first_name']);
      delete (user['last_name']);
      delete (user['phoneNumber']);
      delete (user['profile_image_url']);
      delete (user['roles']);
      delete (user['status_user']);
    })

    return selectedMember;
  }

  //page event
  onShowUsersResponsive = () => {
    if (this.mediaQuery.matches) {
      this.isShowUserList = !this.isShowUserList;
    }
  }

  onShowTeamResponsive = () => {
    if (this.mediaQuery.matches) {
      this.isShowTeamList = !this.isShowTeamList;
    }
  }

  onDeleteTeam = (team) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: {
        message: `Are you sure want to Delete Team?`,
        actionMessage:
          "NOTE: Delete team will be remove all users from team.",
        title: `Are you sure?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result['submit'] === true) {
        this._teamService.archiveTeam(team.id).subscribe(response => {
          if (response && response['status'] == HttpStatus.SUCCESS) {
            const index = this.teamLookups.findIndex(elem => elem.id === team.id);
            this.teamLookups.splice(index, 1);
            this.filteredTeamLookups = this.teamLookups;
          }
        })
      }
    });
  };

  onAssignUsersDialogOpen = (team) => {
    // const AssignUserDialogRef = this.dialog.open(AssignUsersDialogComponent, {
    //   width: "750px",
    //   data: { team: team, userLookups: this.userLookups }
    // });

    // AssignUserDialogRef.afterClosed().subscribe((result) => { });
  };

  onEditUserName = (member, index) => {
    this.memberNameCtrl.setValue(member.name);
    this.isEditUserName = true;
    this.selectedIndex = index;
  }

  onUpdateName = (member?) => {
    if (member && member?.name != this.memberNameCtrl.value) {
      const params = member;
      params['name'] = this.memberNameCtrl.value;
      delete (params['date_entered']);
      delete (params['date_modified']);
      this._teamService.updateTeamMember(params).subscribe(() => {
        this.getAllTeamMembers();
      })
    }
    this.isEditUserName = false;
    this.selectedIndex = null;
  }

  getAllTeamMembers = () => {
    this._teamService.getTeamMembersByTeamId(this.teamId).subscribe(response => {
      this.teamMembers = response && response['data'] ? response['data'] : [];
      if (this.teamMembers.length) {
        this.teamMembers = this.teamMembers.filter(elem => elem.deleted === 0);
      }
    })
  }

  getNameCharacter = (user): string => {
    let name = '';
    if (user && user.first_name && user.last_name) {
      name = user.first_name.charAt(0) + user.last_name.charAt(0)
    } else if (user && user.name) {
      const names = user.name.split(' ');
      if (names[0] && names[1]) {
        name = names[0].charAt(0) + names[1].charAt(0);
      } else {
          name = names[0].charAt(0)
      }
    } else if (user && user.email) {
      name = user.email.substring(0, 2);
    }
    return name;
  }

  selectAllUser = () => {
    this.isAllSeleted = !this.isAllSeleted;
    this.userLookups.forEach(user => user['checked'] = this.isAllSeleted ? true : false);
  }

  updateAllSelect = () => {
    this.isAllSeleted = this.userLookups !== null && this.userLookups.every(user => user.checked);
  }

  selectUser = (isChecked: boolean, index: number) => {
    this.userLookups[index]['checked'] = !isChecked;
    this.updateAllSelect();
  }

  onEditTeam = (team) => {
    this._teamService.setTeamDetail(team);
    this.teamDetail = team;
    this.setDataToEditTeam(team);
    if (!this.isAddTaskMember) {
      this._location.replaceState(`/${RouteConstants.TASK_TEAM_BUILD_ROUTE}/${team.id}`);
    }
  }

  onDeleteTeamMember = (user) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: {
        message: `Are you sure want to remove this user?`,
        title: `Are you sure?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result['submit'] === true) {
        this._teamService.deleteTeamMember(user.id).subscribe(response => {
          if (response && response['status'] == HttpStatus.SUCCESS) {
            this.getAllTeamMembers();
          }
        })
      }
    });
  }

  getTeamLookups = () => {
    this._teamService.getTeamLookups({}).subscribe(response => {
      this.teamLookups = response['data'] ? response['data'] : [];
      this.filteredTeamLookups = this.teamLookups;
      const newTeamIndex = this.filteredTeamLookups.findIndex(elem => elem.id === this.teamId);
      if (newTeamIndex >= 0) {
        this.teamDetail = this.filteredTeamLookups[newTeamIndex]
      }
    })
  }

  onCreateNewTeam = () => {
    this._teamService.setTeamDetail(null);
    this.nameCtrl.setValue('');
    this.descriptionCtrl.setValue('');
    this.colorCtrl.setValue('');
    this.teamId = null;
    this.teamMembers = [];
    if (!this.isAddTaskMember) {
      this._location.replaceState(`/${RouteConstants.TASK_TEAM_BUILD_ROUTE}`);
    }
  }

  onChangeSorting = () => {
    this.userSortOrder = this.userSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    if (this.userSortOrder === SORT_ORDER.ASC) {
      this.filteredUserLookups.sort((a, b) => (a.name > b.name ? 1 : -1))
    } else {
      this.filteredUserLookups.sort((a, b) => (a.name < b.name ? 1 : -1))
    }
  }

  onAssignTeamToProject = () => {
    const params = {}
    params['team_id'] = this.teamDetail.id;
    params['service_id'] = this.taskCardData.id;
    params['app_id'] = "8008";
    params['name'] = this.teamDetail.name;
    params['description'] = this.teamDetail.description;
    this._teamService.addServiceTeamQueue(params).subscribe(() => {
      this.onClose.emit(true);
    })
  }

  ngOnDestroy(): void {
    this._teamService.setTeamDetail(null);
    this.nameCtrl.setValue('');
    this.descriptionCtrl.setValue('');
    this.colorCtrl.setValue('');
    this.teamId = null;
    this.teamMembers = [];
    this.teamDetail = null;
  }
}
