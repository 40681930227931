import {Injectable} from '@angular/core';
import {APIManager} from '../../../../../utility/shared-services';
import {Observable} from 'rxjs';
import {AppApi, HttpMethodsTypeEnum} from '../../../../../utility';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    constructor(private _apiManager: APIManager) {
    }

    getTenantDetailsByDomain(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.TENANT_DETAILS_BY_DOMAIN, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    verifyTenant(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.VERIFY_TENANT, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    getLanguageList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.COUNTRY_LANGUAGES, {},
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    getTimeZoneList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.TIMEZONE, {},
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    getIndustryList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.INDUSTRIES, {},
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    getCountryList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.COUNTRIES, {},
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    getStateList(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.STATES, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    registerUser(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.REGISTER, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    resendEmailConfirmation(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.RESEND_EMAIL_CONFIRMATION, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    addTenant(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ADD_TENANT, params,
            this._apiManager.Content_Type_HttpOptions, true, true);
    }

    resendTenantEmailConfirmation(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.RESEND_TENANT_EMAIL_CONFIRMATION, params,
            this._apiManager.Content_Type_HttpOptions, true, true);
    }

    confirmEmail(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.CONFIRM_EMAIL, params,
            this._apiManager.Content_Type_HttpOptions, true, true);
    }
}
