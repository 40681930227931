import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { RouteConstants } from "app/utility";

@Component({
    selector: "app-header-navigations",
    templateUrl: "./header-navigations.component.html",
    styleUrls: ["./header-navigations.component.scss"],
})
export class HeaderNavigationsComponent implements OnInit {
    @Output() hideMenu = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    get ideaManagementUrl() {
        return "/" + RouteConstants.IDEA;
    }

    get myFileUrl() {
        return "/" + RouteConstants.MY_FILE;
    }

    get taskManagementUrl() {
        return "/" + RouteConstants.TASK_MANAGEMENT;
    }

    get dashboardFixAssetsUrl() {
        return "/" + RouteConstants.DASHBOARD_FIX_ASSETS_ROUTE;
    }

    get surveyManagementUrl() {
        return "/" + RouteConstants.SURVEY;
    }

    get homeUrl() {
        return "/" + RouteConstants.HOME;
    }

    get profileUrl() {
        return "/" + RouteConstants.MY_PROFILE_ROUTE;
    }

    get purchaseManagementUrl() {
        return "/" + RouteConstants.PURCHASE_MANAGEMENT;
    }

    get calendarUrl() {
        return "/" + RouteConstants.CALENDAR;
    }

    get accountManagementUrl() {
        return "/" + RouteConstants.APP_SETTINGS;
    }

    get contactUrl() {
        return "/" + RouteConstants.CONTACT_PROFILE_ROUTE;
    }

    get teamManagementUrl() {
        return "/" + RouteConstants.TASK_TEAM_BUILD_ROUTE;
    }

    onHideNevigation = () => {
        this.hideMenu.emit(true);
    };
}
