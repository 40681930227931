import { FuseNavigation } from '@fuse/types/fuse-navigation';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'calendar',
                title    : 'Takvim',
                translate: 'NAV.CALENDAR.TITLE',
                type     : 'item',
                icon     : 'calendar_today',
                url      : '/calendar',
            },
            {
                id       : 'employee-task',
                title    : 'Görevler',
                translate: 'NAV.TASK.TITLE',
                type     : 'item',
                icon     : 'fact_check',
                url      : '/tasks',
            },
            {
                id       : 'contacts',
                title    : 'Contacts',
                translate: 'NAV.CONTACTS.TITLE',
                type     : 'item',
                icon     : 'account_box',
                url      : '/contacts'
            },
            {
                id       : 'survey',
                title    : 'Anket/Form',
                translate: 'NAV.SURVEY.TITLE',
                type     : 'item',
                icon     : 'dynamic_form',
                url      : '/surveys',
            },            
            {
                id       : 'member',
                title    : 'Personeller',
                translate: 'NAV.MEMBER.TITLE',
                type     : 'item',
                icon     : 'people_outline',
                url      : '/members',
            },
            {
                id       : 'fix-assets',
                title    : 'Sabit Kıymetler',
                translate: 'NAV.FIXASSET.TITLE',
                type     : 'item',
                icon     : 'business',
                url      : '/fixassets',
            },
            {
                id       : 'book-records',
                title    : 'Defter Kayıtları',
                translate: 'NAV.BOOKRECORD.TITLE',
                type     : 'item',
                icon     : 'account_balance',
                url      : '/bookrecords',
            },
            {
                id       : 'organizational-hierarchy',
                title    : 'Organizational Hierarchy',
                translate: 'NAV.ORGANIZATIONAL.TITLE',
                type     : 'collapsable',
                icon     : 'public',
                children : [
                    {
                        id        : 'accounts',
                        title     : 'Accounts',
                        translate : 'NAV.ORGANIZATIONAL.ACCOUNT.TITLE',
                        type      : 'item',
                        url       : '/accounts',
                        exactMatch: true
                    },
                    {
                        id        : 'organizations',
                        title     : 'Organizations',
                        translate : 'NAV.ORGANIZATIONAL.ORG.TITLE',
                        type      : 'item',
                        url       : '/organizations',
                        exactMatch: true
                    },
                    {
                        id        : 'locations',
                        title     : 'Locations',
                        translate : 'NAV.ORGANIZATIONAL.LOCATION.TITLE',
                        type      : 'item',
                        url       : '/locations',
                        exactMatch: true
                    },
                    {
                        id        : 'zones',
                        title     : 'Zones',
                        translate : 'NAV.ORGANIZATIONAL.ZONE.TITLE',
                        type      : 'item',
                        url       : '/zones',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'category-hierarchy',
                title    : 'Category Hierarchy',
                translate: 'NAV.CATEGORY.TITLE',
                type     : 'collapsable',
                icon     : 'format_list_bulleted',
                children : [
                    {
                        id        : 'product-families',
                        title     : 'Product Families',
                        translate : 'NAV.CATEGORY.PRODGROUP.TITLE',
                        type      : 'item',
                        url       : '/productfamily',
                        exactMatch: true
                    },
                    {
                        id        : 'product-categories',
                        title     : 'Product Categories',
                        translate : 'NAV.CATEGORY.PROD.TITLE',
                        type      : 'item',
                        url       : '/productcategory',
                        exactMatch: true
                    },
                    {
                        id        : 'product-lines',
                        title     : 'Product Lines',
                        translate : 'NAV.CATEGORY.PRODTYPE.TITLE',
                        type      : 'item',
                        url       : '/productline',
                        exactMatch: true
                    },
                    {
                        id        : 'product-types',
                        title     : 'Product Types',
                        translate : 'NAV.CATEGORY.PRODSUBTYPE.TITLE',
                        type      : 'item',
                        url       : '/producttype',
                        exactMatch: true
                    },
                    {
                        id        : 'hierarchy-tree',
                        title     : 'hierarchy-tree',
                        translate : 'NAV.CATEGORY.HIERARCHYTREE.TITLE',
                        type      : 'item',
                        url       : '/hierarchytree',
                        exactMatch: true
                    }
                ]
            },                    
        ]
    }
];
