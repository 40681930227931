export const locale = {
    lang: 'de',
    data: {
        LOGIN: {
            TITLE: 'Willkommen bei MAKSELLENT-ANWENDUNGEN',
            DESCRIPTION: 'Es ist jetzt einfacher, Ihr Geschäft mit MAKSELLENT Applications zu verwalten',
            HEADER: 'LOGIN TO YOUR ACCOUNT',
            EMAIL: 'Email',
            PASSWORD: 'Passwort',
            PASSCONFIRM: 'Passwort (Bestätigen)',
            REMEMBERME: 'Erinnere dich an mich',
            FORGOTPASSWORD: 'Passwort vergessen?',
            DONTHAVEACCOUNT: 'Sie haben kein Konto?',
            CREATEACCOUNT: 'Ein Konto erstellen',
        },
        REGISTER: {
            TITLE: 'Willkommen bei MAKSELLENT-ANWENDUNGEN',
            DESCRIPTION: 'Es ist jetzt einfacher, Ihr Geschäft mit MAKSELLENT Applications zu verwalten',
            HEADER: 'CREATE AN ACCOUNT',
            STEPPROFILE: 'Füllen Sie Ihr Profil aus',
            STEPCONTACT: 'Füllen Sie Ihre Kontaktdaten aus',
            STEPADDRESS: 'Geben Sie Ihre Adresse ein',
            STEPDONE: 'Done',
            FIRSTNAME: 'Vorname',
            LASTNAME: 'Nachname',
            ORGANIZATION: 'Organisation',
            DATEofBIRTH: 'Geburtsdatum',
            USERNAME: 'Benutzername',
            GENDER: 'Geschlecht',
            FEMALE: 'Weiblich',
            MALE: 'Männlich',
            EMAIL: 'E-Mail',
            PHONENUMBER: 'Telefonnummer',
            PREFEREDLANG: 'Sprachauswahl',
            ADDRESSCOUNTRY: 'Land',
            ADDRESSCITY: 'Stadt',
            ADDRESSSTREET: 'Straße',            
            HAVEACCOUNT: 'Sie haben bereits ein Konto?',
            ACCEPT: 'Akzeptieren',
            TERMS: 'Geschäftsbedingungen',
        },        
        RECOVER: {
            TITLE: 'Willkommen bei MAKSELLENT-ANWENDUNGEN',
            DESCRIPTION: 'Es ist jetzt einfacher, Ihr Geschäft mit MAKSELLENT Applications zu verwalten',
            HEADER: 'Stellen Sie Ihr Passwort wieder her',
            GOBACK: 'Go back to login',
        },
        RESETPASS: {
            TITLE: 'Willkommen bei MAKSELLENT-ANWENDUNGEN',
            DESCRIPTION: 'Es ist jetzt einfacher, Ihr Geschäft mit MAKSELLENT Applications zu verwalten',
            HEADER: 'RESET YOUR PASSWORD',
        }, 
        BOTTON: {
            CREATE: 'Erstellen',
            SAVE: 'Speichern',
            EDIT: 'Bearbeiten',
            DELETE: 'Löschen',
            CLONE: 'Klonen',
            FILTER: 'Filtern',
            RESET: 'Zurücksetzen',
            MAIN: 'Main', 
            LOGIN: 'LOGIN',
            LOGINLINK: 'Login', 
            PASSWORDRESETLINK: 'SEND RESET LINK',
            PASSWORDRESETBUTTON: 'RESET MY PASSWORD',
            CREATEACCOUNT: 'CREATE AN ACCOUNT',
            BACK: 'Back',
            NEXT: 'Next',
            PREVIOUS: 'Previous',
            SUBMIT: 'Submit',
        },
        DIALOG: {
            NAMEREQUIRED: 'Name ist erforderlich',
            LASTNAMEREQUIRED: 'Nachname ist erforderlich',
            ORGANIZATIONREQUIRED: 'Organisation ist erforderlich',
            EMAILREQUIRED: 'E-Mail ist erforderlich',            
            EMAILWARN: 'Please enter a valid email address',
            EMAILMUSTCONTAIN: 'Must contain (0,9) and [a,z] and [A,Z] and [!@#$%^&\?_-|+>;€~¨] and at least 6 or more characters.',
            PASSWORDREQUIRED: 'Password ist erforderlich',
            PASSWORDCONFIRMREQUIRED: 'Password confirmation ist erforderlich',
            PASSWORDWARN: 'Passwords must match',
            DATEOFBIRTHREQUIRED: 'Date of Birth ist erforderlich',
            DELETEWARN: 'Möchten Sie diesen Datensatz wirklich löschen?',
            DELETESUCCESS: 'Datensatz erfolgreich gelöscht!',
            DELETEFAILURE: 'Problem beim Löschen!',
            EDITABANDONED: 'Nicht gespeicherte Änderungen gehen verloren. Sind Sie sicher, dass Sie weitermachen wollen?',
            EDITSUCCESS: 'Änderung erfolgreich gespeichert!',
            CREATESUCCESS: 'Aufzeichnung erfolgreich gespeichert. Bitte bestätigen Sie Ihre E-Mail!',
        },
    }
};
