import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants, UserDetailModel } from "app/utility";
import { SharedService } from "app/utility/shared-services";

@Component({
    selector: "app-system-navigation",
    templateUrl: "./system-navigation.component.html",
    styleUrls: ["./system-navigation.component.scss"],
})
export class SystemNavigationComponent implements OnInit {

    userDetails: UserDetailModel;
    
    constructor(
        public router: Router,
        private authService: SharedService,
        private sharedService: SharedService,
    ) {}

    ngOnInit(): void {
        this.userDetails = this.sharedService.getUser();
    }

    get dashboardFixAssetsUrl() {
        return "/" + RouteConstants.DASHBOARD_FIX_ASSETS_ROUTE;
    }

    get taskManagementUrl() {
        return "/" + RouteConstants.TASK_MANAGEMENT;
    }

    get teamManagementUrl() {
        return "/" + RouteConstants.TASK_TEAM_BUILD_ROUTE;
    }

    get ideaManagementUrl() {
        return "/" + RouteConstants.IDEA;
    }

    get surveyManagementUrl() {
        return "/" + RouteConstants.SURVEY;
    }

    get profileUrl() {
        return "/" + RouteConstants.MY_PROFILE_ROUTE;
    }

    get myFileUrl() {
        return "/" + RouteConstants.MY_FILE;
    }

    get purchaseManagementUrl() {
        return "/" + RouteConstants.PURCHASE_MANAGEMENT;
    }

    get calendarUrl() {
        return "/" + RouteConstants.CALENDAR;
    }

    get accountsUrl() {
        return "/" + RouteConstants.ACCOUNT_MANAGEMENT;
    }

    get contactUrl() {
        return "/" + RouteConstants.CONTACT_PROFILE_ROUTE;
    }

    get bountyHunterUrl() {
        return "/" + RouteConstants.BOUNTY_HUNTER;
    }
}
