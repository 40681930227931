import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppApi, HttpMethodsTypeEnum } from '../../../../../utility';
import { APIManager } from '../../../../../utility/shared-services';

@Injectable({
    providedIn: 'root'
})
export class ChangeProfileService {

    constructor(private _apiManager: APIManager) {
    }

    changePassword(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.CHANGE_PASSWORD, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    updateUserPreference(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.USER_PREFERENCE, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    updateUserProfile(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.USER_PROFILE, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getAccountList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACCOUNT, {},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    updateUserContact(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_USER_CONTACT, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getEmployeePosition(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.EMPLOYEE_POSITION_BY_ID, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getEmployeeTypeList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.HRM_SYSTEM_SETTINGS_BY_TYPE, { type: 'employeetype' },
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getDepartmentList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.HRM_SYSTEM_SETTINGS_BY_TYPE, { type: 'hrdepartments' },
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getCostCentreList(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.HRM_SYSTEM_SETTINGS_BY_TYPE, { type: 'costcenters' },
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getManagerList(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.EMPLOYEE_MANAGER_BY_USER_ID, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    addEmploymentInformation(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ADD_EMPLOYMENT_POSITION, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    updateEmploymentInformation(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_EMPLOYMENT_POSITION, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getBankList(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.EMPLOYEE_BANK_BY_USER_ID, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getTenantSystemSettingsByType(params: { type: string }): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.TENANT_SYSTEM_SETTINGS_BY_TYPE, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    addAppSettings(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ADD_APP_SETTINGS, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    addBank(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ADD_EMPLOYEE_BANK, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    updateBank(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_EMPLOYEE_BANK, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    deactivateBank(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DEACTIVATE_EMPLOYEE_BANK_ACCOUNT, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    deleteBank(params: any): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DELETE_EMPLOYEE_BANK_ACCOUNT, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    setProfileImage(fileObject = []): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST_MULTIPART, AppApi.SET_USER_PROFILE_IMAGE, {},
            this._apiManager.Authorised_HttpOptions, false, true, {}, fileObject);
    }

    removeProfileImage(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.REMOVE_USER_PROFILE_IMAGE, {},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    updateUserStatus(params: { status_user: string }): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_USER_STATUS, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getExpenseList(params: unknown): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.EXPENSES_BY_FILTER, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getExpenseSummary(params: unknown): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.EXPENSES_SUMMARY, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getSystemSettingsByType(params: { type: string }): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.HRM_SYSTEM_SETTINGS_BY_TYPE, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getTaskCardList(params: unknown): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.TASK_CARD_BY_FILTER, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getUsersList(): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.USER_LOOK_UP, {},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    addEmployeeExpense(params: unknown): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ADD_EMPLOYEE_EXPENSE, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    updateEmployeeExpense(params: unknown): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_EMPLOYEE_EXPENSE, params,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getExpenseCategories(params: { type: string }): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.EXPENSE_CATEGORIES_ASYNC, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    archiveExpense(params: { id: string }): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.ARCHIVE_EXPENSES, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    updateExpenseStatus(params: unknown): Observable<unknown> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_EXPENSE_STATUS, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

}
