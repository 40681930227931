import { SurveyQuestionTypeModel } from '../survey-question-type.model';
import { SurveyResponseDetailModel } from '../survey-response/survey-response-detail.model';

export class SurveyAnswerEditModel {
    id: string;
    srv_question_id: string;
    assigned_user_id: string;
    name: string;
    description: string;
    action_title: string;
    next_display_question_id: string;
    next_redirect_url: string;
    display_image_url: string;
    display_icon_url: string;
    display_help_document_url: string;
    associated_user_tag: string;
    rule_title: string;
    scoring_value: string;
    date_start: Date;
    date_end: Date;
    type: string;
    code: string;
    color: string;
    icon: string;
    sequence: number;
    status: number;
    access_modifier: number;



  constructor(survey?) {
    survey = survey || {};
    this.id = survey.id
    this.srv_question_id = survey.srv_question_id
    this.assigned_user_id = survey.assigned_user_id
    this.name = survey.name
    this.description = survey.description
    this.action_title = survey.action_title
    this.next_display_question_id = survey.next_display_question_id
    this.next_redirect_url = survey.next_redirect_url
    this.display_image_url = survey.display_image_url
    this.display_icon_url = survey.display_icon_url
    this.display_help_document_url = survey.display_help_document_url
    this.associated_user_tag = survey.associated_user_tag
    this.rule_title = survey.rule_title
    this.scoring_value = survey.scoring_value
    this.date_start = survey.date_start
    this.date_end = survey.date_end
    this.type = survey.type
    this.code = survey.code
    this.color = survey.color
    this.icon = survey.icon
    this.sequence = survey.sequence
    this.status = survey.status
    this.access_modifier = survey.access_modifier
  }
    
}
