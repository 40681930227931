import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {SharedService} from '../shared-services';
import {Router} from '@angular/router';
import {AppApi, HttpStatus, MessageConstants} from '../shared-constants';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private _sharedService: SharedService, private _router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!navigator.onLine) {
            return throwError({error: 'NO INTERNET CONNECTION'});
        }
        const jwtReq = request.clone();
        // Pass on the cloned request instead of the original request.
        return next.handle(jwtReq).pipe(
            tap((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                    // Additional processing logic
                }
            }),
            catchError(errorResponse => {
                if (errorResponse instanceof HttpErrorResponse) {
                    let message: string;
                    if (errorResponse.error && errorResponse.error['message']) {
                        message = errorResponse.error['message'];
                        this._sharedService.setSnackBar(message);
                    } else if (
                        !message &&
                        errorResponse.status === HttpStatus.UNAUTHORIZED
                    ) {
                        message = MessageConstants.SESSION_EXPIRE;
                        this._sharedService.setSnackBar(message);
                    }
                    if (errorResponse.status === HttpStatus.UNAUTHORIZED) {
                        this._sharedService.clearSession();
                        this._router.navigate(['/' + AppApi.LOGIN]);
                    }
                }
                return throwError(errorResponse);
            })
        );
    }
}
