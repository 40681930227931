<div class="table-panel table-panel--space full-panel general-team-build">
    <div class="row">
        <div class="col-md-4 col-xs-12 col-sm-12 PL-0 sm-PR0 xs-PR0">
            <h3 (click)="onShowUsersResponsive()" class="general-team-build__users">
                {{ "PACKAGE.USERS" | translate }}
                <span class="material-icons drop-arrow-user" [ngClass]="{'drop-arrow-user--active': isShowUserList}">arrow_drop_down</span>
            </h3>

            <div *ngIf="isShowUserList">
                <div class="row">
                    <div class="col-md-10 col-xs-10 col-sm-10 PLR-0">
                        <div class="search-field">
                            <input
                                type="text"
                                placeholder='{{ "TASK_MANAGEMENT.SEARCH_TO_ADD_MEMBER_TO_TEAM" | translate }}'
                                [formControl]="userSearchCtrl"
                            />
                            <span class="material-icons">search</span>
                        </div>
                    </div>
    
                    <div class="col-md-2 col-xs-2 col-sm-2 PLR-0">
                        <button class="activity-icon" mat-icon-button (click)="onChangeSorting()">
                            <mat-icon>sort</mat-icon>
                        </button>
                    </div>
                </div>
    
                <div class="row MT-2 PB-2 xs-PB-0 sm-PB-1">
                    <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                        <mat-checkbox (change)="selectAllUser()" [checked]="isAllSeleted"
                            >{{ "TASK_MANAGEMENT.SELECT_ALL_CAPITAL" | translate }}</mat-checkbox>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 text-right" *ngIf="teamId">
                        <a class="primary-color add-member-link" (click)="addMembersToTeam()"> <span matTooltip="Add Member" class="material-icons-outlined">group_add</span></a>
                    </div>
                </div>
    
                <div *ngIf="filteredUserLookups.length" class="user-selection">
                    <div
                        *ngFor="
                            let user of filteredUserLookups;
                            let i = index
                        "
                        class="row select-border"
                    >
                        <div class="col-md-8 col-xs-8 col-sm-8 PL-0 PR-0">
                            <div class="details-left">
                                <!-- <div class="details-left__drag">
                                    <a class="drag-icon">
                                        <span class="material-icons-outlined">
                                            drag_indicator
                                        </span>
                                    </a>
                                </div> -->
                                <div class="details-left__checkbox">
                                    <mat-checkbox (change)="selectUser(user.checked, i)" [checked]="user.checked"></mat-checkbox>
                                </div>
    
                                <div class="details-left__profile">
                                    <img
                                        *ngIf="user?.profile_image_url"
                                        [src]="user?.profile_image_url"
                                    />
                                    <span *ngIf="!user?.profile_image_url" class="no-images initial-letter-design">{{getNameCharacter(user)}}</span>
                                </div>
    
                                <div class="details-left__content">
                                    <a>{{user?.name}}</a>
                                    <span>Muğla, 0252</span>
    
                                    <div>
                                        <small *ngIf="user?.status_user" class="green-color">Active</small>
    
                                        <small *ngIf="!user?.status_user" class="red-color">
                                            Annual Leave
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-4 col-xs-4 col-sm-4 PL-0 text-right xs-PR0">
                            <div class="details-right">
                                <span class="details-right__date">2021-11-24</span>
                                <a class="primary-color add-member-link" *ngIf="teamId"
                                    (click)="addAsMemberToTeam(user)"><span matTooltip="Add as a member" class="details-right__add material-icons-outlined">person_add_alt</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="false">
                <div class="no-data-container">
                    <div class="no-data-container__body">
                        <mat-icon svgIcon="not-found"></mat-icon>
                        <h5>{{ "TASK_MANAGEMENT.NO_ANY_USER_ARE_ADDED" | translate }}</h5>
                        <p>{{ "TASK_MANAGEMENT.PICK_FROM_USERS_LIST_AND_ASSIGNED_IN_TO_THE_TEAM" | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-5 col-xs-12 col-sm-12 PR-0 sm-PT-3 xs-PT-3 xs-PL0 sm-PL0">
            <div class="quick-create-team">
                <div class="row">
                    <div class="col-md-6 col-xs-6 PL-0">
                        <h3>{{ "TASK_MANAGEMENT.QUICK_CREATE_TEAM" | translate }}</h3>
                    </div>

                    <div class="col-md-6 col-xs-6 PR-0 text-right" (click)="onCreateNewTeam()">
                        <a *ngIf="isAddTaskMember && teamId" 
                           (click)="onAssignTeamToProject()" 
                           matTooltip="Pick" 
                           class="primary-color add-member-link">
                            <span class="material-icons-outlined">
                                group_add
                            </span>
                        </a>
                        <a class="primary-color add-member-link ML-1"> <span matTooltip="Create team" class="material-icons-outlined">add</span></a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-xs-6 col-sm-6 PL-0">
                    <div class="search-field">
                        <input type="text" placeholder='{{ "TASK_MANAGEMENT.ENTER_TEAM_NAME" | translate }}'
                            [formControl]="nameCtrl" />
                    </div>
                </div>

                <div class="col-md-6 col-xs-6 col-sm-6 PR-0">
                    <span class="color-code">{{ "TASK_MANAGEMENT.COLOR" | translate }}</span>
                    <ul>
                        <li class="checkmark" [ngStyle]="{'background-color': color}"
                            [class.selected]="color === colorCtrl.value"
                            *ngFor="let color of colorCodeList">
                            <span *ngIf="false" class="material-icons">check</span>
                            <input type="radio" [ngModelOptions]="{standalone: true}" [formControl]="colorCtrl"
                                [value]="color" name="color">
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row MT-2">
                <div class="col-md-9 col-xs-9 col-sm-9 PL-0">
                    <div class="search-field">
                        <input type="text" placeholder='{{ "SYSTEM_SETTINGS_MODULES.DESCRIPTION" | translate }}' 
                            [formControl]="descriptionCtrl"/>
                    </div>
                </div>

                <div class="col-md-3 col-xs-3 col-sm-3 PR-0">
                    <button (click)="onSubmitTeamBuild()" *ngIf="!teamId"
                        type="submit"
                        class="gray-btn red-btn"
                        mat-flat-button
                        color="primary"
                    >
                        {{ "BOTTON.SAVE" | translate }}
                    </button>

                    <button (click)="onSubmitTeamBuild()" *ngIf="teamId"
                        type="submit"
                        class="gray-btn red-btn"
                        mat-flat-button
                        color="primary"
                    >
                        {{ !isAddTaskMember ? 'Update' : 'Clone' }}
                    </button>
                </div>
            </div>

            <div class="choose-members">
                <h4>{{ "TASK_MANAGEMENT.MEMBERS" | translate }}</h4>

                <div *ngIf="teamMembers.length" class="members-selection">
                    <div class="row">
                        <div *ngFor="let member of teamMembers; let i=index;"  class="col-md-12 col-xs-12 xs-PR0 PL-0">
                            <div class="row border-bottom ">
                                <div class="col-md-6 col-xs-9 col-sm-6 PL-0">
                                    <div class="members-left">
                                        <div class="profile">
                                            <img
                                                *ngIf="user?.profile_image_url"
                                                [src]="user?.profile_image_url"
                                            />
                                            <span *ngIf="!user?.profile_image_url" class="no-images">{{getNameCharacter(user)}}</span>
                                        </div>
    
                                        <div class="content">
                                            
                                            <div *ngIf="selectedIndex === i">
                                                <input class="update-name" type="text" [formControl]="memberNameCtrl" />
                                            </div>

                                            <label *ngIf="selectedIndex != i">{{member?.name}}</label>
                                            <span *ngIf="(i === 0) || (i === 1) || (i === 3) || (i === 5) || (i === 6) || (i === 7) || (i === 8) || (i === 9) || (i === 10)"
                                            class="green-color"
                                                >Approved</span
                                            >
                                            <span *ngIf="(i === 2)"
                                            class="yellow-color"
                                                >Pending</span
                                            >
                                            <span *ngIf="(i === 4)"
                                            class="red-color"
                                                >Rejected</span
                                            >
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-md-6 col-xs-3 col-sm-6 PR-0 text-right">
                                    <div *ngIf="selectedIndex === i" class="members-right">
                                        <a (click)="onUpdateName(member)" matTooltip="Save">
                                            <span class="material-icons-outlined edit">
                                                done
                                            </span>
                                        </a>
                                        <a (click)="onUpdateName()" matTooltip="Cancel">
                                            <span class="material-icons-outlined delete">
                                                close
                                            </span>
                                        </a>
                                    </div>
                                    
                                    <div *ngIf="selectedIndex != i" class="members-right">
                                        <a (click)="onEditUserName(member, i)" matTooltip="Edit">
                                            <span class="material-icons-outlined edit">
                                                edit
                                            </span>
                                        </a>
    
                                       <a (click)="onDeleteTeamMember(member)" matTooltip="Remove">
                                            <span class="material-icons-outlined delete">
                                                person_remove
                                            </span>
                                       </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!teamMembers.length">
                    <div class="no-data-container">
                        <div class="no-data-container__body">
                            <mat-icon svgIcon="not-found"></mat-icon>
                            <h5>{{ "TASK_MANAGEMENT.NO_ANY_MEMBERS_ARE_ADDED" | translate }}</h5>
                            <p>{{ "TASK_MANAGEMENT.PICK_FROM_USERS_LIST_AND_ASSIGNED_IN_TO_THE_TEAM" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-xs-12 xs-PR0 xs-PL0">
            <div class="profile-navigation">
                <div class="profile-navigation__header">
                    <div class="row">
                        <div class="col-md-7 col-sm-7 col-xs-12 PL-0">
                            <ul>
                                <li>
                                    <a (click)="onShowTeamResponsive()" class="users active">
                                        {{ "TASK_MANAGEMENT.TEAM" | translate }}
                                        <span class="material-icons drop-arrow-user" [ngClass]="{'drop-arrow-user--active': isShowUserList}">arrow_drop_down</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="isShowTeamList">
                    <div class="build-team-container">
                        <div class="build-team">
                            <div class="search-field">
                                <input type="text" placeholder='{{ "TASK_MANAGEMENT.SEARCH" | translate }}' [formControl]="teamSearchCtrl" />
                                <span class="material-icons">search</span>
                            </div>
                    
                            <div class="build-team__body" *ngIf="filteredTeamLookups?.length">
                                <div *ngFor="let team of filteredTeamLookups; let i = index" class="row build-team__border MB-2 sm-MB-1">
                                    <div class="col-md-9 col-xs-9 col-sm-10 PL-0">
                                        <div class="build-team__body__left">
                                            <img src="assets/images/avatars/garry.jpg">
                                            <img class="second-img" src="assets/images/avatars/garry.jpg">
                                        </div>
                    
                                        <div class="build-team__body__right">
                                            <span>{{team?.name}}</span>
                                        </div>
                                    </div>
                    
                                    <div class="col-md-3 col-xs-3 col-sm-2 PR-0 text-right footer-menu">
                                        <button mat-icon-button [matMenuTriggerFor]="menu">
                                            <mat-icon>more_horiz</mat-icon>
                                        </button>
                                        
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="onEditTeam(team)" *ngIf="!isAddTaskMember">
                                                <mat-icon>edit</mat-icon>
                                                <span>{{ "BOTTON.EDIT" | translate }}</span>
                                            </button>
                                            <button mat-menu-item (click)="onEditTeam(team)" *ngIf="isAddTaskMember">
                                                <mat-icon>visibility</mat-icon>
                                                <span>{{ 'View' }}</span>
                                            </button>
                                            <!-- <button (click)="onAssignUsersDialogOpen(team)" mat-menu-item>
                                                <mat-icon>people_outline</mat-icon>
                                                <span>{{ "TASK_MANAGEMENT.PICK_MEMBERS" | translate }}</span>
                                            </button> -->
                                            <button (click)="onDeleteTeam(team)" mat-menu-item>
                                                <mat-icon>delete_outline</mat-icon>
                                                <span>{{ "BOTTON.DELETE" | translate }}</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!filteredTeamLookups?.length" class="invite-user-container">
                        <div class="invite-user">
                            <div class="invite-user__body">
                                <span class="material-icons"> people_alt </span>
                                <h5>{{ "TASK_MANAGEMENT.NO_ANY_TEAM_ARE_AVAILABLE" | translate }}</h5>
                                <p>{{ "TASK_MANAGEMENT.build_a_team_and_add_resources" | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
