<div class="document-gallery-container">
    <div class="document-gallery-header">
        <a matTooltip="previous" class="previous-icon" (click)="onPreviousIndex()">
            <span class="material-icons">arrow_back_ios</span>
        </a>

        <!-- <img src="assets/images/laptop-image.png" /> -->
        <ng-container [ngSwitch]="docType">
            <ng-container *ngSwitchCase="documentViewTypeEnum.DOC">
                <ngx-doc-viewer [url]="url" viewer="office" [embedd]="true" style="width:100%;height:93vh;">
                </ngx-doc-viewer>
            </ng-container>
            <ng-container *ngSwitchCase="documentViewTypeEnum.PDF">
                <ngx-doc-viewer [url]="url" viewer="pdf" [embedd]="true" style="width:100%;height:93vh;">
                </ngx-doc-viewer>
            </ng-container>
            <ng-container *ngSwitchCase="documentViewTypeEnum.IMG">
                <img [src]="url" style="height: 400px; width: auto;"/>
            </ng-container>
            <ng-container *ngSwitchCase="documentViewTypeEnum.VIDEO">
                <div>
                    <video width="320" height="240" controls>
                        <source [src]="url">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </ng-container>
        </ng-container>

        <a matTooltip="next" class="next-icon" (click)="onNextIndex()">
            <span class="material-icons">arrow_forward_ios</span>
        </a>
    </div>

    <div class="document-gallery-footer">
        <ul>
            <li [ngClass]="{'doc-square': !isImage(file.name) && !isPdf(file.name) && !isMp4(file.name) || isPdf(file.name)}" *ngFor="let file of data; let i = index">
                <!-- <a>
                    <img src="assets/images/laptop-image.png" />
                </a> -->
                <ng-container *ngIf="isImage(file.name)">
                    <a>
                        <img [src]="getPreviewUrl(file.id)" />
                    </a>
                </ng-container>
                <mat-icon svgIcon="doc" *ngIf="!isImage(file.name) && !isPdf(file.name) && !isMp4(file.name)"></mat-icon>
                <mat-icon *ngIf="isPdf(file.name)" svgIcon="pdf"></mat-icon>
                <ng-container *ngIf="isMp4(file.name)">
                    <video height="100" controls>
                        <source [src]="url">
                        Your browser does not support the video tag.
                    </video>
                </ng-container>
            </li>
        </ul>
    </div>
</div>

<!-- <ng-container [ngSwitch]="docType">
    <ng-container *ngSwitchCase="documentViewTypeEnum.DOC">
        <ngx-doc-viewer [url]="url" viewer="office" [embedd]="true" style="width:100%;height:93vh;">
        </ngx-doc-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="documentViewTypeEnum.PDF">
        <ngx-doc-viewer [url]="url" viewer="pdf" [embedd]="true" style="width:100%;height:93vh;">
        </ngx-doc-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="documentViewTypeEnum.IMG">
        <div class="image-preview">
            <img class="image-view" [src]="url" />
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="documentViewTypeEnum.VIDEO">
        <div>
            <video width="320" height="240" controls>
                <source [src]="url">
                //<source src="http://techslides.com/demos/sample-videos/small.webm" type="video/webm">
                //<source src="http://techslides.com/demos/sample-videos/small.ogv" type="video/ogg">
                //<source src="http://techslides.com/demos/sample-videos/small.mp4" type="video/mp4">
                //<source src="http://techslides.com/demos/sample-videos/small.3gp" type="video/3gp">
                Your browser does not support the video tag.
            </video>
        </div>
    </ng-container>
</ng-container> -->