<div class="auth">
    <div class="auth__left">
        <img
            *ngIf="isLangActive(languages.English)"
            src="../../../../../assets/images/auth/logo-en.png"
        />
        <img
            *ngIf="isLangActive(languages.German)"
            src="../../../../../assets/images/auth/logo-de.png"
        />
        <img
            *ngIf="isLangActive(languages.Turkish)"
            src="../../../../../assets/images/auth/logo-tr.png"
        />
    </div>
    <div class="auth__right">
        <div *ngIf="isEmailConfirm === 'CONFIRM'"
             class="signup-successfully"
        >
            <h2>{{ "DEMO_ACTIVATION.THANK_YOU" | translate }}</h2>
            <h4>{{ "REGISTER.SIGNUP_COMPLETED" | translate }}</h4>

            <br/>
            <button
                (click)="onLogin()"
                type="button"
                mat-raised-button
                color="accent"
                class="submit-button next-btn"
            >
                {{ "LOGIN.SIGN_IN" | translate }}
            </button>
        </div>

        <div
            class="signup-successfully"
            *ngIf="isEmailConfirm === 'NOT_CONFIRM'"
        >
            <!-- <h2>{{ "REGISTER.SIGNUP_SUCCESSFULLY" | translate }}</h2> -->
            <!--<h4>
                {{ "REGISTER.SENT_ACTIVATION_CODE" | translate }}
                <b>{{loginFormControls['email'].value}}</b>
            </h4>-->
            <h2 *ngIf="!showResendOTPButton">{{ "REGISTER.VERIFING_YOUR_ACCOUNT" | translate }}</h2>
            <p *ngIf="!showResendOTPButton">
                {{ "REGISTER.RETRY" | translate }} <b>{{clockDisplay ? clockDisplay : ''}}</b>
                {{ "REGISTER.MINUTES" | translate }}
            </p>

            <div *ngIf="showResendOTPButton" class="MT-2">
                <p>{{ "REGISTER.LINK_EXPIRED" | translate }}</p>
                <button
                    type="button"
                    mat-raised-button
                    color="accent"
                    class="submit-button next-btn"
                    (click)="onResendOTP()"
                >
                    {{ "REGISTER.RESEND_LINK" | translate }}
                </button>

                <button
                    type="button"
                    mat-raised-button
                    color="accent"
                    class="submit-button next-btn ML-2"
                    (click)="onLogin()"
                >
                    {{ "LOGIN.SIGN_IN" | translate }}
                </button>
            </div>
        </div>

        <ul class="auth__language">
            <li>
                <span class="material-icons"> language </span>
                <label>{{ "LOGIN.LANGUAGE" | translate }}:</label>
            </li>
            <li (click)="setLanguage(languages.English)">
                <a [ngClass]="{ active: isLangActive(languages.English) }">
                    <img src="assets/icons/flags/us.png"/><span>English</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.Turkish)">
                <a [ngClass]="{ active: isLangActive(languages.Turkish) }">
                    <img src="assets/icons/flags/tr.png"/><span>Türkçe</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.German)">
                <a [ngClass]="{ active: isLangActive(languages.German) }">
                    <img src="assets/icons/flags/de.png"/><span>Deutsch</span>
                </a>
            </li>
        </ul>
    </div>
</div>
