<div class="modal-view import-data">
    <div class="row">
        <div class="col-md-12 PLR-0">
            <h2>{{ "BOTTON.SAVE" | translate }} </h2>
        </div>
    </div>

    <form [formGroup]="uploadForm" (ngSubmit)="onSubmitUploadForm(uploadForm)">

        <div class="row">
            <div class="col-md-12 PLR-0">
                <div class="form-control">
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>{{ "FIX_ASSET.TYPE" | translate }}</mat-label>
                        <mat-select formControlName="report_type">
                            <mat-option value=0>{{ "TASK_MANAGEMENT.DEFAULT" | translate }}</mat-option>
                            <mat-option value=1>{{ "TASK_MANAGEMENT.PRIVATE" | translate }}</mat-option>
                            <mat-option value=2>{{ "TASK_MANAGEMENT.PUBLIC" | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row MT-2">
            <div class="col-md-12 PLR-0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "SURVEY_TARGET.NAME" | translate }}</mat-label>
                        <input matInput formControlName="name" autocomplete="off" value=""/>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 PLR-0 MT-25">
                <button type="submit" mat-flat-button color="primary">
                    {{ "BOTTON.SAVE" | translate }}
                </button>
                <button (click)="onCloseDialog()" type="button" mat-flat-button color="secondary" class="ML-2">
                    {{ "BOTTON.CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>