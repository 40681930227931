export const locale = {
    lang: 'en',
    data: {
        LOGIN: {
            TITLE: 'Welcome to MAKSELLENT Applications',
            DESCRIPTION: 'It is now easier to manage your business with MAKSELLENT Applications',
            HEADER: 'LOGIN TO YOUR ACCOUNT',
            EMAIL: 'Email',
            PASSWORD: 'Password',
            PASSCONFIRM: 'Password (Confirm)',
            REMEMBERME: 'Remember Me',
            FORGOTPASSWORD: 'Forgot Password?',
            DONTHAVEACCOUNT: 'Don\'t have an account?',
            CREATEACCOUNT: 'Create an account',
        },
        REGISTER: {
            TITLE: 'Welcome to MAKSELLENT Applications',
            DESCRIPTION: 'It is now easier to manage your business with MAKSELLENT Applications',
            HEADER: 'CREATE AN ACCOUNT',
            STEPFIRST: 'Fill out your login information',
            STEPPROFILE: 'Fill out your profile',
            STEPCONTACT: 'Fill out your contact details',
            STEPADDRESS: 'Fill out your address',
            STEPDONE: 'Done',
            FIRSTNAME: 'First Name',
            LASTNAME: 'Last Name',
            ORGANIZATION: 'Organization',
            DATEofBIRTH: 'Date of Birth',
            USERNAME: 'Username',
            GENDER: 'Gender',
            FEMALE: 'Female',
            MALE: 'Male',
            EMAIL: 'E-Mail',
            PHONENUMBER: 'Phone Number',
            PREFEREDLANG: 'Prefered Language',
            ADDRESSCOUNTRY: 'Country',
            ADDRESSCITY: 'City',
            ADDRESSSTREET: 'Street',            
            HAVEACCOUNT: 'Already have an account?',
            ACCEPT: 'Accept',
            TERMS: 'Terms and Conditions',
        },        
        RECOVER: {
            TITLE: 'Welcome to MAKSELLENT Applications',
            DESCRIPTION: 'It is now easier to manage your business with MAKSELLENT Applications',
            HEADER: 'Recover your password',
            GOBACK: 'Go back to login',
        }, 
        RESETPASS: {
            TITLE: 'Welcome to MAKSELLENT Applications',
            DESCRIPTION: 'It is now easier to manage your business with MAKSELLENT Applications',
            HEADER: 'RESET YOUR PASSWORD',
        },                      
        BOTTON: {
            CREATE: 'Create',
            SAVE: 'Save',
            EDIT: 'Edit',
            DELETE: 'Delete',
            CLONE: 'Clone',
            FILTER: 'Filter',
            RESET: 'Reset',
            MAIN: 'Main', 
            LOGIN: 'LOGIN', 
            LOGINLINK: 'Login', 
            PASSWORDRESETLINK: 'SEND RESET LINK',
            PASSWORDRESETBUTTON: 'RESET MY PASSWORD',
            CREATEACCOUNT: 'CREATE AN ACCOUNT',
            BACK: 'Back',
            NEXT: 'Next',
            PREVIOUS: 'Previous',
            SUBMIT: 'Submit',
        },
        DIALOG: {
            NAMEREQUIRED: 'Name is required',
            LASTNAMEREQUIRED: 'Last Name is required',
            ORGANIZATIONREQUIRED: 'Organization is required',
            EMAILREQUIRED: 'Email is required',            
            EMAILWARN: 'Please enter a valid email address',
            EMAILMUSTCONTAIN: 'Must contain (0,9) and [a,z] and [A,Z] and [!@#$%^&\?_-|+>;€~¨] and at least 6 or more characters.',
            PASSWORDREQUIRED: 'Password is required',
            PASSWORDCONFIRMREQUIRED: 'Password confirmation is required',
            PASSWORDWARN: 'Passwords must match',
            DATEOFBIRTHREQUIRED: 'Date of Birth is required',
            DELETEWARN: 'Do you really want to delete this record?',
            DELETESUCCESS: 'Record successfully deleted!',
            DELETEFAILURE: 'Problem during deletion',
            EDITABANDONED: 'Unsaved changes will be lost. Are you sure to continue?',
            EDITSUCCESS: 'Record successfully saved!',
            CREATESUCCESS: 'Record successfully created. Please verify your Email!',            
        },
    }
};
