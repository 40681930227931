export class FileResponseModel {
    name: string;
    size: string;
    is_main: boolean;
    is_favorite: boolean;
    type: string;
    date_entered: string;
    date_modified: string;
    modified_by: string;
    modified_by_name: string;
    id: string;
    code: string;
    assigned_user_id: string;
    description: string;
    access_modifier : number;
    color: string;
}