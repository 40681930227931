import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { _SurveyModel } from '../models/_survey.model';
import { environment } from '../../../../../environments/environment';
import { PaginatedResult } from 'app/shared/models/pagination';
import { User } from 'app/public-modules/modules/auth/components/shared/model/user.model';
import { SurveyModel } from '../models/survey/survey.model';
import { SurveyCreateModel } from '../models/survey/survey-create.model';
import { SurveyEditModel } from '../models/survey/survey-edit.model';
import { AppApi, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';

@Injectable({
    providedIn: 'root'
})

export class SurveyDotCoreService {
    baseUrl = environment.apiUrl + '/api/v1/Survey/';
    user: User = JSON.parse(localStorage.getItem('user'));

    constructor(private http: HttpClient, protected _sharedService: SharedService, private _apiManager: APIManager) { }

    getAll(page?, itemsPerPage?, famaParams?): Observable<PaginatedResult<SurveyModel[]>> {

        const paginatedResult: PaginatedResult<SurveyModel[]> = new PaginatedResult<SurveyModel[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        if (famaParams != null) {
            if (famaParams.tenantId !== null && famaParams.tenantId !== '') {
                params = params.append('tid', famaParams.tenantId);
            }
            if (famaParams.organizationId != null && famaParams.organizationId !== '') {
                params = params.append('oid', famaParams.organizationId);
            }
            if (famaParams.locationId != null && famaParams.locationId !== '') {
                params = params.append('lid', famaParams.locationId);
            }
            if (famaParams.name != null && famaParams.name !== '') {
                params = params.append('name', famaParams.name);
            }
            if (famaParams.orderBy != null && famaParams.orderBy !== '') {
                params = params.append('orderBy', famaParams.orderBy);
            }
        }

        return this.http.get<SurveyModel[]>(this.baseUrl + 'survey', { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;

                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }

                    return paginatedResult;
                })
            );
    }

    getAllSurvey(page?, itemsPerPage?, famaParams?): Observable<any> {
        const paramsBody = {
            'pageNumber': page != null && itemsPerPage != null ? page : 0,
            'pageSize': page != null && itemsPerPage != null ? itemsPerPage : 0,
            'name': famaParams != null && (famaParams.name != null && famaParams.name !== '') ? famaParams.name : '',
            'srvid': famaParams != null && (famaParams.srvid != null && famaParams.srvid !== '') ? famaParams.srvid : '',
            'srvStart': famaParams != null && (famaParams.srvStart != null && famaParams.srvStart !== '') ? famaParams.srvStart : '',
            'srvEnd': famaParams != null && (famaParams.srvEnd != null && famaParams.srvEnd !== '') ? famaParams.srvEnd : '',
            'tid': famaParams != null && (famaParams.tid != null && famaParams.tid !== '') ? famaParams.tid : '',
            'aid': famaParams != null && (famaParams.aid != null && famaParams.aid !== '') ? famaParams.aid : '',
            'oid': famaParams != null && (famaParams.oid != null && famaParams.oid !== '') ? famaParams.oid : '',
            'status': famaParams != null && (famaParams.status != null && famaParams.status !== '') ? famaParams.status : '',
            'orderBy': famaParams != null && (famaParams.orderBy != null && famaParams.orderBy !== '') ? famaParams.orderBy : '',
        }

        return this.http.post(this.baseUrl + 'GetAllSurveysByFiltersAsync', paramsBody);
    }

    get(id): Observable<SurveyModel> {
        let params = new HttpParams();
        params = params.append('id', id);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + this._sharedService.getToken())
        return this.http.get<SurveyModel>(AppApi.SURVEY_BASIC_DETAIL, { headers, params });
    }

    create(survey: SurveyCreateModel): Observable<any> {
        return this.http.post(this.baseUrl + 'survey', survey);
    }

    saveForm(survey: any): Observable<any> {
        // const mapped = this.mapSurvey(survey);
        return this.http.post(this.baseUrl + 'surveyform', survey);
    }

    update(id: string, survey: SurveyEditModel): Observable<any> {
        return this.http.put(this.baseUrl + 'survey/' + id, survey);
    }

    delete(id: string): Observable<any> {
        return this.http.delete(this.baseUrl + 'survey/' + id, {});
    }

    loadSurveyType(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.SURVEY_TYPE, {},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    updateSurvey(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.SURVEY_UPDATE, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addSurvey(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.SURVEY_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getLookupAccessModifier(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET,
            AppApi.TENANT_SYSTEM_SETTINGS_BY_TYPE,
            { type: 'access_modifier' },
            this._apiManager.Authorised_HttpOptions,
            true,
            true
        );
    }
}
