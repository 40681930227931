import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { PaginatedResult } from 'app/shared/models/pagination';
import { User } from 'app/public-modules/modules/auth/components/shared/model/user.model';
import { AppApi, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';
import { _FixAssetModel } from '../models/_fix-asset-module/_fix-asset.model';

@Injectable({
  providedIn: 'root'
})

export class AssetAccountsDotCoreService {
  
constructor( 
    protected _sharedService: SharedService,
    private _apiManager: APIManager) {}

    loadAccount(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ACCOUNT_LOOKUP, {},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    loadAccountById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ACCOUNT_BY_ID, {id},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    loadAccountByTenantId(tenantId): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACCOUNT_BY_TENANT_ID, { 'tenantId': tenantId },
            this._apiManager.Authorised_HttpOptions, false, true
        )
    }

    loadLocation(tenantId): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_LOCATION_BY_TENANT_ID, {tenant_id : tenantId},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    loadOrganizationByTenantId(tenantId): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ORGANIZATION_BY_TENANT_ID, {tenant_id : tenantId},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    loadOrganizationByAccountId(accountId): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ORGANIZATION_BY_ACCOUNT_ID, {account_id: accountId},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    loadOrganizationById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ORGANIZATION_BY_ID, {id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    loadUnitByOrganizationId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_UNIT_BY_ORGANIZATION_ID, {id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    loadZone(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ACC_ZONE, params,
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getUnitById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_UNIT, {id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getLocationById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_LOCATION_BY_ID, {id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getZoneById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ZONE_BY_ID, {id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

}
