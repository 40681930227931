<div class="auth">
    <div class="auth__left">
        <img
            *ngIf="isLangActive(languages.English)"
            src="../../../../../assets/images/auth/logo-en.png"
        />
        <img
            *ngIf="isLangActive(languages.German)"
            src="../../../../../assets/images/auth/logo-de.png"
        />
        <img *ngIf="isLangActive(languages.Turkish)" src="../../../../../assets/images/auth/logo-tr.png"/>
    </div>
    <div class="auth__right">
        <div class="auth__right-top-text">
            <p>
                {{ "LOGIN.DONTHAVEACCOUNT" | translate }}
                <a [routerLink]="'/auth/register'">{{ "LOGIN.SIGNUP" | translate }}</a>
            </p>
        </div>

        <h2>{{ "RESETPASS.HEADER" | translate }}</h2>
        <!--<p>
            {{ "RESETPASS.DESCRIPTION_OTP" | translate }}
            <b>johndoe@mailsac.com</b>
        </p>-->

        <form
            name="resetPasswordForm"
            [formGroup]="resetPasswordForm"
            (submit)="resetPassword(resetPasswordForm)"
            novalidate
        >
            <mat-form-field appearance="outline">
                <mat-label>
                    <!-- Password -->
                    {{ "LOGIN.PASSWORD" | translate }}
                </mat-label>
                <input
                    placeholder="{{
                        'COMMON.PASSWORD_PLACEHOLDER' | translate
                    }}"
                    autocomplete="off"
                    matInput
                    type="password"
                    formControlName="password"
                />
                <span matSuffix class="material-icons"> vpn_key </span>
                <mat-error>
                    <!-- Password is required -->
                    {{ "DIALOG.PASSWORDREQUIRED" | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    <!-- Password (Confirm) -->
                    {{ "LOGIN.PASSCONFIRM" | translate }}
                </mat-label>
                <input
                    placeholder="{{
                        'COMMON.CONFIRM_PASSWORD_PLACEHOLDER' | translate
                    }}"
                    autocomplete="off"
                    matInput
                    type="password"
                    formControlName="passwordConfirm"
                />
                <span matSuffix class="material-icons"> vpn_key </span>
                <mat-error
                    *ngIf="
                        resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('required')
                    "
                >
                    <!-- Password confirmation is required -->
                    {{ "DIALOG.PASSWORDCONFIRMREQUIRED" | translate }}
                </mat-error>
                <mat-error *ngIf="resetPasswordForm.errors?.doesMatchPassword">
                    {{ "DIALOG.PASSWORDWARN" | translate }}
                </mat-error>
            </mat-form-field>

            <div class="row MT-2">
                <div class="col-md-6 PL-0">
                    <button
                        mat-raised-button
                        class="submit-button"
                        color="accent"
                        [disabled]="resetPasswordForm.invalid"
                    >
                        <!-- RESET MY PASSWORD -->
                        {{ "BOTTON.PASSWORDRESETBUTTON" | translate }}
                    </button>
                </div>
                <div class="col-md-6 text-right PR-0">
                    <a class="link" (click)="onLogin()">
                        <!-- Go back to login -->
                        {{ "RECOVER.GOBACK" | translate }}
                    </a>
                </div>
            </div>
        </form>

        <ul class="auth__language">
            <li>
                <span class="material-icons"> language </span>
                <label>{{ "LOGIN.LANGUAGE" | translate }}:</label>
            </li>
            <li (click)="setLanguage(languages.English)">
                <a [ngClass]="{ active: isLangActive(languages.English) }">
                    <img src="assets/icons/flags/us.png"/><span>English</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.Turkish)">
                <a [ngClass]="{ active: isLangActive(languages.Turkish) }">
                    <img src="assets/icons/flags/tr.png"/><span>Türkçe</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.German)">
                <a [ngClass]="{ active: isLangActive(languages.German) }">
                    <img src="assets/icons/flags/de.png"/><span>Deutsch</span>
                </a>
            </li>
        </ul>
    </div>
</div>
