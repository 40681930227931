import { environment } from '../../../environments/environment';

const BASE_URL = `${environment.apiUrl}/api/v1/Tenant/`;
const BASE_HRM_URL = `${environment.apiUrl}/api/v1/Hrm/`;
const BASE_ACCOUNT_URL = `${environment.apiUrl}/api/v1/Account/`;

const BASE_FIXASSET_URL = `${environment.apiUrl}/api/v1/FixAsset/`;
const BASE_PRODUCT_URL = `${environment.apiUrl}/api/v1/Product/`;
const BASE_SURVEY_URL = `${environment.apiUrl}/api/v1/Survey/`;
const BASE_COMMENT_URL = `${environment.apiUrl}/api/v1/Comment/`;
const BASE_FILE_MANAGEMENT_URL = `${environment.apiUrl}/api/v1/FileManagement/`;
const BASE_TAGS_URL = `${environment.apiUrl}/api/v1/Tag/`;
const BASE_NOTIFICATIONS_URL = `${environment.apiUrl}/api/v1/Notifications/`;
const BASE_TEAM_URL = `${environment.apiUrl}/api/v1/Team/`;
const BASE_TASK_URL = `${environment.apiUrl}/api/v1/Task/`;
const BASE_CALENDAR_URL = `${environment.apiUrl}/api/v1/Calendar/`;
const BASE_SOCIAL_COMMENT_URL = `${environment.apiUrl}/api/v1/Social/Comment/`;

export class AppApi {

    // Authentication
    public static LOGIN = BASE_URL + 'Auth/login';
    public static SOCIAL_LOGIN = BASE_URL + 'Auth/SocialLogin';
    public static FORGOT_PASSWORD = BASE_URL + 'Auth/ForgetPassword';
    public static RESET_PASSWORD = BASE_URL + 'Auth/ResetPassword';
    public static TENANT_DETAILS_BY_DOMAIN = BASE_URL + 'Tenant/GetTenantByDomainAsync';
    public static ACCOUNT_BY_TENANT_ID = BASE_URL + 'Account/GetAllAccountsByTenantIdAsync';
    public static ORGANIZATION_BY_ACCOUNT_ID = BASE_URL + 'Organization/GetAllOrganizationsByAccountIdAsync';
    public static ZONE_BY_LOCATION_ID = BASE_URL + 'Zone/GetAllZonesByLocationIdAsync';
    public static VERIFY_TENANT = BASE_URL + 'Tenant/GetTenantsByCodeAsync';
    public static COUNTRY_LANGUAGES = BASE_URL + 'Lookup/GetAllLanguageAsync';
    public static TIMEZONE = BASE_URL + 'Lookup/GetAllTimeZonesAsync';
    public static COUNTRIES = BASE_URL + 'Lookup/GetAllCountriesAsync';
    public static INDUSTRIES = BASE_URL + 'Lookup/GetAllIndustriesAsync';
    public static STATES = BASE_URL + 'Lookup/GetAllStateProvincesByCountryIdAsync';
    public static REGISTER = BASE_URL + 'Auth/Register';
    public static RESEND_EMAIL_CONFIRMATION = BASE_URL + 'Auth/ResendEmailConfirmation';
    public static ADD_TENANT = BASE_URL + 'Tenant/AddTenantAsync';
    public static RESEND_TENANT_EMAIL_CONFIRMATION = BASE_URL + 'Tenant/ResendSendTenantConfirmationEmail';
    public static CONFIRM_EMAIL = BASE_URL + 'Auth/ConfirmEmailAsync';
    public static LOOKUP_USER_BY_TENANT_ID = BASE_URL + 'Auth/GetUserLookupsByTenantIdAsync';
    public static LOOKUP_USERS = BASE_URL + 'Auth/GetUserLookupsAsync';
    public static INVITE_USER_BY_EMAIL = BASE_URL + 'Auth/InviteUsersByEmail';
    public static ADD_USER_INVITE_BY_EMAIL = BASE_URL + 'UserInvite/AddUserInviteListAsync';
    public static LOOKUP_USERS_BY_EMAIL = BASE_URL + 'Auth/GetUserLookupsByEmailAsync';

    // Fix Asset
    public static FIXASSET_GET_BASIC_DEFINITION = BASE_FIXASSET_URL + 'Asset/GetAllAssetsAsync';
    public static FIXASSET_BASIC_DEFINITION_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetBasicInfoAsync';
    public static FIXASSET_GETBYID = BASE_FIXASSET_URL + 'Asset/GetAllAssetsAsync';
    public static FIXASSET_LIST = BASE_FIXASSET_URL + 'Asset/GetAllAssetsByFiltersAsync';
    public static FIXASSET_BY_TENANTI_ID = BASE_FIXASSET_URL + 'Asset/GetAllAssetsByTenantIdAsync';
    public static FIXASSET_ADD = BASE_FIXASSET_URL + 'Asset/AddAssetAsync';
    public static FIXASSET_IMPORT_EXCEL = BASE_FIXASSET_URL + 'Asset/ImportExcelAssetList';
    public static FIXASSET_UPDATE_REPORT_FILTER = BASE_FIXASSET_URL + 'AssetReport/UpdateAssetReportAsync';
    public static FIXASSET_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetsAsync';
    public static FIXASSET_ARCHIVE = BASE_FIXASSET_URL + 'Asset/ArchiveAssetsAsync';
    public static FIXASSET_DELETE = BASE_FIXASSET_URL + 'Asset/DeleteAssetsAsync';
    public static FIXASSET_PROFILE_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetProfileAsync';
    public static FIXASSET_CATEGORIZATION_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetCategoryAsync';
    public static FIXASSET_LOCATION_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetLocation/GetAllAssetLocationsByAssetIdAsync';
    public static FIXASSET_LOCATION_ADD = BASE_FIXASSET_URL + 'AssetLocation/AddAssetLocationAsync';
    public static FIXASSET_LOCATION_UPDATE = BASE_FIXASSET_URL + 'AssetLocation/UpdateAssetLocationsAsync';
    public static FIXASSET_LOCATION_ARCHIVE = BASE_FIXASSET_URL + 'AssetLocation/ArchiveAssetLocationsAsync';
    public static FIXASSET_LOCATION_DELETE = BASE_FIXASSET_URL + 'AssetLocation/DeleteAssetLocationsAsync';
    public static FIXASSET_LIABILITY_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetLiability/GetAllAssetLiabilitiesByAssetIdAsync';
    public static FIXASSET_LIABILITY_ADD = BASE_FIXASSET_URL + 'AssetLiability/AddAssetLiabilityAsync'
    public static FIXASSET_LIABILITY_UPDATE = BASE_FIXASSET_URL + 'AssetLiability/UpdateAssetLiabilitiesAsync'
    public static FIXASSET_LIABILITY_ARCHIVE = BASE_FIXASSET_URL + 'AssetLiability/ArchiveAssetLiabilitiesAsync';
    public static FIXASSET_LIABILITY_DELETE = BASE_FIXASSET_URL + 'AssetLiability/DeleteAssetLiabilitiesAsync';
    public static FIXASSET_MAINTENANCE = BASE_FIXASSET_URL + 'AssetMaintenance/GetAllAssetMaintenancesAsync';
    public static FIXASSET_MAINTENANCE_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetMaintenance/GetAllAssetMaintenancesByAssetIdAsync';
    public static FIXASSET_MAINTENANCE_BY_FILTER = BASE_FIXASSET_URL + 'AssetMaintenance/GetAllAssetMaintenancesByFiltersAsync';
    public static FIXASSET_MAINTENANCE_ADD = BASE_FIXASSET_URL + 'AssetMaintenance/AddAssetMaintenanceAsync';
    public static FIXASSET_MAINTENANCE_UPDATE = BASE_FIXASSET_URL + 'AssetMaintenance/UpdateAssetMaintenancesAsync';
    public static FIXASSET_MAINTENANCE_ARCHIVE = BASE_FIXASSET_URL + 'AssetMaintenance/ArchiveAssetMaintenancesAsync';
    public static FIXASSET_MAINTENANCE_DELETE = BASE_FIXASSET_URL + 'AssetMaintenance/DeleteAssetMaintenancesAsync';
    public static FIXASSET_INSURANCE = BASE_FIXASSET_URL + 'AssetInsurance/GetAllAssetInsurancesAsync';
    public static FIXASSET_INSURANCE_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetInsurance/GetAllAssetInsurancesByAssetIdAsync';
    public static FIXASSET_INSURANCE_BY_FILTER = BASE_FIXASSET_URL + 'AssetInsurance/GetAllAssetInsurancesByFiltersAsync';
    public static FIXASSET_INSURANCE_ADD = BASE_FIXASSET_URL + 'AssetInsurance/AddAssetInsuranceAsync';
    public static FIXASSET_INSURANCE_UPDATE = BASE_FIXASSET_URL + 'AssetInsurance/UpdateAssetInsurancesAsync';
    public static FIXASSET_INSURANCE_ARCHIVE = BASE_FIXASSET_URL + 'AssetInsurance/ArchiveAssetInsurancesAsync';
    public static FIXASSET_INSURANCE_DELETE = BASE_FIXASSET_URL + 'AssetInsurance/DeleteAssetInsurancesAsync';
    public static FIXASSET_SETTING_BY_TYPES = BASE_FIXASSET_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';
    public static FIXASSET_SETTING_BY_ID = BASE_FIXASSET_URL + 'SystemSetting/GetAllSystemSettingAsync';
    public static FIXASSET_ASSETCODE_BY_TENANT_ID = BASE_FIXASSET_URL + 'AssetCode/GetAllAssetCodesByTenantIdAsync';
    public static FIXASSET_ASSETCODE_BY_ASSETID = BASE_FIXASSET_URL + 'AssetCode/GetAllAssetCodesByAssetIdAsync';
    public static FIXASSET_ASSETCODE_ADD = BASE_FIXASSET_URL + 'AssetCode/AddAssetCodeAsync';
    public static FIXASSET_ASSETCODE_UPDATE = BASE_FIXASSET_URL + 'AssetCode/UpdateAssetCodesAsync';
    public static FIXASSET_ASSETCODE_ARCHIVE = BASE_FIXASSET_URL + 'AssetCode/ArchiveAssetCodesAsync';
    public static FIXASSET_ASSETCODE_DELETE = BASE_FIXASSET_URL + 'AssetCode/DeleteAssetCodesAsync';
    public static FIXASSET_REPORT_BY_ID = BASE_FIXASSET_URL + 'AssetReport/GetAllAssetReportsAsync';
    public static FIXASSET_REPORT_BY_USER_ID = BASE_FIXASSET_URL + 'AssetReport/GetAllAssetReportsByUserIdAsync';
    public static FIXASSET_REPORT_ADD = BASE_FIXASSET_URL + 'AssetReport/AddAssetReportFilterAsync';
    public static FIXASSET_REPORT_UPDATE = BASE_FIXASSET_URL + 'AssetReport/UpdateAssetReportAsync';
    public static FIXASSET_REPORT_FILTER_BY_TYPE = BASE_FIXASSET_URL + 'AssetReport/GetAllUserAssetReportsByTypeAsync';
    public static FIXASSET_DASHBOARD_TOTALASSET = BASE_FIXASSET_URL + 'Asset/GetAssetSummaryAsync';
    public static FIXASSET_DASHBOARD_BRAND_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByBrandSummaryAsync';
    public static FIXASSET_DASHBOARD_BRAND_MODEL_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByBrandModelSummaryAsync';
    public static FIXASSET_DASHBOARD_PRODUCT_FAMILY_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByProductFamilySummaryAsync';
    public static FIXASSET_DASHBOARD_PRODUCT_CATEGORY_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByProductCategorySummaryAsync';
    public static FIXASSET_DASHBOARD_ASSET_BYSTATUS_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByAssetStatusSummaryAsync';


    // PRODUCT
    public static PRODUCT_FAMILY_GETBYID = BASE_PRODUCT_URL + 'ProductFamily/GetAllProductFamiliesAsync';
    public static PRODUCT_FAMILY_LOOKUPS = BASE_PRODUCT_URL + 'ProductFamily/GetAllProductFamilyLookupsAsync';
    public static PRODUCT_FAMILY_LIST = BASE_PRODUCT_URL + 'ProductFamily/GetAllProductFamiliesByFiltersAsync';
    public static PRODUCT_FAMILY_ADD = BASE_PRODUCT_URL + 'ProductFamily/AddProductFamilyAsync';
    public static PRODUCT_FAMILY_UPDATE = BASE_PRODUCT_URL + 'ProductFamily/UpdateProductFamilyAsync';
    public static PRODUCT_FAMILY_ARCHIVE = BASE_PRODUCT_URL + 'ProductFamily/ArchiveProductFamilyAsync';
    public static PRODUCT_FAMILY_DELETE = BASE_PRODUCT_URL + 'ProductFamily/DeleteProductFamilyAsync';

    public static PRODUCT_CATEGORY_GETBYID = BASE_PRODUCT_URL + 'ProductCategory/GetAllProductCategoriesAsync';
    public static PRODUCT_CATEGORY_LOOKUPS = BASE_PRODUCT_URL + 'ProductCategory/GetAllProductCategoryLookupsAsync';
    public static PRODUCT_CATEGORY_LIST = BASE_PRODUCT_URL + 'ProductCategory/GetAllProductCategoriesByFiltersAsync';
    public static PRODUCT_CATEGORY_ADD = BASE_PRODUCT_URL + 'ProductCategory/AddProductCategoryAsync';
    public static PRODUCT_CATEGORY_UPDATE = BASE_PRODUCT_URL + 'ProductCategory/UpdateProductCategoryAsync';
    public static PRODUCT_CATEGORY_ARCHIVE = BASE_PRODUCT_URL + 'ProductCategory/ArchiveProductCategoryAsync';
    public static PRODUCT_CATEGORY_DELETE = BASE_PRODUCT_URL + 'ProductCategory/DeleteProductCategoryAsync';

    public static PRODUCT_LINE_GETBYID = BASE_PRODUCT_URL + 'ProductLine/GetAllProductLinesAsync';
    public static PRODUCT_LINE_LOOKUPS = BASE_PRODUCT_URL + 'ProductLine/GetAllProductLineLookupsAsync';
    public static PRODUCT_LINE_LIST = BASE_PRODUCT_URL + 'ProductLine/GetAllProductLinesByFiltersAsync';
    public static PRODUCT_LINE_ADD = BASE_PRODUCT_URL + 'ProductLine/AddProductLineAsync';
    public static PRODUCT_LINE_UPDATE = BASE_PRODUCT_URL + 'ProductLine/UpdateProductLineAsync';
    public static PRODUCT_LINE_ARCHIVE = BASE_PRODUCT_URL + 'ProductLine/ArchiveProductLineAsync';
    public static PRODUCT_LINE_DELETE = BASE_PRODUCT_URL + 'ProductLine/DeleteProductLineAsync';

    public static PRODUCT_TYPE_GETBYID = BASE_PRODUCT_URL + 'ProductType/GetAllProductTypesAsync';
    public static PRODUCT_TYPE_LOOKUPS = BASE_PRODUCT_URL + 'ProductType/GetAllProductTypeLookupsAsync';
    public static PRODUCT_TYPE_LIST = BASE_PRODUCT_URL + 'ProductType/GetAllProductTypesByFiltersAsync';
    public static PRODUCT_TYPE_ADD = BASE_PRODUCT_URL + 'ProductType/AddProductTypeAsync';
    public static PRODUCT_TYPE_UPDATE = BASE_PRODUCT_URL + 'ProductType/UpdateProductTypeAsync';
    public static PRODUCT_TYPE_ARCHIVE = BASE_PRODUCT_URL + 'ProductType/ArchiveProductTypeAsync';
    public static PRODUCT_TYPE_DELETE = BASE_PRODUCT_URL + 'ProductType/DeleteProductTypeAsync';

    public static BRAND_ADD = BASE_PRODUCT_URL + 'Brand/AddBrandAsync';
    public static BRAND_LOOKUPS = BASE_PRODUCT_URL + 'Brand/GetAllBrandLookupsAsync';

    public static PRODUCT_MODEL = BASE_PRODUCT_URL + 'Model/GetAllModelsAsync';
    public static PRODUCT_MODEL_ADD = BASE_PRODUCT_URL + 'Model/AddModelAsync';
    public static PRODUCT_MODEL_BY_TENANT_ID = BASE_PRODUCT_URL + 'Model/GetAllModelsByTenantIdAsync';
    public static PRODUCT_MODEL_LOOKUPS = BASE_PRODUCT_URL + 'Model/GetAllModelLookupsAsync';

    // Survey
    public static SURVEY_LIST = BASE_SURVEY_URL + 'Survey/GetAllSurveysByFiltersAsync';
    public static SURVEY_DELETE = BASE_SURVEY_URL + 'Survey/DeleteSurveysAsync';
    public static SURVEY_DETAIL = BASE_SURVEY_URL + 'Survey/GetAllSurveysAsync';
    public static SURVEY_ADD = BASE_SURVEY_URL + 'Survey/AddSurveyAsync';
    public static SURVEY_FORM_BY_SURVEY_ID = BASE_SURVEY_URL + 'SurveyDesign/GetAllSurveyFormsBySurveyIdAsync';
    public static SURVEY_FORM_LIST = BASE_SURVEY_URL + 'SurveyDesign/GetAllSurveyFormsAsync';
    public static SURVEY_FORM_ADD = BASE_SURVEY_URL + 'SurveyDesign/AddSurveyFormAsync';
    public static SURVEY_FORM_UPDATE = BASE_SURVEY_URL + 'SurveyDesign/UpdateSurveyFormsAsync';
    public static SURVEY_FORM_UPDATE_ORDER = BASE_SURVEY_URL + 'SurveyDesign/UpdateSurveyFormOrderAsync';
    public static SURVEY_FORM_DELETE = BASE_SURVEY_URL + 'SurveyDesign/DeleteSurveyFormAsync';
    public static SURVEY_QUESTION_LIST = BASE_SURVEY_URL + 'SurveyDesign/GetAllSurveyQuestionsAsync';
    public static SURVEY_QUESTION_BY_FORM_ID = BASE_SURVEY_URL + 'SurveyDesign/GetAllSurveyQuestionsBySurveyFormIdAsync';
    public static SURVEY_QUESTION_ADD = BASE_SURVEY_URL + 'SurveyDesign/AddSurveyQuestionAsync';
    public static SURVEY_QUESTION_UPDATE = BASE_SURVEY_URL + 'SurveyDesign/UpdateSurveyQuestionAsync';
    public static SURVEY_QUESTION_DELETE = BASE_SURVEY_URL + 'SurveyDesign/DeleteSurveyQuestionAsync';
    public static SURVEY_ANSWER_LIST = BASE_SURVEY_URL + 'SurveyDesign/GetAllSurveyAnswersAsync';
    public static SURVEY_ANSWER_ADD = BASE_SURVEY_URL + 'SurveyDesign/AddSurveyAnswerAsync';
    public static SURVEY_ANSWER_UPDATE = BASE_SURVEY_URL + 'SurveyDesign/UpdateSurveyAnswerAsync';
    public static SURVEY_ANSWER_DELETE = BASE_SURVEY_URL + 'SurveyDesign/DeleteSurveyAnswerAsync';
    public static SURVEY_ANSWER_ARCHIVE = BASE_SURVEY_URL + 'SurveyDesign/ArchiveSurveyAnswerAsync';
    public static SURVEY_UPDATE = BASE_SURVEY_URL + 'Survey/UpdateSurveysAsync';
    public static SURVEY_BASIC_DETAIL = BASE_SURVEY_URL + 'Survey/GetAllSurveysAsync';
    public static SURVEY_TYPE = BASE_SURVEY_URL + 'SurveyType/GetAllSurveyTypeByIdAsync';
    public static SURVEY_QUESTION_TYPE = BASE_SURVEY_URL + 'SurveyQuestion/GetAllSurveyComponentByIdAsync';
    public static SURVEY_MEMBER_BY_SURVEYID = BASE_SURVEY_URL + 'Survey/GetAllSurveyUsersBySurveyIdAsync';
    public static SURVEY_RESPONSE_DETAIL_BY_QUESTIONID = BASE_SURVEY_URL + 'SurveyResponse/GetAllSurveyResponseDetailsBySurveyQuestionIdAsync';
    public static SURVEY_RESPONSE_DETAIL_BY_ANSWERID = BASE_SURVEY_URL + 'SurveyResponse/GetAllSurveyResponseDetailsBySurveyAnswerIdAsync';
    public static SURVEY_RESPONSE_BY_SURVEYID = BASE_SURVEY_URL + 'SurveyResponse/GetAllSurveyResponsesBySurveyIdAsync';
    public static SURVEY_APP_ID = '8081';
    public static SURVEY_APP_TYPE = '101';

    // Tenant
    public static LOCATION = BASE_URL + 'Location/GetAllLocationsByIdAsync';
    public static LOCATION_BY_ORG_ID = BASE_URL + 'Location/GetAllLocationsByOrganizationIdAsync';
    public static ORGANIZATION = BASE_URL + 'Organization/GetAllOrganizationsByIdAsync';
    public static USER = BASE_URL + 'Auth/GetUserByIdAsync';
    public static USER_BY_TENANT_ID = BASE_URL + 'Auth/GetUsersByTenantIdAsync';
    public static TENANT_SYSTEM_SETTINGS_BY_TYPE = BASE_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';

    // Account
    public static ACC_ACCOUNT = BASE_ACCOUNT_URL + 'Account/GetAllAccountsByFiltersAsync';
    public static ACC_ACCOUNT_BY_ID = BASE_ACCOUNT_URL + 'Account/GetAllAccountsAsync';
    public static ACC_ACCOUNT_LOOKUP = BASE_ACCOUNT_URL + 'Account/GetAllInternalAccountLookupsAsync';
    public static ACC_ACCOUNT_ADD = BASE_ACCOUNT_URL + 'Account/AddAccountAsync';
    public static ACC_ORGANIZATION = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsByFiltersAsync';
    public static ACC_ORGANIZATION_BY_ID = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsAsync';
    public static ACC_ORGANIZATION_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsByTenantIdAsync';
    public static ACC_ORGANIZATION_BY_ACCOUNT_ID = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsByAccountIdAsync';
    public static ACC_ORGANIZATION_ADD = BASE_ACCOUNT_URL + 'Organization/AddOrganizationAsync';
    public static ACC_UNIT = BASE_ACCOUNT_URL + 'Unit/GetAllUnitsAsync';
    public static ACC_UNIT_ADD = BASE_ACCOUNT_URL + 'Unit/AddUnitAsync';
    public static ACC_UNIT_BY_ORGANIZATION_ID = BASE_ACCOUNT_URL + 'Unit/GetAllUnitsByOrganizationIdAsync';
    public static ACC_LOCATION = BASE_ACCOUNT_URL + 'Location/GetAllLocationsByFiltersAsync';
    public static ACC_LOCATION_BY_ID = BASE_ACCOUNT_URL + 'Location/GetAllLocationsAsync';
    public static ACC_LOCATION_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Location/GetAllLocationsByTenantIdAsync';
    public static ACC_LOCATION_ADD = BASE_ACCOUNT_URL + 'Location/AddLocationAsync';
    public static ACC_LOCATION_BY_UNIT_ID = BASE_ACCOUNT_URL + 'Location/GetAllLocationsByUnitIdAsync';
    public static ACC_ZONE = BASE_ACCOUNT_URL + 'Zone/GetAllZonesByFiltersAsync';
    public static ACC_ZONE_BY_ID = BASE_ACCOUNT_URL + 'Zone/GetAllZonesAsync';
    public static ACC_ZONE_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Zone/GetAllZonesByTenantIdAsync';
    public static ACC_ZONE_BY_LOCATION_ID = BASE_ACCOUNT_URL + 'Zone/GetAllZonesByLocationIdAsync';
    public static ACC_ZONE_ADD = BASE_ACCOUNT_URL + 'Zone/AddZoneAsync';
    public static ACC_VENDOR_ACCOUNT_LOOKUP = BASE_ACCOUNT_URL + 'Account/GetAllVendorAccountLookupsAsync';

    // System settings
    public static LANGUAGES = BASE_URL + '';

    // Profile
    public static PROFILE = BASE_URL + 'Auth/GetLoggedInUserAsync';
    public static CHANGE_PASSWORD = BASE_URL + 'Auth/ChangePassword';
    public static USER_PREFERENCE = BASE_URL + 'Auth/UpdateUserPreference';
    public static USER_PROFILE = BASE_URL + 'Auth/UpdateUserProfile';
    public static ACCOUNT = BASE_ACCOUNT_URL + 'Account/GetAllInternalAccountLookupsAsync';
    public static UPDATE_USER_CONTACT = BASE_URL + 'Auth/UpdateUserContact';
    public static EMPLOYEE_POSITION_BY_ID = BASE_HRM_URL + 'EmployeePosition/GetAllEmployeePositionsByUserIdAsync';
    public static HRM_SYSTEM_SETTINGS_BY_TYPE = BASE_HRM_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';
    public static EMPLOYEE_MANAGER_BY_USER_ID = BASE_HRM_URL + 'Employee/GetEmployeeManagersByUserIdAsync';
    public static ADD_EMPLOYMENT_POSITION = BASE_HRM_URL + 'EmployeePosition/AddEmployeePositionAsync';
    public static UPDATE_EMPLOYMENT_POSITION = BASE_HRM_URL + 'EmployeePosition/UpdateEmployeePositionsAsync';
    public static EMPLOYEE_BANK_BY_USER_ID = BASE_HRM_URL + 'EmployeeBank/GetAllEmployeeBanksByUserIdAsync';
    public static ADD_APP_SETTINGS = BASE_URL + 'AppSetting/AddAppSettingAsync';
    public static ADD_EMPLOYEE_BANK = BASE_HRM_URL + 'EmployeeBank/AddEmployeeBankAsync';
    public static UPDATE_EMPLOYEE_BANK = BASE_HRM_URL + 'EmployeeBank/UpdateEmployeeBanksAsync';
    public static DEACTIVATE_EMPLOYEE_BANK_ACCOUNT = BASE_HRM_URL + 'EmployeeBank/ArchiveEmployeeBanksAsync';
    public static DELETE_EMPLOYEE_BANK_ACCOUNT = BASE_HRM_URL + 'EmployeeBank/DeleteEmployeeBanksAsync';
    public static USER_PROFILE_IMAGE = BASE_FILE_MANAGEMENT_URL + 'Image/profile/get';
    public static SET_USER_PROFILE_IMAGE = BASE_FILE_MANAGEMENT_URL + 'Image/profile/set';
    public static REMOVE_USER_PROFILE_IMAGE = BASE_FILE_MANAGEMENT_URL + 'Image/profile/remove';
    public static UPDATE_USER_STATUS = BASE_URL + 'Auth/UpdateUserStatus';
    public static EXPENSES_BY_FILTER = BASE_HRM_URL + 'EmployeeExpense/GetAllEmployeeExpensesByFiltersAsync';
    public static TASK_CARD_BY_FILTER = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsByFiltersAsync';
    public static USER_LOOK_UP = BASE_URL + 'Auth/GetUserLookupsAsync';
    public static ADD_EMPLOYEE_EXPENSE = BASE_HRM_URL + 'EmployeeExpense/AddEmployeeExpenseAsync';
    public static UPDATE_EMPLOYEE_EXPENSE = BASE_HRM_URL + 'EmployeeExpense/UpdateEmployeeExpensesAsync';
    public static EXPENSE_CATEGORIES_ASYNC = BASE_HRM_URL + 'SystemSetting/GetExpenseCategoryAsync';
    public static EXPENSES_SUMMARY = BASE_HRM_URL + 'EmployeeExpense/GetAllEmployeeExpenseSummaryByFiltersAsync';
    public static ARCHIVE_EXPENSES = BASE_HRM_URL + 'EmployeeExpense/ArchiveEmployeeExpensesAsync';
    public static UPDATE_EXPENSE_STATUS = BASE_HRM_URL + 'EmployeeExpense/UpdateEmployeeExpensesStatusAsync';

    // Comment
    // public static ADD_COMMENT = BASE_COMMENT_URL + 'Comment/AddCommentAsync';
    public static COMMENT_BY_SERVICE_ID = BASE_COMMENT_URL + 'Comment/GetAllCommentsByCommentServiceIdAsync';

    public static ADD_COMMENT = BASE_SOCIAL_COMMENT_URL + 'AddCommentAsync';
    public static UPDATE_COMMENT = BASE_SOCIAL_COMMENT_URL + 'UpdateCommentsAsync';
    public static GET_COMMENT_BY_SERVICE_ID = BASE_SOCIAL_COMMENT_URL + 'GetAllCommentsByServiceIdAsync';
    public static ARCHIVE_COMMENT = BASE_SOCIAL_COMMENT_URL + 'ArchiveCommentsAsync';

    // FileManagement
    public static GET_ALL_FILES_FOLDERS = BASE_FILE_MANAGEMENT_URL + 'File/GetAllActiveSubFilesFoldersAsync';
    public static GET_ALL_FILES_BY_SERVICE_ID = BASE_FILE_MANAGEMENT_URL + 'File/GetAllFilesFoldersByServiceIdAsync';
    public static SEARCH_ALL_FILES_FOLDERS = BASE_FILE_MANAGEMENT_URL + 'File/SearchAllSubFilesFoldersAsync';
    public static CREATE_NEW_FOLDER = BASE_FILE_MANAGEMENT_URL + 'File/AddFolderAsync';
    public static UPLOAD_FILE = BASE_FILE_MANAGEMENT_URL + 'File/AddFileAsync';
    public static ARCHIVE_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'File/ArchiveFilesAsync';
    public static UNARCHIVE_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'File/UnarchiveFilesAsync';
    public static DELETE_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'File/DeleteFilesAsync';
    public static DOWNLOAD_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'File/GetS3FileStream';
    public static UPDATE_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'File/UpdateFilesFoldersAsync';
    public static SET_FAVORITE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/SetFavoriteFileForServiceAsync';
    public static UNSET_FAVORITE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/UnSetFavoriteFileForServiceAsync';
    public static DELETE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/DeleteFilesAsync';
    public static MOVE_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'File/MoveFileFolderAsync';
    public static PREVIEW_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'Image/preview/file';
    public static PREVIEW_THUMBNAIL_REQUEST = BASE_FILE_MANAGEMENT_URL + 'Image/preview/thumbnail';
    public static SET_MAIN_FILE = BASE_FILE_MANAGEMENT_URL + 'File/SetMainFileForServiceAsync';
    public static GET_ALL_ARCHIVED_FILES_FOLDERS = BASE_FILE_MANAGEMENT_URL + 'File/GetAllArchivedFilesFoldersAsync';
    public static REMOVE_ALL_ARCHIVED_FILES_FOLDERS = BASE_FILE_MANAGEMENT_URL + 'File/RemoveAllArchivedAsync';

    // Tags
    public static GET_ALL_TAGS = BASE_TAGS_URL + 'Tag/GetAllTagsByTagServiceIdAsync';
    public static SAVE_TAGS = BASE_TAGS_URL + 'Tag/AddTagAsync';
    public static DELETE_TAGS = BASE_TAGS_URL + 'Tag/DeleteTagAsync';
    public static GET_ALL_TAGS_BY_APP_ID = BASE_TAGS_URL + 'Tag/GetAllTagsByTagAppIdAsync';
    public static ARCHIVE_TAGS = BASE_TAGS_URL + 'Tag/ArchiveTagAsync';

    // Notifications
    public static NEGOTIATE_NOTIFICATIONS_CHANNEL = BASE_NOTIFICATIONS_URL + 'notifications-hub';
    public static INVOKE_NOTIFICATIONS_CHANNEL = BASE_NOTIFICATIONS_URL + 'Notifications/InvokeChannel';
    public static STOP_NOTIFICATIONS_CHANNEL = BASE_NOTIFICATIONS_URL + 'Notifications/StopChannel';
    public static GET_NOTIFICATIONS = BASE_NOTIFICATIONS_URL + 'Notifications/Get';
    public static MARK_READ_NOTIFICATION = BASE_NOTIFICATIONS_URL + 'Notifications/MarkRead';
    public static DELETE_NOTIFICATION = BASE_NOTIFICATIONS_URL + 'Notifications/Delete';

    //Calendars & Reminders
    public static GET_REMINDERS = BASE_CALENDAR_URL + 'Reminder/Get';
    public static CREATE_REMINDER = BASE_CALENDAR_URL + 'Reminder/AddReminderAsync';
    public static MARK_READ_REMINDER = BASE_CALENDAR_URL + 'Reminder/MarkRead';
    public static DELETE_REMINDER = BASE_CALENDAR_URL + 'Reminder/DeleteReminderAsync';
    public static GET_DEFAULT_CALENDAR = BASE_CALENDAR_URL + 'Calendar/GetUserMainPrivateCalendarAsync';
    public static GET_ALL_CALENDARS = BASE_CALENDAR_URL + 'Calendar/GetAllCalendarsByUserIdAsync';
    public static GET_REMINDERS_BY_SERVICE_ID = BASE_CALENDAR_URL + 'Reminder/GetAllRemindersByServiceIdAsync'
    public static GET_REMINDERS_BY_FILTERS = BASE_CALENDAR_URL + 'Reminder/GetAllRemindersByCalendarFiltersAsync'
    public static CREATE_TODOS = BASE_TASK_URL + 'TaskToDo/AddTaskToDoAsync'
    public static GET_TODOS_BY_FILTERS = BASE_TASK_URL + 'TaskToDo/GetAllTaskToDosByFiltersAsync'
    public static CREATE_EVENT = BASE_CALENDAR_URL + 'CalendarEvent/AddCalendarEventAsync';
    public static GET_ALL_EVENTS = BASE_CALENDAR_URL + 'CalendarEvent/GetAllCalendarEventsByFiltersAsync';

    // Team
    public static GET_TEAM_BY_SERVICE_ID = BASE_TEAM_URL + 'Team/GetAllTeamsByTeamServiceIdAsync';
    public static GET_TEAM_BY_APP_ID = BASE_TEAM_URL + 'Team/GetAllTeamsByTeamServiceIdAsync';
    public static GET_TEAM_BY_ID = BASE_TEAM_URL + 'Team/GetAllTeamsAsync';
    public static ADD_TEAM = BASE_TEAM_URL + 'Team/AddTeamAsync';
    public static UPDATE_TEAM = BASE_TEAM_URL + 'Team/UpdateTeamAsync';
    public static DELETE_TEAM = BASE_TEAM_URL + 'Team/DeleteTeamAsync';
    public static ARCHIVE_TEAM = BASE_TEAM_URL + 'Team/ArchiveTeamAsync';
    public static GET_TEAM_LOOKUPS = BASE_TEAM_URL + 'Team/GetAllTeamLookupsByServiceIdAsync';
    public static TEAM_LOOKUPS = BASE_TEAM_URL + 'Team/GetAllTeamLookupsByLoggedInUserAsync'

    // Team Member
    public static GET_TEAM_MEMBER_BY_TEAM_ID = BASE_TEAM_URL + 'TeamMember/GetAllTeamMembersByTeamIdAsync';
    public static ADD_TEAM_MEMBER = BASE_TEAM_URL + 'TeamMember/AddTeamMemberAsync';
    public static ADD_TEAM_MEMBER_LIST = BASE_TEAM_URL + 'TeamMember/AddTeamMemberListAsync';
    public static UPDATE_TEAM_MEMBER = BASE_TEAM_URL + 'TeamMember/UpdateTeamMemberAsync';
    public static DELETE_TEAM_MEMBER = BASE_TEAM_URL + 'TeamMember/DeleteTeamMemberAsync';
    public static ARCHIVE_TEAM_MEMBER = BASE_TEAM_URL + 'TeamMember/ArchiveTeamMemberAsync';

    // Team Service
    public static ADD_SERVICE_TEAM = BASE_TEAM_URL + 'TeamService/AddServiceTeamAsync';
    public static ADD_SERVICE_TEAM_LIST = BASE_TEAM_URL + 'TeamService/AddServiceTeamListAsync';
    public static GET_SERVICE_TEAM_BY_SERVICE_ID = BASE_TEAM_URL + 'TeamService/GetAllServiceTeamsByServiceIdAsync';
    public static DELETE_TEAM_SERVICE = BASE_TEAM_URL + 'TeamService/DeleteServiceTeamAsync';

    // Team queue service
    public static ADD_SERVICE_TEAM_QUEUE = BASE_TEAM_URL + 'TeamQueueService/AddServiceTeamQueueAsync';

    // System Settings
    public static ALL_TENANT_SYSTEM_SETTINGS = BASE_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';
    public static ALL_TASK_SYSTEM_SETTINGS = BASE_TASK_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';
}
