import {Injectable} from '@angular/core';
import {HttpHelperService} from './http-helper.service';
import {SharedService} from './shared.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpMethodsTypeEnum} from '../shared-constants';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class APIManager extends HttpHelperService {
    readonly httpClient: HttpClient;

    constructor(_sharedService: SharedService, http: HttpClient) {
        super(_sharedService, http);
        this.httpClient = http;
    }

    httpHelperMethod(
        methodType: HttpMethodsTypeEnum,
        url: string,
        params = {},
        httpOptions = this.Authorised_HttpOptions,
        showSnackBar,
        showLoader,
        searchParams = {},
        filesObj?: any
    ): Observable<any> {
        if (showLoader) {
            this._sharedService.setLoader(true);
        }
        if (
            methodType === HttpMethodsTypeEnum.POST_MULTIPART ||
            methodType === HttpMethodsTypeEnum.PUT_MULTIPART
        ) {
            const formData = this.createFormDataObject(params, filesObj);
            params = formData;
        }
        if (methodType === HttpMethodsTypeEnum.POST_MULTIPART_UNIQUE) {
            methodType = HttpMethodsTypeEnum.POST_MULTIPART;
            const formData = this.createFormDataObjectUnique(params, filesObj);
            params = formData;
        }
        return super.httpHelperMethod(
            methodType,
            url,
            params,
            httpOptions,
            showSnackBar,
            showLoader,
            searchParams,
            filesObj
        );
    }

    getImage(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, {responseType: 'blob'});
    }

    get Authorised_HttpOptions() {
        const authToken = this._sharedService.getToken();
        const httpOptions = new HttpHeaders({
            Authorization: `Bearer ${authToken}`
        });
        return {headers: httpOptions};
    }

    Authorised_SignUp_HttpOptions(authToken: string) {
        const httpOptions = new HttpHeaders({
            Authorization: `Bearer ${authToken}`
        });
        return {headers: httpOptions};
    }

    get Email_Verify_HttpOptions() {
        const authToken = this._sharedService.getVerificationToken();
        const httpOptions = new HttpHeaders({
            remembertoken: authToken
        });
        return {headers: httpOptions};
    }

    get FORGOT_PASSWORD_HttpOptions() {
        const authToken = this._sharedService.getVerificationToken();
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            Token: `Bearer ${authToken}`
        });
        return {headers: httpOptions};
    }

    get Content_Type_HttpOptions() {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return {headers: httpOptions};
    }

    get Form_URL_Encoded_HttpOptions() {
        const authToken = this._sharedService.getToken();
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `${authToken}`
        });
        return {headers: httpOptions};
    }

    get Blob_HttpOptions(): any {
        const authToken = this._sharedService.getToken();
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${authToken}`
            }),
            responseType: 'blob'
        };
    }

    get Blob_HttpOptions_image(): any {
        const authToken = this._sharedService.getToken();
        const httpOptions = new HttpHeaders({
            Authorization: `Bearer ${authToken}`
        });
        return {
            headers: httpOptions,
            responseType: 'blob'
        };
    }

    get Blob_HttpOptions_2(): any {
        const authToken = this._sharedService.getToken();
        return {
            headers: new HttpHeaders({}),
            responseType: 'blob'
        };
    }

    get Auth_Token(): string {
        return this._sharedService.getToken();
    }

    createFormDataObject = (params, filesObj) => {
        const formData = new FormData();
        for (const obj of filesObj) {
            const imgFilesObj: File[] = obj['files'];
            for (let i = 0; i < imgFilesObj.length; i++) {
                formData.append(obj['reqKey'], imgFilesObj[i], imgFilesObj[i].name);
            }
        }
        if (params && Object.keys(params).length) {
            for (const docKey in params) {
                if (typeof params[docKey] === 'object') {
                    formData.append(docKey, JSON.stringify(params[docKey]));
                } else {
                    formData.append(docKey, params[docKey]);
                }
            }
        }
        return formData;
    };

    createFormDataObjectUnique = (params, filesObj) => {
        const formData = new FormData();
        for (const obj of filesObj) {
            const imgFilesObj: File[] = obj['files'];
            for (let i = 0; i < imgFilesObj.length; i++) {
                // const param = {
                //     'email': obj['email'],
                //     'name': imgFilesObj[i].name
                // };
                const param = {};
                param['email'] = obj['email'];
                param['name'] = imgFilesObj[i].name;
                formData.append(obj['reqKey'], imgFilesObj[i], JSON.stringify(param));
            }
        }
        if (params && Object.keys(params).length) {
            for (const docKey in params) {
                if (typeof params[docKey] === 'object') {
                    formData.append(docKey, JSON.stringify(params[docKey]));
                } else {
                    formData.append(docKey, params[docKey]);
                }
            }
        }
        return formData;
    };
}
