import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onDeleteComment = (team) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "500px",
        data: {
            message: `Are you sure want to delete comment?`,
            title: `Are you sure?`,
        },
    });
  }

}
