import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {locale as english} from '../i18n/en';
import {locale as turkish} from '../i18n/tr';
import {locale as german} from '../i18n/de';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {fuseAnimations} from '@fuse/animations';
import {FormBaseComponent, LANGUAGES, removeEmptyFields, RouteConstants} from 'app/utility';
import {SharedService} from '../../../../../utility/shared-services';
import {LoginService} from '../../services';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent extends FormBaseComponent implements OnInit {
    // Form variables
    forgotPasswordForm: FormGroup;

    // Constant variables
    languages = LANGUAGES;

    // Data variables
    selectedLanguage: string = LANGUAGES.English;

    constructor(
        fb: FormBuilder,
        private loginService: LoginService,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private sharedService: SharedService
    ) {
        super(fb);
        // Set the language defaults
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, german);
    }

    ngOnInit(): void {
        this.selectedLanguage = this.sharedService.getLanguageValue() ? this.sharedService.getLanguageValue() : LANGUAGES.English;
        this.forgotPasswordForm = this._fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    // Page events
    onLogin = () => {
        this.router.navigate(['/' + RouteConstants.LOGIN]);
    };

    forgotPassword(form: FormGroup): void {
        if (this.onSubmit(form)) {
            const params = removeEmptyFields(form.value);
            this.loginService.forgotPassword(params).subscribe((response) => {
                this.onLogin();
            });
        }
    }

    setLanguage(lang: string): void {
        this.selectedLanguage = lang;
        this.sharedService.setLanguageChange(lang);
    }

    // Helper methods
    isLangActive = (lang: string) => {
        return lang === this.selectedLanguage;
    };
}
