import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { locale as english } from '../i18n/en';
import { locale as turkish } from '../i18n/tr';
import { locale as german } from '../i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';
import { appLogger, FormBaseComponent, LANGUAGES, removeEmptyFields } from '../../../../../utility';
import { SharedService } from '../../../../../utility/shared-services';
import { RouteConstants } from 'app/utility';
import { LoginService } from '../../services';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent extends FormBaseComponent implements OnInit {
    // Form group variables
    loginForm: FormGroup;
    rememberMeFormControl: FormControl = new FormControl(false);

    // Constant variables
    languages = LANGUAGES;

    // Data variables
    photoUrl: string;
    userName: string;
    selectedLanguage: string = LANGUAGES.English;

    constructor(
        fb: FormBuilder,
        private loginService: LoginService,
        private router: Router,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _formBuilder: FormBuilder,
        private sharedService: SharedService,
        private socialAuthService: SocialAuthService,
        private route: ActivatedRoute
    ) {
        super(fb);
        // Set the language defaults
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, german);
    }

    ngOnInit(): void {
        this.selectedLanguage = this.sharedService.getLanguageValue() ? this.sharedService.getLanguageValue() : LANGUAGES.English;
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            idToken: [''],
            loginType: ['']
        });
    }

    onSignUp = () => {
        this.router.navigate(['/' + RouteConstants.SIGN_UP]);
    };

    onRegisterCompany = () => {
        this.router.navigate(['/' + RouteConstants.TENANT_REGISTER]);
    };

    onForgotPassword = () => {
        this.router.navigate(['/' + RouteConstants.FORGOT_PASSWORD]);
    };

    onSubmitLoginForm(form: FormGroup): void {
        if (this.onSubmit(form)) {
            const params = removeEmptyFields(form.value);
            this.loginService.login(params).subscribe((response) => {
                this.handleAdminLoginResponse(response);
            });
        }
    }

    handleAdminLoginResponse(response: any) {
        if (this.rememberMeFormControl.value) {
            this.sharedService.setLocalToken(response.data.token);
        } else {
            this.sharedService.setSessionToken(response.data.token);
        }
        this.getProfileData();
    }

    getProfileData = () => {
        this.loginService.getProfileData().subscribe((profileData) => {
            if (this.rememberMeFormControl.value) {
                this.sharedService.setLocalUser(profileData.data);
            } else {
                this.sharedService.setSessionUser(profileData.data);
            }
            this.sharedService.setLoginRequired(true);
            let path: string;
            this.route.queryParams.subscribe((params: Params) => path = params['redirectUrl']);
            if(path) {
                this.router.navigate([path]);
            } else {
                this.router.navigate(['/' + RouteConstants.HOME]);
            }
        });
    };

    setLanguage(lang: string): void {
        this.selectedLanguage = lang;
        this.sharedService.setLanguageChange(lang);
    }

    // Helper methods
    isLangActive = (lang: string) => {
        return lang === this.selectedLanguage;
    };

    async loginWithGoogle() {
        this.loginForm.markAsUntouched();
        var user = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
        if (user?.idToken) {
            this.loginForm.patchValue({
                email: '',
                password: '',
                idToken: user.idToken,
                loginType: 'GOOGLE',
            });
            this.sociallogin();
        }
    }
    sociallogin() {
        const params = removeEmptyFields(this.loginForm.value);
        this.loginService.socialLogin(params).subscribe((response) => {
            this.handleAdminLoginResponse(response);
        });
    }
}
