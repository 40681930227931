import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

    message = 'Are you sure want to delete?';
    title = 'Delete Item';
    actionMessage = '';

    @Output() confirm = new EventEmitter<any>();

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        if (this.data && this.data.message) {
            this.message = this.data.message;
        }
        if (this.data && this.data.title) {
            this.title = this.data.title;
        }
        if (this.data && this.data.actionMessage) {
            this.actionMessage = this.data.actionMessage;
        }
    }

    onCloseDialog(submit = true): void {
        if (submit) {
            this.confirm.emit({submit});
        }
        this.dialogRef.close({submit});
    }
}
