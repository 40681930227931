import { Injectable } from '@angular/core';
import { AppApi, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';


export class Reminder {
    id: string;
    message: string;
    date_trigger: any;
    read: boolean;
    deleted: boolean = false;
}

export class ReminderResponse {
    reminders: Reminder[];
    unread: number;
    start?: number;
    end?: number;
}

@Injectable({
    providedIn: 'root'
})
export class RemindersService {
    private readonly unread$$ = new BehaviorSubject<number>(0);
    readonly unread$ = this.unread$$.asObservable();
    constructor
        (
            private readonly sharedService: SharedService,
            private readonly apiManager: APIManager
        ) {

    }

    getReminders(payload): Observable<ReminderResponse> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.GET_REMINDERS, payload,
            this.apiManager.Authorised_HttpOptions, false, true).
            pipe(
                map(result => result),
                publishReplay(),
                refCount()
            );
    }
    getCountsOfReminders(payload): Observable<ReminderResponse> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.GET_REMINDERS, payload,
            this.apiManager.Authorised_HttpOptions, false, true).
            pipe(
                tap((result: ReminderResponse) => this.unread$$.next(result.unread)),
                publishReplay(),
                refCount()
            );
    }

    async mark(id: string, all = false) {
        if (this.unread$$.value >= 1) {
            this.unread$$.next(all ? 0 : this.unread$$.value - 1);
        }
        return await this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.MARK_READ_REMINDER, { id: id, all: all },
            this.apiManager.Authorised_HttpOptions, false, true).toPromise();

    }
    async delete(id: string, read = false) {
        if (!read && this.unread$$.value >= 1) {
            this.unread$$.next(this.unread$$.value - 1);
        }
        return await this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DELETE_REMINDER, { id: id },
            this.apiManager.Authorised_HttpOptions, false, true).toPromise();

    }
    add(reminder) {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.CREATE_REMINDER, reminder,
            this.apiManager.Authorised_HttpOptions, false, true).
            pipe(
                tap((result: ReminderResponse) => this.unread$$.next(this.unread$$.value + 1)),
            ).toPromise();

    }

    getRemindersByServiceId(id: string): Observable<any> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_REMINDERS_BY_SERVICE_ID, {service_id: id},
            this.apiManager.Authorised_HttpOptions, false, true)
    }
}