export class BASE {
    public static TOAST_TIMEOUT = 3000;
    public static ENCRYPTION_TOKEN = 'boilerplate';
}

export class HttpStatus {
    public static SUCCESS = 200;
    public static UNAUTHORIZED = 401;
    public static FORBIDDEN = 403;
    public static EXPIRED = 450;
}

export enum ToastStatus {
    UNKNOWN = 0,
    SUCCESS = 1,
    ERROR = 2,
    MULTIPLE = 3,
}

export class AppConstant {
    public static NO_DATA = 'No data found';
    public static PAGINATION_ARRAY: number[] = [5, 10, 25, 100];
    public static FIVE_MB_IMAGE_SIZE = 5000000;
}

export const globalToastConfig = {
    positionClass: 'toast-top-center',
    maxOpened: 1,
    preventDuplicates: true,
};

// IndividualConfig
export const individualToastConfig = {
    timeOut: BASE.TOAST_TIMEOUT,
    closeButton: true,
};

export enum HttpMethodsTypeEnum {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PUT_MULTIPART = 'putMultiPart',
    POST_MULTIPART = 'postMultiPart',
    POST_MULTIPART_UNIQUE = 'postMultiPartUnique'
}

export enum ProfileViewEnum {
    VIEW_PROFILE = 'view',
    EDIT_PROFILE = 'edit',
    CHANGE_PASSWORD = 'changePassword',
    CHANGE_EMAIL = 'changeEmail',
}

export enum ComapnyStatusEnum {
    REGISTERED = 'Registered',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const USER_ROLES = [
    {
        id: 1,
        role_name: 'System Admin',
    },
    {
        id: 2,
        role_name: 'Company Admin',
    },
    {
        id: 3,
        role_name: 'Company - User',
    },
];

export enum LANGUAGES {
    English = 'en',
    Turkish = 'tr',
    German = 'de'
}

export class UserRole {
    public static SYSTEM_ADMIN = 1;
    public static COMPANY_ADMIN = 2;
    public static COMPANY_USER = 3;
}

export const CALCULATION_METHOD = [
    { value: 'Stagged', display: 'Stagged' },
    { value: 'Continous', display: 'Continous' },
];

export enum AssessmentStatusEnum {
    IN_PROGRESS = 0,
    COMPLETED = 1,
}

export const assessmentStatus = [
    { id: AssessmentStatusEnum.IN_PROGRESS, value: 'In-Progress' },
    { id: AssessmentStatusEnum.COMPLETED, value: 'completed' },
];

export enum ProcedureViewEnum {
    ASSESSMENT_PROCEDURE = 'assessmentProcedure',
    IMPLEMENTATION_PROCEDURE = 'implementationProcedure',
    ATTACHMENT = 'attachment',
}

export const questionTypes = [
    { value: 1, display: 'Yes/No' },
    { value: 2, display: 'List of choice' },
    { value: 3, display: 'Text Input' },
];

export enum ExpandCollapseEnum {
    'EXPAND_ALL' = 'Expand All',
    'COLLAPSE_ALL' = 'Collapse All',
}

export const assessmentCriteriaStatus = [
    { display: 'Implemented', value: 1 },
    { display: 'Partially Implemented', value: 2 },
    { display: 'Not Implemented', value: 3 },
];

export const assessmentAuthorities = [
    { display: 'All Organisations Users', value: 1 },
    { display: 'Assessment Team', value: 2 },
    { display: 'Plan Users', value: 3 },
];

export enum TargetViewEnum {
    OVERALL_TARGET = 'overallTarget',
    DETAILED_TARGET = 'detailedTarget',
}

export enum TargetLayoutViewEnum {
    NO_TARGET_ADDED = 'noTarget',
    TARGET_ADDED = 'targetAdded',
    PREVIEW_TARGET = 'previewTarget',
}

export const targetTypesList = [
    { display: 'Overall Target', value: 0 },
    { display: 'Detailed Target', value: 1 },
];


export enum DashboardComponentViewEnum {
    ADD = 'add',
    LIST = 'list',
    DETAILS = 'details'
}

export const maximumLevel = 5;
export const maximumScore = 5;

export const assessmentStatuses = [
    { value: '0', name: 'In Progress' },
    { value: '1', name: 'Completed' },
    { value: '2', name: 'Pending' }
];

export const actionStatuses = [{ value: 0, display: 'Open' }, { value: 1, display: 'Completed' }];

export enum CommentUpvoteViewEnum {
    ATTACHMENTS = 'attachment',
    COMMENT = 'comment',
    COMMENT_LIST = 'comment-list',
    UPVOTE = 'upvote',
}

export enum FileSizeEnum {
    TWO_MB_IMAGE_SIZE = 2000000,
    TWENTY_MB_IMAGE_SIZE = 20000000,
    FIVE_MB_IMAGE_SIZE = 5000000
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD'
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'MMM DD, YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export const MY_FORMATS_FOR_TASK = {
    parse: {
        dateInput: 'YYYY-MM-DD'
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'MMM DD, YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export enum HeaderEnum {
    NOTIFICATION = 1,
    MESSAGES = 2,
    NAVIGATION = 3,
    REMINDER = 4
}

export enum GeneralStatusEnum {
    DRAFT = 0,
    ACTIVE = 1,
}

export enum TaskTypeEnum {
    MAIN_TASK = '100',
    SUB_TASK = '101',
    CHECKLIST = '102'
}

export enum CalendarEnum {
    MONTHLY = 1,
    WEEKLY = 2,
    DAILY = 3,
}