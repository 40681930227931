import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {locale as english} from '../i18n/en';
import {locale as turkish} from '../i18n/tr';
import {locale as german} from '../i18n/de';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBaseComponent, LANGUAGES, removeEmptyFields, RouteConstants} from '../../../../../utility';
import {SharedService} from '../../../../../utility/shared-services';
import {LoginService} from '../../services';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent extends FormBaseComponent implements OnInit {
    // Constant variables
    languages = LANGUAGES;

    // From variables
    resetPasswordForm: FormGroup;

    // Data variables
    selectedLanguage: string = LANGUAGES.English;
    code: string;
    userId: string;
    lang: string;

    constructor(
        fb: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private sharedService: SharedService,
        private loginService: LoginService
    ) {
        super(fb);
        // Set the language defaults
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, german);
    }

    ngOnInit(): void {
        this.createResetPasswordForm();
        this.getInitialValues();
    }

    createResetPasswordForm = () => {
        this.resetPasswordForm = this.createForm({
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required]]
        }, {
            validator: this.passwordValidator
        });
    };

    getInitialValues = () => {
        this.activatedRoute.queryParams.subscribe(params => {
            this.code = params['code'];
            this.userId = params['userid'];
            this.lang = params['lang'];
            this.selectedLanguage = this.lang ? this.lang : (this.sharedService.getLanguageValue() ? this.sharedService.getLanguageValue() : LANGUAGES.English);
            this.sharedService.setLanguageChange(this.selectedLanguage);
        });
    };

    // Api Calls
    resetPasswordApiCall = (params: any) => {
        return this.loginService.resetPassword(params);
    };

    // Page events
    resetPassword(form: FormGroup): void {
        const params = removeEmptyFields(form.value);
        params['code'] = this.code;
        params['userId'] = this.userId;
        delete params['passwordConfirm'];
        this.resetPasswordApiCall(params).subscribe(() => {
            this.onLogin();
        });
    }

    setLanguage(lang): void {
        this.selectedLanguage = lang;
        this.sharedService.setLanguageChange(lang);
    }

    onLogin = () => {
        this.router.navigate(['/' + RouteConstants.LOGIN]);
    };

    // Helper methods
    isLangActive = (lang: string) => {
        return lang === this.selectedLanguage;
    };

    passwordValidator(resetPasswordForm: FormGroup): any {
        const password = resetPasswordForm.controls.password;
        const repeatPassword = resetPasswordForm.controls.passwordConfirm;
        if (repeatPassword.value.length <= 0) {
            return null;
        }
        if (password.value.length === 0) {
            return null;
        }
        if (repeatPassword.value !== password.value) {
            repeatPassword.setErrors({incorrect: true});
            return {
                doesMatchPassword: true
            };
        } else {
            repeatPassword.setErrors(null);
        }
        return null;
    }
}


