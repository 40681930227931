import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PUBLIC_ROUTES, RouteConstants } from '../utility';
import { SharedService } from '../utility/shared-services';

@Injectable({
    providedIn: 'root'
})
export class UserAuthGuard implements CanActivate {

    constructor(private router: Router, private sharedService: SharedService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let activateRoute = true;
        const readURL = state.url.split('?')[0];

        // redirect to login or course according to logged in status and current url
        if (this.sharedService.isLoggedIn()) {
            if (PUBLIC_ROUTES.includes(readURL)) {
                this.router.navigate(['/' + RouteConstants.HOME]);
                activateRoute = false;
            }
            /*const details = this._sharedService.getOrganizationDetails();
            // @ts-ignore
            if ((details === '') || (details === null) || (isEmpty(details['organization_user'])) || (details['organization_user']['contact_number'] === null)) {
                if ((readURL !== '/' + OrgRouteConstants.SIGN_UP)) {
                    this.router.navigate(['/' + OrgRouteConstants.SIGN_UP]);
                    activateRoute = false;
                }
            } else {
                if ((readURL === '/' + OrgRouteConstants.LOGIN) || (readURL === '/' + OrgRouteConstants.SIGN_UP)) {
                    this.router.navigate(['/' + OrgRouteConstants.SEARCH_HOTEL_AND_FLIGHTS]);
                    activateRoute = false;
                }
            }*/
        } else {
            if (!PUBLIC_ROUTES.includes(readURL)) {
                this.router.navigate(['/' + RouteConstants.LOGIN], { queryParams: { redirectUrl: state.url } });
                activateRoute = false;
            }
            /*if ((readURL !== '/' + OrgRouteConstants.LOGIN) && (readURL !== '/' + OrgRouteConstants.SIGN_UP)) {
                this.router.navigate(['/' + OrgRouteConstants.LOGIN]);
                activateRoute = false;
            }*/
        }
        return activateRoute;
    }

}
