export class APPStorage {
    public static readonly TOKEN = 'at';
    public static readonly LANGUAGE = 'la';
    public static readonly VERIFICATION_TOKEN = 'vt';
    public static readonly REFRESH_TOKEN = 'rt';
    public static readonly ASSESSMENT_FILTER = 'af';
    public static readonly USER = 'u';
    public static readonly REMEMBER_ME = 'rm';
    public static readonly RESEND_OTP = 'ro';
    public static readonly VERIFY_EMAIL = 've';
    public static readonly OTP_TIMEOUT = 'ot';
    public static readonly SIGN_UP_CODE = 'sc';
}
