import { AppApi } from "app/utility";
import { constants } from "http2";
import { SurveyAnswerCreateModel } from "../models/survey-answer/survey-answer-create.model";
import { SurveyAnswerEditModel } from "../models/survey-answer/survey-answer-edit.model";
import { SurveyAnswerModel } from "../models/survey-answer/survey-answer.model";
import { SurveyCreateModel } from "../models/survey/survey-create.model";
import { SurveyEditModel } from "../models/survey/survey-edit.model";
import { SurveyModel } from "../models/survey/survey.model";

export function copyPropsSurveyObject(params: any, type: string): any {
    console.log(params, type);
    let model: any = null;
    if (type.includes('New')) {
        model = new SurveyCreateModel();
    } else if (type.includes('Edit')) {
        model = new SurveyEditModel();
        model.id = params.id;
    }
    model.description = params.description ? params.description : '';
    model.allow_user_to_see_result = params.allow_user_to_see_result ? params.allow_user_to_see_result : true;;
    model.enable_responder_signature = params.enable_responder_signature ? params.enable_responder_signature : true;
    model.enable_user_signature = params.enable_user_signature ? params.enable_user_signature : true;
    model.location_id = params.location_id;
    model.name = params.name;
    model.organization_id = params.organization_id;
    model.reported_user_id = params.reported_user_id ? params.reported_user_id : '';
    model.srv_type_id = params.srv_type_id;
    model.status = params.status;
    model.tenant_id = params.tenant_id;
    model.account_id = params?.account_id;
    model.organization_id = params?.organization_id;
    model.unit_id = params?.unit_id;
    model.location_id = params?.location_id;
    model.zone_id = params?.zone_id;
    model.type = params.type;
    model.access_modifier = params.access_modifier;
    model.size = params.size;
    model.budget = params.budget;
    model.color = params.color;
    model.assigned_user_id = params.assigned_user_id;
    model.date_start = params.date_start;
    model.date_end = params.date_end;
    return model;
}

export function copyPropsSurveyTeamsObject(params: any, type: string): any {
    console.log(params, type);
    const model: any = {};
    if (type.includes('Edit')) {
        model.id = params.id;
    }
    model.userId = params.userId;
    model.app_id = AppApi.SURVEY_APP_ID;
    model.service_id = params.service_id;
    model.assigned_user_id = params.userId;
    model.name = params.name;
    model.description = params.description;
    model.type = params?.type;
    model.icon = params?.icon;
    model.code = params?.code;
    model.color = params?.color;
    model.sequence = params?.sequence;
    model.priority = params?.priority;
    model.status = params?.status;
    model.access_modifier = params?.access_modifier;

    return model;
}

export function copyPropsSurveyTeamsMemberObject(params: any, type: string): any {
    console.log(params, type);
    const model: any = {};
    if (type.includes('Edit')) {
        model.id = params.id;
    }
    model.team_id = params.team_id;
    model.userId = params.userId;
    model.assigned_user_id = params.userId;
    model.name = params.name;
    model.description = params.description;
    model.type = params.type;
    model.code = params?.code;
    model.icon = params?.icon;
    model.color = params?.color;
    model.sequence = params?.sequence;
    model.priority = params?.priority;
    model.status = params?.status;
    model.access_modifier = params?.access_modifier;

    return model;
}

export function convertAnswerToAnswerCreateOobj(params: SurveyAnswerModel): SurveyAnswerCreateModel {
    console.log(params);

    const model = new SurveyAnswerCreateModel();
    model.access_modifier = params.access_modifier;
    model.action_title = params.action_title;
    model.assigned_user_id = params.assigned_user_id;
    model.name = params.name;
    model.description = params.description;
    model.type = params.type;
    model.code = params?.code;
    model.icon = params?.icon;
    model.color = params?.color;
    model.sequence = params?.sequence;
    model.status = params?.status;
    model.associated_user_tag = params?.associated_user_tag;
    model.date_start = params?.date_start;
    model.date_end = params?.date_end;
    model.display_help_document_url = params?.display_help_document_url;
    model.display_icon_url = params?.display_icon_url;
    model.display_image_url = params?.display_image_url;
    model.next_display_question_id = params?.next_display_question_id;
    model.next_redirect_url = params?.next_redirect_url;
    model.rule_title = params?.rule_title;
    model.scoring_value = params?.scoring_value;
    model.srv_question_id = params?.srv_question_id;

    return model;
}

export function convertAnswerToAnswerUpdateObj(params: SurveyAnswerModel): SurveyAnswerEditModel {
    console.log(params);

    const model = new SurveyAnswerEditModel();
    model.id = params.id;
    model.access_modifier = params.access_modifier;
    model.action_title = params.action_title;
    model.assigned_user_id = params.assigned_user_id;
    model.name = params.name;
    model.description = params.description;
    model.type = params.type;
    model.code = params?.code;
    model.icon = params?.icon;
    model.color = params?.color;
    model.sequence = params?.sequence;
    model.status = params?.status;
    model.associated_user_tag = params?.associated_user_tag;
    model.date_start = params?.date_start;
    model.date_end = params?.date_end;
    model.display_help_document_url = params?.display_help_document_url;
    model.display_icon_url = params?.display_icon_url;
    model.display_image_url = params?.display_image_url;
    model.next_display_question_id = params?.next_display_question_id;
    model.next_redirect_url = params?.next_redirect_url;
    model.rule_title = params?.rule_title;
    model.scoring_value = params?.scoring_value;
    model.srv_question_id = params?.srv_question_id;

    return model;
}

export const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    
    if (removedIndex === null && addedIndex === null) {
        return arr;
    }

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }

    let i = 0;
    result.forEach(element => {
        element.sequence = i;
        i++;
    })

    return result;
};