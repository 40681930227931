import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ForgotPasswordComponent, LoginComponent, RegisterComponent, ResetPasswordComponent, SignupSuccessfullyComponent } from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UtilityModule } from 'app/utility';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupSuccessfullyComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UtilityModule,
    TranslateModule,
    FuseSharedModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
