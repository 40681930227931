import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { APIManager } from "app/utility/shared-services";
import { NgDialogAnimationService } from "ng-dialog-animation";
import { TeamDotCoreService } from "../../../services/team.dotcore.service";
import { SurveyListTeamAddComponent } from "../survey-list-team-add/survey-list-team-add.component";

@Component({
    selector: 'app-survey-list-team-list',
    templateUrl: './survey-list-team-list.component.html',
    styleUrls: ['./survey-list-team-list.component.scss'],
})
export class SurveyListTeamListComponent implements OnInit {

    // Dynamic Variables
    teamList: any = {};
    teamMembers: any [];

    // Directive Variables
    @Input() serviceId: string;

    constructor(
        private _teamService: TeamDotCoreService,
        private modalService: MatDialog,
        public dialogAnimation: NgDialogAnimationService,
        private _apiManager: APIManager,
        private router: Router) { }

    ngOnInit(): void {
        this.loadTeamByServiceId(this.serviceId);
    }

    loadTeamByServiceId(serviceId){
        this._teamService.getTeamByServiceId(serviceId).subscribe((response) => {
            this.teamList = response?.data[0];
            console.log(this.teamList);
        })
    }
    
    addTeam = () => {
        let status = 'New'
        if (this.teamList?.team_members?.length > 0){
            status = 'Edit'
        }
        const dialogRef = this.modalService.open(SurveyListTeamAddComponent, {
            width: '500px',
            panelClass: 'dialog-app-survey-basic-details-create',
            data: {type: status, data: this.teamList, serviceId: this.serviceId}
            
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
        });
    }
}
