import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNow'
})
export class fromNowPipe implements PipeTransform {
  transform(date) {
    return moment(date).fromNow();
  }
}