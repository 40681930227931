export class SurveyTeamCreateModel {
    userId: string;
    app_id: string;
    service_id: string;
    assigned_user_id: string;
    name: string;
    description: string;
    type: string;
    code: string;
    icon: string;
    color: string;
    sequence: number;
    priority: number;
    status: number;
    access_modifier: number;


    constructor(survey?)
    {
        survey = survey || {};
        this.userId = survey.userId;
        this.app_id = survey.app_id;
        this.service_id = survey.service_id;
        this.assigned_user_id = survey.assigned_user_id;
        this.name = survey.name;
        this.description = survey.description;
        this.sequence = survey.sequence;
        this.type = survey.type;
        this.code = survey.code;
        this.status = survey.status;
        this.access_modifier = survey.access_modifier;
        this.icon = survey.icon;
        this.color = survey.color;
        this.priority = survey.priority;
    }
}
