import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBaseComponent } from '../../shared-component/form-base/form-base.component';

@Component({
  selector: 'app-filter-report-dialog',
  templateUrl: './filter-report-dialog.component.html',
  styleUrls: ['./filter-report-dialog.component.scss']
})
export class FilterReportDialogComponent extends FormBaseComponent implements OnInit {

  uploadForm: FormGroup;

  constructor(fb: FormBuilder, public dialogRef: MatDialogRef<FilterReportDialogComponent>) {
    super(fb);
   }

  ngOnInit(): void {
    this.initalize();
  }

  initalize = () => {
    this.createUploadForm();
  }

  createUploadForm = () => {
    this.uploadForm = this.createForm({
      report_type: ['', []],
      name: ['', []],
    });
  }

  onSubmitUploadForm = (form: FormGroup) => {
    if(this.onSubmit(form)){

    }
  }

  onCloseDialog = () => {
    this.dialogRef.close();
  };
}
