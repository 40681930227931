import { environment } from "environments/environment";

const BASE_FIXASSET_URL = `${environment.apiUrl}/api/v1/FixAsset/`;
const BASE_TENANT_URL = `${environment.apiUrl}/api/v1/Tenant/`;
const BASE_FILE_MANAGEMENT_URL = `${environment.apiUrl}/api/v1/FileManagement/`;
const BASE_ACCOUNT_URL = `${environment.apiUrl}/api/v1/Account/`;
const BASE_PRODUCT_URL = `${environment.apiUrl}/api/v1/Product/`;

export class AppApi {

    // Fix Asset
    public static FIXASSET_GET_BASIC_DEFINITION = BASE_FIXASSET_URL + 'Asset/GetAllAssetsAsync';
    public static FIXASSET_BASIC_DEFINITION_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetBasicInfoAsync';
    public static FIXASSET_GETBYID = BASE_FIXASSET_URL + 'Asset/GetAllAssetsAsync';
    public static FIXASSET_LIST = BASE_FIXASSET_URL + 'Asset/GetAllAssetsByFiltersAsync';
    public static FIXASSET_BY_TENANTI_ID = BASE_FIXASSET_URL + 'Asset/GetAllAssetsByTenantIdAsync';
    public static FIXASSET_ADD = BASE_FIXASSET_URL + 'Asset/AddAssetAsync';
    public static FIXASSET_CLONE = BASE_FIXASSET_URL + 'Asset/CloneAssetAsync';
    public static FIXASSET_IMPORT_EXCEL = BASE_FIXASSET_URL + 'Asset/ImportExcelAssetList';
    public static FIXASSET_UPDATE_REPORT_FILTER = BASE_FIXASSET_URL + 'AssetReport/UpdateAssetReportAsync';
    public static FIXASSET_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetsAsync';
    public static FIXASSET_ARCHIVE = BASE_FIXASSET_URL + 'Asset/ArchiveAssetsAsync';
    public static FIXASSET_DELETE = BASE_FIXASSET_URL + 'Asset/DeleteAssetsAsync';
    public static FIXASSET_PROFILE_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetProfileAsync';
    public static FIXASSET_CATEGORIZATION_UPDATE = BASE_FIXASSET_URL + 'Asset/UpdateAssetCategoryAsync';
    public static FIXASSET_LOCATION_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetLocation/GetAllAssetLocationsByAssetIdAsync';
    public static FIXASSET_LOCATION_ADD = BASE_FIXASSET_URL + 'AssetLocation/AddAssetLocationAsync';
    public static FIXASSET_LOCATION_UPDATE = BASE_FIXASSET_URL + 'AssetLocation/UpdateAssetLocationsAsync';
    public static FIXASSET_LOCATION_ARCHIVE = BASE_FIXASSET_URL + 'AssetLocation/ArchiveAssetLocationsAsync';
    public static FIXASSET_LOCATION_DELETE = BASE_FIXASSET_URL + 'AssetLocation/DeleteAssetLocationsAsync';
    public static FIXASSET_LIABILITY_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetLiability/GetAllAssetLiabilitiesByAssetIdAsync';
    public static FIXASSET_LIABILITY_ADD = BASE_FIXASSET_URL + 'AssetLiability/AddAssetLiabilityAsync'
    public static FIXASSET_LIABILITY_UPDATE = BASE_FIXASSET_URL + 'AssetLiability/UpdateAssetLiabilitiesAsync'
    public static FIXASSET_LIABILITY_ARCHIVE = BASE_FIXASSET_URL + 'AssetLiability/ArchiveAssetLiabilitiesAsync';
    public static FIXASSET_LIABILITY_DELETE = BASE_FIXASSET_URL + 'AssetLiability/DeleteAssetLiabilitiesAsync';
    public static FIXASSET_MAINTENANCE = BASE_FIXASSET_URL + 'AssetMaintenance/GetAllAssetMaintenancesAsync';
    public static FIXASSET_MAINTENANCE_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetMaintenance/GetAllAssetMaintenancesByAssetIdAsync';
    public static FIXASSET_MAINTENANCE_BY_FILTER = BASE_FIXASSET_URL + 'AssetMaintenance/GetAllAssetMaintenancesByFiltersAsync';
    public static FIXASSET_MAINTENANCE_ADD = BASE_FIXASSET_URL + 'AssetMaintenance/AddAssetMaintenanceAsync';
    public static FIXASSET_MAINTENANCE_UPDATE = BASE_FIXASSET_URL + 'AssetMaintenance/UpdateAssetMaintenancesAsync';
    public static FIXASSET_MAINTENANCE_ARCHIVE = BASE_FIXASSET_URL + 'AssetMaintenance/ArchiveAssetMaintenancesAsync';
    public static FIXASSET_MAINTENANCE_DELETE = BASE_FIXASSET_URL + 'AssetMaintenance/DeleteAssetMaintenancesAsync';
    public static FIXASSET_INSURANCE = BASE_FIXASSET_URL + 'AssetInsurance/GetAllAssetInsurancesAsync';
    public static FIXASSET_INSURANCE_BY_ASSET_ID = BASE_FIXASSET_URL + 'AssetInsurance/GetAllAssetInsurancesByAssetIdAsync';
    public static FIXASSET_INSURANCE_BY_FILTER = BASE_FIXASSET_URL + 'AssetInsurance/GetAllAssetInsurancesByFiltersAsync';
    public static FIXASSET_INSURANCE_ADD = BASE_FIXASSET_URL + 'AssetInsurance/AddAssetInsuranceAsync';
    public static FIXASSET_INSURANCE_UPDATE = BASE_FIXASSET_URL + 'AssetInsurance/UpdateAssetInsurancesAsync';
    public static FIXASSET_INSURANCE_ARCHIVE = BASE_FIXASSET_URL + 'AssetInsurance/ArchiveAssetInsurancesAsync';
    public static FIXASSET_INSURANCE_DELETE = BASE_FIXASSET_URL + 'AssetInsurance/DeleteAssetInsurancesAsync';
    public static FIXASSET_SETTING_BY_TYPES = BASE_FIXASSET_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';
    public static FIXASSET_SETTING_BY_ID = BASE_FIXASSET_URL + 'SystemSetting/GetAllSystemSettingAsync';
    public static FIXASSET_ASSETCODE_BY_TENANT_ID = BASE_FIXASSET_URL + 'AssetCode/GetAllAssetCodesByTenantIdAsync';
    public static FIXASSET_ASSETCODE_BY_ASSETID = BASE_FIXASSET_URL + 'AssetCode/GetAllAssetCodesByAssetIdAsync';
    public static FIXASSET_ASSETCODE_ADD = BASE_FIXASSET_URL + 'AssetCode/AddAssetCodeAsync';
    public static FIXASSET_ASSETCODE_UPDATE = BASE_FIXASSET_URL + 'AssetCode/UpdateAssetCodesAsync';
    public static FIXASSET_ASSETCODE_ARCHIVE = BASE_FIXASSET_URL + 'AssetCode/ArchiveAssetCodesAsync';
    public static FIXASSET_ASSETCODE_DELETE = BASE_FIXASSET_URL + 'AssetCode/DeleteAssetCodesAsync';
    public static FIXASSET_REPORT_BY_ID = BASE_FIXASSET_URL + 'AssetReport/GetAllAssetReportsAsync';
    public static FIXASSET_REPORT_BY_USER_ID = BASE_FIXASSET_URL + 'AssetReport/GetAllAssetReportsByUserIdAsync';
    public static FIXASSET_REPORT_ADD = BASE_FIXASSET_URL + 'AssetReport/AddAssetReportFilterAsync';
    public static FIXASSET_REPORT_UPDATE = BASE_FIXASSET_URL + 'AssetReport/UpdateAssetReportAsync';
    public static FIXASSET_REPORT_FILTER_BY_TYPE = BASE_FIXASSET_URL + 'AssetReport/GetAllUserAssetReportsByTypeAsync';
    public static FIXASSET_DASHBOARD_TOTALASSET = BASE_FIXASSET_URL + 'Asset/GetAssetSummaryAsync';
    public static FIXASSET_DASHBOARD_BRAND_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByBrandSummaryAsync';
    public static FIXASSET_DASHBOARD_BRAND_MODEL_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByBrandModelSummaryAsync';
    public static FIXASSET_DASHBOARD_PRODUCT_FAMILY_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByProductFamilySummaryAsync';
    public static FIXASSET_DASHBOARD_PRODUCT_CATEGORY_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByProductCategorySummaryAsync';
    public static FIXASSET_DASHBOARD_ASSET_BYSTATUS_SUMMARY = BASE_FIXASSET_URL + 'Asset/GetAssetByAssetStatusSummaryAsync';

    
    // Fix Asset Settings
    public static ALL_SYSTEM_SETTINGS = BASE_FIXASSET_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';

    // Tenant
    public static USER = BASE_TENANT_URL + 'Auth/GetUserByIdAsync';
    public static USERS_LOOKUPS = BASE_TENANT_URL + 'Auth/GetUserLookupsAsync';
    public static LOOKUP_USER_BY_TENANT_ID = BASE_TENANT_URL + 'Auth/GetUserLookupsByTenantIdAsync';

    // Attachment
    public static UPLOAD_FILE = BASE_FILE_MANAGEMENT_URL + 'File/AddFileAsync';
    public static GET_ALL_FILES_BY_SERVICE_ID = BASE_FILE_MANAGEMENT_URL + 'File/GetAllFilesFoldersByServiceIdAsync';
    public static ARCHIVE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/ArchiveFilesAsync';
    public static DOWNLOAD_FILE = BASE_FILE_MANAGEMENT_URL + 'File/GetS3FileStream';
    public static PREVIEW_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'Image/preview/file';
    public static DELETE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/DeleteFilesAsync';

    // Account Hierarchy
    public static ACCOUNT_LIST = BASE_ACCOUNT_URL + 'Account/GetAllInternalAccountLookupsAsync';
    public static ACC_ACCOUNT_BY_ID = BASE_ACCOUNT_URL + 'Account/GetAllAccountsAsync';
    //public static ACC_ACCOUNT_LOOKUP = BASE_ACCOUNT_URL + 'Account/GetAllInternalAccountLookupsAsync';
    public static ACC_ACCOUNT_ADD = BASE_ACCOUNT_URL + 'Account/AddAccountAsync';
    public static ACC_VENDOR_ACCOUNT_LOOKUP = BASE_ACCOUNT_URL + 'Account/GetAllVendorAccountLookupsAsync';
    public static ACC_ORGANIZATION_BY_ACCOUNT_ID = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsByAccountIdAsync';
    public static ACC_ORGANIZATION_ADD = BASE_ACCOUNT_URL + 'Organization/AddOrganizationAsync';
    public static ACC_UNIT = BASE_ACCOUNT_URL + 'Unit/GetAllUnitsAsync';
    public static ACC_UNIT_ADD = BASE_ACCOUNT_URL + 'Unit/AddUnitAsync';
    public static ACC_UNIT_BY_ORGANIZATION_ID = BASE_ACCOUNT_URL + 'Unit/GetAllUnitsByOrganizationIdAsync';
    public static ACC_LOCATION_ADD = BASE_ACCOUNT_URL + 'Location/AddLocationAsync';
    public static ACC_LOCATION_BY_UNIT_ID = BASE_ACCOUNT_URL + 'Location/GetAllLocationsByUnitIdAsync';
    public static ACC_ZONE_BY_LOCATION_ID = BASE_ACCOUNT_URL + 'Zone/GetAllZonesByLocationIdAsync';
    public static ACC_ZONE_ADD = BASE_ACCOUNT_URL + 'Zone/AddZoneAsync';

    public static ACCOUNT_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Account/GetAllAccountsByTenantIdAsync';
    public static ORGANIZATION_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsByTenantIdAsync';
    public static UNITS_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Unit/GetAllUnitsByTenantIdAsync';
    public static LOCATION_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Location/GetAllLocationsByTenantIdAsync';

    // task report 
    public static GET_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/GetAllModuleReportsAsync';
    public static ADD_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/AddModuleUserReportFilterAsync';
    public static UPDATE_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/UpdateModuleReportAsync';
    public static DELETE_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/DeleteModuleReportAsync';

    // PRODUCT
    public static PRODUCT_FAMILY_GETBYID = BASE_PRODUCT_URL + 'ProductFamily/GetAllProductFamiliesAsync';
    public static PRODUCT_FAMILY_LOOKUPS = BASE_PRODUCT_URL + 'ProductFamily/GetAllProductFamilyLookupsAsync';
    public static PRODUCT_FAMILY_LIST = BASE_PRODUCT_URL + 'ProductFamily/GetAllProductFamiliesByFiltersAsync';
    public static PRODUCT_FAMILY_ADD = BASE_PRODUCT_URL + 'ProductFamily/AddProductFamilyAsync';
    public static PRODUCT_FAMILY_UPDATE = BASE_PRODUCT_URL + 'ProductFamily/UpdateProductFamilyAsync';
    public static PRODUCT_FAMILY_ARCHIVE = BASE_PRODUCT_URL + 'ProductFamily/ArchiveProductFamilyAsync';
    public static PRODUCT_FAMILY_DELETE = BASE_PRODUCT_URL + 'ProductFamily/DeleteProductFamilyAsync';

    public static PRODUCT_CATEGORY_GETBYID = BASE_PRODUCT_URL + 'ProductCategory/GetAllProductCategoriesAsync';
    public static PRODUCT_CATEGORY_LOOKUPS = BASE_PRODUCT_URL + 'ProductCategory/GetAllProductCategoryLookupsAsync';
    public static PRODUCT_CATEGORY_LIST = BASE_PRODUCT_URL + 'ProductCategory/GetAllProductCategoriesByFiltersAsync';
    public static PRODUCT_CATEGORY_ADD = BASE_PRODUCT_URL + 'ProductCategory/AddProductCategoryAsync';
    public static PRODUCT_CATEGORY_UPDATE = BASE_PRODUCT_URL + 'ProductCategory/UpdateProductCategoryAsync';
    public static PRODUCT_CATEGORY_ARCHIVE = BASE_PRODUCT_URL + 'ProductCategory/ArchiveProductCategoryAsync';
    public static PRODUCT_CATEGORY_DELETE = BASE_PRODUCT_URL + 'ProductCategory/DeleteProductCategoryAsync';

    public static PRODUCT_LINE_LOOKUPS = BASE_PRODUCT_URL + 'ProductLine/GetAllProductLineLookupsAsync';
    public static PRODUCT_LINE_ADD = BASE_PRODUCT_URL + 'ProductLine/AddProductLineAsync';

    public static PRODUCT_TYPE_LOOKUPS = BASE_PRODUCT_URL + 'ProductType/GetAllProductTypeLookupsAsync';
    public static PRODUCT_TYPE_ADD = BASE_PRODUCT_URL + 'ProductType/AddProductTypeAsync';

    public static BRAND_ADD = BASE_PRODUCT_URL + 'Brand/AddBrandAsync';
    public static BRAND_LOOKUPS = BASE_PRODUCT_URL + 'Brand/GetAllBrandLookupsAsync';

    public static PRODUCT_MODEL_ADD = BASE_PRODUCT_URL + 'Model/AddModelAsync';
    public static PRODUCT_MODEL_LOOKUPS = BASE_PRODUCT_URL + 'Model/GetAllModelLookupsAsync';

}
