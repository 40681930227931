<div class="modal-view">
    <div class="row">
        <div class="col-md-12 PLR-0">
            <h2 *ngIf="!surveyStatus.includes('Edit')">{{ "TASK_MANAGEMENT.CREATE_TEAM" | translate }}</h2>
            <h2 *ngIf="surveyStatus.includes('Edit')">{{ "TASK_MANAGEMENT.EDIT_TEAM" | translate }}</h2>
        </div>
    </div>
    
    <form [formGroup]="surveyAddForm" (submit)="onSurveyAddSubmit(surveyAddForm)">
        <div class="row">
            <div class="col-md-12 PLR-0">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{ "APP_SETTINGS.MEMBER" | translate }}</mat-label>
                      <mat-select formControlName="srv_assign_users" (selectionChange)="onChangeUser($event.value)">
                          <ng-container *ngFor="let item of assignedUserId">
                              <mat-option value="{{item.userId}}">{{item?.first_name + ' ' + item?.last_name}}</mat-option>
                          </ng-container>
                      </mat-select>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 PLR-0">
                <button type="submit" mat-flat-button color="primary">
                    {{ "BOTTON.SAVE" | translate }}
                </button>
                <button (click)="onCloseDialog()" type="button" mat-flat-button color="secondary" class="ML-2">
                    {{ "BOTTON.CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>