import { Injectable } from '@angular/core';
import { AppApi, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager } from 'app/utility/shared-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private _apiManager: APIManager) { }

  addComment(params): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_COMMENT, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  updateComment(params): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT, AppApi.UPDATE_COMMENT, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  getCommentsByServiceId(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, `${AppApi.GET_COMMENT_BY_SERVICE_ID}?ServiceId=${id}`, {},
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  archive(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE, `${AppApi.ARCHIVE_COMMENT}?id=${id}`, {},
      this._apiManager.Authorised_HttpOptions, true, true);
  }
}
