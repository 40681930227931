import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppApi, FormBaseComponent, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager } from 'app/utility/shared-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-survey-filter',
  templateUrl: './survey-filter.component.html',
  styleUrls: ['./survey-filter.component.scss']
})
export class SurveyFilterComponent extends FormBaseComponent implements OnInit {

  // Form group variables
  surveyFilterForm: FormGroup;

  // Dynamic Variable
  surveyTypes: any;
  locations: any;
  organizations: any;
  users: any;

  filterParams: any = {};

  @Output() applyFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(fb: FormBuilder,
              private _apiManager: APIManager) {
      super(fb);
  }

  ngOnInit(): void {
      console.log('On Init');
      this.initialize();
  }

  initialize = () => {
      this.createSurveyFilterForm();
      this.loadSurveyType().subscribe((response) => {
          this.surveyTypes = response.data;
      })
      this.loadLocation().subscribe((response) => {
          this.locations = response.data
      })
      this.loadOrganization().subscribe((response) => {
        this.organizations = response.data
      })
      this.loadUsers().subscribe((response) => {
        this.users = response.data;
        console.log(this.users);
      })
  };

  loadSurveyType(): Observable<any> {
      return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, AppApi.SURVEY_TYPE, {},
        this._apiManager.Authorised_HttpOptions, false, true);
  }

  loadLocation(): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, AppApi.LOCATION, {},
        this._apiManager.Authorised_HttpOptions, false, true);
  }

  loadLocationByOrganizationId(orgId): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, AppApi.LOCATION_BY_ORG_ID, { 'organizaitonId': orgId },
        this._apiManager.Authorised_HttpOptions, false, true);
  }

  loadOrganization(): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, AppApi.ORGANIZATION, {},
        this._apiManager.Authorised_HttpOptions, false, true);
  }

  loadUsers(): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, AppApi.USER, {},
        this._apiManager.Authorised_HttpOptions, false, true);
  }

  onChangeOrganization(id) {
      this.loadLocationByOrganizationId(id).subscribe((response) => {
          this.locations = response.data;
      })
  }

  createSurveyFilterForm = () => {
      this.surveyFilterForm = this._fb.group({
          survey_name: [''],
          srv_type: [''],
          account: [''],
          organization: [''],
          location: [''],
          start_date: [''],
          end_date: [''],
          createdby: [''],
          modifiedby: [''],
      });
  };

  onSurveyFilterSubmit = (form: FormGroup) => {
      const controls = this.surveyFilterForm.controls;
      let filterCount = 0;
      console.log(controls);
      if (this.onSubmit(form)) {
        if (controls.survey_name.value !== '') {
            filterCount++;
        }
        if (controls.start_date.value !== '') {
            filterCount++;
        }
        if (controls.end_date.value !== '') {
            filterCount++;
        }
        if (controls.organization.value !== '') {
            filterCount++;
        }
        if (controls.account.value !== '') {
            filterCount++;
        }
        if (controls.srv_type.value !== '') {
            filterCount++;
        }
        this.filterParams.name = controls.survey_name.value;
        this.filterParams.orderBy = 'date_start';
        this.filterParams.orderByDirection = 'a2z';
        this.filterParams.assigned_user_id = '';
        this.filterParams.srvid = '';
        this.filterParams.tid = '';
        this.filterParams.srvStart = controls.start_date.value;
        this.filterParams.srvEnd = controls.end_date.value;
        this.filterParams.oid = controls.organization.value;
        this.filterParams.aid = controls.account.value;
        this.filterParams.srvTypeId = controls.srv_type.value;
        this.filterParams.status = '';

        this.filterParams.countFilter = filterCount;
        this.applyFilter.emit(this.filterParams);
      }
  };
  

}
