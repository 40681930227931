import * as _ from 'lodash';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SharedService, SharedUserService } from 'app/utility/shared-services';
import { FileSizeEnum } from 'app/utility';
import { FileManagementService } from 'app/private-modules/modules/my-file-module/services/file-management.service';
import { FileListsModel } from 'app/private-modules/modules/my-file-module/models/file-lists.model';


export enum FileQueueStatus {
  Pending,
  Success,
  Error,
  Progress
}

export class FileQueueObject {
  public file: any;
  public status: FileQueueStatus = FileQueueStatus.Pending;
  public progress: number = 0;
  public request: Subscription = null;
  public response: HttpResponse<any> | HttpErrorResponse = null;
  public parentFolderId: string;
  public serviceId: string;
  public appId: string;
  public fileId: string;
  constructor(file: any) {
    this.file = file;
  }

  public upload = () => { /* set in service */ };
  public cancel = () => { /* set in service */ };
  public remove = () => { /* set in service */ };

  public isPending = () => this.status === FileQueueStatus.Pending;
  public isSuccess = () => this.status === FileQueueStatus.Success;
  public isError = () => this.status === FileQueueStatus.Error;
  public inProgress = () => this.status === FileQueueStatus.Progress;
  public isUploadable = () => this.status === FileQueueStatus.Pending || this.status === FileQueueStatus.Error;

}

@Injectable()
export class SharedFileUploaderService {
  private _queue: BehaviorSubject<FileQueueObject[]>;
  private _files: FileQueueObject[] = [];
  refresh$$ = new BehaviorSubject(false);
  constructor(
    private readonly fileManagementService: FileManagementService,
    private readonly sharedUserService: SharedUserService,
    private readonly sharedService: SharedService,
  ) {
    this._queue = <BehaviorSubject<FileQueueObject[]>>new BehaviorSubject(this._files);
  }

  public get queue() {
    return this._queue.asObservable();
  }

  public addToQueue(data: any, parentFolderId: string, serviceId: string, appId: string) {
    _.each(data, (file: any) => this._addToQueue(file, parentFolderId, serviceId, appId));
  }

  public clearQueue() {
    this._files = [];
    this._queue.next(this._files);
  }

  public async uploadAll() {
    for (const queueObj of this._files) {
      if (queueObj.isUploadable()) {
        await this._upload(queueObj);
      }
    }
  }
  
  private _addToQueue(file: any, parentFolderId: string, serviceId: string, appId: string) {

    if (file?.size > FileSizeEnum.TWENTY_MB_IMAGE_SIZE) {
      this.sharedService.setSnackBar('File should be less than 20 MB');
      return;
    }
    const queueObj = new FileQueueObject(file);
    queueObj.parentFolderId = parentFolderId;
    queueObj.serviceId = serviceId;
    queueObj.appId = appId;
    queueObj.upload = async () => await this._upload(queueObj);
    queueObj.remove = async () => await this._removeFromQueue(queueObj);
    queueObj.cancel = () => this._cancel(queueObj);

    this._files.push(queueObj);
    this._queue.next(this._files);
  }

  private async _removeFromQueue(queueObj: FileQueueObject) {
    _.remove(this._files, queueObj);
    if (queueObj.isSuccess && queueObj.fileId) {
      await this.fileManagementService.deleteRequest({ id: queueObj.fileId }).toPromise();
    }
  }

  private async _upload(queueObj: FileQueueObject) {

    queueObj.status = FileQueueStatus.Progress;
    var response = await this.fileManagementService.uploadFileWithProgress<FileListsModel>({
      app_id: queueObj.appId,
      UserId: this.sharedUserService.getUser().id,
      service_id: queueObj.serviceId,
      parent_id: queueObj.parentFolderId,
      file: queueObj.file,
      name: queueObj.file.name,
      description: '',
      access_modifier : 0
    }, percent => {
      queueObj.progress = percent;
      queueObj.progress = 100;
      queueObj.status = FileQueueStatus.Success;
      return true;
    }).catch(err => {
      queueObj.progress = 0;
      this.sharedService.setSnackBar('Failed to upload');
      this._uploadFailed(queueObj, err);
      return null;
    });

    if (queueObj.isSuccess && response.status == 200 && response.data) {
      this.refresh$$.next(true);
      queueObj.fileId = response.data;
    }
  }

  private _cancel(queueObj: FileQueueObject) {
    // update the FileQueueObject as cancelled
    queueObj.request.unsubscribe();
    queueObj.progress = 0;
    queueObj.status = FileQueueStatus.Pending;
    this._queue.next(this._files);
  }

  private _uploadFailed(queueObj: FileQueueObject, response: HttpErrorResponse) {
    // update the FileQueueObject as errored
    queueObj.progress = 0;
    queueObj.status = FileQueueStatus.Error;
    queueObj.response = response;
    this._queue.next(this._files);
  }

}