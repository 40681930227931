import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { PaginatedResult } from 'app/shared/models/pagination';
import { User } from 'app/public-modules/modules/auth/components/shared/model/user.model';
import { APIManager, SharedService } from 'app/utility/shared-services';
import { AppApi } from '../shared-constants/app-api-constants';
import { ProjectModel } from '../models/project.model';
import { EncryptionFunctions, HttpMethodsTypeEnum } from 'app/utility';

@Injectable({
  providedIn: 'root'
})

export class ProjectDotCoreService {
  baseUrl = environment.apiUrl + '/api/v1/Task/';
  user: User = JSON.parse(localStorage.getItem('user'));

  private projectData = '';

constructor(private http: HttpClient,
            private _apiManager: APIManager,
            protected _sharedService: SharedService) {}

getAll(page?, itemsPerPage?, famaParams?): Observable<PaginatedResult<ProjectModel[]>> {

    const paginatedResult: PaginatedResult<ProjectModel[]> = new PaginatedResult<ProjectModel[]>();

    let params  = new HttpParams();
    
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (famaParams != null) {
      if ( famaParams.tenantId !== null && famaParams.tenantId !== '') {
        params = params.append('tid', famaParams.tenantId);
      }
      if ( famaParams.organizationId != null && famaParams.organizationId !== '' ) {
        params = params.append('oid', famaParams.organizationId);
      }
      if ( famaParams.locationId != null && famaParams.locationId !== '' ) {
        params = params.append('lid', famaParams.locationId);
      }
      if ( famaParams.name != null && famaParams.name !== '' ) {
        params = params.append('name', famaParams.name);
      }
      if ( famaParams.orderBy != null && famaParams.orderBy !== '' ) {
        params = params.append('orderBy', famaParams.orderBy);
      }
    }

    return this.http.get<ProjectModel[]>(AppApi.PROJECT_LIST, { observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;

        if (response.headers.get('Pagination') != null ) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }

        return paginatedResult;
      })
    );
  }

  getProjectById(params): Observable<ProjectModel> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.GET, AppApi.PROJECT_DETAIL, params,
        this._apiManager.Authorised_HttpOptions, false, true);
  }

  addProject(params: ProjectModel): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.POST, AppApi.PROJECT_ADD, params,
        this._apiManager.Authorised_HttpOptions, true, true);
  }

  updateProject(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.PUT, AppApi.PROJECT_UPDATE, params,
        this._apiManager.Authorised_HttpOptions, true, true);
  }

  getProjectListByUserId = (id: string): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.PROJECT_LIST_BY_USER, {UserId: id},
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  getProjectTypeList = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.ALL_SYSTEM_SETTINGS, params,
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  getAllProjects = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.PROJECT_LIST, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  archiveProject = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE, AppApi.PROJECT_ARCHIVE, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  toggleFavouriteStatusOfProject = (id, currentStatus): Observable<any> => {
    const url = currentStatus ? AppApi.PROJECT_SET_UN_FAVORITE + `?id=${id}` : AppApi.PROJECT_SET_FAVORITE + `?id=${id}`;
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT, url, {},
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  getUserLookups = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.USERS_LOOKUPS, params,
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  getTeamLookups = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.TEAM_LOOKUPS, params,
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  getAllProjectByTenentId = (id: string): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.PROJECT_LIST_BY_TENENT_ID, {tenant_id: id},
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  setProjectData(value): void {
    localStorage.setItem(
        'pd',
        EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.projectData = value;
  }

  getProjectData() {
    this.projectData = EncryptionFunctions.DECRYPT_OBJ(localStorage.getItem('pd'));
    return this.projectData;
  }
}
