<div *ngIf="isShowLoader" class="loader-main">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div class="loader-layer"></div>
</div>

<!-- <button mat-button fxHide fxShow.gt-xs
        class="language-button"
        [matMenuTriggerFor]="languageMenu">
    <div fxLayout="row" fxLayoutAlign="center center">
    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
    </div>
</button> -->

<!-- <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
        <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso">{{lang.title}}</span>
        </span>
    </button> 
</mat-menu> -->

<div *ngIf="isLoggedIn">
    <app-navbar></app-navbar>
</div>

<div [ngClass]="{'panel' : isLoggedIn}">
    <router-outlet></router-outlet>
    <app-notifications-alert *ngIf="isLoggedIn"></app-notifications-alert>
</div>
