import { FixAssetLookupLocationModel } from "./fix-asset-lookup-location.model";

export class FixAssetLookupUnitModel {
    id: string;
    name: string;
    code: string;
    sequence: number;
    type: string;
    organization_id: string;
    color: string;
    icon: string;
    locations: FixAssetLookupLocationModel[];


    /**
     * Constructor
     *
     * @param asset
     */
    constructor(asset?)
    {
        asset = asset || {};
        this.color = asset.color
        this.icon = asset.icon;
        this.id = asset.id;
        this.organization_id = asset.organization_id;
        this.name = asset.name;
        this.code = asset.code;
        this.sequence = asset.sequence;
        this.type = asset.type;
    }    
}
