<div class="privacy-policy">
    <div class="privacy-policy__header">
        <div class="row">
            <div class="col-md-6 PL-0">
                <div class="privacy-policy__logo">
                    <a routerLink="/">
                        <img *ngIf="isLangActive(languages.English)" src="../../../../../assets/images/auth/logo-en.png" />
                        <img *ngIf="isLangActive(languages.Turkish)" src="../../../../../assets/images/auth/logo-tr.png" />
                    </a>
                </div>
            </div>

            <div class="col-md-6 PR-0 text-right">
                <div class="privacy-policy__language">
                    <button type="button" mat-button [matMenuTriggerFor]="menu">
                        <div
                            class="privacy-policy__flag-circle"
                            *ngIf="isLangActive(languages.English)"
                        >
                            <img
                                src="assets/icons/flags/us.png"
                                alt=""
                            />
                        </div>
                        <span *ngIf="isLangActive(languages.English)" class="privacy-policy__name">En</span>

                        <div
                            class="privacy-policy__flag-circle"
                            *ngIf="isLangActive(languages.Turkish)"
                        >
                            <img
                                src="assets/icons/flags/tr.png"
                                alt=""
                            />
                        </div>
                        <span *ngIf="isLangActive(languages.Turkish)" class="privacy-policy__name">Tr</span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            type="button"
                            (click)="setLanguage(languages.English)"
                            mat-menu-item
                        >
                            English
                        </button>
                        <button
                            type="button"
                            (click)="setLanguage(languages.Turkish)"
                            mat-menu-item
                        >
                            Türkçe
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>

    <h1>{{ "PRIVACY_POLICY.MAKSELLENT_PRIVACY_STATEMENT" | translate }}</h1>
    <span>{{ "PRIVACY_POLICY.LAST_UPDATE_DATE" | translate }}</span>
    <p>
        {{ "PRIVACY_POLICY.PARAGRAPH" | translate }}
    </p>

    <div class="privacy-policy__personal-data">
        <div class="row">
            <div class="col-md-6 PL-0">
                <ul>
                    <li>
                        <a (click)="scroll(section1)">
                            <label>{{ "PRIVACY_POLICY.WHAT_PERSONAL_DATA_DO_WE_RECEIVE" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section2)">
                            <label>{{ "PRIVACY_POLICY.HOW_DO_WE_USE_PERSONAL_DATA" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section3)">
                            <label>{{ "PRIVACY_POLICY.HOW_DO_WE_SHARE_PERSONAL_DATA" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section4)">
                            <label
                                >{{ "PRIVACY_POLICY.WHO_CAN_SEE_AND_SHARE_MY_PERSONAL_DATA_WHEN_I_USE_MAKSELLENT" | translate }}</label
                            >
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section5)">
                            <label>{{ "PRIVACY_POLICY.PRIVACY_RIGHT_AND_CHOICES" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section6)">
                            <label>{{ "PRIVACY_POLICY.CHILDREN" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section7)">
                            <label>{{ "PRIVACY_POLICY.HOW_TO_CONTACT_US" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section8)">
                            <label>{{ "PRIVACY_POLICY.RETENTION" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section9)">
                            <label
                                >{{ "PRIVACY_POLICY.EUROPEAN_DATA_PROTECTION_SPECIFIC_INFORMATION" | translate }}</label
                            >
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section10)">
                            <label>{{ "PRIVACY_POLICY.CALIFORNIA_PRIVACY_RIGHTS" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>

                    <li>
                        <a (click)="scroll(section11)">
                            <label>{{ "PRIVACY_POLICY.CHANGES_TO_THIS_PRIVACY_STATEMENT" | translate }}</label>
                            <span class="material-icons-outlined arrow-icon"
                                >chevron_right</span
                            >
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div #section1 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.WHAT_PERSONAL_DATA_DO_WE_RECEIVE" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.WHAT_PERSONAL_DATA_DO_WE_RECEIVE_DESCRIPTION" | translate }}:
        </p>

        <ul>
            <li>
                <h4>{{ "PRIVACY_POLICY.ACCOUNT_INFORMATION" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.ACCOUNT_INFORMATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.PROFILE_AND_PARTICIPANT_INFORMATION" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.PROFILE_AND_PARTICIPANT_INFORMATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.CONTACTS_AND_CALENDAR_INTEGRATION" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.CONTACTS_AND_CALENDAR_INTEGRATION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.SETTINGS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.SETTINGS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.REGISTRATION_INFORMATION" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.REGISTRATION_INFORMATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.DEVICE_INFORMATION" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.DEVICE_INFORMATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.MEETING_WEBINAR_AND_MESSAGING_CONTENT_AND_CONTEXT" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.MEETING_WEBINAR_AND_MESSAGING_CONTENT_AND_CONTEXT_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.PRODUCT_AND_WEBSITE_USAGE" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.PRODUCT_AND_WEBSITE_USAGE_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.COMMUNICATIONS_WITH_MAKSELLENT" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.COMMUNICATIONS_WITH_MAKSELLENT_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.INFORMATION_FROM_PARTNERS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.INFORMATION_FROM_PARTNERS_DESCRIPTION" | translate }}
                </p>
            </li>
        </ul>
    </div>

    <div #section2 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.HOW_DO_WE_USE_PERSONAL_DATA" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.HOW_DO_WE_USE_PERSONAL_DATA_DESCRIPTION_ONE" | translate }}:
        </p>

        <ul>
            <li>
                <h4>{{ "PRIVACY_POLICY.PROVIDE_MAKSELLENT_PRODUCTS_AND_SERVICES" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.PROVIDE_MAKSELLENT_PRODUCTS_AND_SERVICES_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.PRODUCT_RESEARCH_AND_DEVELOPMENT" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.PRODUCT_RESEARCH_AND_DEVELOPMENT_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.MARKETING_PROMOTIONS_AND_THIRD_PARTY_ADVERTISING" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.MARKETING_PROMOTIONS_AND_THIRD_PARTY_ADVERTISING_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.AUTHENTICATION_INTEGRITY_SECURITY_AND_SAFETY" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.AUTHENTICATION_INTEGRITY_SECURITY_AND_SAFETY_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.COMMUNICATE_WITH_YOU" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.COMMUNICATE_WITH_YOU_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.LEGAL_REASONS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.LEGAL_REASONS_DESCRIPTION" | translate }}
                </p>
            </li>
        </ul>

        <p>
            {{ "PRIVACY_POLICY.HOW_DO_WE_USE_PERSONAL_DATA_DESCRIPTION_TWO" | translate }}
        </p>
    </div>

    <div #section3 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.HOW_DO_WE_SHARE_PERSONAL_DATA" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.HOW_DO_WE_SHARE_PERSONAL_DATA_DESCRIPTION" | translate }}
        </p>

        <ul>
            <li>
                <h4>{{ "PRIVACY_POLICY.RESELLERS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.RESELLERS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.VENDORS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.VENDORS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.FOR_LEGAL_REASONS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.FOR_LEGAL_REASONS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.MARKETING_ADVERTISING_AND_ANALYTICS_PARTNERS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.MARKETING_ADVERTISING_AND_ANALYTICS_PARTNERS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.CORPORATE_AFFILIATES" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.CORPORATE_AFFILIATES_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.CHANGE_OF_CONTROL" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.CHANGE_OF_CONTROL_DECRIPTION" | translate }}
                </p>
            </li>
        </ul>
    </div>

    <div #section4 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.WHO_CAN_SEE_AND_SHARE_MY_PERSONAL_DATA_WHEN_I_USE_MAKSELLENT" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.WHO_CAN_SEE_AND_SHARE_MY_PERSONAL_DATA_WHEN_I_USE_MAKSELLENT_DESCRIPTION" | translate }}
        </p>

        <ul>
            <li>
                <h4>{{ "PRIVACY_POLICY.ACCOUNT_OWNER" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.ACCOUNT_OWNER_ONE" | translate }} <br />
                    {{ "PRIVACY_POLICY.ACCOUNT_OWNER_TWO" | translate }}
                </p>

                <ul>
                    <li>
                        <h4>{{ "PRIVACY_POLICY.ACCOUNT_USAGE" | translate }}:</h4>

                        <ul>
                            <li>
                                <h4>{{ "PRIVACY_POLICY.PRODUCT_USAGE" | translate }}:</h4>
                                <p>
                                    {{ "PRIVACY_POLICY.PRODUCT_USAGE_DESCRIPTION" | translate }}
                                </p>
                            </li>

                            <li>
                                <h4>{{ "PRIVACY_POLICY.PARTICIPANT_LIST" | translate }}:</h4>
                                <p>
                                    {{ "PRIVACY_POLICY.PARTICIPANT_LIST_DESCRIPTION" | translate }}
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h4>{{ "PRIVACY_POLICY.REGISTRATION_INFORMATION" | translate }}:</h4>
                        <p>
                            {{ "PRIVACY_POLICY.REGISTRATION_INFORMATION_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>{{ "PRIVACY_POLICY.MAKSELLENT_CHAT_OUT_OF_MEETING_MESSAGE" | translate }}:</h4>
                        <p>
                            {{ "PRIVACY_POLICY.MAKSELLENT_CHAT_OUT_OF_MEETING_MESSAGE_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>{{ "PRIVACY_POLICY.IN_MEETING_WEBINAR_MESSAGES" | translate }}:</h4>
                        <p>
                            {{ "PRIVACY_POLICY.IN_MEETING_WEBINAR_MESSAGES_DESCRIPTION" | translate }}
                        </p>

                        <ul>
                            <li>
                                <p>
                                    {{ "PRIVACY_POLICY.MESSAGE_SENT_TO_EVERYONE_IN_A_MEETING_THAT_IS_RECORDED" | translate }}
                                </p>
                            </li>

                            <li>
                                <p>
                                    {{ "PRIVACY_POLICY.MESSAGE_SENT_TO_PANELISTS_IN_A_WEBINAR_THAT_IS_RECORDED" | translate }}
                                </p>
                            </li>

                            <li>
                                <p>
                                    {{ "PRIVACY_POLICY.DIRECT_MESSAGES_IF_THE_ACCOUNT_OWNER_HAS_ENABLED_ARCHIVING" | translate }}
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h4>{{ "PRIVACY_POLICY.RECORDINGS" | translate }}:</h4>
                        <p>
                            {{ "PRIVACY_POLICY.RECORDINGS_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>{{ "PRIVACY_POLICY.POLLING_QA_AND_FEEDBACK" | translate }}:</h4>
                        <p>
                            {{ "PRIVACY_POLICY.POLLING_QA_AND_FEEDBACK_DESCRIPTION" | translate }}
                        </p>
                    </li>
                </ul>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.MEETING_HOSTS_AND_PARTICIPANTS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.MEETING_HOSTS_AND_PARTICIPANTS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.WEBINAR_PANELISTS_AND_ATTENDEES" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.WEBINAR_PANELISTS_AND_ATTENDEES_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.LIVESTREAMS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.LIVESTREAMS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.THIRD_PARTY_APPS_AND_INTEGRATIONS" | translate }}:</h4>

                <ul>
                    <li>
                        <p>
                            {{ "PRIVACY_POLICY.THIRD_PARTY_APPS_AND_INTEGRATIONS_DESCRIPTION_ONE" | translate }}
                        </p>
                    </li>

                    <li>
                        <p>
                           {{ "PRIVACY_POLICY.THIRD_PARTY_APPS_AND_INTEGRATIONS_DESCRIPTION_TWO" | translate }}
                        </p>
                    </li>

                    <li>
                        <p>
                            {{ "PRIVACY_POLICY.THIRD_PARTY_APPS_AND_INTEGRATIONS_DESCRIPTION_THREE" | translate }}
                        </p>
                    </li>

                    <li>
                        <p>
                            {{ "PRIVACY_POLICY.THIRD_PARTY_APPS_AND_INTEGRATIONS_DESCRIPTION_FOUR" | translate }}
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>

    <div #section5 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_DESCRIPTION" | translate }}
        </p>

        <ul>
            <li>
                <p>
                    {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_DESCRIPTION_ONE" | translate }}
                </p>
            </li>

            <li>
                <p>
                    {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_DESCRIPTION_TWO" | translate }}
                </p>
            </li>

            <li>
                <p>
                    {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_DESCRIPTION_THREE" | translate }}
                </p>
            </li>
        </ul>

        <p>
            {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_PARAGRAPH_ONE" | translate }}
            <a>privacy@zoom.us</a>. {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_PARAGRAPH_TWO" | translate }} <br> {{ "PRIVACY_POLICY.PRIVACY_RIGHTS_AND_CHOICES_PARAGRAPH_THREE" | translate }}
        </p>
    </div>

    <div #section6 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.CHILDREN" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.CHILDREN_PARAGRAPH_ONE" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.CHILDREN_PARAGRAPH_TWO" | translate }}
        </p>
    </div>

    <div #section7 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.HOW_TO_CONTACT_US" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.HOW_TO_CONTACT_US_DESCRIPTION" | translate }} <a>privacy@zoom.us</a>.
        </p>

        <p>{{ "PRIVACY_POLICY.HOW_TO_CONTACT_US_ADDRESS" | translate }}</p>

        <address>
            {{ "PRIVACY_POLICY.MAKSELLENT_VIDEO_COMMUNICATIONS" | translate }} <br />
            {{ "PRIVACY_POLICY.INC_ATTENTION_DATA_PRIVACY_OFFICER" | translate }} <br />
            {{ "PRIVACY_POLICY.ALMADEN_BLVD_SUITE" | translate }} <br />
            {{ "PRIVACY_POLICY.SAN_JOSE_CA" | translate }}
        </address>

        <p>{{ "PRIVACY_POLICY.HOW_TO_CONTACT_US_UK_ADDRESS" | translate }}</p>

        <address>
            {{ "PRIVACY_POLICY.LIONHEART_SQUARED_LTD" | translate }} <br />
            {{ "PRIVACY_POLICY.ATTN_DATA_PRIVACY" | translate }} <br />
            {{ "PRIVACY_POLICY.PEMBROKE_HOUSE" | translate }} <br />
            {{ "PRIVACY_POLICY.UPPER_PEMBROKE_STREET" | translate }} <br />
            {{ "PRIVACY_POLICY.DUBLIN" | translate }} <br />
            {{ "PRIVACY_POLICY.DO_EK" | translate }} <br />
            {{ "PRIVACY_POLICY.REPUBLIC_OF_LRELAND" | translate }} <br />
            {{ "PRIVACY_POLICY.EMAIL" | translate }}
            <a>zoom@LionheartSquared.eu</a>
        </address>

        <address>
            {{ "PRIVACY_POLICY.LIONHEART_SQUARED_LIMITED" | translate }} <br />
            {{ "PRIVACY_POLICY.ATTN_DATA_PRIVACY" | translate }} <br />
            {{ "PRIVACY_POLICY.GLASSHOUSE_STUDIOS" | translate }} <br />
            {{ "PRIVACY_POLICY.FRYERN_COURT_ROAD" | translate }} <br />
            {{ "PRIVACY_POLICY.FORDINGBRIDGE" | translate }} <br />
            {{ "PRIVACY_POLICY.HAMPSHIRE" | translate }} <br />
            {{ "PRIVACY_POLICY.SP_QX" | translate }} <br />
            {{ "PRIVACY_POLICY.UNITED_KINGDOM" | translate }} <br />
            {{ "PRIVACY_POLICY.CONTACT" | translate }} <a>zoom@LionheartSquared.co.uk</a>
        </address>

        <p>
            {{ "PRIVACY_POLICY.DATA_PROTECTION_OFFICER" | translate }}
            <a>privacy@zoom.us</a>.
        </p>
    </div>

    <div #section8 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.RETENTION" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.RETENTION_DESCRIPTION" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.RETENTION_RULES" | translate }}
        </p>

        <ul>
            <li>
                <p>
                    {{ "PRIVACY_POLICY.RETENTION_RULES_ONE" | translate }}
                </p>
            </li>

            <li>
                <p>
                    {{ "PRIVACY_POLICY.RETENTION_RULES_TWO" | translate }}
                </p>
            </li>

            <li>
                <p>
                    {{ "PRIVACY_POLICY.RETENTION_RULES_THREE" | translate }}
                </p>
            </li>

            <li>
                <p>
                    {{ "PRIVACY_POLICY.RETENTION_RULES_FOUR" | translate }}
                </p>
            </li>
        </ul>
    </div>

    <div #section9 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.EUROPEAN_DATA_PROTECTION_SPECIFIC_INFORMATION" | translate }}</h1>

        <h4 class="privacy-policy__tital">{{ "PRIVACY_POLICY.DATA_SUBJECTS_RIGHTS" | translate }}</h4>

        <p>
            {{ "PRIVACY_POLICY.DATA_SUBJECTS_RIGHTS_DESCRIPTION" | translate }}
        </p>

        <ul>
            <li>
                <h4>{{ "PRIVACY_POLICY.RIGHT_OF_ACCESS_AND_OR_PORTABILITY" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.RIGHT_OF_ACCESS_AND_OR_PORTABILITY_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.RIGHT_OF_ERASURE" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.RIGHT_OF_ERASURE_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.RIGHT_TO_OBJECT_TO_PROCESSING" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.RIGHT_TO_OBJECT_TO_PROCESSING_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.RIGHT_TO_RECTIFICATION" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.RIGHT_TO_RECTIFICATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.RIGHT_TO_RESTRICT_PROCESSING" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.RIGHT_TO_RESTRICT_PROCESSING_DESCRIPTION" | translate }}
                </p>
            </li>
        </ul>

        <p>
            {{ "PRIVACY_POLICY.RIGHT_TO_RESTRICT_PROCESSING_PARAGRAPH_ONE" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.RIGHT_TO_RESTRICT_PROCESSING_PARAGRAPH_TWO" | translate }}
        </p>

        <h4 class="privacy-policy__tital">
            {{ "PRIVACY_POLICY.LEGAL_BASIS_FOR_PROCESSING_PERSONAL_DATA" | translate }}
        </h4>

        <p>
            {{ "PRIVACY_POLICY.LEGAL_BASIS_FOR_PROCESSING_PERSONAL_DATA_DESCRIPTION" | translate }}
        </p>

        <ul>
            <li>
                <h4>{{ "PRIVACY_POLICY.AS_NECESSARY_FOR_OUR_CONTACT" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.AS_NECESSARY_FOR_OUR_CONTACT_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.CONSOSTENT_WITH_SPECIFIC_REVOCABLE_CONSENTS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.CONSOSTENT_WITH_SPECIFIC_REVOCABLE_CONSENTS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.AS_NECESSARY_TO_COMPLY_WITH_OUR_LEGAL_OBLIGATIONS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.AS_NECESSARY_TO_COMPLY_WITH_OUR_LEGAL_OBLIGATIONS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "PRIVACY_POLICY.TO_PROTECT_YOUR_VITAL_INTERESTS_OR_THOSE_OF_OTHERS" | translate }}:</h4>
                <p>
                    {{ "PRIVACY_POLICY.TO_PROTECT_YOUR_VITAL_INTERESTS_OR_THOSE_OF_OTHERS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>
                    {{ "PRIVACY_POLICY.AS_NECESSARY_FOR_OUR_LEGITIMATE_INTERESTS" | translate }}
                </h4>
                <p>
                   {{ "PRIVACY_POLICY.AS_NECESSARY_FOR_OUR_LEGITIMATE_INTERESTS_DESCRIPTION_ONE" | translate }} <br />
                   {{ "PRIVACY_POLICY.AS_NECESSARY_FOR_OUR_LEGITIMATE_INTERESTS_DESCRIPTION_TWO" | translate }}
                </p>
            </li>
        </ul>

        <h4 class="privacy-policy__tital">{{ "PRIVACY_POLICY.INTERNATIONAL_DATA_TRANSFERS" | translate }}</h4>

        <p>
            {{ "PRIVACY_POLICY.INTERNATIONAL_DATA_TRANSFERS_PRAGRAPH_ONE" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.INTERNATIONAL_DATA_TRANSFERS_PRAGRAPH_TWO" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.INTERNATIONAL_DATA_TRANSFERS_PRAGRAPH_THREE" | translate }}
        </p>
    </div>

    <div #section10 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.CALIFORNIA_PRIVACY_RIGHTS" | translate }}</h1>

        <h4 class="privacy-policy__tital">{{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT" | translate }}</h4>

        <p>
            {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT_DESCRIPTION" | translate }}
        </p>

        <ul>
            <li>
                <p>
                    {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT_ACCESS" | translate }}
                </p>
            </li>

            <li>
                <p>{{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT_DELETE" | translate }}</p>
            </li>

            <li>
                <p>
                   {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT_OPT_OUT_OF_THE_SALE_ONE" | translate }}
                    <br />
                    {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT_OPT_OUT_OF_THE_SALE_TWO" | translate }} <br />
                    {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PRIVACY_ACT_OPT_OUT_OF_THE_SALE_THREE" | translate }}
                </p>
            </li>
        </ul>

        <p>
            {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PARAGRAPH_ONE" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PARAGRAPH_TWO" | translate }} <a>privacy@zoom.us</a> {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PARAGRAPH_FIVE" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PARAGRAPH_THREE" | translate }}
        </p>

        <p>
            {{ "PRIVACY_POLICY.CALIFORNIA_CONSUMER_PARAGRAPH_FOUR" | translate }}
        </p>

        <h4 class="privacy-policy__tital">{{ "PRIVACY_POLICY.CALIFORNIAS_SHINE_THE_LIGHT_LAW" | translate }}</h4>

        <p>
            {{ "PRIVACY_POLICY.CALIFORNIAS_SHINE_THE_LIGHT_LAW_DESCRIPTION" | translate }}
        </p>
    </div>

    <div  #section11 class="privacy-policy__data">
        <h1>{{ "PRIVACY_POLICY.CHANGES_TO_THIS_PRIVACY_STATEMENT" | translate }}</h1>

        <p>
            {{ "PRIVACY_POLICY.CHANGES_TO_THIS_PRIVACY_STATEMENT_DESCRIPTION" | translate }}
        </p>
    </div>
</div>
