import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
    CheckEmptyPipe,
    FormatDecimalPipe,
    PlaceNAPipe,
    TruncatePipe,
} from "./pipes";
import {
    CommentDetailsComponent,
    CommentsListComponent,
    ConfirmationDialogComponent,
    FormBaseComponent,
    NoDataComponent,
    GeneralTeamBuildComponent,
    AttachmentsComponent,
    ContactDetailsComponent,
    FileGalleryViewComponent
} from "./shared-component";
import {
    AutofocusDirective,
    DisableFormControlDirective,
    OnlyNumberDirective,
} from "./shared-directive";
import { MaterialModule } from "./material";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { TranslateModule } from "@ngx-translate/core";
import { HttpInterceptors } from "./http-interceptors/index-Interceptor";
import { FileSizePipe } from "./pipes/filesize.pipe";
import { ColorPickerModule } from "ngx-color-picker";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { fromNowPipe } from "./pipes/fromNow.pipe";
import { FilterReportDialogComponent } from './shared-component/filter-report-dialog/filter-report-dialog.component';
import { formatDatePipe } from "./pipes/formatDate.pipe";
import { UploadFilesComponent } from "./shared-component/file-upload/files-upload.component";
import { FileManagementService } from "app/private-modules/modules/my-file-module/services/file-management.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        ReactiveFormsModule,
        HttpClientModule,
        ColorPickerModule,
        InfiniteScrollModule,
        NgxDocViewerModule,
    ],
    declarations: [
        TruncatePipe,
        CheckEmptyPipe,
        PlaceNAPipe,
        FormatDecimalPipe,
        FormBaseComponent,
        AutofocusDirective,
        DisableFormControlDirective,
        OnlyNumberDirective,
        NoDataComponent,
        ConfirmationDialogComponent,
        FileGalleryViewComponent,
        CommentsListComponent,
        GeneralTeamBuildComponent,
        CommentDetailsComponent,
        FileSizePipe,
        fromNowPipe,
        formatDatePipe,
        GeneralTeamBuildComponent,
        AttachmentsComponent,
        FilterReportDialogComponent,
        ContactDetailsComponent,
        UploadFilesComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        NgxDocViewerModule,
        TruncatePipe,
        CheckEmptyPipe,
        PlaceNAPipe,
        FormatDecimalPipe,
        AutofocusDirective,
        DisableFormControlDirective,
        OnlyNumberDirective,
        FormBaseComponent,
        NoDataComponent,
        ConfirmationDialogComponent,
        FileGalleryViewComponent,
        CommentsListComponent,
        GeneralTeamBuildComponent,
        CommentDetailsComponent,
        ColorPickerModule,
        InfiniteScrollModule,
        FileSizePipe,
        fromNowPipe,
        formatDatePipe,
        AttachmentsComponent,
        ContactDetailsComponent,
    ],
    providers: [
        HttpInterceptors,
        FileManagementService
    ],
    entryComponents: [ConfirmationDialogComponent],
})
export class UtilityModule {}
