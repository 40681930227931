<div class="terms-services">
    <div class="terms-services__header">
        <div class="row">
            <div class="col-md-6 PL-0">
                <div class="terms-services__logo">
                    <a routerLink="/">
                        <img *ngIf="isLangActive(languages.English)" src="../../../../../assets/images/auth/logo-en.png" />
                        <img *ngIf="isLangActive(languages.Turkish)" src="../../../../../assets/images/auth/logo-tr.png" />
                    </a>
                </div>
            </div>

            <div class="col-md-6 PR-0 text-right">
                <div class="terms-services__language">
                    <button type="button" mat-button [matMenuTriggerFor]="menu">
                        <div
                            class="terms-services__flag-circle"
                            *ngIf="isLangActive(languages.English)"
                        >
                            <img
                                src="assets/icons/flags/us.png"
                                alt=""
                            />
                        </div>
                        <span *ngIf="isLangActive(languages.English)" class="terms-services__name">En</span>

                        <div
                            class="terms-services__flag-circle"
                            *ngIf="isLangActive(languages.Turkish)"
                        >
                            <img
                                src="assets/icons/flags/tr.png"
                                alt=""
                            />
                        </div>
                        <span *ngIf="isLangActive(languages.Turkish)" class="terms-services__name">Tr</span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            type="button"
                            (click)="setLanguage(languages.English)"
                            mat-menu-item
                        >
                            English
                        </button>
                        <button
                            type="button"
                            (click)="setLanguage(languages.Turkish)"
                            mat-menu-item
                        >
                            Türkçe
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>

    <h1>{{ "TERMS_SERVICES.MAKSELLENT_TERMS_OF_SERVICE" | translate }}</h1>
    <span>{{ "TERMS_SERVICES.EFFECTIVE_DATE" | translate }}</span>
    <p>
        {{ "TERMS_SERVICES.PARAGRAPH_ONE" | translate }}
    </p>
    <p>
        {{ "TERMS_SERVICES.PARAGRAPH_TWO" | translate }}
    </p>
    <p>
        {{ "TERMS_SERVICES.PARAGRAPH_THREE" | translate }}
    </p>

    <div class="terms-services__required">
        <a class="terms-services__system">{{ "TERMS_SERVICES.SYSTEM_REQUIREMENTS" | translate }}</a>
        <p>
            {{ "TERMS_SERVICES.SYSTEM_REQUIREMENTS_DESCRIPTION" | translate }}
        </p>

        <ol>
            <li>
                <h4>{{ "TERMS_SERVICES.DEFINITIONS" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.DEFINITIONS_ONE" | translate }} <a>www.zoom.us</a> {{ "TERMS_SERVICES.DEFINITIONS_SEVEN" | translate }} <br />
                    {{ "TERMS_SERVICES.DEFINITIONS_TW0" | translate }} <br />
                    {{ "TERMS_SERVICES.DEFINITIONS_THREE" | translate }} <br />
                    {{ "TERMS_SERVICES.DEFINITIONS_FOUR" | translate }} <br />
                    {{ "TERMS_SERVICES.DEFINITIONS_FIVE" | translate }} <br />
                    {{ "TERMS_SERVICES.DEFINITIONS_SIX" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.SERVICES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.SERVICES_DESCRIPTION" | translate }}
                </p>

                <ul>
                    <li>
                        <h4>2.1 {{ "TERMS_SERVICES.BETA_SERVICES" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.BETA_SERVICES_DESCRIPTION" | translate }}
                        </p>
                    </li>
                </ul>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.USE_OF_SERVICES_AND_YOUR_RESPONSIBILITIES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.USE_OF_SERVICES_AND_YOUR_RESPONSIBILITIES_DESCRIPTION" | translate }}
                </p>

                <ul>
                    <li>
                        <h4>3.1 {{ "TERMS_SERVICES.REGISTRATION_INFORMATION" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.REGISTRATION_INFORMATION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>3.2 {{ "TERMS_SERVICES.YOUR_CONTENT" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.YOUR_CONTENT_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>3.3 {{ "TERMS_SERVICES.RECORDINGS" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.RECORDINGS_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>3.4 {{ "TERMS_SERVICES.PROHIBITED_USE" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.PROHIBITED_USE_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>3.5 {{ "TERMS_SERVICES.LIMITATIONS_ON_USE" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.LIMITATIONS_ON_USE_DESCRIPTION" | translate }}
                        </p>
                    </li>
                </ul>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.RESPONSIBILITY_FOR_END_USERS" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.RESPONSIBILITY_FOR_END_USERS_ONE" | translate }}
                    <a>trust@zoom.us</a>.{{ "TERMS_SERVICES.RESPONSIBILITY_FOR_END_USERS_TWO" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.MAKSELLENT_OBLIGATIONS_FOR_CONTENT" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.MAKSELLENT_OBLIGATIONS_FOR_CONTENT_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.ELIGIBILITY" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.ELIGIBILITY_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.INTENDED_USE_RESTRICTION_ON_USE_BY_CHILDREN" | translate }}:</h4>
                <p>
                   {{ "TERMS_SERVICES.INTENDED_USE_RESTRICTION_ON_USE_BY_CHILDREN_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.CHARGES_AND_CANCELLATION" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.CHARGES_AND_CANCELLATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.TERMINATION" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.TERMINATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.PROPRIETARY_RIGHTS" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.PROPRIETARY_RIGHTS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.COPYRIGHT" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.COPYRIGHT_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.EXPORT_RESTRICTIONS" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.EXPORT_RESTRICTIONS_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.NO_HIGH_RISK_USE" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.NO_HIGH_RISK_USE_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.INJUNCTIVE_RELIEF" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.INJUNCTIVE_RELIEF_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.NO_WARRANTIES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.NO_WARRANTIES_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.INDEMNIFICATION" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.INDEMNIFICATION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.LIMITATION_OF_LIABILITY" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.LIMITATION_OF_LIABILITY_DESCRIPTIONN" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.AGREEMENT_TO_ARBITRATE_WAIVER_OF_CLASS_ACTION" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.AGREEMENT_TO_ARBITRATE_WAIVER_OF_CLASS_ACTION_DESCRIPTION" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.PRIVACY_AND_OTHER_POLICIES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.PRIVACY_AND_OTHER_POLICIES_ONE" | translate }}
                    <a>www.zoom.us/legal</a> {{ "TERMS_SERVICES.PRIVACY_AND_OTHER_POLICIES_TWO" | translate }}
                    <a>https://zoom.us/docs/doc/Maksellent_GLOBAL_DPA.pdf</a> {{ "TERMS_SERVICES.PRIVACY_AND_OTHER_POLICIES_THREE" | translate }}
                </p>
            </li>

            <li>
                <h4>{{ "TERMS_SERVICES.MISCELLANEOUS" | translate }}:</h4>

                <ul>
                    <li>
                        <h4>20.1 {{ "TERMS_SERVICES.CHOICE_OF_LAW_AND_FORUM" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.CHOICE_OF_LAW_AND_FORUM_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>20.2 {{ "TERMS_SERVICES.CONTRACTING_ENTITY" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.CONTRACTING_ENTITY_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>20.3 {{ "TERMS_SERVICES.WAIVER_AND_SEVERABILITY" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.WAIVER_AND_SEVERABILITY_DESCRIPTION" | translate }}
                        </p>
                    </li>

                    <li>
                        <h4>20.4 {{ "TERMS_SERVICES.GENERAL_PROVISIONS" | translate }}</h4>
                        <p>
                            {{ "TERMS_SERVICES.GENERAL_PROVISIONS_DESCRIPTION" | translate }}
                        </p>
                    </li>
                </ul>
            </li>
        </ol>
    </div>

    <h4 class="terms-services__tital MT-5">{{ "TERMS_SERVICES.Exhibit_A" | translate }}</h4>

    <h4 class="terms-services__tital">{{ "TERMS_SERVICES.BINDING_ARBITRATION" | translate }}</h4>

    <p>
        {{ "TERMS_SERVICES.BINDING_ARBITRATION_DESCRIPTION" | translate }}
    </p>

    <div class="terms-services__required">
        <ol>
            <li>
                <h4>{{ "TERMS_SERVICES.DISPUTES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.DISPUTES_DESCRIPTION" | translate }} <a>legal@zoom.us.</a>
                </p>
            </li>
    
            <li>
                <h4>{{ "TERMS_SERVICES.SMALL_CLAIMS_COURT_AVAILABLE" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.SMALL_CLAIMS_COURT_AVAILABLE_DESCRIPTION" | translate }}
                </p>
            </li>
    
            <li>
                <h4>{{ "TERMS_SERVICES.ARVITRAYION_PROCEDURE" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.ARVITRAYION_PROCEDURE_DESCRIPTION" | translate }}
                </p>
            </li>
    
            <li>
                <h4>{{ "TERMS_SERVICES.ARBITRATION_FEES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.ARBITRATION_FEES_DESCRIPTION" | translate }}
                </p>
            </li>
    
            <li>
                <h4>{{ "TERMS_SERVICES.CONFLICT_WITH_AAA_RULES" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.CONFLICT_WITH_AAA_RULES_DESCRIPTION" | translate }}
                </p>
            </li>
    
            <li>
                <h4>{{ "TERMS_SERVICES.REQUIREMENT_TO_FILE_WITHIN_ONE_YEAR" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.REQUIREMENT_TO_FILE_WITHIN_ONE_YEAR_DESCRIPTION" | translate }}
                </p>
            </li>
    
            <li>
                <h4>{{ "TERMS_SERVICES.SEVERABILITY" | translate }}:</h4>
                <p>
                    {{ "TERMS_SERVICES.SEVERABILITY_DESCRIPTION" | translate }}
                </p>
            </li>
        </ol>
    </div>
</div>
