<div class="idea-box-white comment-box">
    <form
    [formGroup]="commentForm"
    (ngSubmit)="onSubmitCommentForm(commentForm)"
    *ngIf="!selectedComment"
    >
    <div class="comment__box">
        <img
            *ngIf="userData?.image_url"
            [src]="userData?.image_url"
        />
        <span *ngIf="!userData?.image_url" class="no-images">{{getNameChar(userData)}}</span>
    
        <div class="form-control">
            <mat-form-field appearance="outline">
                <textarea
                    formControlName="comment"
                    (focus)="onFocusComment()"
                    type="text"
                    matInput
                    placeholder='{{ "TASK_MANAGEMENT.TYPE_A_COMMENT" | translate }}'
                ></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="comment__box__btn" *ngIf="isShowCommentButton">
        <button mat-flat-button color="primary" type="submit">
            {{ "BOTTON.SAVE" | translate }}
        </button>
        <button
            (click)="onHideCommentForm()"
            type="button"
            mat-flat-button
            color="secondary"
            class="ML-2"
        >
            {{ "BOTTON.CANCEL" | translate }}
        </button>
    </div>
    </form>
    <!-- <ul>
        <li *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12]; let j = index;">
            <div>
                <img src="assets/images/avatars/garry.jpg" alt="">
            </div>
            <div>
                <span>John Doe</span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s.</p>
                <small>14 March 2021, 07:45 PM</small>
            </div>

            <div class="comment-box__action">
                <a (click)="onUpdateComment()" matTooltip='{{ "BOTTON.EDIT" | translate }}'>
                    <span class="material-icons-outlined">
                        edit
                    </span>
                </a>
                <a (click)="onDeleteComment()" matTooltip='{{ "BOTTON.DELETE" | translate }}'>
                    <span class="material-icons-outlined">
                        delete
                    </span>
                </a>
            </div>
        </li>
    </ul> -->

    <div *ngIf="commentList.length">
        <div *ngFor="let comment of commentList; let j = index" class="comment__reply">
            <div class="comment__reply__left">
                <!-- <img src="assets/images/avatars/Arnold.jpg"/> -->
                <img
                *ngIf="comment?.image_url"
                [src]="comment?.image_url"
            />
            <span *ngIf="!comment?.image_url" class="no-images">{{getNameChar(userData)}}</span>
            </div>
            <div class="comment__reply__right">
                <a matTooltip='{{"BOTTON.EDIT" | translate}}' class="edit-btn" (click)="onEditComment(comment)">
                    <mat-icon>edit</mat-icon>
                </a>
                <a matTooltip='{{"MY_FILE.ARCHIVE" | translate}}' class="delete-btn" (click)="onArchiveComment(comment)">
                    <mat-icon>delete_outline</mat-icon>
                </a>
                <div>
                    <p>
                        <b>{{comment?.description}}</b> {{ "TASK_MANAGEMENT.ADDED" | translate }} a <b>{{ "FIX_ASSET.COMMENT" | translate }}</b>
                        <small>{{comment?.date_entered | formatDate:'YYYY-MM-DD hh:mm'}}</small>
                        <span>{{ "TASK_MANAGEMENT.COMMENT_CAPITAL" | translate }}</span>
                    </p>
                    <h4 *ngIf="comment != selectedComment">
                        {{comment?.name}}
                        <!-- <a>Mark Wood</a> <a [ngClass]="{'active' : true}">John Doe</a> -->
                    </h4>
                    
                    <div *ngIf="selectedComment === comment">
                        <div class="form-control full-width">
                            <mat-form-field appearance="outline">
                                <textarea
                                    [formControl]="updateComment"
                                    type="text"
                                    matInput
                                    placeholder='{{ "TASK_MANAGEMENT.TYPE_A_COMMENT" | translate }}'
                                ></textarea>
                            </mat-form-field>
                        </div>

                        <!-- <textarea
                            [formControl]="updateComment"
                            type="text"
                            placeholder='{{ "TASK_MANAGEMENT.TYPE_A_COMMENT" | translate }}'
                        ></textarea> -->
                       
                        <div class="comment__box__btn PL-0 MT-2">
                            <!-- <button (click)="onUpdateComment()">update</button>
                            <button (click)="onCancelComment()">cancel</button> -->

                            <button (click)="onUpdateComment()" mat-flat-button color="primary" type="submit">
                                {{ "BOTTON.UPDATE" | translate }}
                            </button>
                           
                            <button
                                (click)="onCancelComment()"
                                type="button"
                                mat-flat-button
                                color="secondary"
                                class="ML-2"
                            >
                                {{ "BOTTON.CANCEL" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a class="view-more-link">Scroll to View More</a>
</div>