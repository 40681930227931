import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {APP_SVG} from '../shared-constants/app-svg-icons-constants';

@Injectable({
    providedIn: 'root'
})

export class SvgIconService {
    baseSvgPath = 'assets/images/svg-files/';
    iconExtension = '.svg';

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    }

    registerIcons() {
        APP_SVG.forEach(icon => {
            //console.log(icon.toString());
            this.addSvgIcon(icon);
        });
    }

    addSvgIcon = (icon: string) => {
        this.matIconRegistry.addSvgIcon(icon,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `${this.baseSvgPath}${icon}${this.iconExtension}`
            )
        );
    };
}