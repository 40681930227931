import { Component, OnInit } from "@angular/core";
import { NotificationsService } from "./notifications.service";

@Component({
    selector: "app-notifications-alert",
    templateUrl: "./notifications-alert.component.html",
    styleUrls: ["./notifications-alert.component.scss"],
})
export class NotificationsAlertComponent {

    constructor
        (
            public notificationService: NotificationsService
        ) {

    }

    ngOnInit(): void {
        this.notificationService.startConnection();
        this.notificationService.addNotificationsDataListener();
    }
}
