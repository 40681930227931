import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { PaginatedResult } from 'app/shared/models/pagination';
import { User } from 'app/public-modules/modules/auth/components/shared/model/user.model';
import { AppApi, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';

@Injectable({
  providedIn: 'root'
})

export class TeamDotCoreService {
  
constructor( 
    private http: HttpClient, 
    protected _sharedService: SharedService,
    private _apiManager: APIManager) {}

    getTeamByServiceId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_TEAM_BY_SERVICE_ID, {ServiceId: id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getTeamByAppId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_TEAM_BY_APP_ID, { AppId: id },
            this._apiManager.Authorised_HttpOptions, false, true
        )
    }

    getTeamById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_TEAM_BY_ID, { id: id },
            this._apiManager.Authorised_HttpOptions, false, true
        )
    }

    deleteTeamMember(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DELETE_TEAM_MEMBER, { id },
            this._apiManager.Authorised_HttpOptions, false, true
        )
    }

    deleteTeamService(serviceId: string, teamId: string): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, `${AppApi.DELETE_TEAM_SERVICE}?service_id=${serviceId}&team_id=${teamId}`, {},
            this._apiManager.Authorised_HttpOptions, false, true
        )
    }

    getAllServiceTeamByServiceId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_SERVICE_TEAM_BY_SERVICE_ID, {service_id: id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }
 
}
