export class RouteConstants {
    public static AUTH = 'auth';
    public static LOGIN = 'login';
    public static SIGN_UP = 'signup';
    public static FORGOT_PASSWORD = 'forgot';
    public static RESET_PASSWORD = 'reset-password';
    public static SIGNUP_SUCCESSFULLY = 'signup-successfully';

    public static ADD = 'add';
    public static EDIT = 'edit';

    public static SYSTEM_SETTINGS = 'system-settings';

    public static MODULES = 'modules';
    public static PACKAGES = 'packages';
    public static ROLES = 'roles';
    public static INDUSTRY = 'industry';
    public static STATUS = 'status';
    public static LANGUAGE = 'language';

    public static IDEA = 'idea';
    public static SURVEY = 'survey';
    public static IMPORT_SURVEY_TARGET_ROUTE = 'import-survey-target';
    public static IMPORT_SURVEY_TARGET =
        RouteConstants.SURVEY + '/' + RouteConstants.IMPORT_SURVEY_TARGET_ROUTE;
    public static TENANT_ACTIVATION = 'tenant-activation';

    public static TENANT_REGISTER = 'tenant-register';
    public static APP_SETTINGS = 'app-settings';
    public static POWER_USER_CONTAINER = 'power-user';
    public static POWER_USER_CONTAINER_ROUTE = `${RouteConstants.APP_SETTINGS}/${RouteConstants.POWER_USER_CONTAINER}`;

    public static PROFILE = 'profile';
    public static MY_PROFILE = 'my-profile';
    public static MY_PREFERENCES = 'my-preferences';
    public static MY_ROLE = 'my-role';
    public static EMPLOYEMENT_INFORMATION = 'employement-information';
    public static CONTACT_INFORMATION = 'contact-information';
    public static EXPENSES = 'expenses';
    public static PAYMENTS = 'payments';
    public static BANK_ACCOUNT = 'bank-account';
    public static WELCOME = 'welcome';

    public static MY_FILE = 'my-file';
    public static FILES_DETAIL = 'files-detail';
    public static FILES_DETAIL_ROUTE = `${RouteConstants.MY_FILE}/${RouteConstants.FILES_DETAIL}`;

    public static TRASH = 'trash';
    public static TRASH_LIST_ROUTE = `${RouteConstants.MY_FILE}/${RouteConstants.TRASH}`;

    public static MY_PROFILE_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.MY_PROFILE}`;
    public static MY_PREFERENCES_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.MY_PREFERENCES}`;
    public static MY_ROLE_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.MY_ROLE}`;
    public static EMPLOYEMENT_INFORMATION_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.EMPLOYEMENT_INFORMATION}`;
    public static CONTACT_INFORMATION_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.CONTACT_INFORMATION}`;
    public static EXPENSES_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.EXPENSES}`;
    public static PAYMENTS_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.PAYMENTS}`;
    public static BANK_ACCOUNT_ROUTE = `${RouteConstants.PROFILE}/${RouteConstants.BANK_ACCOUNT}`;

    public static USER_MANAGEMENT = 'user-management';
    public static USER_MANAGEMENT_ROUTE = `${RouteConstants.APP_SETTINGS}/${RouteConstants.USER_MANAGEMENT}`;

    public static INTERNAL_ACCOUNTS = 'internal-accounts';
    public static INTERNAL_ACCOUNTS_ROUTE = `${RouteConstants.APP_SETTINGS}/${RouteConstants.INTERNAL_ACCOUNTS}`;

    public static ACCOUNT_MANAGEMENT = "account-management";

    public static BOUNTY_HUNTER = "bounty-hunter";

    public static ACCOUNTS = 'accounts';
    public static ACCOUNTS_ROUTE = `${RouteConstants.ACCOUNT_MANAGEMENT}/${RouteConstants.ACCOUNTS}`;

    public static PROSPECTS = 'prospects';
    public static PROSPECTS_ROUTE = `${RouteConstants.ACCOUNT_MANAGEMENT}/${RouteConstants.PROSPECTS}`;

    public static SURVEY_RESULT = 'result';
    public static SURVEY_SUMMARY = 'summary';
    public static FIX_ASSETS = 'fix-assets';
    public static FIX_ASSETS_DETAILS = 'details';
    public static FIX_ASSETS_DETAILS_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}`;

    public static DASHBOARD = 'dashboard';
    public static DASHBOARD_FIX_ASSETS_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.DASHBOARD}`;

    public static FINANCIAL_LIST = 'financial';
    public static FINANCIAL_LIST_FIX_ASSETS_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FINANCIAL_LIST}`;

    public static HOME = 'home';

    public static TERMS_SERVICES = 'terms-services';

    public static PRIVACY_POLICY = 'privacy-policy';

    public static FIX_ASSETS_BASIC_DETAILS_MODULE = 'basic';
    public static FIX_ASSETS_BASIC_DETAILS_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_BASIC_DETAILS_MODULE}`;

    public static FIX_ASSETS_PROFILE_MODULE = 'profile';
    public static FIX_ASSETS_PROFILE_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_PROFILE_MODULE}`;

    public static FIX_ASSETS_CATEGORIZATION_MODULE = 'categorization';
    public static FIX_ASSETS_CATEGORIZATION_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_CATEGORIZATION_MODULE}`;

    public static FIX_ASSETS_STATUS_LIABILITY_MODULE = 'status-liability';
    public static FIX_ASSETS_STATUS_LIABILITY_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_STATUS_LIABILITY_MODULE}`;

    public static FIX_ASSETS_MAINTENANCE_MODULE = 'maintenance';
    public static FIX_ASSETS_MAINTENANCE_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_MAINTENANCE_MODULE}`;

    public static FIX_ASSETS_INSUARANCE_MODULE = 'insurance';
    public static FIX_ASSETS_INSUARANCE_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_INSUARANCE_MODULE}`;

    public static FIX_ASSETS_LOCATION_MODULE = 'location';
    public static FIX_ASSETS_LOCATION_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_LOCATION_MODULE}`;

    public static FIX_ASSETS_SCANNER_CODE_MODULE = 'scanner-code';
    public static FIX_ASSETS_SCANNER_CODE_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_SCANNER_CODE_MODULE}`;

    public static FIX_ASSETS_PHOTO_DOCUMENT_MODULE = 'photo-document';
    public static FIX_ASSETS_PHOTO_DOCUMENT_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_PHOTO_DOCUMENT_MODULE}`;

    public static FIX_ASSETS_REVALUATION_MODULE = 'revaluation';
    public static FIX_ASSETS_REVALUATION_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.FIX_ASSETS_REVALUATION_MODULE}`;

    public static AMORTIZATION_MODULE = 'amortization';
    public static AMORTIZATION_ROUTE = `${RouteConstants.FIX_ASSETS}/${RouteConstants.FIX_ASSETS_DETAILS}/${RouteConstants.AMORTIZATION_MODULE}`;

    public static TASK_MANAGEMENT = 'task-management';

    public static TASK_MANAGEMENT_LIST = 'tasks';
    public static TASK_MANAGEMENT_LIST_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.TASK_MANAGEMENT_LIST}`;

    public static CREATE_PROJECT = 'create-project';
    public static CREATE_PROJECT_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.CREATE_PROJECT}`;

    public static UPDATE_PROJECT = 'update-project';
    public static UPDATE_PROJECT_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.UPDATE_PROJECT}`;

    public static PROJECT_LIST = 'projects';
    public static PROJECT_LIST_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.PROJECT_LIST}`;

    public static BOARD_LIST = 'boards';
    public static BOARD_LIST_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.BOARD_LIST}`;

    public static BUILD_TEAM_LIST = 'teams';
    public static BUILD_TEAM_LIST_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.BUILD_TEAM_LIST}`;

    public static TASK_DETAILS = 'task-cards';
    public static TASK_DETAILS_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.TASK_DETAILS}`;

    public static MY_TASK_LIST = 'my-task';
    public static MY_TASK_LIST_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.MY_TASK_LIST}`;

    public static TASK_TEAM_BUILD = "task-team-build";
    public static TASK_TEAM_BUILD_ROUTE = `${RouteConstants.TASK_MANAGEMENT}/${RouteConstants.TASK_TEAM_BUILD}`;

    public static PURCHASE_MANAGEMENT = "purchase-management";

    public static PURCHASE_MANAGEMENT_CONTAINER = 'details';
    public static PURCHASE_MANAGEMENT_CONTAINER_ROUTE = `${RouteConstants.PURCHASE_MANAGEMENT}/${RouteConstants.PURCHASE_MANAGEMENT_CONTAINER}`;

    public static CALENDAR = "calendar";

    public static CONTACT = "contact";
    
    public static CONTACT_PROFILE_ROUTE = `${RouteConstants.CONTACT}/${RouteConstants.PROFILE}`;

    public static CHAT = 'chat';
    public static CONTACT_CHAT_ROUTE = `${RouteConstants.CONTACT}/${RouteConstants.CHAT}`;

    public static REACTION = 'reaction';
    public static CONTACT_REACTION_ROUTE = `${RouteConstants.CONTACT}/${RouteConstants.REACTION}`;

    public static COMMENT = 'comment';
    public static CONTACT_COMMENT_ROUTE = `${RouteConstants.CONTACT}/${RouteConstants.COMMENT}`;

    public static BOUNTY_MISSIONS = 'missions';
    public static BOUNTY_MISSIONS_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.BOUNTY_MISSIONS}`;

    public static CONTEST = 'contest';
    public static CONTEST_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.CONTEST}`;

    public static BOUNTY_PROGRESS = 'progress';
    public static BOUNTY_PROGRESS_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.BOUNTY_PROGRESS}`;

    public static POINTS_EARNED = 'points-earned';
    public static POINTS_EARNED_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.POINTS_EARNED}`;

    public static EARNED_DATA = 'data-earned';
    public static EARNED_DATA_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.EARNED_DATA}`;

    public static MISSIONS_TYPE = 'missions-type';
    public static MISSIONS_TYPE_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.MISSIONS_TYPE}`;

    public static MISSIONS_LIST = 'missions-list';
    public static MISSIONS_LIST_ROUTE = `${RouteConstants.BOUNTY_HUNTER}/${RouteConstants.MISSIONS_LIST}`;
}

export const PUBLIC_ROUTES = [
    `/${RouteConstants.AUTH}`,
    `/${RouteConstants.LOGIN}`,
    `/${RouteConstants.SIGN_UP}`,
    `/${RouteConstants.FORGOT_PASSWORD}`,
    `/${RouteConstants.RESET_PASSWORD}`,
    `/${RouteConstants.SIGNUP_SUCCESSFULLY}`,
    `/${RouteConstants.TENANT_REGISTER}`,
];
