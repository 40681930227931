<div
    class="table-panel table-panel--space full-panel system-navigation-container"
>
    <div class="system-navigation-container__header">
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <h3>{{ "DASHBOARD_FIX_ASSETS.WELCOME" | translate }} {{userDetails?.first_name + " " +userDetails?.last_name}}!</h3>
            </div>

            <div class="col-md-6 col-xs-12 xs-text-left text-right">
                <!-- Comment out to be implemented with connect with Google
                <span>{{ "SYSTEM_NAVIGATION.LAST_LOGGED_IN" | translate }} 04/12/2021, 03:34 PM</span> -->
            </div>
        </div>
    </div>

    <div class="system-navigation-container__body">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="taskManagementUrl">
                        <img src="assets/images/task-management.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.TASK_MANAGEMENT" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.TASK_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="surveyManagementUrl">
                        <img src="assets/images/survey-management.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.SURVEY_MANAGEMENT" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.SURVEY_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box active">
                    <a [routerLink]="dashboardFixAssetsUrl">
                        <img src="assets/images/fix-assets-home.png" />

                        <h4>{{ "SIDEBAR.FIX_ASSETS" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.FIX_ASSET_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="ideaManagementUrl">
                        <img src="assets/images/idea-management-home.png" />

                        <h4>{{ "IDEA.IDEA_MANAGEMENT" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.IDEA_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="teamManagementUrl">
                        <img src="assets/images/team-build.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.TEAM_MANAGEMENT" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.TEAM_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="calendarUrl">
                        <img src="assets/images/calendar.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.CALENDAR" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.CALENDAR_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="myFileUrl">
                        <img src="assets/images/file-manager-home.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.FILE_MANAGER" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.FILE_MANAGER_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="profileUrl">
                        <img src="assets/images/profile-home.png" />

                        <h4>{{ "APP_SETTINGS.PROFILE" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.PROFILE_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="purchaseManagementUrl">
                        <img src="assets/images/purchase-management.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.PURCHASE_MANAGEMENT" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.PURCHASE_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>
            
            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="accountsUrl">
                        <img src="assets/images/account-home.png" />

                        <h4>{{ "SIDEBAR.ACCOUNTS" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.ACCOUNT_MANAGEMENT_DESC" | translate }}
                        </p>

                        <!-- <span class="material-icons right-arrow">
                            chevron_right
                        </span> -->
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="contactUrl">
                        <img src="assets/images/contact.png" />

                        <h4>{{ "SIDEBAR.CONTACT" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.CONTACT_MANAGEMENT_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a>
                        <strong>{{ "COMMON.COMING_SOON" | translate }}</strong>
                        <img src="assets/images/products-home.png" />

                        <h4>{{ "SIDEBAR.PRODUCTS" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.PRODUCT_MANAGEMENT_DESC" | translate }}
                        </p>

                        <!-- <span class="material-icons right-arrow">
                            chevron_right
                        </span> -->
                    </a>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="system-navigation-container__body__box">
                    <a [routerLink]="bountyHunterUrl">
                        <img class="height-auto" src="assets/images/bounty-hunters/turkcell-logo_325x244.png" />

                        <h4>{{ "SYSTEM_NAVIGATION.BIP_BOUNTY_HUNTERS" | translate }}</h4>

                        <p>
                            {{ "SYSTEM_NAVIGATION.BIP_BOUNTY_HUNTERS_DESC" | translate }}
                        </p>

                        <span class="material-icons right-arrow">
                            chevron_right
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
