<div class="auth">
    <div class="auth__left">
        <img
            *ngIf="isLangActive(languagesArray.English)"
            src="../../../../../assets/images/auth/logo-en.png"
        />
        <img
            *ngIf="isLangActive(languagesArray.German)"
            src="../../../../../assets/images/auth/logo-de.png"
        />
        <img
            *ngIf="isLangActive(languagesArray.Turkish)"
            src="../../../../../assets/images/auth/logo-tr.png"
        />
    </div>
    <div class="auth__right">
        <div
            *ngIf="isShowView !== registerStepsViewEnum.SIGN_UP_SUCCESSFULLY"
            class="auth__right-top-text"
        >
            <p>
                {{ "REGISTER.ALREADY_ACCOUNT_SIGNUP" | translate }}
                <a (click)="onLogin()">
                    {{ "LOGIN.SIGNIN" | translate }}
                </a>
            </p>
        </div>

        <div *ngIf="isShowView !== registerStepsViewEnum.SIGN_UP_SUCCESSFULLY">
            <h2>{{ "REGISTER.SIGN_UP" | translate }}</h2>

            <p *ngIf="isShowView === registerStepsViewEnum.LOGIN_INFORMATION">
                {{ "REGISTER.LOGIN_INFORMATION" | translate }}
            </p>
            <p *ngIf="isShowView === registerStepsViewEnum.PROFILE_INFORMATION">
                {{ "REGISTER.PROFILE_INFORMATION" | translate }}
            </p>
            <p *ngIf="isShowView === registerStepsViewEnum.CONTACT_INFORMATION">
                {{ "REGISTER.CONTACT_INFORMATION" | translate }}
            </p>
            <p *ngIf="isShowView === registerStepsViewEnum.ADDRESS_INFORMATION">
                {{ "REGISTER.ADDRESS_INFORMATION" | translate }}
            </p>

            <div class="auth__steps">
                <ul>
                    <li>
                        <a
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.LOGIN_INFORMATION
                                )
                            "
                            [ngClass]="{
                                active:
                                    isShowView ===
                                    registerStepsViewEnum.LOGIN_INFORMATION
                            }"
                        >
                            <span class="material-icons"> vpn_key </span>
                        </a>
                    </li>
                    <li>
                        <a
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.PROFILE_INFORMATION
                                )
                            "
                            [ngClass]="{
                                active:
                                    isShowView ===
                                    registerStepsViewEnum.PROFILE_INFORMATION
                            }"
                        >
                            <span class="material-icons"> people_outline </span>
                        </a>
                    </li>
                    <li>
                        <a
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.CONTACT_INFORMATION
                                )
                            "
                            [ngClass]="{
                                active:
                                    isShowView ===
                                    registerStepsViewEnum.CONTACT_INFORMATION
                            }"
                        >
                            <span class="material-icons">
                                perm_contact_calendar
                            </span>
                        </a>
                    </li>
                    <li>
                        <a
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.ADDRESS_INFORMATION
                                )
                            "
                            [ngClass]="{
                                active:
                                    isShowView ===
                                    registerStepsViewEnum.ADDRESS_INFORMATION
                            }"
                        >
                            <span class="material-icons"> home </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Step 1 -->
        <div *ngIf="isShowView === registerStepsViewEnum.LOGIN_INFORMATION">
            <form [formGroup]="loginFormGroup">
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
                        <input
                            (focusout)="onEmailFocusOut()"
                            autocomplete="off"
                            placeholder="{{
                                'COMMON.EMAIL_PLACEHOLDER' | translate
                            }}"
                            matInput
                            formControlName="email"
                            required
                        />
                    </mat-form-field>

                    <div class="row MT-2">
                        <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "LOGIN.PASSWORD" | translate
                                    }}</mat-label>
                                <input
                                    type="password"
                                    autocomplete="off"
                                    placeholder="{{
                                        'COMMON.PASSWORD_PLACEHOLDER'
                                            | translate
                                    }}"
                                    matInput
                                    formControlName="password"
                                    required
                                />
                                <mat-error *ngIf="isRequiredField(loginFormControls['password'])">
                                    {{"VALIDATION.PASSWORD_REQUIRED" | translate}}
                                </mat-error>
                                <mat-error *ngIf="isValidLength(loginFormControls['password'])">
                                    {{"VALIDATION.PASSWORD_MIN_LENGTH" | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6 col-sm-6  col-xs-6 PR-0">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "LOGIN.PASSCONFIRM" | translate
                                    }}</mat-label>
                                <input
                                    type="password"
                                    matInput
                                    placeholder="{{
                                        'COMMON.CONFIRM_PASSWORD_PLACEHOLDER'
                                            | translate
                                    }}"
                                    autocomplete="off"
                                    formControlName="passwordConfirm"
                                    required
                                />
                                <mat-error *ngIf="isRequiredField(loginFormControls['passwordConfirm'])">
                                    {{"VALIDATION.CONFIRM_PASSWORD_REQUIRED" | translate}}
                                </mat-error>
                                <mat-error *ngIf="loginFormGroup.errors?.doesMatchPassword">
                                    {{"VALIDATION.CONFIRM_PASSWORD_NOT_MATCHED" | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="position-relative MT-2">
                        <mat-form-field appearance="outline">
                            <mat-label>{{
                                "REGISTER.ORGANIZATION" | translate
                                }}</mat-label>
                            <input
                                matInput
                                placeholder="{{
                                    'PLACEHOLDER.ENTER_ORGANISATION' | translate
                                }}"
                                autocomplete="off"
                                formControlName="tenant"
                                required
                            />
                        </mat-form-field>
                        <a
                            *ngIf="showVerifyButton()"
                            (click)="onClickVerify()"
                        >{{ "REGISTER.VERIFY" | translate }}</a
                        >
                        <span
                            *ngIf="(isSuccessVerify === showVerifyButtonEnum.SUCCESS)"
                            class="material-icons success-icon"
                        >
                            check_circle
                        </span>
                        <span
                            *ngIf="(isSuccessVerify === showVerifyButtonEnum.UNSUCCESSFUL)"
                            class="material-icons failure-icon"
                        >
                            highlight_off
                        </span>
                    </div>
                </div>
                <div class="text-right">
                    <button
                        (click)="
                            onRegisterViewChange(
                                registerStepsViewEnum.PROFILE_INFORMATION
                            )
                        "
                        type="button"
                        mat-raised-button
                        color="accent"
                        class="submit-button next-btn"
                    >
                        {{ "REGISTER.NEXT" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <!-- Step 2 -->
        <div *ngIf="isShowView === registerStepsViewEnum.PROFILE_INFORMATION">
            <form [formGroup]="profileFormGroup">
                <div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "REGISTER.FIRSTNAME" | translate
                                    }}</mat-label>
                                <input
                                    autocomplete="off"
                                    placeholder="{{
                                        'PLACEHOLDER.ENTER_FIRST_NAME'
                                            | translate
                                    }}"
                                    matInput
                                    formControlName="first_name"
                                    required
                                />
                            </mat-form-field>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-6 PR-0">
                            <mat-form-field appearance="outline">
                                <mat-label>{{
                                    "REGISTER.LASTNAME" | translate
                                    }}</mat-label>
                                <input
                                    autocomplete="off"
                                    placeholder="{{
                                        'PLACEHOLDER.ENTER_LAST_NAME'
                                            | translate
                                    }}"
                                    matInput
                                    formControlName="last_name"
                                    required
                                />
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "REGISTER.DATEofBIRTH" | translate
                            }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            placeholder="{{
                                'PLACEHOLDER.SELECT_DATE_OF_BIRTH' | translate
                            }}"
                            [matDatepicker]="picker"
                            formControlName="date_birth"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-radio-group formControlName="gender">
                        <mat-radio-button value="M">{{ "REGISTER.MALE" | translate }}</mat-radio-button>
                        <mat-radio-button value="F">{{ "REGISTER.FEMALE" | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row MT-2">
                    <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                        <button
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.LOGIN_INFORMATION
                                )
                            "
                            mat-button
                            type="button"
                            class="back-btn"
                        >
                            {{ "REGISTER.BACK" | translate }}
                        </button>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 PR-0 text-right">
                        <button
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.CONTACT_INFORMATION
                                )
                            "
                            type="button"
                            mat-raised-button
                            color="accent"
                            class="submit-button next-btn"
                        >
                            {{ "REGISTER.NEXT" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Step 3 -->
        <div *ngIf="isShowView === registerStepsViewEnum.CONTACT_INFORMATION">
            <form [formGroup]="contactFormGroup">
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "REGISTER.PHONENUMBER" | translate
                            }}</mat-label>
                        <input
                            placeholder="{{
                                'PLACEHOLDER.ENTER_PHONE_NO' | translate
                            }}"
                            autocomplete="off"
                            matInput
                            formControlName="phoneNumber"
                            required
                        />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "PLACEHOLDER.SELECT_PREFERRED_LANGUAGE" | translate
                            }}</mat-label>
                        <input
                            type="text"
                            placeholder="{{
                                'PLACEHOLDER.SEARCH_PREFERRED_LANGUAGE'
                                    | translate
                            }}"
                            autocomplete="off"
                            matInput
                            formControlName="languageInput"
                            [matAutocomplete]="autoLang"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #autoLang="matAutocomplete"
                        >
                            <mat-option (onSelectionChange)="changeLanguage(lang, $event)"
                                        *ngFor="let lang of searchCountryLanguageList"
                                        [value]="lang?.description">{{lang?.description}}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="isInvalidNameField(contactFormControls['languageInput'])">
                            {{
                            "VALIDATION.VALID_LANGUAGE" | translate
                            }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "PLACEHOLDER.SELECT_TIMEZONE" | translate
                            }}</mat-label>
                        <input
                            type="text"
                            placeholder="{{
                                'PLACEHOLDER.SEARCH_TIMEZONE'
                                    | translate
                            }}"
                            autocomplete="off"
                            matInput
                            formControlName="timezoneInput"
                            [matAutocomplete]="autoTimezone"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #autoTimezone="matAutocomplete"
                        >
                            <mat-option (onSelectionChange)="changeTimeZone(zone, $event)"
                                        *ngFor="let zone of searchTimezoneList"
                                        [value]="zone?.name">{{zone?.name}}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="isInvalidNameField(contactFormControls['timezoneInput'])">
                            {{
                            "VALIDATION.VALID_TIMEZONE" | translate
                            }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                        <button
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.PROFILE_INFORMATION
                                )
                            "
                            mat-button
                            type="button"
                            class="back-btn"
                        >
                            {{ "REGISTER.BACK" | translate }}
                        </button>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 PR-0 text-right">
                        <button
                            (click)="
                                onRegisterViewChange(
                                    registerStepsViewEnum.ADDRESS_INFORMATION
                                )
                            "
                            type="button"
                            mat-raised-button
                            color="accent"
                            class="submit-button next-btn"
                        >
                            {{ "REGISTER.NEXT" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Step 4 -->
        <div *ngIf="isShowView === registerStepsViewEnum.ADDRESS_INFORMATION">
            <form [formGroup]="addressFormGroup">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                        <mat-form-field appearance="outline">
                            <mat-label>{{
                                "REGISTER.ADDRESSCOUNTRY" | translate
                                }}</mat-label>
                            <input
                                type="text"
                                autocomplete="off"
                                placeholder="{{
                                    'PLACEHOLDER.ENTER_COUNTRY' | translate
                                }}"
                                matInput
                                formControlName="countryInput"
                                [matAutocomplete]="autoCountry"
                            />
                            <mat-autocomplete
                                autoActiveFirstOption
                                #autoCountry="matAutocomplete"
                            >
                                <mat-option (onSelectionChange)="changeCountry(country, $event)"
                                            *ngFor="let country of searchCountryList"
                                            [value]="country?.name">{{country?.name}}</mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="isInvalidNameField(addressFormControls['countryInput'])">
                                {{
                                "VALIDATION.VALID_COUNTRY" | translate
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 PR-0">
                        <mat-form-field appearance="outline">
                            <mat-label>{{
                                "PLACEHOLDER.STATE_PROVINCE" | translate
                                }}</mat-label>
                            <input
                                type="text"
                                placeholder="{{
                                    'PLACEHOLDER.ENTER_STATE' | translate
                                }}"
                                autocomplete="state"
                                matInput
                                formControlName="stateInput"
                                [matAutocomplete]="autoState"
                            />
                            <mat-autocomplete
                                autoActiveFirstOption
                                #autoState="matAutocomplete"
                            >
                                <mat-option (onSelectionChange)="changeState(state, $event)"
                                            *ngFor="let state of searchStateList"
                                            [value]="state?.name">{{state?.name}}</mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="isInvalidNameField(addressFormControls['stateInput'])">
                                {{
                                "VALIDATION.VALID_STATE" | translate
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                        <mat-form-field appearance="outline">
                            <mat-label>{{
                                "REGISTER.ADDRESSCITY" | translate
                                }}</mat-label>
                            <input
                                type="text"
                                placeholder="{{
                                    'PLACEHOLDER.ENTER_CITY' | translate
                                }}"
                                formControlName="address_city"
                                matInput
                                autocomplete="city"
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 PR-0">
                        <mat-form-field appearance="outline">
                            <mat-label>{{
                                "PLACEHOLDER.ENTER_POSTAL_CODE" | translate
                                }}</mat-label>
                            <input
                                matInput
                                autocomplete="postal"
                                placeholder="{{
                                    'PLACEHOLDER.ENTER_POSTAL_CODE' | translate
                                }}"
                                formControlName="postal_code"
                                required
                            />
                        </mat-form-field>
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{
                        "REGISTER.ADDRESSSTREET" | translate
                        }}</mat-label>
                    <input
                        matInput
                        placeholder="{{
                            'PLACEHOLDER.ENTER_STREET' | translate
                        }}"
                        autocomplete="street"
                        formControlName="address_street"
                        required
                    />
                </mat-form-field>
            </form>
            <div class="terms">
                <mat-checkbox name="terms" required [formControl]="termsFormControl">
                    <span>{{ "REGISTER.ACCEPT_TERMS" | translate }} </span>
                    <a routerLink="/terms-services"  target="_blank" class="terms-link">{{ "REGISTER.TERMS" | translate }}</a>
                    <span> {{ "REGISTER.AND" | translate }} </span>
                    <a routerLink="/privacy-policy" target="_blank" class="terms-link">{{ "REGISTER.PRIVACY_POLICY" | translate }}</a>
                </mat-checkbox>
            </div>
            <div class="row MT-2">
                <div class="col-md-6 col-sm-6 col-xs-6 PL-0">
                    <button
                        (click)="
                            onRegisterViewChange(
                                registerStepsViewEnum.CONTACT_INFORMATION
                            )
                        "
                        mat-button
                        type="button"
                        class="back-btn"
                    >
                        {{ "REGISTER.BACK" | translate }}
                    </button>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 PR-0 text-right">
                    <button
                        type="submit"
                        mat-raised-button
                        color="accent"
                        class="submit-button next-btn"
                        (click)="onCLickRegistration(addressFormGroup)"
                    >
                        {{ "COMMON.SUBMIT_CAPS" | translate }}
                    </button>
                </div>
            </div>
        </div>

        <!-- Step 5 -->
        <div
            class="signup-successfully"
            *ngIf="isShowView === registerStepsViewEnum.SIGN_UP_SUCCESSFULLY"
        >
            <h2>{{ "REGISTER.SIGNUP_SUCCESSFULLY" | translate }}</h2>
            <h4>
                {{ "REGISTER.SENT_ACTIVATION_CODE" | translate }}
                <b>{{loginFormControls['email'].value}}</b>
            </h4>
            <p *ngIf="!showResendOTPButton">
                {{ "REGISTER.RETRY" | translate }} <b>{{clockDisplay ? clockDisplay : ''}}</b>
                {{ "REGISTER.MINUTES" | translate }}
            </p>
            <a *ngIf="showResendOTPButton" (click)="onResendOTP()">{{ "REGISTER.RESEND_LINK" | translate }}</a>

            <button
                type="button"
                mat-raised-button
                color="accent"
                class="submit-button next-btn"
                (click)="onLogin()"
            >
                {{ "LOGIN.SIGN_IN" | translate }}
            </button>
        </div>

        <ul class="auth__language">
            <li>
                <span class="material-icons"> language </span>
                <label>{{ "LOGIN.LANGUAGE" | translate }}:</label>
            </li>
            <li (click)="setLanguage(languagesArray.English)">
                <a [ngClass]="{ active: isLangActive(languagesArray.English) }">
                    <img src="assets/icons/flags/us.png"/><span>English</span>
                </a>
            </li>
            <li (click)="setLanguage(languagesArray.Turkish)">
                <a [ngClass]="{ active: isLangActive(languagesArray.Turkish) }">
                    <img src="assets/icons/flags/tr.png"/><span>Türkçe</span>
                </a>
            </li>
            <li (click)="setLanguage(languagesArray.German)">
                <a [ngClass]="{ active: isLangActive(languagesArray.German) }">
                    <img src="assets/icons/flags/de.png"/><span>Deutsch</span>
                </a>
            </li>
        </ul>
    </div>
</div>
