<div class="message">
    <div class="message__header">
        <div class="row">
            <div class="col-md-6 col-xs-6 col-sm-6 PL-0">
                <h3>{{ "SYSTEM_NAVIGATION.MESSAGES" | translate }}</h3>
            </div>

            <div class="col-md-6 col-xs-6 col-sm-6 text-right PR-0">
                <a>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_READ" | translate }}</a>

                <button class="setting-design" mat-icon-button [matMenuTriggerFor]="messageMenu">
                    <mat-icon>settings</mat-icon>
                </button>
                
                <mat-menu #messageMenu="matMenu">
                    <button type="button" mat-menu-item>
                        <mat-icon>volume_mute</mat-icon>
                        <span>{{ "SYSTEM_NAVIGATION.MUTE_MESSAGES" | translate }}</span>
                    </button>
                    
                    <button type="button" mat-menu-item>
                        <mat-icon>check</mat-icon>
                        <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_READ" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="message__body">
        <ul>
            <li>
                <a [ngClass]="{'active': true}">
                    <div class="message__left">
                        <div class="message__left__profile">
                            <img src="assets/images/avatars/garry.jpg" >
                        </div>
            
                        <div class="message__left__content">
                            <h4>John Doe</h4>
                            <p>John sent an attachment <span class="sepration"></span> <small>1m</small></p>
                        </div>
                    </div>
            
                    <div class="message__right">
                        <button type="button" color="primary"  mat-icon-button [matMenuTriggerFor]="messageNewMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        
                        <mat-menu #messageNewMenu="matMenu">
                            <button type="button" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.DELETE_MESSAGE" | translate }}</span>
                            </button>
                            
                            <button type="button" mat-menu-item>
                                <mat-icon>check</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_UNREAD" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>

            <li>
                <a [ngClass]="{'active': true}">
                    <div class="message__left content-row">
                        <div class="message__left__profile">
                            <img src="assets/images/avatars/garry.jpg" >
                        </div>
            
                        <div class="message__left__content">
                            <h4>John Doe</h4>
                            <p>Can you check latest idea which I have shared? <span class="sepration"></span> <small>8m</small></p>
                        </div>
                    </div>
            
                    <div class="message__right">
                        <button type="button" color="primary"  mat-icon-button [matMenuTriggerFor]="messageNewMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        
                        <mat-menu #messageNewMenu="matMenu">
                            <button type="button" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.DELETE_MESSAGE" | translate }}</span>
                            </button>
                            
                            <button type="button" mat-menu-item>
                                <mat-icon>check</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_UNREAD" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>

            <li>
                <a>
                    <div class="message__left content-row">
                        <div class="message__left__profile">
                            <img src="assets/images/avatars/garry.jpg" >
                        </div>
            
                        <div class="message__left__content">
                            <h4>John Doe</h4>
                            <p>I have assigned one task with you. Please check it. <span class="sepration"></span> <small>1w</small></p>
                        </div>
                    </div>
            
                    <div class="message__right">
                        <button type="button" color="primary"  mat-icon-button [matMenuTriggerFor]="messageNewMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        
                        <mat-menu #messageNewMenu="matMenu">
                            <button type="button" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.DELETE_MESSAGE" | translate }}</span>
                            </button>
                            
                            <button type="button" mat-menu-item>
                                <mat-icon>check</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_UNREAD" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>

            <li>
                <a>
                    <div class="message__left content-row">
                        <div class="message__left__profile">
                            <img src="assets/images/avatars/garry.jpg" >
                        </div>
            
                        <div class="message__left__content">
                            <h4>John Doe</h4>
                            <p><label>You:</label> Thank you. <span class="sepration"></span> <small>2w</small></p>
                        </div>
                    </div>
            
                    <div class="message__right">
                        <button type="button" color="primary"  mat-icon-button [matMenuTriggerFor]="messageNewMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        
                        <mat-menu #messageNewMenu="matMenu">
                            <button type="button" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.DELETE_MESSAGE" | translate }}</span>
                            </button>
                            
                            <button type="button" mat-menu-item>
                                <mat-icon>check</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_UNREAD" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>

            <li>
                <a>
                    <div class="message__left content-row">
                        <div class="message__left__profile">
                            <img src="assets/images/avatars/garry.jpg" >
                        </div>
            
                        <div class="message__left__content">
                            <h4>John Doe</h4>
                            <p>John sent an attachment<span class="sepration"></span> <small>9w</small></p>
                        </div>
                    </div>
            
                    <div class="message__right">
                        <button type="button" color="primary"  mat-icon-button [matMenuTriggerFor]="messageNewMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        
                        <mat-menu #messageNewMenu="matMenu">
                            <button type="button" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.DELETE_MESSAGE" | translate }}</span>
                            </button>
                            
                            <button type="button" mat-menu-item>
                                <mat-icon>check</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_UNREAD" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>

            <li>
                <a>
                    <div class="message__left content-row">
                        <div class="message__left__profile">
                            <img src="assets/images/avatars/garry.jpg" >
                        </div>
            
                        <div class="message__left__content">
                            <h4>John Doe</h4>
                            <p><label>You:</label> Screenshot deleted on survey. <span class="sepration"></span> <small>1y</small></p>
                        </div>
                    </div>
            
                    <div class="message__right">
                        <button type="button" color="primary"  mat-icon-button [matMenuTriggerFor]="messageNewMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        
                        <mat-menu #messageNewMenu="matMenu">
                            <button type="button" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.DELETE_MESSAGE" | translate }}</span>
                            </button>
                            
                            <button type="button" mat-menu-item>
                                <mat-icon>check</mat-icon>
                                <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_UNREAD" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>
        </ul>
    </div>

    <div class="message__footer">
        <a>
            {{ "SYSTEM_NAVIGATION.VIEW_ALL" | translate }}
        </a>
    </div>
</div>