export const APP_SVG = [
  "factory",
  "module",
  "order-tracking",
  "xmlid",
  "translation",
  "not-found",
  "file",
  "file-1",
  "responsibility",
  "page-1",
  "analysis",
  "document",
  "question",
  "input",
  "checkbox",
  "dropdown",
  "account",
  "group-206",
  "choices",
  "identification",
  "calendar",
  "star",
  "dollar-tag",
  "buy", 
  "shopping-bag",
  "xmlid_1",
  "credit-card",
  "expense",
  "accounting",
  "asset",
  "career-promotion",
  "preference",
  "settings",
  "user",
  "darts",
  "folder",
  "doc",
  "jpg",
  "pdf",
  "png",
  "ppt",
  "high-priority",
  "medium-priority",
  "low-priority",
  "radio_button_checked",
  "multiple-checkbox",
  "no-priority",
  "close"
];
