import { environment } from "environments/environment";

const BASE_TASK_URL = `${environment.apiUrl}/api/v1/Task/`;
const BASE_TENANT_URL = `${environment.apiUrl}/api/v1/Tenant/`;
const BASE_TEAM_URL = `${environment.apiUrl}/api/v1/Team/`;
const BASE_FILE_MANAGEMENT_URL = `${environment.apiUrl}/api/v1/FileManagement/`;
const BASE_ACCOUNT_URL = `${environment.apiUrl}/api/v1/Account/`;

export class AppApi {

    // Project 
    public static PROJECT_LIST = BASE_TASK_URL + 'Project/GetAllProjectsByFiltersAsync';
    public static PROJECT_DETAIL = BASE_TASK_URL + 'Project/GetAllProjectsAsync';
    public static PROJECT_LOOKUPS = BASE_TASK_URL + 'Project/GetAllProjectLookupsAsync';
    public static PROJECT_ADD = BASE_TASK_URL + 'Project/AddProjectAsync';
    public static PROJECT_UPDATE = BASE_TASK_URL + 'Project/UpdateProjectAsync';
    public static PROJECT_ARCHIVE = BASE_TASK_URL + 'Project/ArchiveProjectAsync';
    public static PROJECT_DELETE = BASE_TASK_URL + 'Project/DeleteProjectAsync';
    public static PROJECT_LIST_BY_USER = BASE_TASK_URL + 'Project/GetAllProjectsByUserIdAsync';
    public static PROJECT_SET_FAVORITE = BASE_TASK_URL + 'Project/SetFavoriteProjectForServiceAsync';
    public static PROJECT_SET_UN_FAVORITE = BASE_TASK_URL + 'Project/UnSetFavoriteProjectForServiceAsync';
    public static PROJECT_LIST_BY_TENENT_ID = BASE_TASK_URL + 'Project/GetAllProjectsByTenantIdAsync';
    // Board
    public static BOARD_LIST = BASE_TASK_URL + 'Board/GetAllBoardsByFiltersAsync';
    public static BOARD_DETAIL = BASE_TASK_URL + 'Board/GetAllBoardsAsync';
    public static BOARD_ADD = BASE_TASK_URL + 'Board/AddBoardAsync';
    public static BOARD_UPDATE = BASE_TASK_URL + 'Board/UpdateBoardAsync';
    public static BOARD_ARCHIVE = BASE_TASK_URL + 'Board/ArchiveBoardAsync';
    public static BOARD_DELETE = BASE_TASK_URL + 'Board/DeleteBoardAsync';
    public static BOARD_LIST_BY_PROJECT_ID = BASE_TASK_URL + 'Board/GetAllBoardsByProjectIdAsync';
    public static BOARD_SET_FAVORITE = BASE_TASK_URL + 'Board/SetFavoriteBoardForServiceAsync';
    public static BOARD_SET_UN_FAVORITE = BASE_TASK_URL + 'Board/UnSetFavoriteBoardForServiceAsync';
    public static BOARD_LIST_BY_TENANT_ID = BASE_TASK_URL + 'Board/GetAllBoardsByTenantIdAsync';

    // Section
    public static SECTION_LIST = BASE_TASK_URL + 'Section/GetAllSectionsByFiltersAsync';
    public static SECTION_DETAIL = BASE_TASK_URL + 'Section/GetAllSectionsAsync';
    public static SECTION_ADD = BASE_TASK_URL + 'Section/AddSectionAsync';
    public static SECTION_UPDATE = BASE_TASK_URL + 'Section/UpdateSectionAsync';
    public static SECTION_ARCHIVE = BASE_TASK_URL + 'Section/ArchiveSectionAsync';
    public static SECTION_DELETE = BASE_TASK_URL + 'Section/DeleteSectionAsync';
    public static SECTION_DETAIL_BY_BOARD_ID = BASE_TASK_URL + 'Section/GetAllSectionsByBoardIdAsync';
    public static SECTION_SEQUENCE_UPDATE = BASE_TASK_URL + 'Section/UpdateSectionSequenceAsync';
    public static SECTION_LIST_BY_TENANT_ID = BASE_TASK_URL + 'Section/GetAllSectionsByTenantIdAsync';

    // Task Card
    public static TASK_CARD_LIST = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsAsync';
    public static TASK_CARD_DETAIL = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsAsync';
    public static TASK_CARD_LOOKUPS = BASE_TASK_URL + 'TaskCard/GetAllTaskCardLookupsAsync';
    public static TASK_CARD_ADD = BASE_TASK_URL + 'TaskCard/AddTaskCardAsync';
    public static TASK_CARD_UPDATE = BASE_TASK_URL + 'TaskCard/UpdateTaskCardAsync';
    public static TASK_CARD_DELETE = BASE_TASK_URL + 'TaskCard/DeleteTaskCardAsync';
    public static TASK_CARD_ARCHIVE = BASE_TASK_URL + 'TaskCard/ArchiveTaskCardAsync';
    public static TASK_CARD_LIST_ARCHIVE = BASE_TASK_URL + 'TaskCard/ArchiveTaskCardListAsync';
    public static TASK_CARD_BY_TENENT_ID = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsByTenantIdAsync';
    public static TASK_CARD_BY_PROJECT_ID = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsByProjectIdAsync';
    public static TASK_CARD_BY_PARENT_ID = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsByParentIdAsync';
    public static TASK_CARD_BY_ID = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsAsync';
    public static TASK_CARD_CLONE = BASE_TASK_URL + 'TaskCard/CloneTaskCardAsync';
    public static TASK_CARD_SEQUENCE_UPDATE = BASE_TASK_URL + 'TaskCard/UpdateTaskCardSequenceAsync';
    public static TASK_CARD_BY_BOARD_ID = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsByBoardIdAsync';
    public static TASK_CARD_CONVERT_TO_SUBTASK = BASE_TASK_URL + 'TaskCard/ConvertTaskCardToSubTaskAsync';
    public static TASK_CARD_SET_FAVORITE = BASE_TASK_URL + 'TaskCard/SetFavoriteTaskCardForServiceAsync';
    public static TASK_CARD_UN_SET_FAVORITE = BASE_TASK_URL + 'TaskCard/UnSetFavoriteTaskCardForServiceAsync';
    public static SHARE_TASK_BY_EMAIL = BASE_TASK_URL + 'TaskCard/ShareTaskByEmail';
    public static GET_TASK_CARD_BY_FILTER = BASE_TASK_URL + 'TaskCard/GetAllTaskCardsByFiltersAsync';

    // Task Unit
    public static TASK_UNIT_LIST = BASE_TASK_URL + 'TaskUnit/GetAllTaskUnitsAsync';
    public static TASK_UNIT_DETAIL = BASE_TASK_URL + 'TaskUnit/GetAllTaskUnitsAsync';
    public static TASK_UNIT_LOOKUPS = BASE_TASK_URL + 'TaskUnit/GetAllTaskUnitLookupsAsync';
    public static TASK_UNIT_ADD = BASE_TASK_URL + 'TaskUnit/AddTaskUnitAsync';
    public static TASK_UNIT_UPDATE = BASE_TASK_URL + 'TaskUnit/UpdateTaskUnitAsync';
    public static TASK_UNIT_DELETE = BASE_TASK_URL + 'TaskUnit/DeleteTaskUnitAsync';
    public static TASK_UNIT_ARCHIVE = BASE_TASK_URL + 'TaskUnit/ArchiveTaskUnitAsync';
    // Task Details
    public static TASK_DETAIL_LIST = BASE_TASK_URL + 'TaskDetail/GetAllTaskDetailsAsync';
    public static TASK_DETAIL_DETAIL = BASE_TASK_URL + 'TaskDetail/GetAllTaskDetailsAsync';
    public static TASK_DETAIL_LOOKUPS = BASE_TASK_URL + 'TaskDetail/GetAllTaskDetailLookupsAsync';
    public static TASK_DETAIL_ADD = BASE_TASK_URL + 'TaskDetail/AddTaskDetailAsync';
    public static TASK_DETAIL_UPDATE = BASE_TASK_URL + 'TaskDetail/UpdateTaskDetailAsync';
    public static TASK_DETAIL_DELETE = BASE_TASK_URL + 'TaskDetail/DeleteTaskDetailAsync';
    public static TASK_DETAIL_ARCHIVE = BASE_TASK_URL + 'TaskDetail/ArchiveTaskDetailAsync';
    public static TASK_DETAIL_SUMMARY = BASE_TASK_URL + 'TaskDetail/GetTaskDetailSummaryAsync';
    public static TASK_DETAIL_BY_TASK_CARD_ID = BASE_TASK_URL + 'TaskDetail/GetAllTaskDetailsByTaskCardIdAsync';
    
    // task Settings
    public static ALL_SYSTEM_SETTINGS = BASE_TASK_URL + 'SystemSetting/GetAllSystemSettingsByTypeAsync';
    public static TASK_CATEGORIES_ASYNC = BASE_TASK_URL + 'SystemSetting/GetTaskCategoryAsync';

    // Tenant
    public static USERS_LOOKUPS = BASE_TENANT_URL + 'Auth/GetUserLookupsAsync';

    // Team 
    public static TEAM_LOOKUPS = BASE_TEAM_URL + 'Team/GetAllTeamLookupsByLoggedInUserAsync'

    // attachment
    public static UPLOAD_FILE = BASE_FILE_MANAGEMENT_URL + 'File/AddFileAsync';
    public static GET_ALL_FILES_BY_SERVICE_ID = BASE_FILE_MANAGEMENT_URL + 'File/GetAllFilesFoldersByServiceIdAsync';
    public static ARCHIVE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/ArchiveFilesAsync';
    public static DOWNLOAD_FILE = BASE_FILE_MANAGEMENT_URL + 'File/GetS3FileStream';
    public static PREVIEW_FILE_REQUEST = BASE_FILE_MANAGEMENT_URL + 'Image/preview/file';
    public static DELETE_FILE = BASE_FILE_MANAGEMENT_URL + 'File/DeleteFilesAsync';

    public static ACCOUNT_LIST = BASE_ACCOUNT_URL + 'Account/GetAllInternalAccountLookupsAsync';
    public static ACCOUNT_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Account/GetAllAccountsByTenantIdAsync';
    public static ORGANIZATION_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Organization/GetAllOrganizationsByTenantIdAsync';
    public static UNITS_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Unit/GetAllUnitsByTenantIdAsync';
    public static LOCATION_BY_TENANT_ID = BASE_ACCOUNT_URL + 'Location/GetAllLocationsByTenantIdAsync';

    // task report 
    public static GET_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/GetAllModuleReportsAsync';
    public static ADD_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/AddModuleUserReportFilterAsync';
    public static UPDATE_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/UpdateModuleReportAsync';
    public static DELETE_MODULE_REPORT = BASE_TENANT_URL + 'ModuleReport/DeleteModuleReportAsync';
}
