<div class="notification">
    <div class="notification__header">
        <div class="row">
            <div class="col-md-6 col-xs-6 col-sm-6 PL-0">
                <h3>{{ "DASHBOARD_FIX_ASSETS.REMINDERS" | translate }}</h3>
            </div>

            <div class="col-md-6 col-xs-6 col-sm-6 text-right PR-0">
                <a (click)="markAllRead()">{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_READ" | translate }}</a>

                <button class="setting-design" mat-icon-button [matMenuTriggerFor]="messageMenu">
                    <mat-icon>settings</mat-icon>
                </button>

                <mat-menu #messageMenu="matMenu">
                    <button type="button" mat-menu-item>
                        <mat-icon>volume_mute</mat-icon>
                        <span>{{ "SYSTEM_NAVIGATION.MUTE_REMINDER" | translate }}</span>
                    </button>

                    <button type="button" mat-menu-item>
                        <mat-icon>check</mat-icon>
                        <span>{{ "SYSTEM_NAVIGATION.MARK_ALL_AS_READ" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div *ngIf="remindersDataSource$ | async as dataSource">
        <ng-container *ngIf="dataSource?.first$ | async as first" [ngSwitch]="first">
            <div *ngSwitchCase="dataSource.searching" class="no-inner-data">
                <h5>
                    {{ "SYSTEM_NAVIGATION.SEARCHING" | translate }}
                </h5>
            </div>
            <div *ngSwitchCase="dataSource.nothing" class="no-inner-data">
                <img src="assets/images/notification.png" alt="">
                <h5>
                    {{
                    "DASHBOARD_FIX_ASSETS.NO_REMINDER"
                    | translate
                    }}
                </h5>
            </div>
            <div *ngSwitchDefault class="notification__body">
                <cdk-virtual-scroll-viewport [itemSize]="7" #cdk class="full">
                    <ul>
                        <li *cdkVirtualFor="let reminder of dataSource">
                            <a [ngClass]="{'active': !reminder.read && !allRead}" *ngIf="!reminder.deleted">
                                <div class="notification__left content-row">
                                    <div class="notification__left__profile">
                                        <mat-icon class="notification-icons">event</mat-icon>
                                    </div>

                                    <div class="notification__left__content">
                                        <p>
                                            {{reminder.name}}
                                        </p>

                                        <small>{{reminder.date_trigger | fromNow}}</small>
                                    </div>
                                </div>

                                <div class="notification__right">
                                    <button color="primary" type="button" mat-icon-button
                                        [matMenuTriggerFor]="notificationMenu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>

                                    <mat-menu #notificationMenu="matMenu">
                                        <button type="button" mat-menu-item (click)="delete(reminder)">
                                            <mat-icon>delete_outline</mat-icon>
                                            <span>{{ "SYSTEM_NAVIGATION.DELETE_NOTIFICATION" | translate }}</span>
                                        </button>

                                        <button type="button" mat-menu-item (click)="markRead(reminder)">
                                            <mat-icon>check</mat-icon>
                                            <span>{{ "SYSTEM_NAVIGATION.MARK_AS_READ" | translate }}</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </a>
                        </li>
                    </ul>
                </cdk-virtual-scroll-viewport>
            </div>
        </ng-container>
        <div class="notification__footer">
            <a>
                {{ "SYSTEM_NAVIGATION.VIEW_ALL" | translate }}
            </a>
        </div>
    </div>
</div>