import { ScrollingModule } from "@angular/cdk/scrolling";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import {
    MatMomentDateModule,
    MomentDateModule
} from "@angular/material-moment-adapter";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { AppComponent } from "app/app.component";
import { fuseConfig } from "app/fuse-config";
import { LayoutModule } from "app/layout/layout.module";
import { environment } from "environments/environment";
import {
    HeaderNavigationsComponent,
    MessagesComponent,
    NotificationComponent, PrivacyPolicyComponent, SystemNavigationComponent,
    TermsServicesComponent
} from "./components";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { RemindersComponent } from "./components/reminders/reminders.component";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { SurveyModule } from "./private-modules/modules/survey-module/survey.module";
import { AuthModule } from "./public-modules/modules/auth/auth.module";
// Icon
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectivesModule } from "./shared/directives/directives.module";
import { RouteConstants, UtilityModule } from "./utility";
import { NotificationsAlertComponent } from "./utility/shared-component/notifications-alert/notifications-alert.component";
import { UserAuthGuard } from "./_guards";

const appRoutes: Routes = [
    {
        path: "",
        redirectTo: RouteConstants.LOGIN,
        pathMatch: "full",
    },
    {
        path: RouteConstants.TENANT_REGISTER,
        loadChildren: () =>
            import(
                "./public-modules/modules/demo-activation-module/demo-activation.module"
            ).then((m) => m.DemoActivationModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.SYSTEM_SETTINGS,
        loadChildren: () =>
            import(
                "./private-modules/modules/system-settings-module/system-settings.module"
            ).then((m) => m.SystemSettingsModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.IDEA,
        loadChildren: () =>
            import(
                "./private-modules/modules/idea-management-module/idea-management.module"
            ).then((m) => m.IdeaManagementModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.SURVEY,
        loadChildren: () =>
            import(
                "./private-modules/modules/survey-module/survey.module"
            ).then((m) => m.SurveyModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.TENANT_ACTIVATION,
        loadChildren: () =>
            import(
                "./private-modules/modules/tenant-activation-module/tenant-activation.module"
            ).then((m) => m.TenantActivationModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.APP_SETTINGS,
        loadChildren: () =>
            import(
                "./private-modules/modules/app-settings-module/app-settings.module"
            ).then((m) => m.AppSettingsModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.PROFILE,
        loadChildren: () =>
            import(
                "./private-modules/modules/profile-module/profile.module"
            ).then((m) => m.ProfileModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.MY_FILE,
        loadChildren: () =>
            import(
                "./private-modules/modules/my-file-module/my-file.module"
            ).then((m) => m.MyFileModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.FIX_ASSETS,
        loadChildren: () =>
            import(
                "./private-modules/modules/fix-assets-module/fix-assets.module"
            ).then((m) => m.FixAssetsModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.TASK_MANAGEMENT,
        loadChildren: () =>
            import(
                "./private-modules/modules/task-management-module/task-management.module"
            ).then((m) => m.TaskManagementModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.PURCHASE_MANAGEMENT,
        loadChildren: () =>
            import(
                "./private-modules/modules/purchase-management-module/purchase-management.module"
            ).then((m) => m.PurchaseManagementModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.CALENDAR,
        loadChildren: () =>
            import(
                "./private-modules/modules/calendar-module/calendar.module"
            ).then((m) => m.CalendarModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.CONTACT,
        loadChildren: () =>
            import(
                "./private-modules/modules/contact-module/contact.module"
            ).then((m) => m.ContactModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.ACCOUNT_MANAGEMENT,
        loadChildren: () =>
            import(
                "./private-modules/modules/account-management-module/account-management.module"
            ).then((m) => m.AccountManagementModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.BOUNTY_HUNTER,
        loadChildren: () =>
            import(
                "./private-modules/modules/bounty-hunter-module/bounty-hunter.module"
            ).then((m) => m.BountyHunterModule),
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.HOME,
        component: SystemNavigationComponent,
        canActivate: [UserAuthGuard],
    },
    {
        path: RouteConstants.TERMS_SERVICES,
        component: TermsServicesComponent,
    },
    {
        path: RouteConstants.PRIVACY_POLICY,
        component: PrivacyPolicyComponent,
    },
    {
        path: "**",
        redirectTo: RouteConstants.LOGIN,
    },
];

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        SystemNavigationComponent,
        RemindersComponent,
        NotificationComponent,
        MessagesComponent,
        HeaderNavigationsComponent,
        NotificationsAlertComponent,
        TermsServicesComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),

        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        UtilityModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AuthModule,
        SurveyModule,

        // Fama Modules
        DirectivesModule,
        MomentDateModule,

        //CDK
        ScrollingModule,
        SocialLoginModule,

        NgxMaterialTimepickerModule
    ],
    bootstrap: [AppComponent],
    providers: [{
        provide: 'SocialAuthServiceConfig',
        useValue: {
            autoLogin: true,
            providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider(
                        environment.googleClientId
                    )
                }
            ]
        } as SocialAuthServiceConfig,
    }]
})
export class AppModule { }
