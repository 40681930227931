<div class="menu-box">
    <div class="row">
        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="taskManagementUrl" (click)="onHideNevigation()">
                <img src="assets/images/task-management.png" />
                <h5>{{ "SYSTEM_NAVIGATION.TASK_MANAGEMENT" | translate }}</h5>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="surveyManagementUrl" (click)="onHideNevigation()">
                <img src="assets/images/survey-management.png" />
                <h5>{{ "SYSTEM_NAVIGATION.SURVEY_MANAGEMENT" | translate }}</h5>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="dashboardFixAssetsUrl" (click)="onHideNevigation()">
                <img src="assets/images/fix-assets-home.png" />
                <h5>{{ "SIDEBAR.FIX_ASSETS" | translate }}</h5>
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="ideaManagementUrl" (click)="onHideNevigation()">
                <img src="assets/images/idea-management-home.png" />
                <h5>{{ "IDEA.IDEA_MANAGEMENT" | translate }}</h5>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="teamManagementUrl" (click)="onHideNevigation()">
                <img src="assets/images/team-build.png" />
                <h5>{{ "SYSTEM_NAVIGATION.TEAM_MANAGEMENT" | translate }}</h5>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="calendarUrl" (click)="onHideNevigation()">
                <img src="assets/images/calendar.png" />
                <h5>{{ "SYSTEM_NAVIGATION.CALENDAR" | translate }}</h5>
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-xs-4 col-sm-4">
            <a [routerLink]="myFileUrl" (click)="onHideNevigation()">
                <img src="assets/images/file-manager-home.png" />
                <h5>{{ "SYSTEM_NAVIGATION.FILE_MANAGER" | translate }}</h5>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a (click)="onHideNevigation()" [routerLink]="profileUrl">
                <img src="assets/images/profile-home.png" />
                <h5>{{ "APP_SETTINGS.PROFILE" | translate }}</h5>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a (click)="onHideNevigation()" [routerLink]="purchaseManagementUrl">
                <img src="assets/images/purchase-management.png" />
                <h5>{{ "SYSTEM_NAVIGATION.PURCHASE_MANAGEMENT" | translate }}</h5>
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-xs-4 col-sm-4">
            <a (click)="onHideNevigation()" [routerLink]="accountManagementUrl">
                <img src="assets/images/account-home.png" />
                <h5>{{ "SIDEBAR.ACCOUNTS" | translate }}</h5>
                <small>{{ "COMMON.SOON" | translate }}</small>
            </a>
        </div>

        <div class="col-md-4 col-xs-4 col-sm-4">
            <a (click)="onHideNevigation()" [routerLink]="contactUrl">
                <img src="assets/images/contact.png" />
                <h5>{{ "SIDEBAR.CONTACT" | translate }}</h5>
            </a>
        </div>
        
        <div class="col-md-4 col-xs-4 col-sm-4">
            <a (click)="onHideNevigation()">
                <img src="assets/images/products-home.png" />
                <h5>{{ "SIDEBAR.PRODUCTS" | translate }}</h5>
                <small>{{ "COMMON.SOON" | translate }}</small>
            </a>
        </div>
    </div>
</div>