import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate'
})
export class formatDatePipe implements PipeTransform {
  transform(date, format) {
    return moment.utc(date).local().format(format ?? 'yyyy-MM-dd HH:mm:ss');
  }
}