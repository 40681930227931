import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';
import { AppApi } from '../../shared-constants/app-api-constants';

export interface IAppNotification {
    NotificationMessage: any;
}

export class Notification {
    id: string;
    name: string;
    context: {};
    modified: Date;
    isRead: boolean;
    deleted: boolean = false;
}

export class NotificationsResponse {
    notifications: Notification[];
    date: Date;
    unread: number;
    start?: number;
    end?: number;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private readonly unread$$ = new BehaviorSubject<number>(0);
    readonly unread$ = this.unread$$.asObservable();

    constructor
        (
            private readonly sharedService: SharedService,
            private readonly apiManager: APIManager
        ) {

    }
    private hubConnection: signalR.HubConnection
    public startConnection = () => {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(AppApi.NEGOTIATE_NOTIFICATIONS_CHANNEL, {
                accessTokenFactory: () => this.apiManager.Auth_Token,
                withCredentials: false
            })
            .withAutomaticReconnect()
            .build();
        this.hubConnection
            .start()
            .then(() => { console.log('Connection started') })
            .catch(err => console.log('Error while starting connection: ' + err))
            .finally(() => {
                this.getCountsOfNotifications({
                    since: new Date()
                }).subscribe();
            })
    }
    public addNotificationsDataListener = () => {
        this.hubConnection.on('notificationsData', (data) => {
            try {
                const parsedData: IAppNotification = JSON.parse(data);
                this.unread$$.next(this.unread$$.value + 1)
                this.sharedService.setSnackBar(parsedData.NotificationMessage.InAppMessage);
            }
            catch (ex) {
                console.log(ex)
            }
        });
    }
    async disconnectChannel() {
        await this.hubConnection.stop();
    }
    getNotifications(payload): Observable<NotificationsResponse> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.GET_NOTIFICATIONS, payload,
            this.apiManager.Authorised_HttpOptions, false, true).
            pipe(
                map(result => result),
                publishReplay(),
                refCount()
            );
    }
    getCountsOfNotifications(payload): Observable<NotificationsResponse> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.GET_NOTIFICATIONS, payload,
            this.apiManager.Authorised_HttpOptions, false, true).
            pipe(
                tap((result: NotificationsResponse) => this.unread$$.next(result.unread)),
                publishReplay(),
                refCount()
            );
    }

    async markRead(id: string, all = false) {
        if (this.unread$$.value >= 1) {
            this.unread$$.next(all ? 0 : this.unread$$.value - 1);
        }
        return await this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.MARK_READ_NOTIFICATION, { id: id, all: all },
            this.apiManager.Authorised_HttpOptions, false, true).toPromise();

    }
    async delete(id: string, read = false) {
        if (!read && this.unread$$.value >= 1) {
            this.unread$$.next(this.unread$$.value - 1);
        }
        return await this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DELETE_NOTIFICATION, { id: id },
            this.apiManager.Authorised_HttpOptions, false, true).toPromise();

    }
}