import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AppApi, appLogger, ConfirmationDialogComponent, FormBaseComponent, GeneralStatusEnum, HttpMethodsTypeEnum, HttpStatus, MY_FORMATS } from "app/utility";
import { APIManager, SharedService, SharedUserService } from 'app/utility/shared-services';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { SurveyModel } from '../../models/survey/survey.model';
import { UserDetailModel } from 'app/utility/shared-model/users.model';
import { TenantAuthDotCoreService } from '../../services/user.dotcore.service';
import { AssetAccountsDotCoreService } from 'app/private-modules/modules/fix-assets-module/services/fix-assets-account.dotcore.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { SurveyDotCoreService } from '../../services/survey.dotcore.service';
import { SurveyCreateModel } from '../../models/survey/survey-create.model';
import { FixAssetLookupAccountModel } from 'app/private-modules/modules/fix-assets-module/models/fix-asset-lookup/fix-asset-lookup-account.model';
import { FixAssetLookupLocationModel } from 'app/private-modules/modules/fix-assets-module/models/fix-asset-lookup/fix-asset-lookup-location.model';
import { FixAssetLookupOrganizationModel } from 'app/private-modules/modules/fix-assets-module/models/fix-asset-lookup/fix-asset-lookup-organization.model';
import { FixAssetLookupUnitModel } from 'app/private-modules/modules/fix-assets-module/models/fix-asset-lookup/fix-asset-lookup-unit.model';
import { FixAssetLookupZoneModel } from 'app/private-modules/modules/fix-assets-module/models/fix-asset-lookup/fix-asset-lookup-zone.model';
import { FixAssetDotCoreService } from 'app/private-modules/modules/fix-assets-module/services/fix-asset.dotcore.service';
import { map, startWith } from 'rxjs/operators';
import { NotificationService } from 'app/shared/services/notification.service';
import { copyPropsSurveyObject } from '../../util/survey-utils';
import { StorageService } from 'app/shared/helpers/storage.service';
import { AccessTypeModel } from 'app/shared/models/access-type.model';
import { SurveyTypeModel } from '../../models/survey-type.model';
@Component({
    selector: 'app-survey-add',
    templateUrl: './survey-add.component.html',
    styleUrls: ['./survey-add.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ],
})
export class SurveyAddComponent extends FormBaseComponent implements OnInit {

    // Form group variables
    surveyAddForm: FormGroup;

    // Const Variable
    surveyStatus = 'New';
    userDetail: UserDetailModel;

    // Dynamic Variables
    surveyType:SurveyTypeModel[] = [];
    surveyObj: any;
    accessModifierList:AccessTypeModel[] = [];
    // accessModifier:string = "";

    tenantId: string = Guid.EMPTY;
    accountId: string = Guid.EMPTY;
    organizationId: string = Guid.EMPTY;
    locationId: string = Guid.EMPTY;
    zoneId: string = Guid.EMPTY;
    assignedUserId: any = [];

    accounts: FixAssetLookupAccountModel[];
    filteredAccounts: FixAssetLookupAccountModel[];
    filterAccountLookups: Observable<FixAssetLookupAccountModel[]>;
    organizations: FixAssetLookupOrganizationModel[];
    filteredOrganizations: FixAssetLookupOrganizationModel[];
    filterOrganizationLookups: Observable<FixAssetLookupOrganizationModel[]>;
    units: FixAssetLookupUnitModel[];
    filteredUnits: FixAssetLookupUnitModel[];
    filterUnitLookups: Observable<FixAssetLookupUnitModel[]>;
    locations: FixAssetLookupLocationModel[];
    filteredLocations: FixAssetLookupLocationModel[];
    filterLocationLookups: Observable<FixAssetLookupLocationModel[]>;
    zones: FixAssetLookupZoneModel[];
    filteredZones: FixAssetLookupZoneModel[];
    filterZoneLookups: Observable<FixAssetLookupZoneModel[]>;
    filteredOptions: Observable<string[]>;
    // options: string[] = ['Account 1', 'Account 2', 'Account 3'];
    currentLanguage: any;

    constructor(fb: FormBuilder,
                public dialogRef: MatDialogRef<SurveyAddComponent>,
                private _translateService: TranslateService,
                private _sharedUserService: SharedUserService,
                private _userService: TenantAuthDotCoreService,
                private _accountService: AssetAccountsDotCoreService,
                private _apiManager: APIManager,
                private _storageService: StorageService,
                private _surveyService: SurveyDotCoreService,
                private _fixAssetService: FixAssetDotCoreService,
                public dialog: MatDialog,
                private sharedService: SharedService,
                private notificationService: NotificationService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super(fb);
        
    }

    ngOnInit(): void {
        if (this.data) {
            this.surveyStatus = this.data.type;
            // appLogger(this.surveyStatus);
            this.surveyObj = this.data?.data;
        } else {
            this.surveyObj = new SurveyCreateModel();
            this.surveyObj.color = '#ffffff';
        }
        if (this._storageService.getString('currentLanguage')){
            this.currentLanguage = JSON.parse(this._storageService.getString('currentLanguage'));
        }
        this.initialize();
        this._surveyService.loadSurveyType().subscribe((response) => {
            this.surveyType = response.data;
            this.surveyType.forEach((currentType, typeIndex) => {
                currentType.srv_type_langs.forEach((currentLang, langIndex) => {
                    if(currentLang.lang == this.currentLanguage) {
                        this.surveyType[typeIndex].name = currentLang.name;
                    }
                });
              });

        });
        
        this._userService.loadTenantDefault().subscribe((response) => {
            this.tenantId = response?.data?.id;
            // this.loadAssignedUser(this.tenantId);
            // this._userService.getUserLookupByTenantId(this.tenantId).subscribe(responseAssignedUser => {
            //     this.assignedUserId = responseAssignedUser?.data;
            // })
            // appLogger(this.tenantId);
            // this._accountService.loadAccountByTenantId(this.tenantId).subscribe((respAccount) => {
            //     this.accountId = respAccount?.data[0]?.id;
            //     appLogger(respAccount);
            //     this._accountService.loadOrganizationByAccountId(this.accountId).subscribe((respOrganization) => {
            //         this.organizationId = respOrganization?.data[0]?.id;
            //         appLogger(this.organizationId);
            //         this._accountService.loadLocation(this.organizationId).subscribe((respLocation) => {
            //             appLogger(respLocation);
            //             this.locationId = respLocation?.data ? respLocation?.data[0]?.id : Guid.EMPTY;
            //             appLogger(this.locationId);
            //             if (!this.locationId.includes(Guid.EMPTY)){
            //                 this._accountService.loadZone(this.locationId).subscribe((respZone) => {
            //                     this.zoneId = respZone?.data ? respZone.data[0].id : Guid.EMPTY;
            //                     appLogger(this.zoneId);
            //                 })
            //             }
            //         })
            //     })
            // })
        })
        this.loadAccounts();
        if (this.data?.data){
            // this.loadAccountById(this.surveyObj.account_id);
            this.loadOrganization(this.surveyObj.organization_id);
            this.loadUnitByOrganizationId(this.surveyObj.organization_id);
            this.loadLocationsByUnitId(this.surveyObj.unit_id);
            this.loadZoneByLocationId(this.surveyObj.location_id);
        }
        // this.filteredOptions = this.surveyAddForm.controls['account_id'].valueChanges.pipe(
        //     startWith(''),
        //     map(value => this._filter(value))
        //   );
    }

    // private _filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();
        
    //     return this.options.filter(option => option.toLowerCase().includes(filterValue));
    // }
    
    loadAccessModifierLookup(){
        this._surveyService.getLookupAccessModifier().subscribe((response) => {
            this.accessModifierList = response?.data;
            // appLogger(this.accessModifierList);
            if (this.accessModifierList){
                this.accessModifierList.forEach((currentType, typeIndex) => {
                    currentType.sys_setting_langs.forEach((currentLang, langIndex) => {
                        if(currentLang.lang == this.currentLanguage) {
                            this.accessModifierList[typeIndex].name = currentLang.name;
                            this.accessModifierList[typeIndex].lang = currentLang.lang;
                            this.accessModifierList[typeIndex].description = currentLang.description;
                        }
                    });
                  });
                  this.patchAccessModifierData();
            }
        })
    }
    
    patchAccessModifierData = () => {
        // appLogger(this.surveyObj.access_modifier);
        const accessObj = this.accessModifierList.find(rec => rec.code === this.surveyObj.access_modifier.toString());
        // appLogger(accessObj);
        if (accessObj) {
            // this.surveyObj.access_modifier = accessObj;
            // this.accessModifier = accessObj.sys_setting_langs.find(rec => rec.lang === this.currentLanguage).name;
            // this.formControls['accessModifierInput'].setValue(accessObj.sys_setting_langs.find(rec => rec.lang === this.currentLanguage).name);
            this.formControls['accessModifierInput'].setValue(accessObj);
        }
    };

    onChangeAccessModifier(accessModifierCode: string, event) {
        if (accessModifierCode && event.isUserInput) {
            this.surveyObj.access_modifier = accessModifierCode;
            appLogger( this.surveyObj.access_modifier);
        }
    }

    get formControls() {
        return this.surveyAddForm.controls;
    }

    initialize = () => {
        this.createModuleForm();
        this.userDetail = this._sharedUserService.getUser();
        this.loadAccessModifierLookup();
    };
    

    loadAssignedUser(tenantId) {
        this._userService.getUserLookupByTenantId(tenantId).subscribe(response => {
            this.assignedUserId = response?.data;
        })
    }
    
    createModuleForm = () => {
        this.surveyAddForm = this.createForm({
            survey_name: [''],
            start_date: [''],
            end_date: [''],
            srv_type: [''],
            access_modifier: [''],
            accessModifierInput: [''],
            srv_assign_users: [''],
            srv_color: [''],
            srv_description: [''],
            account_id: ['', []],
            organization_id: ['', []],
            unit_id: ['', []],
            location_id: ['', []],
            zone_id: ['', []],
            date_start: ['', []],
            date_end: ['', []],
            // size: [''],
            // budget: [''],
        });
        
    };

    onSurveyAddSubmit = (form: FormGroup) => {
        const controls = this.surveyAddForm.controls;
        // const user = this._sharedUserService.getUser();
        // appLogger(user);
        if (this.onSubmit(form)) {
            // const param: any = {
            //     'tenant_id': this.tenantId,
            //     'account_id': this.accountId,
            //     'organization_id': this.organizationId,
            //     'location_id': this.locationId,
            //     'zone_id': this.zoneId,
            //     'date_start': controls.start_date.value,
            //     'date_end': controls.end_date.value,
            //     'size': this.surveyStatus === 'Edit' ? this.surveyObj.size : 0,
            //     'budget': this.surveyStatus === 'Edit' ? this.surveyObj.budget : 0,
            //     'srv_type_id': controls.srv_type.value,
            //     'allow_user_to_see_result': this.surveyStatus === 'Edit' ? this.surveyObj.allow_user_to_see_result : true,
            //     'collect_responder_email': this.surveyStatus === 'Edit' ? this.surveyObj.collect_responder_email : '',
            //     'agreement_text': this.surveyStatus === 'Edit' ? this.surveyObj.agreement_text : '',
            //     'enable_user_signature': this.surveyStatus === 'Edit' ? this.surveyObj.enable_user_signature : true,
            //     'enable_responder_signature': this.surveyStatus === 'Edit' ? this.surveyObj.enable_responder_signature : true,
            //     'user_term': this.surveyStatus === 'Edit' ? this.surveyObj.user_term : '',
            //     'responder_term': this.surveyStatus === 'Edit' ? this.surveyObj.responder_term : '',
            //     'name': controls.survey_name.value ? controls.survey_name.value : '',
            //     'description': this.surveyStatus === 'Edit' ? this.surveyObj.description : '',
            //     'status': this.surveyStatus === 'Edit' ? this.surveyObj.status : 0,
            //     'tag': this.surveyStatus === 'Edit' ? this.surveyObj.tag : ''
            // }
            this.surveyObj.tenant_id = this.tenantId,
            this.surveyObj.account_id = this.surveyObj?.account_id?.id,
            this.surveyObj.organization_id = this.surveyObj?.organization_id?.id,
            this.surveyObj.location_id = this.surveyObj?.location_id?.id,
            this.surveyObj.unit_id = this.surveyObj?.unit_id?.id,
            this.surveyObj.zone_id = this.surveyObj?.zone_id?.id,
            this.surveyObj.date_start = controls.start_date.value;
            this.surveyObj.date_end = controls.end_date.value;
            this.surveyObj.allow_user_to_see_result = this.surveyStatus === 'Edit' ? this.surveyObj.allow_user_to_see_result : true;
            
            const param: any = copyPropsSurveyObject(this.surveyObj, this.surveyStatus)
            param.status = 0;
            
            // if (this.surveyStatus === 'Edit') {
            //     param.id = this.surveyObj.id;
            // }
            this.saveSurvey(param).subscribe((response) => {
                if (response.status === HttpStatus.SUCCESS) {
                    this.dialogRef.close();
                }
            })
        }
    };

    saveSurvey(param): Observable<any> {
        if (this.surveyStatus === 'Edit') {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.PUT, AppApi.SURVEY_UPDATE, param,
                this._apiManager.Authorised_HttpOptions, true, true);
        } else {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.POST, AppApi.SURVEY_ADD, param,
                this._apiManager.Authorised_HttpOptions, true, true);
        }
    }

    loadAccounts() {
        this._accountService.loadAccount().subscribe((response) => {
            if (response) {
                this.accounts = response ? response.data : null;
                this.filteredAccounts = this.accounts;
            } else {
                this.accounts = [];
                const obj = new FixAssetLookupAccountModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_ACCOUNT');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.accounts.push(obj);
            }
            // appLogger(this.accounts);
            this._loadfilterAccount(this.accounts);
            this.accounts.forEach(acc => {
                if (acc.id === this.surveyObj.account_id) {
                    this.surveyObj.account_id = acc;
                }
            });
        })
    }
    
    loadAccountById(id) {
        this._accountService.loadAccountById(id).subscribe((response) => {
            if (response) {
                this.accounts = response ? response.data : null;
                this.filteredAccounts = this.accounts;
            } else {
                this.accounts = [];
                const obj = new FixAssetLookupAccountModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_ACCOUNT');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.accounts.push(obj);
            }
            this._loadfilterAccount(this.accounts);
            this.accounts.forEach(acc => {
                if (acc.id === this.surveyObj.account_id) {
                    this.surveyObj.account_id = acc;
                }
            });
        })
    }

    private _loadfilterAccount(accounts: FixAssetLookupAccountModel[]) {
        this.filterAccountLookups = this.formControls.account_id.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value?.name),
                    map(name => name ? this._filterAccount(name) : accounts?.slice())
                );
    }

    private _filterAccount(name: string): any[] {
        const filterValue = name.toLowerCase();
        const result = this.accounts.filter(option => option.name.toLowerCase().includes(filterValue));
        if (result.length < 1){
            const obj = new FixAssetLookupAccountModel();
            obj.name = this._translateService.instant('APP_SETTINGS.ADD_ACCOUNT');
            obj.type = 'ADD';
            obj.code = name;
            obj.sequence = 1;
            
            result.push(obj);
        } 
        return result;
    }

    loadOrganization(id){
        this._accountService.loadOrganizationById(id).subscribe((response) => {
            if (response) {
                this.organizations = response ? response['data'] : null;
                this.filteredOrganizations = this.organizations?.slice();
            } else {
                this.organizations = [];
                const obj = new FixAssetLookupOrganizationModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_ORGANIZATION');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.organizations.push(obj);
            }
            this._loadfilterOrganization(this.organizations);
            this.organizations?.forEach(org => {
                if (org.id === this.surveyObj.organization_id){
                    this.surveyObj.organization_id = org;
                }
            });
        });
    }

    loadOrganizationByAccountId(accountId){
        this._accountService.loadOrganizationByAccountId(accountId).subscribe((response) => {
            if (response) {
                this.organizations = response ? response['data'] : null;
                this.filteredOrganizations = this.organizations?.slice();
            } else {
                this.organizations = [];
                const obj = new FixAssetLookupOrganizationModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_ORGANIZATION');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.organizations.push(obj);
            }
            this._loadfilterOrganization(this.organizations);
            this.organizations?.forEach(org => {
                if (org.id === this.surveyObj.organization_id){
                    this.surveyObj.organization_id = org;
                }
            });
        });
    }

    loadUnit(id){
        this._accountService.getUnitById(id).subscribe((response) => {
            if (response) {
                this.units = response ? response['data'] : null;
                this.filteredUnits = this.units?.slice();
            } else {
                this.units = [];
                const obj = new FixAssetLookupUnitModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_UNIT');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.units.push(obj);
            }
            this._loadfilterUnit(this.units);
            this.filteredUnits = this.units?.slice();
            this.units?.forEach(unit => {
                if (unit.id === this.surveyObj.unit_id){
                    this.surveyObj.unit_id = unit;
                }
            });
        });
    }

    loadUnitByOrganizationId(organizationId){
        this._fixAssetService.getUnitByOrganizationId(organizationId).subscribe((response) => {
            if (response) {
                this.units = response ? response['data'] : null;
                this.filteredUnits = this.units?.slice();
                // appLogger(this.units);
            } else {
                this.units = [];
                const obj = new FixAssetLookupUnitModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_UNIT');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.units.push(obj);
            }
            this._loadfilterUnit(this.units);
            this.filteredUnits = this.units?.slice();
            this.units?.forEach(unit => {
                if (unit.id === this.surveyObj.unit_id){
                    this.surveyObj.unit_id = unit;
                }
            });
        });
    }

    loadLocations(id){
        // 
        this._accountService.getLocationById(id).subscribe((response) => {
            if (response) {
                this.locations = response ? response['data'] : null;
                this.filteredLocations = this.locations?.slice();
            } else {
                this.locations = [];
                const obj = new FixAssetLookupLocationModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_LOCATION');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.locations.push(obj);
            }
            this.locations?.forEach(location => {
                if (location.id === this.surveyObj.location_id){
                    this.surveyObj.location_id = location;
                }
            });
        });
    }

    loadLocationsByUnitId(unitId){
        this._fixAssetService.getLocationByUnitId(unitId).subscribe((response) => {
            if (response) {
                this.locations = response ? response['data'] : null;
                this.filteredLocations = this.locations?.slice();
            } else {
                this.locations = [];
                const obj = new FixAssetLookupLocationModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_LOCATION');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.locations.push(obj);
            }
            this._loadfilterLocation(this.locations);
            this.locations?.forEach(location => {
                if (location.id === this.surveyObj.location_id){
                    this.surveyObj.location_id = location;
                }
            });
        });
    }

    loadZone(id){
        this._accountService.getZoneById(id).subscribe((response) => {
            if (response) {
                this.zones = response ? response['data'] : null;
                this.filteredZones = this.zones?.slice();
            } else {
                this.zones = [];
                const obj = new FixAssetLookupZoneModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_ZONE');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.zones.push(obj);
            }
            this._loadfilterZone(this.zones);
            this.zones?.forEach(zone => {
                if (zone.id === this.surveyObj.zone_id){
                    this.surveyObj.zone_id = zone;
                }
            });
        });
    }

    loadZoneByLocationId(locationId){
        this._fixAssetService.getZoneByLocationId(locationId).subscribe((response) => {
            if (response) {
                this.zones = response ? response['data'] : null;
                this.filteredZones = this.zones?.slice();
            } else {
                this.zones = [];
                const obj = new FixAssetLookupZoneModel();
                obj.name = this._translateService.instant('APP_SETTINGS.ADD_ZONE');
                obj.type = 'ADD';
                obj.sequence = 1;
                this.zones.push(obj);
            }
            this._loadfilterZone(this.zones);
            this.zones?.forEach(zone => {
                if (zone.id === this.surveyObj.zone_id){
                    this.surveyObj.zone_id = zone;
                }
            });
        });
    }


    displayAccount(account: FixAssetLookupAccountModel): string {
        return account && account.name ? account.name : '';
    }

    private _loadfilterOrganization(organizations: FixAssetLookupOrganizationModel[]) {
        this.filterOrganizationLookups = this.formControls.organization_id.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value?.name),
                map(name => name ? this._filterOrganization(name) : organizations?.slice())
            );
    }

    private _filterOrganization(name: string): any[] {
        const filterValue = name.toLowerCase();
        const result = this.organizations.filter(option => option.name.toLowerCase().includes(filterValue));
        if (result.length < 1){
            const obj: any = {};
            obj.name = this._translateService.instant('APP_SETTINGS.ADD_ORGANIZATION');
            obj.type = 'ADD';
            obj.code = name;
            obj.sequence = 1;
            
            result.push(obj);
        } 
        return result;
    }

    displayOrganization(organization: FixAssetLookupOrganizationModel): string {
        return organization && organization.name ? organization.name : '';
    }

    private _loadfilterUnit(units: FixAssetLookupUnitModel[]) {
        this.filterUnitLookups = this.formControls.unit_id.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value?.name),
                map(name => name ? this._filterUnit(name) : units?.slice())
            );
    }

    private _filterUnit(name: string): any[] {
        const filterValue = name.toLowerCase();
        const result = this.units.filter(option => option.name.toLowerCase().includes(filterValue));
        if (result.length < 1){
            const obj: any = {};
            obj.name = this._translateService.instant('APP_SETTINGS.ADD_UNIT');
            obj.type = 'ADD';
            obj.code = name;
            obj.sequence = 1;
            
            result.push(obj);
        } 
        return result;
    }

    displayUnit(unit: FixAssetLookupUnitModel): string {
        return unit && unit.name ? unit.name : '';
    }

    private _loadfilterLocation(locations: FixAssetLookupLocationModel[]) {
        this.filterLocationLookups = this.formControls.location_id.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value?.name),
                map(name => name ? this._filterLocation(name) : locations?.slice())
            );
    }

    private _filterLocation(name: string): any[] {
        const filterValue = name.toLowerCase();
        const result = this.locations.filter(option => option.name.toLowerCase().includes(filterValue));
        if (result.length < 1){
            const obj: any = {};
            obj.name = this._translateService.instant('APP_SETTINGS.ADD_LOCATION');
            obj.type = 'ADD';
            obj.code = name;
            obj.sequence = 1;
            
            result.push(obj);
        } 
        return result;
    }

    displayLocation(location: FixAssetLookupLocationModel): string {
        return location && location.name ? location.name : '';
    }

    private _loadfilterZone(zones: FixAssetLookupZoneModel[]) {
        this.filterZoneLookups = this.formControls.zone_id.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value?.name),
                map(name => name ? this._filterZone(name ? name : '') : zones?.slice())
            );
    }

    private _filterZone(name: string): any[] {
        const filterValue = name.toLowerCase();
    
        const result = this.zones.filter(option => option.name.toLowerCase().includes(filterValue));
        if (result.length < 1){
            const obj: any = {};
            obj.name = this._translateService.instant('APP_SETTINGS.ADD_ZONE');
            obj.type = 'ADD';
            obj.code = name;
            obj.sequence = 1;
            
            result.push(obj);
        } 
        return result;
    }

    displayZone(zone: FixAssetLookupZoneModel): string {
        return zone && zone.name ? zone.name : '';
    }

    onChangeAccount(id) {
        // appLogger(id);
        if (id?.name.includes(this._translateService.instant('APP_SETTINGS.ADD_ACCOUNT'))) {
            this.showConfirmationAddData(this.formControls.account_id.value?.code).afterClosed().subscribe(result => {
                if (result.submit) {
                    const account = this.initiateAccountParams('I', '1001');
                    account.name = this.formControls.account_id.value?.code;
                    this._fixAssetService.addAccount(account).subscribe((response) => {
                        if (response?.status === HttpStatus.SUCCESS) {
                            this.notificationService.success(response?.message);
                        } else {
                            this.notificationService.warn(response?.message);
                        }
                        this.formControls.account_id.setValue(null);
                        this.loadAccounts();
                    });
                }
            })
        } else {
            this.clearOrganizations();
            this.clearUnits();
            this.clearLocations();
            this.clearZones();
            this.loadOrganizationByAccountId(id?.id);
        }
    }

    onChangeOrganization(id) {
        if (id?.name.includes(this._translateService.instant('APP_SETTINGS.ADD_ORGANIZATION'))) {
            this.showConfirmationAddData(this.formControls.organization_id.value?.code).afterClosed().subscribe(result => {
                if (result.submit) {
                    const account = this.initiateAccountParams('1', '1002');
                    account.account_id = this.formControls.account_id.value?.id;
                    account.name = this.formControls.organization_id.value?.code;
                    this._fixAssetService.addOrganization(account).subscribe((response) => {
                        if (response?.status === HttpStatus.SUCCESS) {
                            this.notificationService.success(response?.message);
                        } else {
                            this.notificationService.warn(response?.message);
                        }
                        this.clearOrganizations();
                        this.loadAccounts();
                    });
                }
            })
        }  else {
            this.filteredOrganizations = this.organizations.filter(organization => organization.id === id.id);
            // appLogger(this.filteredOrganizations);
            this.units = [];
            this.loadUnitByOrganizationId(id?.id);
        }
    }

    onChangeUnit(id) {
        if (id?.name.includes(this._translateService.instant('APP_SETTINGS.ADD_UNIT'))) {
            this.showConfirmationAddData(this.formControls.unit_id.value?.code).afterClosed().subscribe(result => {
                if (result.submit) {
                    const account = this.initiateAccountParams('1', '1002');
                    account.organization_id = this.formControls.organization_id.value?.id;
                    account.name = this.formControls.unit_id.value?.code;
                    this._fixAssetService.addUnit(account).subscribe((response) => {
                        if (response?.status === HttpStatus.SUCCESS) {
                            this.notificationService.success(response?.message);
                        } else {
                            this.notificationService.warn(response?.message);
                        }
                        this.clearUnits();
                        this.loadUnitByOrganizationId(this.formControls.organization_id.value?.id);
                    });
                }
            })
        } else {
            this.filteredUnits = this.units.filter(unit => unit.id === id.id);
            // appLogger(this.filteredUnits);
            this.locations = [];
            this.loadLocationsByUnitId(id?.id);
        }
    }

    onChangeLocation(id) {
        if (id?.name.includes(this._translateService.instant('APP_SETTINGS.ADD_LOCATION'))) {
            this.showConfirmationAddData(this.formControls.location_id.value?.code).afterClosed().subscribe(result => {
                if (result.submit) {
                    const account = this.initiateAccountParams('1', '1002');
                    account.unit_id = this.formControls.unit_id.value?.id;
                    account.name = this.formControls.location_id.value?.code;
                    this._fixAssetService.addLocation(account).subscribe((response) => {
                        if (response?.status === HttpStatus.SUCCESS) {
                            this.notificationService.success(response?.message);
                        } else {
                            this.notificationService.warn(response?.message);
                        }
                        this.clearLocations();
                        this.loadLocationsByUnitId(this.formControls.unit_id.value?.id);
                    });
                }
            })
        } else {
            this.zones = [];
            this._fixAssetService.getZoneByLocationId(id?.id).subscribe((response) => {
                this.zones = response ? response?.data : [];
                this.filteredZones = this.zones;
                this._loadfilterZone(this.zones);
            })
        }
    }

    onChangeZone(id) {
        // appLogger(id);
        if (id?.name.includes(this._translateService.instant('APP_SETTINGS.ADD_ZONE'))) {
            this.showConfirmationAddData(this.formControls.zone_id.value?.code).afterClosed().subscribe(result => {
                if (result.submit) {
                    const account = this.initiateAccountParams('1', '1002');
                    account.location_id = this.formControls.location_id.value?.id;
                    account.name = this.formControls.zone_id.value?.code;
                    this._fixAssetService.addZone(account).subscribe((response) => {
                        if (response?.status === HttpStatus.SUCCESS) {
                            this.notificationService.success(response?.message);
                        } else {
                            this.notificationService.warn(response?.message);
                        }
                        this.clearZones();
                        this.loadZoneByLocationId(this.formControls.location_id.value?.id);
                    });
                }
            })
        } else {
            this.filteredLocations = this.locations.filter(unit => unit.id === id.id);
            // appLogger(this.filteredLocations);
            this.zones = [];
            for (const location of this.filteredLocations){
                for (const zone of location.zones){
                    this.zones.push(zone);
                }
            }
            // appLogger(this.zones);
            this.filteredZones = this.zones;
            this._loadfilterZone(this.zones);
        }
    }

    showConfirmationAddData(value): MatDialogRef<ConfirmationDialogComponent, any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            message: `Are you sure want to Add New Data ` + value + `?`,
            title: `Are you sure?`,
          },
        });
        return dialogRef;
    }

    clearOrganizations(){
        this.surveyObj.organization_id = null;
        this.organizations = null;
        this._loadfilterOrganization(this.organizations);
    }

    clearUnits(){
        this.surveyObj.unit_id = null;
        this.units = null;
        this._loadfilterUnit(this.units);
    }

    clearLocations(){
        this.surveyObj.location_id = null;
        this.locations = null;
        this._loadfilterLocation(this.locations);
    }

    clearZones(){
        this.surveyObj.zone_id = null;
        this.zones = null;
        this._loadfilterZone(this.zones);
    }

    initiateAccountParams(type, code): any{
        this.userDetail = this.sharedService.getUser();
        const account: any = {};
        account.state_province_id = this.userDetail.state_province_id;
        account.country_id = this.userDetail.country_id;
        account.type = type;
        account.code = code;
        account.access_modifier = 0;
        account.status = GeneralStatusEnum.ACTIVE;
        account.iban = null;
        account.assigned_user_id = this.userDetail.id;
        return account;
    }

    onCloseDialog = () => {
        this.dialogRef.close();
    };

}
