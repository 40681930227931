<div class="table-panel table-panel--space">
    <div class="table-panel__navigation">
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12 xs-PLR0">
                <h2>
                    {{ "SURVEY_MODULES.SURVEY" | translate }}
                </h2>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12 xs-text-left xs-PLR0 text-right">
                <label class="filter-applied mr-8" *ngIf="filterCounts > 0"
                    >{{ filterCounts }} Filters Applied</label
                >
                <label
                    class="reset-all mr-8"
                    *ngIf="filterCounts > 0"
                    (click)="resetFilter()"
                    >{{ "SURVEY_MODULES.RESET_ALL" | translate }}</label
                >
                <button
                    type="button"
                    mat-flat-button
                    color="secondary"
                    class="survey-card-button"
                >
                    <span class="material-icons"> download </span>
                    <label>
                        {{ "SURVEY_MODULES.DOWNLOAD" | translate }}
                    </label>
                </button>
                <button
                    type="button"
                    mat-flat-button
                    color="secondary"
                    (click)="
                        !isShowFilter ? openModal(content) : openModal(content)
                    "
                >
                    <span *ngIf="!isShowFilter" class="material-icons">
                        filter_list
                    </span>
                    <span *ngIf="isShowFilter" class="material-icons"> close </span>
                    <label *ngIf="!isShowFilter">
                        {{ "SURVEY_MODULES.SHOW_FILTER" | translate }}
                    </label>
                    <label *ngIf="isShowFilter">
                        {{ "SURVEY_MODULES.HIDE_FILTER" | translate }}
                    </label>
                </button>
                <button
                    type="button"
                    mat-flat-button
                    color="primary"
                    (click)="createNewSurvey()"
                >
                    {{ "SURVEY_MODULES.ADD" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="table-panel__body"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        (scrolled)="onScroll()"
    >
        <div *ngIf="surveyList" class="row survey-container">
            <ng-container *ngFor="let item of surveyList">
                <div class="col-md-12 col-sm-12 xs-PLR0 PR-0">
                    <div class="survey-card">
                        <div class="row">
                            <div class="col-md-6 col-sm-5">
                                <div class="kt-widget4">
                                    <div class="kt-widget4__item">
                                        <div class="kt-widget4__icon">
                                            <div class="color-box" [ngStyle]="{'background-color': item.color? item.color: '#000'}"></div>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <a (click)="goToDetails(item.id)">{{ item.name }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-7 text-right xs-PR0 xs-text-left mobile-none-menu-right">
                                <ul class="comment-text">
                                    <li>
                                        <a
                                            (click)="
                                                onSurveyViewDetails(
                                                    commentUpvoteViewEnum.COMMENT_LIST
                                                )
                                            "
                                            [ngClass]="{
                                                active:
                                                    isShowView ===
                                                    commentUpvoteViewEnum.COMMENT_LIST
                                            }"
                                        >
                                            <span class="material-icons">
                                                comment
                                            </span>
                                            <label>46 COMMENTS</label>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            [ngClass]="{
                                                active: false
                                            }"
                                        >
                                            <span class="material-icons">
                                                thumb_up
                                            </span>
                                            <label>34 UPVOTE</label>
                                        </a>
                                    </li>
                                </ul>

                                <ul class="right-actions-survey">
                                    <li>
                                        <ng-container *ngFor="let accessModifier of accessModifierList">
                                            <ng-container *ngIf="accessModifier.code === toString(item?.access_modifier);then thenBlock else elseBlock"></ng-container>
                                            <ng-template #thenBlock>
                                                <ng-container *ngFor="let langOption of accessModifier.sys_setting_langs">
                                                    <div *ngIf="langOption.lang === this.currentLanguage">
                                                        <span>{{ langOption.name }}</span>
                                                    </div>  
                                                </ng-container>
                                            </ng-template>
                                            <!-- <span *ngIf="item?.access_modifier === 102">{{ "TASK_MANAGEMENT.PUBLIC" | translate }}</span>
                                            <span *ngIf="item?.access_modifier === 103">{{ "TASK_MANAGEMENT.TEAM" | translate }}</span> -->
                                        </ng-container>
                                    </li>
                                    <li>
                                        <a matTooltip="Copy Link">
                                            <span class="material-icons">
                                                content_copy
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 col-sm-3">
                                
                                <div class="kt-widget4">
                                    <div class="kt-widget4__item">
                                        <!-- <div class="icon-wrapper mr-8">
                                            <fa-icon [icon]="icon.document" class="kt-widget4__icon icon"></fa-icon>
                                        </div> -->
                                        <div class="survey-card__icon mr-8">
                                            <span class="material-icons">
                                                list_alt
                                            </span>
                                        </div>

                                        <div class="kt-widget4__info">
                                            <p class="kt-widget4__text">{{"SURVEY_MODULES.TYPE" | translate }}</p>
                                            <ng-container *ngFor="let type of item.srv_type.srv_type_langs">
                                            <p class="kt-widget4__username"
                                                *ngIf="type.lang === this.currentLanguage"
                                            >
                                                {{type.name}}
                                            </p>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 space-divider">
                                <div class="kt-widget4">
                                    <div class="kt-widget4__item">
                                        <div class="survey-card__icon mr-8">
                                            <span class="material-icons">
                                                access_time
                                            </span>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <p class="kt-widget4__text">
                                                {{"SURVEY_MODULES.PLACEHOLDER.START_DATE" | translate }}
                                            </p>
                                            <p class="kt-widget4__username">
                                                {{
                                                    item.date_start
                                                        | date: "yyyy-MM-dd"
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 space-divider">
                                <div class="kt-widget4">
                                    <div class="kt-widget4__item">
                                        <div class="survey-card__icon mr-8">
                                            <span class="material-icons">
                                                access_time
                                            </span>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <p class="kt-widget4__text">
                                                {{"SURVEY_MODULES.PLACEHOLDER.END_DATE" | translate }}
                                            </p>
                                            <p class="kt-widget4__username">
                                                {{
                                                    item.date_end
                                                        | date: "yyyy-MM-dd"
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-3 text-right xs-text-left">
                                <div class="row">
                                    <div class="col-md-12 PL-0">
                                        <app-survey-list-team-list [serviceId]="item.id" *ngIf="item?.access_modifier === 103"></app-survey-list-team-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row survey-footer-link">
                            <div class="col-md-8 col-sm-6 space-divider PL-0">
                                <ul class="btn-row">
                                    <li>
                                        <a
                                            class="button-footer"
                                            (click)="goToDetails(item.id)"
                                        >
                                            <span class="material-icons">
                                                create
                                            </span>
                                            <label>
                                                {{
                                                    "SURVEY_MODULES.EDIT"
                                                        | translate
                                                }}
                                            </label>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            class="button-footer"
                                            (click)="goToPreview(item.id)"
                                        >
                                            <span class="material-icons">
                                                preview
                                            </span>
                                            <label>
                                                {{
                                                    "SURVEY_MODULES.PREVIEW"
                                                        | translate
                                                }}
                                            </label>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            class="button-footer"
                                            (click)="goToResponse(item.id)"
                                        >
                                            <span class="material-icons">
                                                group
                                            </span>
                                            <label>
                                                {{
                                                    "SURVEY_MODULES.RESPONSES"
                                                        | translate
                                                }}
                                            </label>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            class="button-footer__delete"
                                            (click)="deleteSurvey(item.id)"
                                        >
                                            <span class="material-icons">
                                                delete
                                            </span>
                                            <label class="delete">
                                                {{
                                                    "SURVEY_MODULES.DELETE"
                                                        | translate
                                                }}
                                            </label>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="col-md-6 col-sm-7 text-right xs-PR0 xs-text-left mobile-bottom-menu">
                                <ul class="comment-text">
                                    <li>
                                        <a
                                            (click)="
                                                onSurveyViewDetails(
                                                    commentUpvoteViewEnum.COMMENT_LIST
                                                )
                                            "
                                            [ngClass]="{
                                                active:
                                                    isShowView ===
                                                    commentUpvoteViewEnum.COMMENT_LIST
                                            }"
                                        >
                                            <span class="material-icons">
                                                comment
                                            </span>
                                            <label>46 COMMENTS</label>
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            [ngClass]="{
                                                active: false
                                            }"
                                        >
                                            <span class="material-icons">
                                                thumb_up
                                            </span>
                                            <label>34 UPVOTE</label>
                                        </a>
                                    </li>
                                </ul>

                                <ul class="right-actions-survey">
                                    <li>
                                        <span>Public</span>
                                    </li>
                                    <li>
                                        <a matTooltip="Copy Link">
                                            <span class="material-icons">
                                                content_copy
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-md-4 col-sm-6">
                                <ul class="action-comment xs-text-left">
                                    <li>
                                        <a>
                                            <span class="material-icons">
                                                thumb_up
                                            </span>
                                            <label>LIKE</label>
                                        </a>
                                    </li>

                                    <li>
                                        <a (click)="onSurveyViewDetails(
                                            commentUpvoteViewEnum.COMMENT
                                        )"
                                            [ngClass]="{
                                                active: isShowView ===
                                                commentUpvoteViewEnum.COMMENT_LIST
                                            }"
                                        >
                                            <span class="material-icons">
                                                comment
                                            </span>
                                            <label>GIVE YOUR COMMENT</label>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div
                            *ngIf="
                                isShowView ===
                                commentUpvoteViewEnum.COMMENT_LIST
                            "
                            class="row MB-2"
                        >
                            <div class="col-md-12">
                                <app-comments-list></app-comments-list>
                            </div>
                        </div>

                        <div *ngIf="isShowView === commentUpvoteViewEnum.COMMENT" class="row MB-2">
                            <div class="col-md-12 xs-PR0">
                                <app-comment-details></app-comment-details>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="!surveyList" class="no-data">
            <div class="no-data__content">
                <mat-icon svgIcon="not-found"></mat-icon>
                <h5>{{ "SURVEY_MODULES.NO_SURVEY" | translate }}</h5>
                <p>{{ "SURVEY_MODULES.NO_SURVEY_TEXT" | translate }}</p>
                <button
                    class="red-btn"
                    (click)="createNewSurvey()"
                    type="button"
                    mat-flat-button
                    color="primary"
                >
                    {{ "SURVEY_MODULES.ADD_SURVEY" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="table-panel__footer" style="visibility: hidden" *ngIf="surveyList">
        <mat-paginator
            [length]="surveyList ? surveyList.length : 0"
            [pageSizeOptions]="[10, 50, 100]"
            [pageSize]="10"
            #matPaginator
            showFirstLastButtons
            style="visibility: hidden"
        ></mat-paginator>
    </div>
</div>

<div *ngIf="!surveyList">
    <app-no-data></app-no-data>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <div class="row mb-16">
            <div class="col-md-11 col-xs-8">
                <h2 class="modal-title">
                    {{ "SURVEY_MODULES.FILTER" | translate }}
                </h2>
            </div>
            <div class="col-md-1 col-xs-4">
                <div
                    class="survey-filter__icon mr-8"
                    (click)="closeDialog()"
                    matTooltip="{{ 'BOTTON.CLOSE' | translate }}"
                >
                    <span class="survey__icon material-icons"> close </span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <app-survey-filter
            (applyFilter)="applyFilter($event)"
        ></app-survey-filter>
    </div>
</ng-template>
