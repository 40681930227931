import { HttpEventType, HttpHeaders, HttpProgressEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApi, DocumentViewTypeEnum, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager } from 'app/utility/shared-services';
import { Observable } from 'rxjs';
import { FileListsModel } from '../models/file-lists.model';
import { FileResponseModel } from '../models/file-response.model';

@Injectable()
export class FileManagementService {
    folderTree: Array<any>;
    constructor(
        private apiManager: APIManager) {
        this.folderTree = [];
    }

    getAllFilesFolders(params): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_ALL_FILES_FOLDERS, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    getAllSubFilesFolders(parentId = ''): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_ALL_FILES_FOLDERS, { parentId: parentId },
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    getAllArchivedFilesFolders(): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_ALL_ARCHIVED_FILES_FOLDERS, undefined,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    searchAllSubFilesFolders(searchString, deleted = 0): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.SEARCH_ALL_FILES_FOLDERS, {
            searchString: searchString,
            deleted: deleted
        },
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    createNewFolder(params): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.CREATE_NEW_FOLDER, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    uploadFile(params, files): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST_MULTIPART, `${AppApi.UPLOAD_FILE}?storageProvider=S3`, params,
            this.apiManager.Authorised_HttpOptions, false, true, undefined, files);
    }
    uploadFileWithProgress<T>(params, progress: ((percent: number) => boolean)) {
        const formData = new FormData();
        Object.keys(params).map((param) => {
            formData.append(param, params[param]);
        });
        const req = new HttpRequest(HttpMethodsTypeEnum.POST,
            `${AppApi.UPLOAD_FILE}?storageProvider=S3`,
            formData,
            {
                ...this.apiManager.Authorised_HttpOptions,
                reportProgress: true,
                responseType: "json"
            });
        return new Promise<T>((resolve, reject) => {
            const unsubscribe = this.apiManager.httpClient.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const e = event as HttpProgressEvent;
                    const percentDone = Math.round(100 * e.loaded / e.total);
                    if (!progress(percentDone)) {
                        unsubscribe.unsubscribe();
                        reject("Aborted");
                    }
                } else if (event instanceof HttpResponse) {
                    unsubscribe.unsubscribe();
                    const e = event as HttpResponse<Object>;
                    resolve(e.body as T);
                }
            },
                error => {
                    unsubscribe.unsubscribe();
                    reject(error);
                });
        });
    }
    archivalRequest(params): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.ARCHIVE_FILE_REQUEST, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    unarchivalRequest(params): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.UNARCHIVE_FILE_REQUEST, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    deleteRequest(params): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DELETE_FILE_REQUEST, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    download(params): Observable<any> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.DOWNLOAD_FILE_REQUEST, params,
            this.apiManager.Blob_HttpOptions, false, true);
    }
    updateFileFolder(params): Observable<any> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.PUT, AppApi.UPDATE_FILE_REQUEST, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    moveFileFolder(params): Observable<any> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.MOVE_FILE_REQUEST, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    getPreviewUrl(fileId: string): string {
        var qs = {
            fileId: fileId,
            access_token: this.apiManager.Auth_Token
        }
        return AppApi.PREVIEW_FILE_REQUEST
            + "?" + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
    }
    getThumbnailUrl(serviceId: string): string {
        var qs = {
            serviceId: serviceId,
            access_token: this.apiManager.Auth_Token
        }
        return AppApi.PREVIEW_THUMBNAIL_REQUEST
            + "?" + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
    }
    getPreviewType(type: string) {
        if (type.match(/.(jpg|jpeg|png|gif)$/i)) return DocumentViewTypeEnum.IMG;
        else if (type.match(/pdf$/i)) return DocumentViewTypeEnum.PDF;
        else return DocumentViewTypeEnum.DOC;
    }
    getServiceTags(fileId: string) {
        var params = {
            ServiceId: fileId,
        };
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.GET_ALL_TAGS, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    deleteServiceTag(tagId: string) {
        var params = {
            id: tagId,
        };
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.DELETE_TAGS, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    addServiceTag(file: any, tag: string) {
        var params = {
            userId: file.userId,
            app_id: file.app_id,
            service_id: file.id,
            assigned_user_id: file.assigned_user_id,
            name: tag,
            type: "Tag"
        };
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.SAVE_TAGS, params,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
    emptyTrash(): Observable<FileListsModel> {
        return this.apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.REMOVE_ALL_ARCHIVED_FILES_FOLDERS, undefined,
            this.apiManager.Authorised_HttpOptions, false, true);
    }
}
