import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedFileService } from 'app/utility/shared-services/shared-file.service';

export enum DocumentViewTypeEnum {
  DOC = 1,
  IMG = 2,
  VIDEO = 3,
  PDF = 4,
}

@Component({
  selector: 'app-file-gallery-view',
  templateUrl: './file-gallery-view.component.html',
  styleUrls: ['./file-gallery-view.component.scss'],
})
export class FileGalleryViewComponent implements OnInit {

//   @Input() url: string;
//   @Input() docType: DocumentViewTypeEnum = DocumentViewTypeEnum.DOC;
  documentViewTypeEnum = DocumentViewTypeEnum;
  docType: DocumentViewTypeEnum = DocumentViewTypeEnum.DOC;
  selectedIndex = 0;
  url: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private _sharedFileService: SharedFileService,
              private _cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.url = this.getPreviewUrl(this.data[0].id);
    this.docType = this.getDocType(this.data[0]);
  }

  getPreviewUrl = (fileId: string) => this._sharedFileService.getPreviewUrl(fileId);
  getDocType = (data) => this._sharedFileService.getPreviewType(data.type);
  isImage = (fileName: string) => !!fileName.match(/.(jpg|jpeg|png|gif)$/i);
  isPdf = (fileName: string) => !!fileName.match(/.(pdf)$/i);
  isMp4 = (fileName: string) => !!fileName.match(/.(mp4)$/i);

  onNextIndex = () => {
    this.selectedIndex = this.selectedIndex + 1;
    if (this.selectedIndex === this.data.length) {
      this.selectedIndex = 0;
    }
    this.url = this.getPreviewUrl(this.data[this.selectedIndex]['id']);
    this.docType = this.getDocType(this.data[this.selectedIndex]);
  }

  onPreviousIndex = () => {
    this.selectedIndex = this.selectedIndex - 1;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.data.length - 1;
    }
    this.url = this.getPreviewUrl(this.data[this.selectedIndex]['id']);
    this.docType = this.getDocType(this.data[this.selectedIndex]);
  }
}
