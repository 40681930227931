import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { PaginatedResult } from 'app/shared/models/pagination';
import { User } from 'app/public-modules/modules/auth/components/shared/model/user.model';
import { DocumentViewTypeEnum, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';
import { _FixAssetModel } from '../models/_fix-asset-module/_fix-asset.model';
import { AppApi } from '../shared-constants/app-api-constants';

@Injectable({
    providedIn: 'root'
})

export class FixAssetDotCoreService {
    baseUrl = environment.apiUrl + '/api/v1/Asset/';
    user: User = JSON.parse(localStorage.getItem('user'));

    constructor(private http: HttpClient,
                protected _sharedService: SharedService,
                private _apiManager: APIManager) { }

    getAll(page?, itemsPerPage?, famaParams?): Observable<PaginatedResult<_FixAssetModel[]>> {

        const paginatedResult: PaginatedResult<_FixAssetModel[]> = new PaginatedResult<_FixAssetModel[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
            params = params.append('pageNumber', page);
            params = params.append('pageSize', itemsPerPage);
        }

        if (famaParams != null) {
            if (famaParams.tenantId !== null && famaParams.tenantId !== '') {
                params = params.append('tid', famaParams.tenantId);
            }
            if (famaParams.organizationId != null && famaParams.organizationId !== '') {
                params = params.append('oid', famaParams.organizationId);
            }
            if (famaParams.locationId != null && famaParams.locationId !== '') {
                params = params.append('lid', famaParams.locationId);
            }
            if (famaParams.name != null && famaParams.name !== '') {
                params = params.append('name', famaParams.name);
            }
            if (famaParams.orderBy != null && famaParams.orderBy !== '') {
                params = params.append('orderBy', famaParams.orderBy);
            }
        }

        return this.http.get<_FixAssetModel[]>(AppApi.FIXASSET_LIST, { observe: 'response', params })
            .pipe(
                map(response => {
                    paginatedResult.result = response.body;

                    if (response.headers.get('Pagination') != null) {
                        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
                    }

                    return paginatedResult;
                })
            );
    }

    getAllFixAssets(page?, itemsPerPage?, famaParams?): Observable<any> {
        const paramsBody = {
            'pageNumber': page != null && itemsPerPage != null ? page : 0,
            'pageSize': page != null && itemsPerPage != null ? itemsPerPage : 0,
            'name': famaParams != null && (famaParams.name != null && famaParams.name !== '') ? famaParams.name : '',
            'srvid': famaParams != null && (famaParams.srvid != null && famaParams.srvid !== '') ? famaParams.srvid : '',
            'srvStart': famaParams != null && (famaParams.srvStart != null && famaParams.srvStart !== '') ? famaParams.srvStart : '',
            'srvEnd': famaParams != null && (famaParams.srvEnd != null && famaParams.srvEnd !== '') ? famaParams.srvEnd : '',
            'tid': famaParams != null && (famaParams.tid != null && famaParams.tid !== '') ? famaParams.tid : '',
            'aid': famaParams != null && (famaParams.aid != null && famaParams.aid !== '') ? famaParams.aid : '',
            'oid': famaParams != null && (famaParams.oid != null && famaParams.oid !== '') ? famaParams.oid : '',
            'status': famaParams != null && (famaParams.status != null && famaParams.status !== '') ? famaParams.status : '',
            'orderBy': famaParams != null && (famaParams.orderBy != null && famaParams.orderBy !== '') ? famaParams.orderBy : '',
        }

        return this.http.post(AppApi.FIXASSET_LIST, paramsBody);
    }

    getFixAssetListByFilter(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.FIXASSET_LIST, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getById(id): Observable<_FixAssetModel> {
        let params = new HttpParams();
        params = params.append('id', id);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + this._sharedService.getToken())
        return this.http.get<_FixAssetModel>(AppApi.FIXASSET_GETBYID, { headers, params });
    }

    loadVendorAccount(id): Observable<any> {
        if (id){
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.GET, AppApi.ACC_ACCOUNT_BY_ID, {id},
                this._apiManager.Authorised_HttpOptions, true, true);
        } else {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.GET, AppApi.ACC_VENDOR_ACCOUNT_LOOKUP, {},
                this._apiManager.Authorised_HttpOptions, true, true);
        }
    }

    archiveAsset(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.FIXASSET_ARCHIVE, id,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    deleteAsset(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.FIXASSET_DELETE, id,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    cloneFixAsset(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.FIXASSET_CLONE, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addAccount(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ACC_ACCOUNT_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addOrganization(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ACC_ORGANIZATION_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addUnit(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ACC_UNIT_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getUnitByOrganizationId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_UNIT_BY_ORGANIZATION_ID, {organization_id: id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addLocation(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ACC_LOCATION_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getLocationByUnitId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_LOCATION_BY_UNIT_ID, {unit_id: id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addZone(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.ACC_ZONE_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    getZoneByLocationId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_ZONE_BY_LOCATION_ID, {location_id: id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addBrand(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.BRAND_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addModel(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.PRODUCT_MODEL_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addProductFamily(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.PRODUCT_FAMILY_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addProductCategory(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.PRODUCT_CATEGORY_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addProductLine(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.PRODUCT_LINE_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    addProductType(param): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.PRODUCT_TYPE_ADD, param,
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    loadUserByTenantId(tenantId): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET,
            AppApi.LOOKUP_USER_BY_TENANT_ID,
            { tenantId: tenantId },
            this._apiManager.Authorised_HttpOptions,
            true,
            true
        );
    }
    
    loadUsers(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.USER, {},
            this._apiManager.Authorised_HttpOptions, false, true);
    }

    getUnitById(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.ACC_UNIT, {id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    // uploadFile(params, files): Observable<any> {
    //     return this._apiManager.httpHelperMethod(
    //       HttpMethodsTypeEnum.POST_MULTIPART, `${AppApi.UPLOAD_FILE}?storageProvider=S3`, params,
    //       this._apiManager.Authorised_HttpOptions, false, true, undefined, files);
    //   }
    
      getAllFilesByServiceId(id: string): Observable<any> {
        return this._apiManager.httpHelperMethod(
          HttpMethodsTypeEnum.GET, `${AppApi.GET_ALL_FILES_BY_SERVICE_ID}`, { ServiceId: id },
          this._apiManager.Authorised_HttpOptions, false, true);
      }
    
      archiveFile(id: string): Observable<any> {
        return this._apiManager.httpHelperMethod(
          HttpMethodsTypeEnum.DELETE, `${AppApi.ARCHIVE_FILE}`, { id: id },
          this._apiManager.Authorised_HttpOptions, true, true);
      }
      deleteFile(id: string): Observable<any> {
        return this._apiManager.httpHelperMethod(
          HttpMethodsTypeEnum.DELETE, `${AppApi.DELETE_FILE}`, { id: id },
          this._apiManager.Authorised_HttpOptions, true, true);
      }
    
      download(id: string): Observable<any> {
        return this._apiManager.httpHelperMethod(
          HttpMethodsTypeEnum.GET, `${AppApi.DOWNLOAD_FILE}`, { id: id },
          this._apiManager.Blob_HttpOptions, true, true);
      }
    
      getPreviewUrl(fileId: string): string {
        var qs = {
          fileId: fileId,
          access_token: this._apiManager.Auth_Token
        }
        return AppApi.PREVIEW_FILE_REQUEST
          + "?" + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
      }
    
      getPreviewType(type: string) {
        if (type.match(/.(jpg|jpeg|png|gif)$/i)) return DocumentViewTypeEnum.IMG;
        else if (type.match(/pdf$/i)) return DocumentViewTypeEnum.PDF;
        else if (type.match(/mp4$/i)) return DocumentViewTypeEnum.VIDEO;
        else return DocumentViewTypeEnum.DOC;
      }
}
