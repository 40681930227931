import { Component, OnInit } from "@angular/core";
import { FormBaseComponent } from "../form-base/form-base.component";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
    selector: "app-comment-details",
    templateUrl: "./comment-details.component.html",
    styleUrls: ["./comment-details.component.scss"],
})
export class CommentDetailsComponent extends FormBaseComponent implements OnInit {
    // Form group variables
    ideaCommentForm: FormGroup;

    constructor(fb: FormBuilder) {
        super(fb);
    }

    ngOnInit(): void {
        this.initialize();
    }

    initialize = () => {
        this.createIdeaCommentForm();
    };

    createIdeaCommentForm = () => {
        this.ideaCommentForm = this.createForm({
            description: ["", []],
        });
    };

    onIdeaCommentSubmit = (form: FormGroup) => {
        if (this.onSubmit(form)) {
        }
    };
}
