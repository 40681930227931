import { Injectable } from '@angular/core';
import { AppApi, EncryptionFunctions, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager } from 'app/utility/shared-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private teamDetail = null;

  constructor(private _apiManager: APIManager) { }

  addUpdateTeam(params, teamId?: string): Observable<any> {
    const method = teamId ? HttpMethodsTypeEnum.PUT : HttpMethodsTypeEnum.POST;
    const url = teamId ? AppApi.UPDATE_TEAM : AppApi.ADD_TEAM;
    return this._apiManager.httpHelperMethod(
      method, url, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  getTeamLookupsByServiceId(id): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.GET_TEAM_LOOKUPS, { ServiceId: id },
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  getTeamMembersByTeamId(id): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.GET_TEAM_MEMBER_BY_TEAM_ID, { team_id: id },
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  addTeamMember(params): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_TEAM_MEMBER, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  addTeamMemberList(params): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_TEAM_MEMBER_LIST, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  archiveTeam(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE, AppApi.ARCHIVE_TEAM, {id: id},
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  archiveTeamMember(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE, AppApi.ARCHIVE_TEAM_MEMBER, {id: id},
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  deleteTeamMember(id: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE, AppApi.DELETE_TEAM_MEMBER, {id: id},
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  updateTeamMember(params): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT, AppApi.UPDATE_TEAM_MEMBER, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  addServiceTeam(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_SERVICE_TEAM, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  addServiceTeamList(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_SERVICE_TEAM_LIST, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  addServiceTeamQueue(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_SERVICE_TEAM_QUEUE, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  getTeamLookups = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.TEAM_LOOKUPS, params,
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  inviteUserByEmail = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.INVITE_USER_BY_EMAIL, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  addUserInvitateByEmail = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST, AppApi.ADD_USER_INVITE_BY_EMAIL, params,
      this._apiManager.Authorised_HttpOptions, true, true);
  }

  getLoggedInUserTeamLookups = (params): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET, AppApi.TEAM_LOOKUPS, params,
      this._apiManager.Authorised_HttpOptions, false, true);
  }

  setTeamDetail(value): void {
    localStorage.setItem(
        'td',
        EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.teamDetail = value;
  }

  getTeamDetail() {
    this.teamDetail = EncryptionFunctions.DECRYPT_OBJ(localStorage.getItem('td'));
    return this.teamDetail;
  }

  deleteTeamService(serviceId: string, teamId: string): Observable<any> {
    return this._apiManager.httpHelperMethod(
        HttpMethodsTypeEnum.DELETE, `${AppApi.DELETE_TEAM_SERVICE}?service_id=${serviceId}&team_id=${teamId}`, {},
        this._apiManager.Authorised_HttpOptions, false, true
    )
}
}
