import {Injectable} from '@angular/core';
import {APIManager} from '../../../../../utility/shared-services';
import {Observable} from 'rxjs';
import {AppApi, HttpMethodsTypeEnum} from '../../../../../utility';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private _apiManager: APIManager) {
    }

    login(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.LOGIN, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }
    socialLogin(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.SOCIAL_LOGIN, params,
            this._apiManager.Content_Type_HttpOptions, false, true);
    }

    forgotPassword(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.FORGOT_PASSWORD, params,
            this._apiManager.Content_Type_HttpOptions, true, true);
    }

    resetPassword(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.RESET_PASSWORD, params,
            this._apiManager.Content_Type_HttpOptions, true, true);
    }

    getProfileData(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.PROFILE, {},
            this._apiManager.Authorised_HttpOptions, false, true);
    }
}
