import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {navigation} from 'app/navigation/navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationTurkish} from 'app/navigation/i18n/tr';
import {locale as navigationGerman} from 'app/navigation/i18n/de';
import * as _ from 'lodash';
import {SharedService, SvgIconService} from './utility/shared-services';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {MY_FORMATS} from './utility';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    selectedLanguage: any;

    // Observable variables
    loggedInStatusSubscriber$: any;
    languageChangeSubscriber$: any;
    snackBarSubscriber$: any;
    $loaderSubscriber: any;

    isLoggedIn = false;
    isShowLoader = false;

    languages = [
        {
            id: 'en',
            title: 'English',
            flag: 'us'
        },
        {
            id: 'tr',
            title: 'Türkçe',
            flag: 'tr'
        },
        {
            id: 'de',
            title: 'Deutsch',
            flag: 'de'
        }
    ];

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private sharedService: SharedService,
        private snackBar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private svgService: SvgIconService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr', 'de']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish, navigationGerman);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         * this._translateService.setDefaultLang('en');
         * this._translateService.setDefaultLang('tr');
         * });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.svgService.registerIcons();
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
        this.subscribeLoggedInStatus();
        this.subscribeLanguageChange();
        this.getSnackBarSubscriber();
        this.getLoaderSubscriber();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this.loggedInStatusSubscriber$) {
            this.loggedInStatusSubscriber$.unsubscribe();
        }
        if (this.languageChangeSubscriber$) {
            this.languageChangeSubscriber$.unsubscribe();
        }
        if (this.snackBarSubscriber$) {
            this.snackBarSubscriber$.unsubscribe();
        }
        if (this.$loaderSubscriber) {
            this.$loaderSubscriber.unsubscribe();
        }
    }

    subscribeLoggedInStatus = () => {
        this.loggedInStatusSubscriber$ = this.sharedService
            .getLoginRequired()
            .subscribe(() => {
                this.isLoggedIn = this.sharedService.isLoggedIn();
                this.changeDetectorRef.detectChanges();
            });
    };

    subscribeLanguageChange = () => {
        this.languageChangeSubscriber$ = this.sharedService
            .getLanguageChange()
            .subscribe((lang) => {
                this.setLanguageChange(lang);
            });
    };

    setLanguageChange = (lang: string) => {
        this.selectedLanguage = lang;
        this.sharedService.setLanguageValue(lang);
        this._translateService.use(lang);
    };

    getSnackBarSubscriber = () => {
        if (this.snackBarSubscriber$) {
            this.snackBarSubscriber$.unsubscribe();
        } else {
            this.snackBarSubscriber$ = this.sharedService
                .getSnackBar()
                .subscribe(message => {
                    if (message) {
                        this.openSnackBar(message);
                    }
                });
        }
    };

    getLoaderSubscriber = () => {
        this.$loaderSubscriber = this.sharedService.getLoader().subscribe((isLoading) => {
            this.isShowLoader = isLoading;
            this.changeDetectorRef.detectChanges();
        });
    };

    openSnackBar(message: string) {
        const configSnackBar = new MatSnackBarConfig();
        configSnackBar.verticalPosition = 'bottom';
        configSnackBar.horizontalPosition = 'center';
        configSnackBar.duration = 2000;
        configSnackBar.panelClass = ['white-snackbar'];
        this.snackBar.open(message, 'Close', configSnackBar);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
