import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGES, RouteConstants} from 'app/utility';
import {SharedService} from '../../../../../utility/shared-services';
import {locale as english} from '../i18n/en';
import {locale as turkish} from '../i18n/tr';
import {locale as german} from '../i18n/de';
import {RegisterService} from '../../services';

@Component({
    selector: 'app-signup-successfully',
    templateUrl: './signup-successfully.component.html',
    styleUrls: ['./signup-successfully.component.scss']
})
export class SignupSuccessfullyComponent implements OnInit {

    // Constant variables
    languages = LANGUAGES;

    // Data variables
    selectedLanguage: string = LANGUAGES.English;
    userId: string;
    code: string;
    lang: string;
    email: string;
    isEmailConfirm = '';
    clockDisplay: string;
    otpTimeOut: number;
    duration: number;
    interval: any;

    // State variables
    showResendOTPButton = true;

    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                private _translateService: TranslateService, private router: Router, private sharedService: SharedService,
                private activatedRoute: ActivatedRoute, private registerService: RegisterService) {
        // Set the language defaults
        this._fuseTranslationLoaderService.loadTranslations(english, turkish, german);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.userId = params['userid'];
            this.code = params['code'];
            this.lang = params['lang'];
            this.email = params['email'];
            this.selectedLanguage = this.lang ? this.lang : (this.sharedService.getLanguageValue() ? this.sharedService.getLanguageValue() : LANGUAGES.English);
            this.sharedService.setLanguageChange(this.selectedLanguage);
            this.confirmEmailApiCall().subscribe(() => {
                this.isEmailConfirm = 'CONFIRM';
            }, () => {
                this.isEmailConfirm = 'NOT_CONFIRM';
            });
        });
    }

    // Api calls
    confirmEmailApiCall = () => {
        return this.registerService.confirmEmail({code: this.code, userId: this.userId});
    };

    resendEmailConfirmationApiCall = (params: unknown) => {
        return this.registerService.resendEmailConfirmation(params);
    };

    // Page events
    onResendOTP() {
        const params = {email: encodeURIComponent(this.email), lang: this.selectedLanguage};
        this.resendEmailConfirmationApiCall(params).subscribe(res => {
            this.showResendOTPButton = false;
            this.setTimerValue();
        });
    }

    setTimerValue = () => {
        this.otpTimeOut = 3; // set 3 minute timeout
        if (this.otpTimeOut > 0) {
            this.duration = 60 * +this.otpTimeOut;
            this.showResendOTPButton = false;
            this.startTimer(this.duration);
        } else {
            this.showResendOTPButton = true;
        }
    };

    startTimer(duration: number) {
        if (duration > 0) {
            let timer: number = +duration;
            let minutes: any;
            let seconds: any;
            const self = this;
            self.interval = setInterval(function () {
                if (timer < 0) {
                    clearInterval(self.interval);
                    self.showResendOTPButton = true;
                    self.clockDisplay = '';
                }
                minutes = Math.floor(timer / 60);
                seconds = Math.floor(timer % 60);
                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                self.clockDisplay = minutes + ':' + seconds;
                if (--timer < 0) {
                    self.showResendOTPButton = true;
                    self.clockDisplay = '';
                    clearInterval(self.interval);
                }
            }, 1000);
        }
    }

    onLogin = () => {
        this.router.navigate(['/' + RouteConstants.LOGIN]);
    };

    setLanguage(lang: string): void {
        this.selectedLanguage = lang;
        this.sharedService.setLanguageChange(lang);
    }

    // Helper methods
    isLangActive = (lang: string) => {
        return lang === this.selectedLanguage;
    };
}
