<div class="auth">
    <div class="auth__left">
        <img *ngIf="false" src="../../../../../assets/images/auth/logo-en.png"/>
        <img *ngIf="false" src="../../../../../assets/images/auth/logo-de.png"/>
        <img *ngIf="true" src="../../../../../assets/images/auth/logo-tr.png"/>
    </div>
    <div class="auth__right">
        <div class="auth__right-top-text">
            <p>
                {{ "LOGIN.DONTHAVEACCOUNT" | translate }}
                <a [routerLink]="'/signup'">{{ "LOGIN.SIGNUP" | translate }}</a>
            </p>
        </div>

        <h2>{{ "RECOVER.TITLE" | translate }}</h2>
        <p>{{'RECOVER.HEADER' | translate}}</p>

        <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" (submit)="forgotPassword(forgotPasswordForm)"
              novalidate>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>
                        <!-- Email -->
                        {{'LOGIN.EMAIL' | translate}}
                    </mat-label>
                    <input placeholder="{{'COMMON.EMAIL_PLACEHOLDER' | translate}}" autocomplete="off" matInput
                           formControlName="email">
                    <span matSuffix class="material-icons"> vpn_key </span>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        <!-- Email is required -->
                        {{'DIALOG.EMAILREQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        <!-- Please enter a valid email address -->
                        {{'DIALOG.EMAILWARN' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="row MT-2">
                <div class="col-md-6 col-xs-6 PL-0">
                    <button mat-raised-button class="submit-button" color="accent"
                            [disabled]="forgotPasswordForm.invalid">
                        <!-- SEND RESET LINK -->
                        {{'BOTTON.PASSWORDRESETLINK' | translate}}
                    </button>
                </div>
                <div class="col-md-6 PR-0 col-xs-6 text-right">
                    <a class="link" (click)="onLogin()">
                        <!-- Go back to login -->
                        {{'RECOVER.GOBACK' | translate}}
                    </a>
                </div>
            </div>

        </form>

        <ul class="auth__language">
            <li>
                <span class="material-icons"> language </span>
                <label>{{ "LOGIN.LANGUAGE" | translate }}:</label>
            </li>
            <li (click)="setLanguage(languages.English)">
                <a [ngClass]="{'active' : isLangActive(languages.English)}">
                    <img src="assets/icons/flags/us.png"/><span>English</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.Turkish)">
                <a [ngClass]="{'active' : isLangActive(languages.Turkish)}">
                    <img src="assets/icons/flags/tr.png"/><span>Türkçe</span>
                </a>
            </li>
            <li (click)="setLanguage(languages.German)">
                <a [ngClass]="{'active' : isLangActive(languages.German)}">
                    <img src="assets/icons/flags/de.png"/><span>Deutsch</span>
                </a>
            </li>
        </ul>
    </div>
</div>
