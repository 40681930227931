import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteConstants} from 'app/utility';
import {
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    SignupSuccessfullyComponent
} from './components';
import {UserAuthGuard} from '../../../_guards';

const routes: Routes = [
    {
        path: RouteConstants.LOGIN,
        component: LoginComponent,
        canActivate: [UserAuthGuard]
    },
    {
        path: RouteConstants.SIGN_UP,
        component: RegisterComponent,
        canActivate: [UserAuthGuard]
    },
    {
        path: RouteConstants.FORGOT_PASSWORD,
        component: ForgotPasswordComponent,
        canActivate: [UserAuthGuard]
    },
    {
        path: RouteConstants.RESET_PASSWORD,
        component: ResetPasswordComponent,
        canActivate: [UserAuthGuard]
    },
    {
        path: RouteConstants.SIGNUP_SUCCESSFULLY,
        component: SignupSuccessfullyComponent,
        canActivate: [UserAuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
