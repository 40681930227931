export const locale = {
    lang: "de",

    data: {
        COMMON: {
            EMAIL_PLACEHOLDER: "Email eingeben",
            PASSWORD_PLACEHOLDER: "Passwort eingeben",
            CONFIRM_PASSWORD_PLACEHOLDER: "Geben Sie Passwort bestätigen ein",
            SUBMIT_CAPS: "EINREICHEN",
            COMING_SOON: "Kommt bald",
            SOON: "Demnächst",
        },

        PLACEHOLDER: {
            ENTER_ORGANISATION: "Organisation eingeben",
            ENTER_PHONE_NO: "Geben Sie die Telefonnummer ein.",
            SELECT_PREFERRED_LANGUAGE: "Bevorzugte Sprache",
            SELECT_TIMEZONE: "Zeitzone",
            STATE: "Zustand",
            STATE_PROVINCE: "Staat / Provinz",
            POSTAL_CODE: "Postleitzahl",
            ENTER_POSTAL_CODE: "Geben Sie die Postleitzahl ein",
            ENTER_COUNTRY: "Land suchen",
            ENTER_STATE: "Land suchen",
            ENTER_CITY: "Stadt betreten",
            SEARCH_INDUSTRY: "Suchindustrie",
            SEARCH_PREFERRED_LANGUAGE: "Suchsprache",
            SEARCH_TIMEZONE: "Zeitzone suchen",
            ENTER_STREET: "Betreten Sie die Straße",
            ENTER_FIRST_NAME: "Bitte Vornamen eingeben",
            ENTER_LAST_NAME: "Nachnamen eingeben",
            SELECT_DATE_OF_BIRTH: "Geburtsdatum auswählen",
        },

        LOGIN: {
            TITLE: "Willkommen bei MAKSELLENT-Anwendungen!",
            DESCRIPTION:
                "Mit MAKSELLENT Applications ist jetzt alles einfacher...",
            HEADER: "MELDE DICH IN DEINEM KONTO AN",
            ALREADY_ACCOUNT:"Bitte melden Sie sich an, wenn Sie bereits ein Konto haben",
            DONTHAVEACCOUNT: "Sie haben kein Konto?",
            CREATEACCOUNT: "Ein Konto erstellen",
            REGISTERCOMPANY: 'Registrierung für Unternehmensdemo',
            EMAIL: "Email",
            PASSWORD: "Passwort",
            PASSCONFIRM: "Kennwort bestätigen",
            REMEMBERME: "Erinnere dich an mich",
            FORGOTPASSWORD: "Passwort vergessen?",
            SIGNIN: "Einloggen",
            SIGN_IN: "ANMELDEN",
            SIGNUP: "Anmeldung",
            SIGN_UP: "ANMELDUNG",
            LANGUAGE: "Sprache",
            OR_SIGN_IN_WITH: "oder Anmelden mit",
            SIGN_IN_WITH_GOOGLE: "Anmeldung mit Google",
            SYNC_WITH_GOOGLE: "Mit Google synchronisieren",
        },

        REGISTER: {
            SIGN_UP: "Anmeldung",
            LOGIN_INFORMATION: "Anmeldeinformationen",
            PROFILE_INFORMATION: "Profil Information",
            CONTACT_INFORMATION: "Kontakt Informationen",
            ADDRESS_INFORMATION: "Adresse",
            NEXT: "NÄCHSTER",
            ALREADY_ACCOUNT_SIGNUP: "Sie haben bereits ein Konto?",
            BACK: "ZURÜCK",
            HEADER: "CREATE AN ACCOUNT",
            STEPPROFILE: "Fill out your profile",
            STEPCONTACT: "Fill out your contact details",
            STEPADDRESS: "Fill out your address",
            STEPDONE: "Done",
            FIRSTNAME: "Vorname",
            LASTNAME: "Nachname",
            ORGANIZATION: "Organisation",
            DATEofBIRTH: "Geburtsdatum",
            USERNAME: "Benutzername",
            GENDER: "Geschlecht",
            FEMALE: "Weiblich",
            MALE: "Männlich",
            EMAIL: "E-Mail",
            PHONENUMBER: "Telefonnummer",
            PREFEREDLANG: "Sprachauswahl",
            ADDRESSCOUNTRY: "Land",
            ADDRESSCITY: "Stadt",
            ADDRESSSTREET: "Straße",
            HAVEACCOUNT: "Already have an account?",
            ACCEPT_TERMS: "Ich nehme an",
            TERMS: "Geschäftsbedingungen",
            VERIFY: "ÜBERPRÜFEN",
            SIGNUP_SUCCESSFULLY: "Melden Sie sich erfolgreich an",
            SENT_ACTIVATION_CODE:
                "Wir haben einen gesendeten Aktivierungscode in Ihrer unten stehenden E-Mail.",
            RETRY: "Falls Sie danach keine E-Mail-Wiederholung erhalten haben",
            MINUTES: "Protokoll.",
            RESEND_LINK: "ERNEUT SENDEN",
            SIGNUP_COMPLETED:
                "Sie haben die Anmeldung erfolgreich abgeschlossen.",
            VERIFING_YOUR_ACCOUNT: "Verifizierung Ihres Kontos",
            LINK_EXPIRED:
                "Unser Aktivierungslink ist abgelaufen, bitte klicken Sie auf Erneut senden, um einen neuen Aktivierungslink zu erhalten.",
            AND: "und",
        },

        RECOVER: {
            TITLE: "Passwort vergessen",
            HEADER: "Stellen Sie Ihr Passwort wieder her",
            GOBACK: "Gehen Sie zurück zum Login",
        },

        RESETPASS: {
            HEADER: "Setze dein Passwort zurück",
            DESCRIPTION_OTP:
                "Geben Sie das 6-stellige OTP ein, das an Ihre E-Mail gesendet wurde",
        },

        BOTTON: {
            ADD: "Hinzufügen",
            CREATE: "Erstellen",
            SAVE: "Speichern",
            SAVE_AS: "Speichern Als",
            CREATE_REPORT: "Bericht Erstellen",
            EDIT: "Bearbeiten",
            DELETE: "Löschen",
            FILTER: "Filtern",
            RESET: "Zurücksetzen",
            MAIN: "Main",
            LOGIN: "ANMELDUNG",
            LOGINLINK: "Anmeldung",
            PASSWORDRESETLINK: "RESET-LINK SENDEN",
            PASSWORDRESETBUTTON: "SETZE MEIN PASSWORT ZURÜCK",
            CREATEACCOUNT: "EIN KONTO ERSTELLEN",
            BACK: "Zurück",
            NEXT: "Nächster",
            SUBMIT: "Einreichen",
            CANCEL: "Stornieren",
            CLOSE: "Schließen",
            UPDATE: "Aktualisieren",
            REMOVE: "Entfernen",
            UPLOAD: "Hochladen",
            COLORTAG: "Farbetikett",
        },

        DEMO_ACTIVATION: {
            TITLE: "Workspace-Management-Anwendungen",
            DESCRIPTION:
                "Wir möchten, dass Sie unser Wertversprechen besser verstehen. Bitte nutzen Sie alle Aspekte der App, um den Demo-Zeitraum effektiv zu berücksichtigen, und teilen Sie uns mit, ob Sie unsere Unterstützung benötigen.",
            ALREADY_ACCOUNT:"Wenn Sie bereits ein Firmenkonto haben, ",
            BENEFITS_ONE: "Kostenlose Nutzung für 3 Monate",
            BENEFITS_TWO: "Keine Kreditkarte",
            BENEFITS_THREE: "Ohne Verpflichtungen",
            TRY_NOW_BTN: "VERSUCH'S JETZT",
            COMPANY_NAME_LABEL: "Name der Firma",
            COMPANY_NAME_PLACEHOLDER: "Geben Sie den Firmennamen ein",
            SECTOR_LABEL: "Sektor",
            ACTIVATION_CODE:
                "Wir haben einen Aktivierungscode an Ihre E-Mail-Adresse gesendet",
            INBOX_MSG:
                "Falls Sie keine E-Mail in Ihrem Posteingang für erhalten",
            ANOTHER_ACTIVATION_CODE:
                "Minuten, bitte klicken Sie, um einen weiteren Aktivierungscode zu erhalten.",
            ADDITIONAL_MSG:
                "Überprüfen Sie außerdem Ihren Posteingang auf eine separate E-Mail mit Ihrem Unternehmensaktivierungscode, die andere Unternehmensbenutzer bei der Registrierung für die Anwendung verwenden müssen, wenn sie generische E-Mail-Konten mit Google Mail, Hotmail oder Yahoo verwenden.",
            THANK_YOU: "Vielen Dank!",
            REGISTRATION_COMPLETED:
                "Sie haben die Mieterregistrierung erfolgreich abgeschlossen.",
        },

        DIALOG: {
            NAMEREQUIRED: "Name ist erforderlich",
            LASTNAMEREQUIRED: "Nachname ist erforderlich",
            ORGANIZATIONREQUIRED: "Organisation ist erforderlich",
            EMAILREQUIRED: "E-Mail ist erforderlich",
            EMAILWARN: "Please enter a valid email address",
            EMAILMUSTCONTAIN:
                "Must contain (0,9) and [a,z] and [A,Z] and [!@#$%^&?_-|+>;€~¨] and at least 6 or more characters.",
            PASSWORDREQUIRED: "Password ist erforderlich",
            PASSWORDCONFIRMREQUIRED: "Password confirmation ist erforderlich",
            PASSWORDWARN: "Passwords must match",
            DATEOFBIRTHREQUIRED: "Date of Birth ist erforderlich",
            DELETEWARN: "Möchten Sie diesen Datensatz wirklich löschen?",
            DELETESUCCESS: "Datensatz erfolgreich gelöscht!",
            DELETEFAILURE: "Problem beim Löschen!",
            EDITABANDONED:
                "Nicht gespeicherte Änderungen gehen verloren. Sind Sie sicher, dass Sie weitermachen wollen?",
            EDITSUCCESS: "Änderung erfolgreich gespeichert!",
            CREATESUCCESS:
                "Aufzeichnung erfolgreich gespeichert. Bitte bestätigen Sie Ihre E-Mail!",
        },

        HEADER: {
            MY_TASKS: "Meine Aufgaben",
            MY_ASSETS: "Mein Vermögen",
            MY_ACCOUNTS: "Meine Accounts",
            MY_SHORTCUT: "Meine Verknüpfung N.",
        },

        SIDEBAR: {
            TASKS: "Aufgaben",
            FIX_ASSETS: "Anlagevermögen",
            ACCOUNTS: "Konten",
            SURVEY: "Umfrage",
            PRODUCTS: "Produkte",
            CONTACT: "Kontakt",
        },

        SYSTEM_SETTINGS_MENU: {
            MODULES: "Module",
            ROLES: "Rollen",
            INDUSTRY: "Industrie",
            STATUS: "Status",
            LANGUAGE: "Sprache",
        },

        SYSTEM_SETTINGS_MODULES: {
            SETTINGS: "die Einstellungen",
            SEQUENCE: "Reihenfolge",
            NAME: "Name",
            CODE: "Code",
            DESCRIPTION: "Beschreibung",
            ACTION: "Aktion",
            ACTIVATE: "aktivieren Sie",
            DEACTIVATE: "Deaktivieren",
            SHOW_FILTER: "Filter anzeigen",
            HIDE_FILTER: "Filter ausblenden",
            CLEAR_FILTER: "Filter löschen",
            RESULTS_FOUND: "gefundene Ergebnisse",
            ENTER_NAME: "Name eingeben",
            ENTER_CODE: "Code eingeben",
            ENTER_DESCRIPTION: "Beschreibung eingeben",
            ARE_YOU_SURE: "Bist du sicher?",
            ARE_YOU_SURE_DELETE: "Möchten Sie wirklich löschen?",
            LANGUAGE_QUESTION_MARK: "Sprache?",
            ADD: "HINZUFÜGEN",
            CSS_ICON: "CSS-Symbol",
            ENTER_CSS_ICON: "Geben Sie das CSS-Symbol ein",
            ASSIGN_MODULE: "Modul zuweisen",
            ASSIGN_ROLE: "Rolle zuweisen",
            WARNING_ROLE:
                "Es wurden noch keine Module hinzugefügt. Bitte fügen Sie ein Modul für die Zuweisung in der Rolle hinzu.",
            WARNING_MODULE:
                "Es wurden noch keine Rollen hinzugefügt. Bitte fügen Sie dem Modul eine Rolle für die Zuweisung hinzu.",
            MODULE: "Modul",
            ROLE: "Rolle",
        },

        SURVEY_MODULES: {
            SURVEY: "Umfrage",
            DOWNLOAD: "Herunterladen",
            SHOW_FILTER: "Filter anzeigen",
            HIDE_FILTER: "Filter ausblenden",
            CLEAR_FILTER: "Filter löschen",
            ADD: "HINZUFÜGEN",
            EDIT: "BEARBEITEN",
            PREVIEW: "VORSCHAU",
            RESPONSES: "ANTWORTEN",
            DELETE: "LÖSCHEN",
            FILTER: "Filter",
            CREATE_NEW: "Neue Umfrage erstellen",
            ADD_PAGE: "Neue Seite hinzufügen",
            DRAFT: "Save as Draft",
            DELETE_PAGE: "Seite löschen",
            SAVE_PAGE: "Seite speichern",
            ADD_QUESTION: "Neue Frage hinzufügen",
            NO_SURVEY: "Keine Umfrage hinzugefügt",
            TYPE: "Typ",
            ACCESS_TYPE: "Zugangsart",
            SIZE: "Größe",
            BUDGET: "Budget",
            NO_SURVEY_TEXT:
                "Sind Sie gespannt, Ihre erste Umfrage zu erstellen? Lassen Sie uns gleich einsteigen!",
            ADD_SURVEY: "Umfrage hinzufügen",
            REQUIRED: "Erforderlich",
            ONLY_MY_SURVEY: "Nur meine Umfrage",
            MEMBER_LIST: "Mitgliederliste",
            RESET_ALL: "Alles zurücksetzen",
            MENU: {
                BASIC_DETAIL: "Grundlegende Details",
                DESIGN: "Designumfrage",
                PREVIEW: "Vorschau",
                RESPONSE: "Antworten",
                RESULT: "Ergebnisse",
                SUMMARY: "Zusammenfassung",
                DETAILS: "Details",
            },
            PLACEHOLDER: {
                ENTER_SURVEY_NAME: "Umfragenamen eingeben",
                SELECT_SURVEY_TYPE: "Umfragetyp auswählen",
                SELECT_ACCOUNT: "Konto auswählen",
                SELECT_ORGANIZATION: "Organisation auswählen",
                SELECT_LOCATION: "Ort auswählen",
                START_DATE: "Startdatum",
                END_DATE: "Endtermin",
                CREATED_BY: "Erstellt von",
                MODIFIED_BY: "Geändert durch",
            },
            VALIDATION: {
                QUESTION_REQUIRED: "Diese Frage darf nicht leer bleiben",
            },
        },

        IDEA: {
            IDEA_MANAGEMENT: "Ideenmanagement",
            TOTAL_IDEAS: "Gesamtideen",
            ADD_IDEA: "IDEE HINZUFÜGEN",
            ADD_IDEA_SMALL: "Idee hinzufügen",
            CATEGORY: "Kategorie",
            SUBMITTED_BY: "Eingereicht von",
            SUBMITTED_ON: "Eingereicht am",
            ATTACHMENTS: "ANHÄNGE",
            COMMENTS: "BEMERKUNGEN",
            UPVOTE: "UPVOTE",
            LIKE: "MÖGEN",
            GIVE_YOUR_COMMENT: "Geben Sie Ihren Kommentar",
            FILTERS: "Filter",
            SELECT_CATEGORY: "Kategorie wählen",
            TITLE: "Titel",
            ENTER_TITLE: "Titel eingeben",
            DESCRIPTION: "Beschreibung",
            ENTER_YOUR_IDEA_DESCRIPTION: "Geben Sie Ihre Ideenbeschreibung ein",
            ATTACHMENT: "Anhang",
            SELECT_ATTACHMENT: "Wählen Sie Anhang",
            SEARCH_IDEA: "Suchidee",
            ENTER_IDEA_NAME: "Geben Sie den Ideennamen ein",
            ONLY_MY_IDEA: "Nur meine Idee",
            VIEW_MORE: "MEHR SEHEN",
            SAVE: "SPEICHERN",
            CANCEL: "STORNIEREN",
            SELECT_START_DATE: "Wählen Sie Startdatum",
            SELECT_END_DATE: "Wählen Sie Enddatum",
            NO_ANY_IDEA_ADDED: "Keine Idee hinzugefügt",
            NO_ANY_IDEA_DESC:
                "Sind Sie gespannt darauf, Ihre erste Aufgabe zu erstellen? Lassen Sie uns gleich einsteigen!",
        },

        PACKAGE: {
            SETTINGS: "die Einstellungen",
            MONTHLY: "Monatlich",
            ANNUALLY: "Jährlich",
            ASSIGN_MODULES: "MODELLE ZUWEISEN",
            ACTIVE: "Aktiv",
            DEACTIVE: "Deaktivieren",
            USER_MANAGEMENT: "Benutzerverwaltung",
            ADD_FREE_TEXT: "KOSTENLOSEN TEXT HINZUFÜGEN",
            DELETE_PACKAGE: "PAKET LÖSCHEN",
            PACKAGE: "Paket",
            PLAN_TYPE: "Planart",
            SELECT_PLAN_TYPE: "Planart auswählen",
            PRICE: "Preis",
            ENTER_PRICE: "Preis eingeben",
            FREE_TEXT_IN_PACKAGE: "Freitext im Paket",
            TEXT: "Text",
            ENTER_TEXT: "Text eingeben",
            BUY_PLAN: "Plan kaufen",
            CHOOSE_PLAN: "Wählen Sie einen Plan",
            ORDER_DETAILS: "Bestelldetails",
            COMPANY_DETAILS: "Firmendetails",
            PAYMENT_DETAIL: "Zahlungsdetails",
            PER_USER: "pro Benutzer",
            BUY_NOW: "Kaufe jetzt",
            USERS: "Benutzer",
            CHANGE: "VERÄNDERUNG",
            BASIC_PACKAGE: "Basispaket",
            ORDER_SUMMARY: "Bestellübersicht",
            BASIC_PLAN: "Grundplan",
            SUB_TOTAL: "Zwischensumme",
            GRAND_TOTAL: "Gesamtsumme",
            ADDRESS_STREET: "Adresse Straße",
            ENTER_ADDRESS_STREET: "Geben Sie die Adressstraße ein",
            COUNTRY: "Land",
            SELECT_COUNTRY: "Land auswählen",
            SELECT_STATE: "Staat wählen",
            SELECT_CITY: "Stadt wählen",
            LEGAL_ENTITY: "Rechtsperson",
            PRIVATE_COMPANY: "Privatunternehmen",
            TAX_OFFICE: "Finanzamt",
            ENTER_TAX_OFFICE: "Finanzamt betreten",
            TAX_ID: "Steuer ID",
            NATIONAL_ID: "Personalausweis",
            ENTER: "Eingeben",
            OFFICE_PHONE_NO: "Bürotelefon-Nr.",
            ENTER_OFFICE_PHONE_NO: "Geben Sie die Office-Telefonnummer ein.",
            MOBILE_NO: "Handynr.",
            ENTER_MOBILE_NO: "Geben Sie die Handynummer ein.",
            Fax: "Fax",
            ENTER_FAX: "Fax eingeben",
            CREDIT_CARD: "Kreditkarte",
            NET_BANKING: "Net Banking",
            CARD_HOLDER_NAME: "Name des Karteninhabers",
            ENTER_CARD_HOLDAR_NAME:
                "Geben Sie den Namen des Karteninhabers ein",
            CARD_NUMBER: "Kartennummer",
            ENTER_CARD_NUMBER: "Kartennummer eingeben",
            MM: "MM",
            YYYY: "JJJJ",
            CVC: "CVC",
            ENTER_CVC: "Geben Sie CVC ein",
            NOTE: "Hinweis",
            ORDER_NUMBER: "Bestellnummer",
            NET_BANKING_TITLE:
                "MAKSELLENT LTD. - Überweisungs- / EFT-Bankkontoinformationen",
            NET_BANKING_CONTENT: "Yapı & Kredi Bank (USD Konto)",
            IBAN_TOP: "IBAN: TR22 0006 7010 0000 0044 1380 97",
            IBAN_BOTTOM: "IBAN: TR83 0006 7010 0000 0088 0942 95",
            NOTE_DESCRIPTION_TOP:
                "Der Kauf wird abgeschlossen, nachdem die Überweisung / EFT-Transaktion abgeschlossen ist. Bitte geben Sie Ihre",
            NOTE_DESCRIPTION_BOTTOM:
                "Bestellnummer in der Überweisungs- / Überweisungsbeschreibung.",
        },

        VALIDATION: {
            EMAIL_REQUIRED: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
            PASSWORD_REQUIRED: "Passwort wird benötigt",
            PASSWORD_MIN_LENGTH: "Passwort Mindestlänge 8",
            CONFIRM_PASSWORD_REQUIRED: "Passwort bestätigen ist erforderlich",
            CONFIRM_PASSWORD_NOT_MATCHED:
                "Passwort und Passwortbestätigung stimmen nicht überein!",
            VALID_INDUSTRY: "Bitte wählen Sie eine gültige Branche aus",
            VALID_COUNTRY: "Bitte wählen Sie ein gültiges Land",
            VALID_STATE: "Bitte wählen Sie einen gültigen Status",
            VALID_LANGUAGE: "Bitte wählen Sie eine gültige Sprache",
            VALID_TIMEZONE: "Bitte wählen Sie eine gültige Zeitzone",
            NAME_REQUIRED: "Name ist erforderlich",
            CONFIRM_ADD_DATA: "Möchten Sie wirklich neue Daten hinzufügen?",
            CONFIRM_ARCHIVE_DATA: "Möchten Sie die Daten wirklich archivieren?",
            CONFIRM_UNSAVED_CHANGE:
                "Nicht gespeicherte Änderungen gehen verloren. Möchten Sie wirklich fortfahren?",
            CONFIRM_TITLE: "Bist du sicher",
            CONFIRM_TITLE_UNSAVED_CHANGE: "Nicht gespeicherte Änderung",
            PLEASE_ENTER_VALID_EMPLOYEE_TYPE:
                "Bitte geben Sie einen gültigen Mitarbeitertyp ein",
            PLEASE_ENTER_VALID_DEPARTMENT: "Bitte gültige Abteilung eingeben",
            PLEASE_ENTER_VALID_COST_CENTRE:
                "Bitte gültige Kostenstelle eingeben",
            PLEASE_ENTER_VALID_ACCOUNT:
                "Bitte geben Sie ein gültiges Konto ein",
            PLEASE_ENTER_VALID_ORGANIZATION:
                "Bitte geben Sie eine gültige Organisation ein",
            PLEASE_ENTER_VALID_UNIT: "Bitte gültige Einheit eingeben",
            PLEASE_ENTER_VALID_LOCATION:
                "Bitte geben Sie einen gültigen Standort ein",
            PLEASE_ENTER_VALID_ZONE: "Bitte gültige Zone eingeben",
            PLEASE_ENTER_VALID_TASK_NAME:
                "Bitte geben Sie einen gültigen Aufgabennamen ein",
            PLEASE_ENTER_VALID_EXPENSE_TYPE_NAME:
                "Bitte geben Sie einen gültigen Spesentypnamen ein",
            PLEASE_ENTER_VALID_USER_NAME:
                "Bitte gültigen Benutzernamen eingeben",
            EXPENSE_CATEGORY: "Ausgabenkategorie ist erforderlich",
            EXPENSE_TYPE: "Die Ausgabenart ist erforderlich",
            PROOF: "Ein Nachweis ist erforderlich",
            USER_NAME: "Ein Benutzername wird benötigt",
            ASSIGN_TO: "Zuweisen zu ist erforderlich",
            AMOUNT: "Betrag bis ist erforderlich",
            PLEASE_ENTER_VALID_BANK: "Bitte gültige Bank eingeben",
            PLEASE_ENTER_VALID_CURRENCY: "Bitte geben Sie eine gültige Währung ein",
            PREFIX_REQUIRED: "Präfix ist erforderlich"
        },

        APP_SETTINGS: {
            ADD_ACCOUNT: "Konto hinzufügen",
            NAME: "Name",
            ADD_ZONE: "Zone hinzufügen",
            ADD_ORGANIZATION: "Organisation hinzufügen",
            ADD_UNIT: "Einheit Erstellen",
            ADD_LOCATION: "Ort hinzufügen",
            APP_SETTING: "App Einstellungen",
            ADD: "Hinzufügen",
            PROFILE: "Profil",
            ENTER_NAME: "Name eingeben",
            TENNANT: "Mieterin",
            STATUS: "Status",
            DESCRIPTION: "Beschreibung",
            ENTER_DESCRIPTION: "Beschreibung eingeben",
            LOCATION: "Ort",
            ENTER_LOCATION: "Ort eingeben",
            COUNTRY: "Land",
            CITY: "Stadt",
            STREET: "Straße",
            ENTER_STREET: "Betreten Sie die Straße",
            CONTACT: "Kontakt",
            EMAIL: "Email",
            ENTER_EMAIL: "Email eingeben",
            PHONE_NO: "Telefonnummer.",
            ENTER_PHONE_NO: "Geben Sie die Telefonnummer ein.",
            DOCUMENTS: "Unterlagen",
            SELECT_ATTACHMENTS: "Wählen Sie Anhänge",
            ACCOUNT_NAME: "Kontobezeichnung",
            ENTER_ACCOUNT_NAME: "Geben Sie den Kontonamen ein",
            ORGANIZATION: "Organisation",
            ENTER_ORGANIZATION_NAME: "Geben Sie den Namen der Organisation ein",
            ZONE_NAME: "Zonenname",
            ENTER_ZONE_NAME: "Geben Sie den Zonennamen ein",
            MOBILE_NUMBER: "Handy Nummer",
            ENTER_MOBILE_NUMBER: "Handynummer eingeben",
            SELECT_UNIT_CAPITAL: "Einheit auswählen",
            SELECT_ZONE_CAPITAL: "Zone auswählen",
            SELECT_ROLE: "Rolle auswählen",
            POWER_USER: "Hauptbenutzer",
            SELECT_ALL: "Wählen Sie Alle",
            IMPORT: "Importieren",
            MOBILE_NO: "Handy Nr.",
            ROLES: "ROLLEN",
            MEMBER: "Mitglied",
            TASK_MANAGER: "Taskmanager",
            HR_MANAGER: "HR Manager",
            ASSET_MANAGER: "Vermögensverwalter",
            FILE_MANAGER: "Dateimanager",
            LOCATION_CAPITAL: "LAGE",
            PEPSICO: "Pepsico",
        },

        PROFILE: {
            PROFILE: "Profil",
            MYPROFILE: "Mein Profil",
            PREFERENCES: "Meine Präferenzen",
            ASSETS: "Mein Vermögen",
            BANK_ACCOUNT: "Mein Bankkonto",
            CHANGE_PASSWORD: "Kennwort ändern",
            CHANGE_PROFILE: "Profil ändern",
            ADD_PICTURE: "Bild hinzufügen",
            CHANGE_PICTURE: "Bild ändern",
            CHOOSE_DATE: "Wählen Sie Datum",
            ENTER_NATIONAL_ID: "Geben Sie die nationale ID ein",
            UPDATE: "AKTUALISIEREN",
            RESET: "RESET",
            OLD_PASSWORD: "Altes Passwort",
            ENTER_OLD_PASSWORD: "Geben Sie das alte Passwort ein",
            NEW_PASSWORD: "Neues Kennwort",
            ENTER_NEW_PASSWORD: "Neues Passwort eingeben",
            CONFIRM_PASSWORD: "Kennwort bestätigenz",
            DEPARTMENT: "Abteilung",
            SELECT_DEPARTMENT: "Wählen Sie Abteilung",
            ORGANIZATION: "Organisation",
            SELECT_ORGANIZATION: "Wählen Sie Organisation",
            ACCOUNT: "Konto",
            SELECT_ACCOUNT: "Konto auswählen",
            EMPLOYEMENT_INFORMATION: "Beschäftigungsinformationen",
            EMPLOYEE_ID: "Angestellten ID",
            ENTER_EMPLOYEE_ID: "Mitarbeiternummer eingeben",
            JOB_TITLE: "Berufsbezeichnung",
            ENTER_JOB_TITLE: "Geben Sie die Berufsbezeichnung ein",
            TYPE_OF_EMPLOYEE: "Art des Mitarbeiters",
            SELECT_EMPLOYEE: "Mitarbeiter auswählen",
            MANAGER: "Manager",
            SELECT_MANAGER: "Manager auswählen",
            COST_CENTER: "Kostenstelle",
            SELECT_COST_CENTER: "Kostenstelle auswählen",
            ENTER_ORGANIZATION: "Organisation eingeben",
            ENTER_UNIT: "Einheit eingeben",
            ENTER_ZONE: "Zone betreten",
            OFFICE_NO: "Büro-Nr",
            ENTER_OFFICE_NO: "Geben Sie die Büro-Nr. ein",
            ENTER_PHONE_NUMBER: "Telefonnummer eingeben",
            PROVINCE_CODE: "Provinzcode",
            ENTER_PROVINCE_CODE: "Provinzcode eingeben",
            EXPENSES: "Meine Ausgaben",
            ONLY_MY: "Nur mein",
            PAID_EXPENSE: "Bezahlte Ausgaben",
            UNPAID_EXPENSE: "Unbezahlte Ausgaben",
            TASK_NAME: "Aufgabennname",
            ASSIGN_TO: "Zuweisen",
            AMOUNT: "Betrag",
            PAID_AMOUNT: "Bezahlte Menge",
            PAYMENT_DATE: "Zahlungsdatum",
            SELECT_TASK: "Aufgabe auswählen",
            SELECT: "Auswählen",
            ENTER: "Eintreten",
            SELECT_USER: "Nutzer wählen",
            SELECT_PAYMENT_STATUS: "Zahlungsstatus auswählen",
            SELECT_PAYMENT_RANGE: "Zahlungsbereich auswählen",
            SELECT_DATE_RANGE: "Datumsbereich auswählen",
            SELECT_PAYMENT_TYPE: "Wähle eine Zahlmethode aus",
            PAYMENTS: "Meine Zahlungen",
            BANK_ACCOUNTS: "Bankkonten",
            FULL_NAME: "Vollständiger Name",
            ENTER_FULL_NAME: "Geben Sie den vollständigen Namen ein",
            BANK_NAME: "Bank Name",
            IBAN: "IBAN",
            ENTER_IBAN: "Geben Sie iban ein",
            CURRENCY: "Währung",
            SELECT_CURRENCY: "Währung wählen",
            SELECT_BANK: "Bank auswählen",
            SELECT_BANK_SMALL: "Bank auswählen",
            BRANCH_CODE: "Branchencode",
            ENTER_BRANCH_CODE: "Branchencode eingeben",
            ACCOUNT_CODE: "Konto Code",
            ENTER_ACCOUNT_CODE: "Kontocode eingeben",
            ENTER_FULL_NAME_AS_NATIONAL_ID_CARD:
                "Geben Sie den vollständigen Namen als Personalausweis ein",
            USER_NAME: "Nutzername",
            SELECT_AMOUNT: "Betrag auswählen",
            SELECT_PAID_AMOUNT: "Bezahlten Betrag auswählen",
            MY_EXPENSES: "Meine Kosten",
            APPROVE_SELECTED: "Ausgewählte genehmigen",
            CUSTOMER: "Kunde",
            CUSTOMER_CAPITAL: "KUNDE",
            EXPENSE_TYPE: "Kostenart",
            SELECT_EXPENSE_TYPE: "Ausgabenart auswählen",
            PROOF: "Nachweisen",
            SELECT_PROOF: "Nachweis auswählen",
            SELECT_USER_NAME: "Benutzernamen auswählen",
            MIN_AMOUNT_PAID: "Mindestbetrag bezahlt",
            SELECT_MIN_AMOUNT_PAID: "Wählen Sie den Mindestbetrag aus",
            MAX_AMOUNT_PAID: "Maximal gezahlter Betrag",
            SELECT_MAX_AMOUNT_PAID: "Wählen Sie den maximalen bezahlten Betrag",
            MIN_AMOUNT: "Mindestbetrag",
            SELECT_MIN_AMOUNT: "Mindestbetrag auswählen",
            MAX_AMOUNT: "Maximaler Betrag",
            SELECT_MAX_AMOUNT: "Maximalbetrag auswählen",
            ENTER_YOUR_COMMENT: "Geben Sie Ihren Kommentar ein",
            CLONE: "Klon",
            APPROVE: "Genehmigen",
            VALID_EXPENSE_CATEGORY:
                "Veuillez saisir un nom de catégorie de dépenses valide",
        },

        SURVEY_TARGET: {
            SURVEY_TARGET: "Umfrageziel",
            ADD_TARGET: "Ziel hinzufügen",
            ENTER_NAME: "Name eingeben",
            NO_OF_DATA: "Anzahl der Daten",
            IMPORT_DATA: "Daten importieren",
            ADD_SURVEY_TARGET: "Umfrageziel hinzufügen",
            PREVIOUS_CUSTOMER_LIST: "Vorherige Kundenliste",
            SELECT_FILE: "Datei aussuchen",
            DOWNLOAD_SAMPLE_FILE: "Beispieldatei herunterladen",
            CSV: ".csv",
            XSLX: ".xslx",
            WANT_TO_SAVE_FILE_IN_SYSTEM:
                "Möchten Sie die Datei im System speichern?",
            SEND_EMAIL: "E-Mail senden",
            NAME: "Name",
            SURNAME: "Nachname",
            JOB_TITLE: "Berufsbezeichnung",
            NATIONAL_ID: "Personalausweis",
            Description: "Beschreibung",
            SEARCH_FIRST_NAME: "Vorname suchen",
            SEARCH_LAST_NAME: "Nachname suchen",
            SELECT_GENDER: "Wähle Geschlecht",
            SEARCH_EMAIL: "Suche Email",
            SEARCH_PHONE_NO: "Suche Telefonnummer",
            CHOOSE_DATE: "Datum auswählen",
        },

        MY_FILE: {
            UPLOAD_FILE: "Datei hochladen",
            CREATE_FOLDER: "Ordner erstellen",
            FOLDER_NAME: "Ordnernamen",
            ENTER_FOLDER_NAME: "Ordnernamen eingeben",
            MY_FILES: "Meine Akten",
            CREATE_NEW_FOLDER: "Neuen Ordner erstellen",
            FOLDERS: "ORDNER",
            FILES_SMALL: "Dateien",
            FILES_CAPITAL: "DATEIEN",
            FILE_TYPE: "Dateityp",
            FILE_SIZE: "Dateigröße",
            UPDATED_BY: "aktualisiert von",
            UPDATED_ON: "Aktualisiert am",
            MODIFIED_ON: "Geändert am",
            ARCHIVE: "Archiv",
            EMPTY_TRASH_NOW: "Jetzt Papierkorb leeren",
            NO_FOLDER_TEXT: "Noch kein Ordner hinzugefügt",
            NO_FILE_TEXT: "Noch keine Datei hinzugefügt",
            SHARE: "Teilen",
            DELETE_FOREVER: "Unwiederuflich löschen",
            UNDO: "Rückgängig machen",
            TRASH: "Papierkorb",
            OTHER_INFORMATION: "Andere Informationen",
            SELECT_TAGS: "Tags auswählen",
            SELECT_ACCESS_MODIFIER: "Zugriffsmodifikator auswählen",
            MARK_AS_UNFAVOURITE: "Als nicht Favorit markieren",
            MARK_AS_MAIN: "Als main markieren",
        },

        SURVEY_RESULT: {
            SURVEY_RESULT: "Umfrageergebnisse",
            RESPONDENTS: "BEANTWORTETE",
            OF: "von",
            PRINT: "Drucken",
            ANSWERED: "Antwortete",
            SKIPPED: "Übersprungen",
            TOTAL: "GESAMT",
        },

        FIX_ASSET: {
            THUMBNAIL: "Miniaturansicht",
            ASSET_CODE: "Asset-Code",
            ENTER_ASSET_CODE: "Asset-Code eingeben",
            BRAND: "Marke",
            MODEL: "Modell",
            SERIAL_NUMBER: "Ordnungsnummer",
            DEFINITION: "Definition",
            ENTER_DEFINITION: "Definition eingeben",
            MODIFICATION_DATE: "Änderungsdatum",
            CATEGORIZATION: "Kategorisierung",
            CATEGORIZATION_CAPITAL: "KATEGORISIERUNG",
            COMMENT: "Kommentar",
            TRANSFER: "Transfer",
            EXCEL: "Excel",
            CSV: "CSV",
            JSON: "json",
            TXT: "TXT",
            PRODUCT_FAMILY: "Produktfamilie",
            ADD_PRODUCT_FAMILY: "Product family",
            ENTER_PRODUCT_FAMILY: "Ürün ailesini girin",
            IT_EQUIPMENTS: "IT-Ausrüstung",
            PRODUCT_CATEGORY: "Produktkategorie",
            ADD_PRODUCT_CATEGORY: "Add Product category",
            ENTER_PRODUCT_CATEGORY: "Produktkategorie eingeben",
            COMPUTERS: "Computers",
            PRODUCT_LINE: "Produktreihe",
            ADD_PRODUCT_LINE: "Add Product line",
            ENTER_PRODUCT_LINE: "Enter product line",
            LAPTOP: "Laptop",
            PRODUCT_TYPE: "Produktart",
            ADD_PRODUCT_TYPE: "Add Product type",
            ENTER_PRODUCT_TYPE: "Produktlinie eingeben",
            XSD: "XSD",
            ORGANISATION_HIERARCHY: "Organisationshierarchie",
            ACCOUNT: "Konto",
            ZONE: "Zone",
            SELECT_ZONE: "Zone auswählen",
            PRODUCT_GROUP: "Produktgruppe",
            ENTER_PRODUCT_GROUP: "Produktgruppe eingeben",
            PRODUCT: "Produkt",
            ENTER_PRODUCT: "Produkt eingeben",
            ASSET: "ANLAGEGUT",
            ASSET_STATUS: "Asset-Status",
            SELECT_ASSET_STATUS: "Asset-Status auswählen",
            ASSET_USAGE: "Asset-Nutzung",
            ENTER_COMPANY_ASSET_CODE:
                "Geben Sie den Anlagencode des Unternehmens ein",
            COMPANY_ASSET_CODE: "Code des Unternehmensvermögens",
            MASTER_ASSET_CODE: "Master-Asset-Code",
            ENTER_MASTER_ASSET_CODE: "Geben Sie den Master-Asset-Code ein",
            GENERAL: "ALLGEMEINES",
            STATUS_DETAILS: "Statusdetails",
            SELECT_STATUS_DETAILS: "Statusdetail auswählen",
            STATUS_DETAIL: "Statusdetails",
            EMPLOYEE: "Mitarbeiter",
            ADD_BRAND: "Add Brand",
            ENTER_BRAND: "Marke eingeben",
            ADD_MODEL: "Add Model",
            ENTER_MODEL: "Modell eingeben",
            ENTER_SERIAL_NUMBER: "Seriennummer eingeben",
            COMMENTS: "Bemerkungen",
            ENTER_COMMENT: "Kommentar eingeben",
            BASIC_DEFINITIONS: "Grundlegende Definitionen",
            STATUS_LIABILITY: "Status/Haftung",
            ADD_STATUS_LIABILITY: "Status/Haftung hinzufügen",
            MAINTENANCE: "Instandhaltung",
            INSURANCE: "Versicherung",
            FINANCIAL_DETAILS: "Finanzielle Details",
            PHOTOS_DOCUMENTS: "Fotos/Dokumente",
            FIRM_ASSET_CODE: "Firmenwertcode",
            ENTER_FIRM_ASSET_CODE: "Geben Sie den festen Anlagencode ein",
            MAIN_ASSET_CODE: "Haupt-Asset-Code",
            ENTER_MAIN_ASSET_CODE: "Geben Sie den Haupt-Asset-Code ein",
            EXPLANATION: "Erläuterung",
            ENTER_EXPLANATION: "Erklärung eingeben",
            WIDTH: "Breite",
            ENTER_WIDTH: "Breite eingeben",
            HEIGHT: "Höhe",
            ENTER_HEIGHT: "Höhe eingeben",
            LENGTH: "Länge",
            ENTER_LENGTH: "Länge eingeben",
            QUANTITY: "Menge",
            ENTER_QUANTITY: "Menge eingeben",
            UNIT: "Einheit",
            SELECT_UNIT: "Einheit auswählen",
            ASSET_LOCATION: "Asset-Standort",
            START_DATE: "Anfangsdatum",
            END_DATE: "Endtermin",
            LIABLE_EMPLOYEE: "Haftpflichtiger Mitarbeiter",
            SELECT_LIABLE_EMPLOYEE: "Haftungspflichtigen Mitarbeiter auswählen",
            ASSET_ATTRIBUTE: "Asset-Attribut",
            SELECT_ASSET_ATTRIBUTE: "Asset-Attribut auswählen",
            TYPE: "Art",
            VENDOR: "Verkäufer",
            MAINTENANCE_DATE: "Wartungsdatum",
            NEXT_MAINTENANCE_DATE: "Nächstes Wartungsdatum",
            COMPLETION_DATE: "Fertigstellungstermin",
            SET_REMINDER: "Erinnerung einstellen",
            CUSTOM: "Benutzerdefiniert",
            ADD_REMINDER: "Erinnerung hinzufügen",
            CHOOSE_TIME: "Wählen Sie Zeit",
            ENTER_NOTE: "Hinweis eingeben",
            ADD_ASSET_MAINTENANCE: "Asset-Wartung hinzufügen",
            MAINTENANCE_TYPE: "Wartungsart",
            SELECT_MAINTENANCE_TYPE: "Wartungsart",
            MAINTENANCE_VENDOR: "Wartungsanbieter",
            INSURANCE_COMPANY: "Versicherungsunternehmen",
            ENTER_INSURANCE_COMPANY: "Versicherungsgesellschaft eingeben",
            POLICY_NUMBER: "Versicherungsnummer",
            ENTER_POLICY_NUMBER: "Versicherungsnummer eingeben",
            AGENT_NAME: "Agentenname",
            ENTER_AGENT_NAME: "Geben Sie den Agentennamen ein",
            POLICY_TYPE: "Richtlinientyp",
            ADD_ASSET_INSURANCE: "Vermögensversicherung hinzufügen",
            SCANNER_CODE: "Scannercode",
            GENERATE_NEW_CODE: "NEUEN CODE ERSTELLEN",
            QR_CODE: "QR-Code",
            BARCODE: "Strichcode",
            RFID_CODE: "RFID-Code",
            UNIQUE_KEY: "Einzigartiger Schlüssel",
            SELECT_LOCATION: "Ort auswählen",
            ENTER_POLICY_TYPE: "Richtlinientyp eingeben",
            REMINDER: "Erinnerung",
            NEXT_WEEK: "Nächste Woche",
            TOMORROW: "Morgen",
            IN: "In",
            HOURS: "Std",
            MINUTES: "Protokoll",
            APPLY: "SICH BEWERBEN",
            UPLOAD: "HOCHLADEN",
            SEE_ALL_PHOTOS: "ALLE FOTOS ANSEHEN",
            SHARE: "TEILEN",
            UPLOAD_PHOTO_DOCUMENT: "Foto/Dokument hochladen",
            UPLOAD_PHOTO_DOCUMENT_TEXT:
                "Wählen Sie ein beliebiges Foto aus, das als Asset-Hauptfoto festgelegt werden soll",
            SEE_ARCHIVE: "Siehe Archivdatei",
            INITIAL_COST: "Anschaffungskosten",
            ENTER_INITIAL_COST: "Geben Sie die Anfangskosten ein",
            ACQUISTONO_DATE: "Acquistino-Datum",
            BOOK_VALUE: "Buchwert",
            CURRENT_VALUE: "Aktueller Wert",
            AMORTIZATION: "Amortisation",
            FISCAL_YEAR_END: "Geschäftsjahresende",
            ENTER_FISCAL_YEAR_END: "Geschäftsjahresende eingeben",
            MONTHLY_AMORTISATION: "Monatliche Abschreibung",
            ENTER_MONTHLY_AMORTISATION:
                "Geben Sie die monatliche Amortisation ein",
            MONTHLY_HELD: "Monatlich gehalten",
            ENTER_MONTHLY_HELD: "Geben Sie ein Monatlich gehalten",
            DATE_ACQUIRED: "Erwerbsdatum",
            DATE_OF_DISPOSITION: "Datum der Disposition",
            PERIOD: "Zeitraum",
            MONTH_THIS_PERIOD: "Monate in diesem Zeitraum",
            AMORTIZATION_THIS_PERIOD: "Amortisation in diesem Zeitraum",
            ACCUMULATED_AMORTIZATION: "Kumulierte Amortisation",
            NET_BOOK_VALUE: "Nettobuchwert",
            REVALUATION: "Aufwertung",
            INVOICE_COST: "Rechnungskosten",
            REVALUATION_FACTOR: "Neubewertungsfaktor (%)",
            REVALUATION_DATE: "Neubewertungsdatum",
            SELECT_UNIT_CAPITAL: "Einheit auswählen",
            NO_FIX_ASSETS_DESC:
                "Sind Sie gespannt darauf, Ihr erstes Fix-Asset zu erstellen? Lassen Sie uns gleich einsteigen!",
            ADD_ASSETS: "ANLAGEVERMÖGEN HINZUFÜGEN",
            CLONE_ASSETS: "KLONEN ANLAGEVERMÖGEN",
            NAME: "Name",
            DESCRIPTION: "Beschreibung",
            SHARE_FILES: "Dateien teilen",
        },

        TASK_MANAGEMENT: {
            PROJECT_MEMBERS: "Projektmitglieder",
            TEAM: "Mannschaft",
            NO_ANY_TEAM_ARE_AVAILABLE: "Es sind keine Teams verfügbar",
            build_a_team_and_add_resources:
                "Bilden Sie ein Team und fügen Sie Ressourcen hinzu.",
            BUILD: "BAUEN",
            CREATE: "SCHAFFEN",
            CREATE_TEAM: "Team erstellen",
            EDIT_TEAM: "Team bearbeiten",
            TEAM_NAME: "Teamname",
            ENTER_TEAM_NAME: "Teamnamen eingeben",
            SEARCH_USERS: "Nutzer suchen",
            INVITE: "EINLADEN",
            SR_NO: "Sr. Nr.",
            MEMBERS: "Mitglieder",
            CREATE_NEW_BOARD: "Neues Board erstellen",
            UPDATE_BOARD: "Board aktualisieren",
            BOARD_NAME: "Boardname",
            ENTER_BOARD_NAME: "Boardnamen eingeben",
            CHOOSE_BACKGROUND: "Hintergrund auswählen",
            REMOVE_FROM_FAVOURITE: "Aus Favoriten entfernen",
            DELETE_BOARD: "Tafel löschen",
            NAME_YOUR_PROJECT: "Benennen Sie Ihr Projekt",
            CREATE_PROJECT_TEXT:
                "Sie können diese Angaben jederzeit in Ihren Projekteinstellungen ändern.",
            PROJECT_NAME: "Projektname",
            ENTER_PROJECT_NAME: "Projektnamen eingeben",
            PREFIX: "Präfix",
            ENTER_PREFIX: "Präfix eingeben",
            TYPE_OF_PROJECT: "Art des Projekts",
            LIMIT_OF_RESOURCES: "Limit der Ressourcen",
            COLOR: "Farbe",
            PRIVATE_PROJECT: "Privates Projekt",
            SAVE_NEXT: "SPEICHERN WEITER",
            NO_ANY_USERS_ARE_AVAILABLE: "Es sind keine Benutzer verfügbar",
            INVITE_USERS_TEXT:
                "Laden Sie jetzt Benutzer ein und fügen Sie einem beliebigen Projekt hinzu.",
            VIEW_PROFILE: "Profil anzeigen",
            INVITE_USER: "Benutzer einladen",
            INVITE_TEAMMATES: "Teamkollegen einladen",
            ENTER_EMAIL_ADDRESSES: "E-Mail-Adressen eingeben",
            MESSAGE: "Nachricht",
            MESSAGE_PLACEHOLDER:
                "Fügen Sie Ihrer Einladung eine persönliche Nachricht hinzu",
            INVITE_SMALL: "Einladen",
            MANAGE_TEAM_FOR: "Team verwalten für",
            ADD_TEAMMATES: "Teamkollegen hinzufügen",
            ENTER_TEAMMATES: "Teamkollegen eingeben",
            PROJECTS: "Projekte",
            NO_PROJECT: "Keine Projekte hinzugefügt",
            NO_PROJECT_TEXT:
                "Sind Sie aufgeregt, Ihr erstes Projekt zu erstellen? Lassen Sie uns gleich einsteigen!",
            ADD_PROJECT: "PROJEKT HINZUFÜGEN",
            SEE_MEMBERS: "MITGLIEDER ANSEHEN",
            VIEW_TASKS: "Aufgaben anzeigen",
            MANAGE_TEAM: "Team verwalten",
            KEY: "Taste",
            CREATED_BY: "Erstellt von",
            BOARD: "Planke",
            ADD_MEMBERS: "Mitglieder hinzufügen",
            SHARE_TASK: "Aufgabe teilen",
            NAME_OR_EMAIL: "Name oder E-Mail",
            ENTER_NAME_OR_EMAIL: "Name oder E-Mail eingeben",
            ADD_A_MESSAGE: "Nachricht hinzufügen",
            FLAGGED: "Gekennzeichnet",
            EDIT_TASK: "Aufgabe bearbeiten",
            EDIT_COLOR: "Farbe bearbeiten",
            CONVERT_TO_SUB_TASK: "In Unteraufgabe umwandeln",
            COPY_TASK_LINK: "Aufgabenlink kopieren",
            MOVE_IN: "Einziehen",
            DUPLICATE_TASK: "Doppelte Aufgabe",
            ADD_FLAG: "Flagge hinzufügen",
            REMOVE_COLOR_TAG: "Farbetikett entfernen",
            ENTER_TASK_BRIEF: "Aufgabenbeschreibung eingeben",
            ADD_SUBTASK: "Unteraufgabe hinzufügen",
            CHECKLIST: "Checkliste",
            ASSIGNEE: "Abtretungsempfängerin",
            UNASSIGNED: "Nicht zugewiesen",
            REPORTER: "Reporterin",
            TAGS: "Stichworte",
            ENTER_NEW_TAG: "Neues tag eingeben",
            PRIORITY: "Priorität",
            DUE_DATE: "Geburtstermin",
            CREATED: "Erstellt",
            UPDATED: "Aktualisiert",
            RESOLVED: "Behoben",
            ACTIVITY: "Aktivität",
            NEWEST_FIRST: "Das neuste zuerst",
            OLDEST_FIRST: "Die ältesten zu erst",
            ALL: "Alle",
            HISTORY: "Geschichte",
            TYPE_A_COMMENT: "Geben Sie einen Kommentar ein",
            DELETE_ALL: "Alles löschen",
            DOWNLOAD_ALL: "Alles herunterladen",
            ATTACHMENT_TEXT: "Es werden keine Anhänge hinzugefügt",
            ADD_AN_ITEM: "Artikel hinzufügen",
            ADDED: "hinzugefügt",
            CHANGED: "geändert",
            HISTORY_CAPITAL: "GESCHICHTE",
            IN_PROGRESS: "Im Gange",
            COMMENT_CAPITAL: "KOMMENTAR",
            SUB_TASKS: "Unteraufgaben",
            MARK_AS_FAVOURITE: "Als Favorit markieren",
            OPEN_BOARD: "Offener Vorstand",
            ADD_SECTION: "Abschnitt hinzufügen",
            ADD_TASK: "Abschnitt hinzufügen",
            SEARCH: "Suche",
            ONLY_MY_ISSUE: "NUR MEIN PROBLEM",
            RECENTLY_UPDATED: "KÜRZLICH AKTUALISIERT",
            LABEL: "ETIKETT",
            TASK: "Aufgabe",
            SUBTASK: "Unteraufgabe",
            COLOR_CAPITAL: "FARBE",
            CLEAR_FILTERS: "KLARE FILTER",
            GROUP_BY: "GRUPPIERE NACH",
            COMPLETED: "Vollendet",
            INCOMPLETED: "Unvollständig",
            RENAME: "Umbenennen",
            ENTER_TEXT: "Text eingeben",
            ASSIGN_TEAM: "Team zuweisen",
            ASSIGN_MEMBER: "Mitglied zuweisen",
            MARK_AS_MILESTONE: "Als Meilenstein markieren",
            ENTER_TASK_NAME: "Aufgabennamen eingeben",
            TAG: "SCHILD",
            PRODUCT_MANAGEMENT: "Produkt Management",
            PROJECT_TYPE: "Projekttyp",
            PROJECT_KEY: "Projektschlüssel",
            SEARCH_PROJECT_KEY: "Projektschlüssel suchen",
            PUBLIC_PROJECT: "Öffentliches Projekt",
            MODIFIED_BY: "Geändert durch",
            ONLY_MY_PROJECT: "Nur mein Projekt",
            SHOW_ONLY_FAVOURITE: "Nur Favoriten anzeigen",
            PRIVATE: "Privatgelände",
            PUBLIC: "Öffentlich",
            DEFAULT: "Default",
            STATUS_CAPITAL: "STATUS",
            CREATED_ON: "Erstellt am",
            QUICK_CREATE_TEAM: "Team schnell erstellen",
            TEAM_DESCRIPTION: "Teambeschreibung",
            PICK_MEMBERS: "Mitglieder auswählen",
            SELECT_MEMBERS: "Mitglieder auswählen",
            VISIBILITY: "Sichtweite",
            ACCESS_MODIFIER: "Zugriffsmodifikator",
            TEAMS: "Mannschaften",
            UI_UX_TEAM_MEMBERS: "(UI/UX-Teammitglieder)",
            ADD_BOARD: "Board hinzufügen",
            SELECT_TEAM: "Team auswählen",
            ADD_TEAM: "Team hinzufügen",
            SEARCH_TO_ADD_MEMBER_TO_TEAM:
                "Suchen, um ein Mitglied zum Team hinzuzufügen",
            SELECT_ALL_CAPITAL: "WÄHLEN SIE ALLE",
            NO_ANY_MEMBERS_ARE_ADDED: "Es wurden keine Mitglieder hinzugefügt",
            PICK_FROM_USERS_LIST_AND_ASSIGNED_IN_TO_THE_TEAM:
                "Aus der Benutzerliste auswählen und dem Team zuordnen",
            ADD_ATTACHMENT: "Anhang hinzufügen",
            ADD_CHECKLIST: "Checkliste hinzufügen",
            TASK_ESTIMATION: "Aufgabenschätzung (in Stunden)",
            ADD_DESCRIPTION: "Beschreibung hinzufügen",
            ENTER_TASK_ESTIMATION: "Aufgabenschätzung eingeben",
            NONE: "Keiner",
            PROGRESS: "Fortschritt",
            ADD_EXPENSE: "Ausgaben hinzufügen",
            EXPENSE_TYPE: "Kostenart",
            SELECT_EXPENSE_TYPE: "Ausgabenart auswählen",
            EXPENSE_AMOUNT: "Aufwandsbetrag",
            ENTER_EXPENSE_AMOUNT: "Ausgabenbetrag eingeben",
            ARCHIVE_BOARD: "Archivtafel",
            ADD_TO_CONTACTS: "Als Kontakt hinzufügen",
            SHOW_USERS_TEAM: "Afficher les utilisateurs/équipes",
            SHOW_TEAM: "Team zeigen",
            EXPENSE_CATEGORY: "Ausgabenkategorie",
            TO_DO: "Aufgaben",
            ALL_TASK_MANGEMENT: "Alle",
            SECTION: "Abschnitt",
            SELECT_PROJECT: "Wählen Sie Projekt aus",
            ONLY_CREATED_BY_ME: "Nur von mir erstellt",
            ONLY_ASSIGNED_TO_ME: "Nur mir zugeordnet",
            ONLY_REPORTED_TO_ME: "Nur bei mir gemeldet",
            ONLY_ME_AS_A_TEAM_MEMBER: "Nur ich als Teammitglied",
            IN_PROGRESS_CAPITAL: "IN BEARBEITUNG",
            DONE: "FERTIG",
            TO_DO_CAPITAL: "MACHEN",
            DUE_ON: "Fällig am",
            REPORT_NAME: "Berichtsname",
            SELECT_REPORT_NAME: "Wählen Sie den Berichtsnamen aus",
            NAME: "Name",
            CONTACT_DETAILS: "Kontaktdetails",
            USE_COMMA_TO_SEPERATE_INVITATION_ACCOUNTS: "Verwenden Sie Kommas, um Einladungskonten zu trennen",
            FAVORITE: "Favorit",
            RECURRING: "Wiederkehrend",
            SELECT_DUE_DATE: "Wählen Sie Fälligkeitsdatum aus",
            NAVIGATIONAL: "NAVIGATION",
            SELECT_BOARD: "Vorstand auswählen",
            SELECT_SECTION: "Abschnitt auswählen",
            PARENT: "Elternteil",
            SELECT_PARENT: "Wählen Sie Eltern aus",
            OWNERSHIP_MEMBERSHIP: "EIGENTUM/MITGLIEDSCHAFT",
            REPORTED_TEAM: "Gemeldetes Team",
            SELECT_REPORTED_TEAM: "Wählen Sie Gemeldetes Team aus",
            OWNER_USER: "Eigentümer Benutzer",
            SELECT_OWNER_USER: "Wählen Sie Eigentümerbenutzer aus",
            REPORTED_USER: "Gemeldeter Benutzer",
            SELECT_REPORTED_USER: "Wählen Sie Gemeldeter Benutzer aus",
            ASSIGNED_USER: "Zugewiesener Benutzer",
            SELECT_ASSIGNED_USER: "Wählen Sie Zugewiesener Benutzer",
            SELECT_CREATED_BY: "Wählen Sie Erstellt von aus",
            SELECT_MODIFIED_BY: "Wählen Sie Geändert von aus",
            TASK_CATEGORY: "Kategorie",
            SELECT_CATEGORY: "Kategorie Wählen",
            TASK_SUB_CATEGORY: "Unterkategorie",
            SELECT_SUB_CATEGORY: "Unterkategorie Auswählen"
        },

        DASHBOARD_FIX_ASSETS: {
            WELCOME: "Willkommen",
            BRANDS: "Zählt nach Marke",
            CATEGORY_COUNT: "Zählt nach Kategorie",
            ASSET_BYSTATUS_COUNT: "Zählt nach Status",
            COUNT: "Zählen",
            NOTIFICATIONS: "Benachrichtigungen",
            REMINDERS: "Erinnerungen",
            ADDED: "hinzugefügt",
            A: "ein",
            ON: "An",
            UPVOTE: "Upvote",
            SHOW_MORE: "Zeig mehr",
            DUE_INSURANCE: "Fällige Versicherung",
            DATE: "Datum",
            DUE_MAINTENANCE: "Fällige Wartung",
            NO_NOTIFICATION: "Keine aktuelle Benachrichtigung",
            NO_REMINDER: "Keine aktuelle Erinnerungen",
            NO_INSURANCE: "Keine Versicherung gefunden",
            NO_MAINTENANCE: "Keine Wartung gefunden",
            NO_DATA: "Herhangi bir veri bulunamadı",
        },

        SYSTEM_NAVIGATION: {
            HOME: "Heim",
            DASHBOARD: "Armaturenbrett",
            MY_SURVEY: "Meine Umfrage",
            MY_IDEAS: "Meine Ideen",
            TENANTS: "Mieter",
            MY_PROFILE: "Mein Profil",
            LOGOUT: "Ausloggen",
            TASK_MANAGEMENT: "Aufgabenmanagement",
            TASK_MANAGEMENT_DESC:
                "Verwalten Sie Ihre Aufgaben in ihrem Lebenszyklus. Planen, testen, verfolgen und berichten. Erreichen Sie Ihre individuellen Ziele oder arbeiten Sie mit Ihren Teamkollegen zusammen.",
            SURVEY_MANAGEMENT: "Umfragemanagement",
            SURVEY_MANAGEMENT_DESC:
                "Entwerfen, verwalten und veröffentlichen Sie professionelle Umfragen und sammeln Sie Feedback, um Ihren geschäftlichen Entscheidungsprozess zu unterstützen.",
            FIX_ASSET_MANAGEMENT_DESC:
                "Verfolgen und verwalten Sie die physischen Vermögenswerte und Geräte Ihrer Organisationseinheiten. Überwachen Sie den Status, die Verbindlichkeiten der Mitarbeiter, die Wartung, die Versicherung und den finanziellen Lebenszyklus.",
            IDEA_MANAGEMENT_DESC:
                "Generieren, erfassen, diskutieren und verbessern. Organisieren, bewerten und priorisieren Sie wertvolle Erkenntnisse oder alternative Denkprozesse.",
            PRODUCT_MANAGEMENT_DESC:
                "Verwalten Sie wichtige Produktdetails, die Käufern bei der Kaufentscheidung helfen. Produktmerkmale, Beschreibungen, Abmessungen, Preis, Gewicht, Verfügbarkeit, Farbe, Kundenbewertungen und mehr.",
            ACCOUNT_MANAGEMENT_DESC:
                "Verwalten Sie Ihre Interessenten und Kunden. Behalten Sie das Geschäft Ihrer Kunden und erweitern Sie diese Möglichkeiten. Helfen Sie ihnen, ihre Ziele zu erreichen.",
            CONTACT_MANAGEMENT_DESC:
                "Ermöglichen Sie Ihren Benutzern, Kontaktinformationen zu den von ihnen verwalteten Konten wie Namen, Adressen und Telefonnummern einfach zu speichern und zu finden. Verfolgen Sie alle mit Kontakten verknüpften Aktivitäten.",
            FILE_MANAGER: "Dateimanager",
            FILE_MANAGER_DESC:
                "Verwalten Sie die digitalen Daten Ihres Unternehmens, arbeiten Sie effektiv zusammen. Verwalten Sie den Geschäftsfluss mit organisierten wichtigen Daten in einer durchsuchbaren Datenbank.",
            PROFILE_MANAGEMENT_DESC:
                "Verwalten Sie Ihr Profil, Ihre Präferenzen und Ihre Kontaktinformationen. Reichen Sie Ihre Ausgaben ein und achten Sie auf Ihre Entschädigung und erhaltenen Zahlungen.",
            MESSAGES: "Mitteilungen",
            MARK_ALL_AS_READ: "Markiere alle als gelesen",
            MARK_ALL_AS_UNREAD: "als ungelesen markieren",
            MUTE_MESSAGES: "Nachrichten stumm schalten",
            DELETE_MESSAGE: "Nachricht löschen",
            VIEW_ALL: "Alle ansehen",
            MUTE_NOTIFICATION: "Benachrichtigung stumm schalten",
            MUTE_REMINDER: "Erinnerungen stumm schalten",
            MARK_AS_READ: "als gelesen markieren",
            DELETE_NOTIFICATION: "Benachrichtigung löschen",
            LAST_LOGGED_IN: "Zuletzt eingeloggt:",
            LOCALE_ACCOUNTS: "Lokale Konten",
            USER_MANAGEMENT: "Benutzerverwaltung",
            PURCHASE_MANAGEMENT: "Einkaufsmanagement",
            PURCHASE_MANAGEMENT_DESC:
                "Verwalten Sie Ihre Prozesse effektiv, damit Ihre Produkte und Dienstleistungen in der richtigen Qualität, in der richtigen Menge, zum richtigen Zeitpunkt, zum richtigen Preis und aus der richtigen Quelle bereitgestellt werden.",
            REMINDERS: "Erinnerungen",
            MARK_AS_READ_UNREAD: "Als gelesen/ungelesen markieren",
            CHANGE_REMINDER: "Erinnerung ändern",
            TEAM_MANAGEMENT: "Team Management",
            TEAM_MANAGEMENT_DESC:
                "Helfen Sie den Teammitgliedern, ihre Aufgaben im Auge zu behalten und weniger Zeit zu verschwenden. Verbessern Sie die Kommunikation und verwalten Sie das Budget effektiv.",
            CALENDAR: "Kalender",
            CALENDAR_DESC:
                "Verwalten Sie Ihre Termine, Erinnerungen, persönlichen Aufgaben und Ihre Teamaufgaben mit der benutzerfreundlichen, einfach zu teilenden und mit Funktionen vollgepackten Kalender-App",
            INTERNAL_ACCOUNTS: "Interne Konten",
            PROSPECT_ACCOUNTS: "Prospektkonten",
            CUSTOMER_ACCOUNTS: "Kundenkonten",
            SEARCHING: "Suche...",
            BIP_BOUNTY_HUNTERS: "BIP - Bounty Hunters",
            BIP_BOUNTY_HUNTERS_DESC: "Only real bounty hunters can win prizes here.",
        },

        PROFILE_STATUS: {
            ONLINE: "Online",
            AWAY: "Ein Weg",
            IN_A_MEETING: "In einem Meeting",
            COMMUTING: "Pendeln",
            OFFLINE: "Offline",
            OUT_OF_OFFICE: "Ausserhaus",
            DO_NOT_DISTURB: "Bitte nicht stören",
        },

        PURCHASE_MANAGEMENT: {
            PURCHASE_INVOICE: "Kaufrechnung",
            INVOICE_NO: "Rechnung Nr.",
            DOCUMENT_TYPE: "Art des Dokuments",
            PROJECT: "Projekt",
            ENTER_PROJECT: "Projekt eingeben",
            DATE_OF_ISSUE: "Ausgabedatum",
            EXPIRY_DATE: "Verfallsdatum",
            INVOICE_AMOUNT: "Rechnungsbetrag",
            LOCAL_AMOUNT: "Lokaler Betrag",
            BALANCE: "Gleichgewicht",
            HEADER_TITLE: "HEADER",
            DETAILS_TITLE: "EINZELHEITEN",
            TOTALS_TITLE: "SUMMEN",
            FILE_PHOTOS: "DATEI/FOTOS",
            INVOICE_DETAILS: "RECHNUNGS-DETAILS",
            SELECT_TAGS_NEW: "Tags auswählen",
            NEW_TAG: "New tag",
            UPLOAD_DOCUMENT: "Dokument hochladen",
            ADDRESS: "Die Anschrift",
            ENTER_ADDRESS: "Adresse eingeben",
            ORDER_NO: "Best.-Nr.",
            ENTER_ORDER_NO: "Geben Sie die Bestell-Nr.",
            ORDER_DATE: "Auftragsdatum",
            WAYBILL_DETAILS: "QUERSCHEIN-DETAILS",
            WAYBILL_NO: "Frachtbrief Nr.",
            ENTER_WAYBILL_NO: "Geben Sie die Frachtbrief-Nr.",
            WAYBILL_DATE: "Frachtbriefdatum",
            ADD_NEW: "NEUE HINZUFÜGEN",
            ENTER_PRODUCT_NAME: "Produktname eingeben",
            UNIT_PRICE: "Stückpreis",
            VAT_RATE: "Mehrwertsteuersatz",
            TOTAL_VAT_AMOUNT: "Gesamtbetrag der Mehrwertsteuer",
            TOTAL_EXCLUDING_VAT: "Gesamtsumme ohne Mehrwertsteuer)",
            TOTAL_INCLUDING_VAT: "Gesamt (inkl. MwSt.)",
            ENTER_IN_DOLLAR: "Eingabe (in $)",
            ENTER_IN_PERCENTAGE: "Eingabe (in %)",
            CURRENCY_TOTALS: "Währungssummen",
            IN_USD: "(IN USD)",
            SUBTOTAL: "Zwischensumme",
            LINE_DISCOUNT: "Linienrabatt",
            GROSS_TOTAL: "Brutto Gesamt",
            TOTAL_NET_FEE: "Gesamtnettogebühr",
            TOTAL_VAT_EIGHT: "Gesamt-Mehrwertsteuer (8,0%)",
            TOTAL_VAT_EIGHTEEN: "Gesamt-Mehrwertsteuer (18,00%)",
            LOCAL_TOTALS: "Lokale Summen",
            CURRENT_ACCOUNT: "Aktuelles Konto",
            ENTER_CURRENT_ACCOUNT: "Geben Sie das Girokonto ein",
            SERIAL_NO: "Seriennummer.",
            ENTER_SERIAL_NO: "Geben Sie die Seriennummer ein.",
            PAYMENT_STATUS: "Zahlungsstatus",
            TRACKING_CURRENCY: "Tracking-Währung",
            SELECT_TRACKING_CURRENCY: "Tracking-Währung auswählen",
            SELECT_CURRENT_ACCOUNT_NAME: "Aktuellen Kontonamen auswählen",
        },

        CALENDAR: {
            CREATE_TASK: "",
            EVENTS: "Veranstaltungen",
            COMPLETED_TASKS: "Abgeschlossene Aufgaben",
            PENDING_TASKS: "Ausstehende Aufgaben",
            MY_CALENDARS: "Meine Kalender",
            WORK: "Arbeiten",
            TRIP: "Reise",
            FUNCTIONS: "Funktionen",
            EVENT: "Fall",
            CHOOSE_START_TIME: "Wählen Sie die Startzeit",
            ALL_DAY: "Den ganzen Tag",
            CHOOSE_END_TIME: "Wählen Sie Endzeit",
            SCHEDULE: "Zeitplan",
            SELECT_SCHEDULE: "Wählen Sie Zeitplan",
            PRIVATE_TASK: "Private Aufgabe",
            OPEN_TASK_ON_MY_TASKS_AFTER_SAVE:
                "Nach dem Speichern Aufgabe in Meine Aufgaben öffnen",
            TIME_ZONE: "ZEITZONE:",
            ADD_GUEST: "Gäste hinzufügen",
            SELECT_GUEST: "Gast auswählen",
            MONTH: "MONAT",
            DAILY: "Täglich",
            WEEKLY: "Wöchentlich",
            MOVE_TO_TODAY: "Verschieben Sie nach heute",
            HH: "HH",
            MARK_COMPLETED: "BEENDET MARKIEREN",
        },

        CONTACT: {
            CHAT: "Plaudern",
            REACTION: "Reaktionen",
            THREADS: "Threads",
            LIKE_SMALL: "Wie",
            SEARCH_CONTACT: "Kontakte durchsuchen",
            CONTACT_LOG: "Kontaktprotokoll",
            TO_DO_S: "TO DO’s",
            CONTACTS: "Kontakte",
            CONTACTS_SMALL: "Kontakte",
            ADD_EVENT_APPOINTMENT: "Ereignis/Termin hinzufügen",
            ADD_TO_DO: "Aufgabe hinzufügen",
            INTERNAL: "Intern",
            EXTERNAL: "Extern",
            EDIT_CONTACT: "Kontakt bearbeiten",
        },

        FILE_EDITOR: {
            QUEUE_PROGRESS: "Warteschlangenfortschritt:",
            QUEUE_LENGTH: "Warteschlangenlänge:",
            UPLOAD_QUEUE: "Warteschlange hochladen",
            SINGLE: "Einzel",
            MULTIPLE: "Mehrere",
            DROP_PHOTOS_HERE: "Legen Sie Fotos hier ab",
            SELECTFILES: "Dateien auswählen",
            USER_DETAIL: "Benutzerdetail",
            LOADING_DATA: "Daten werden geladen...",
            NO_DATA: "Keine Daten.",
        },

        BIP_BH_WELCOME: {
            TITLE: "Welcome to Bounty Hunters",
            MESSAGE: "Only real bounty hunters can win prizes here.",
            BUTTON: "Join To Missions",
        },

        BIP_BH_MISSION: {
            TITLE: "Easy Mission",
            MESSAGE: "Download and use BipMeet",
            BUTTON: "Do It",
            POINT: "Points",
        },

        BIP_BH_PROGRESS: {
            TIMER: "Timer:",
            TITLE: "Bounty Hunters",
            MESSAGE: "Only real bounty hunters can win prizes here.",
            BUTTON: "Go to Mission",
            DAYS: "Days",
            HOURS: "Hours",
            MINUTES: "Minutes",
            SECONDS: "Seconds",
        },

        BIP_CONTEST: {
            TITLE: "Contests",
            MESSAGE: "Burada sadece gerçek ödül avcıları ödül kazanabilir.",
            BUTTON1: "Turkcell Bounty Hunters",
            BUTTON2: "Youtube Bounty Hunters",
        },

        BIP_EARNED: {
            TITLE: "Congrats",
            MESSAGE: "You earned 2 GB per week",
            BUTTON: "Share on social media",
        },

        BIP_EARNED_LIST: {
            TITLE: "Turkcell Bounty Hunters",
            MESSAGE: "Total Points Earned",
            ITEM1: "10+ Year Turkcell members",
            ITEM2: "BipMeet download and use",
        },

        BIP_MISSION_LIST: {
            MISSIONTYPE: "Easy Missions",
            MENU1: "Active Missions",
            MENU2: "Old Missions",
            MENU3: "Help",
            TITLE: "Turkcell Bounty Hunters",
            BUTTON: "Start New Race",
        },

        BIP_MISSION_TYPE: {
            TITLE: "Turkcell Bounty Hunters",
            SUBTITLE: "Mission Types",
            MISSIONTYPE1: "Easy Missions",
            MISSIONTYPE2: "Normal Missions",
            MISSIONTYPE3: "Super Missions",
        },
    },
};
