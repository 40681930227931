import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { APIManager } from 'app/utility/shared-services';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { concatAll, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { Notification, NotificationsResponse, NotificationsService } from "../../utility/shared-component/notifications-alert/notifications.service";

class NotificationsDataSource extends DataSource<Notification> {

  private cached: Notification[] = [];
  private readonly data$: Observable<Notification[]>;
  private finished = false;
  static readonly pageSize = 7;

  readonly searching = {} as Notification;
  readonly nothing = {} as Notification;
  readonly first$: Observable<Notification>;

  constructor(
    private readonly notificationService: NotificationsService,
    private readonly since: Date
  ) {
    super();

    this.data$ = this.rangeToItems(of({ start: 0, end: 0 })).pipe(shareReplay(1));
    this.first$ = this.data$
      .pipe(
        map((list: Notification[]) => list.find(_ => true)),
        map((a: Notification) => a ? a : this.nothing),
        startWith(this.searching)
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<Notification[]> {

    return merge(
      this.data$,
      this.rangeToItems(collectionViewer.viewChange)
    );
  }

  disconnect(/*collectionViewer: CollectionViewer*/): void {
  }

  private rangeToItems(ranges: Observable<ListRange>): Observable<Notification[]> {
    return ranges
      .pipe(
        map(r => {
          return ({ start: this.cached.length, end: r.end + NotificationsDataSource.pageSize } as ListRange)
        }),
        map((range: ListRange) => {
          return range.end > this.cached.length && !this.finished
            ? this.getItems(range)
              .pipe(
                map((items: Notification[]) => {
                  if (items) this.cached.splice(range.start, range.end, ...items);
                  return this.cached;
                })
              )
            : of(this.cached)
        }
        ),
        concatAll()
      );
  }

  private getItems(range: ListRange): Observable<Notification[]> {
    if (this.finished) return of([]);
    return this.notificationService.getNotifications({
      since: this.since,
      start: range.start,
      end: range.end
    })
      .pipe(
        tap((result: NotificationsResponse) => this.finished = result.end === result.start),
        map((result: NotificationsResponse) => result.notifications),
        // Transform dates
        tap((notifications: Notification[]) => notifications.map(notification => notification.modified = new Date(notification.modified)))
      );
  }
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  readonly notificationsDataSource$: Observable<NotificationsDataSource>;
  allRead = false;
  constructor(public notificationService: NotificationsService) {

    this.notificationsDataSource$ = of(new NotificationsDataSource(notificationService, new Date()));
  }

  ngOnInit(): void {
  }
  async markRead(notification: Notification) {
    if (notification.isRead) {
      return;
    }
    await this.notificationService.markRead(notification.id);
    notification.isRead = true;
  }
  async markAllRead() {
    await this.notificationService.markRead(null, true);
    this.allRead = true;
  }
  async delete(notification: Notification) {
    await this.notificationService.delete(notification.id, notification.isRead);
    notification.deleted = true;
  }
  getNotificationLink(maskedLink: string) {
    if (maskedLink?.length > 0) {
      var type = maskedLink.split('||');
      switch (type[0]) {
        case 'TASK': {
          return `task-management/task-details/${type[1]}`
        }
        case 'CALENDAR': {
          return `calendar/${type[1]}`
        }
        default: {
          return '#';
        }
      }
    }
  }
}
