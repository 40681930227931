<div class="contact-details">
    <div class="contact-details__left">
        <img src="assets/images/avatars/carl.jpg" />
    </div>

    <div class="contact-details__right">
        <h2>Dejesus Michael 
            <span *ngIf="true" class="material-icons-outlined">favorite_border</span>
            <span *ngIf="false" class="material-icons-outlined">favorite</span>
        </h2>
        
        <div class="contact-details__phone-responsive">
            <span class="material-icons-outlined">mail_outline</span>
            <label>johndoe@gmail.com</label>
        </div>

        <ul class="xs-text-center">
            <li
                class="checkmark"
                [ngStyle]="{
                    'background-color': 'red'
                }"
                *ngFor="
                    let color of [1, 2, 3, 4, 5, 6]
                "
            >
                <span
                    *ngIf="false"
                    class="material-icons"
                    >check</span
                >
                <input
                    type="radio"
                    [value]="'red'"
                    name="color"
                />
            </li>
        </ul>

        <div class="contact-details__team">
            <a>Band</a>
            <a>Baseball Team</a>
        </div>

        <div>
            <span class="material-icons-outlined">work_outline</span>
            <label>Track Service Worker</label>
        </div>

        <div>
            <span class="material-icons-outlined">domain</span>
            <label>Softmicro</label>
        </div>

        <div class="contact-details__call-responsive">
            <span class="material-icons-outlined">call</span>
            <div class="contact-details__call">
                <label>+352 805 502 3677</label>
                <label>+352 925 527 2973</label>
            </div>
        </div>

        <div>
            <span class="material-icons-outlined">location_on</span>
            <label>157 Woodhull Street, Rutherford, West Virginia, PO6646</label>
        </div>

        <div>
            <span class="material-icons-outlined">cake</span>
            <label>May 17, 1987</label>
        </div>

        <div>
            <span class="material-icons-outlined">feed</span>
            <label>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                Ipsum has been the industry’s standard dummy text ever since the 1500s, when 
                an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                It has survived not only five centuries, but also the leap into electronic typesetting, 
                remaining essentially unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more recently with desktop 
                publishing software like Aldus PageMaker including versions of Lorem Ipsum</label>
            </div>
    </div>
</div>