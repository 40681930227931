import { FixAssetLookupOrganizationModel } from "./fix-asset-lookup-organization.model";

export class FixAssetLookupAccountModel {
    id: string;
    name: string;
    code: string;
    sequence: number;
    type: string;
    color: string;
    icon: string;
    organizations: FixAssetLookupOrganizationModel[];


    /**
     * Constructor
     *
     * @param asset
     */
    constructor(asset?)
    {
        asset = asset || {};
        this.color = asset.color
        this.icon = asset.icon;
        this.id = asset.id;
        this.name = asset.name;
        this.code = asset.code;
        this.sequence = asset.sequence;
        this.type = asset.type;
    }    
}
