import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
//import { JwtHelperService } from '@auth/angular-jwt';

import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthDotCoreService {
  baseurl = environment.apiUrl + 'auth/';
 // jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: User;
  photoUrl = new BehaviorSubject<string>('../../assets/images/avatars/avatar-girl.png');
  currentPhotoUrl = this.photoUrl.asObservable();
  userName = new BehaviorSubject<string>('');
  currentUserName = this.userName.asObservable();
  userLang = new BehaviorSubject<string>('');
  currentUserLang = this.userLang.asObservable();

constructor(private http: HttpClient,
            private _translateService: TranslateService
            ) { }

changeMemberPhoto(userPhotoUrl: string): void {
  this.photoUrl.next(userPhotoUrl);
}

changeUserName(userName: string): void {
  this.userName.next(userName);
}

changeUserLang(userLang: string): void {
  this.userLang.next(userLang);
  // Use a language
  this._translateService.use(userLang);
}

login(model: any): Observable<void> {

  return this.http
  .post(this.baseurl + 'login', model)
  .pipe(
    map((response: any) => {
      const user = response;
      if (user) {
        localStorage.setItem('token', user.token);
        localStorage.setItem('user', JSON.stringify(user.user));

       // this.decodedToken = this.jwtHelper.decodeToken(user.token);

        this.currentUser = user.user;
        this.changeMemberPhoto(this.currentUser.photo_url);
        this.changeUserName(this.currentUser.first_name + ' ' + this.currentUser.last_name);
        this.changeUserLang(this.currentUser.language);
      }
    })
  );
}

register(user: User): Observable<any> {
  return this.http.post(this.baseurl + 'register', user);
}

forgotPassword(model: any): Observable<any> {
  return this.http.post(this.baseurl + 'forgotpassword', model);
}

resetPassword(model: any): Observable<any> {
  return this.http
  .post(this.baseurl + 'resetpassword', model)
  .pipe(
    map((response: any) => {
      const user = response;
      if (user) {
        localStorage.setItem('token', user.token);
        localStorage.setItem('user', JSON.stringify(user.user));

      //  this.decodedToken = this.jwtHelper.decodeToken(user.token);

        this.currentUser = user.user;
        this.changeMemberPhoto(this.currentUser.photo_url);
        this.changeUserName(this.currentUser.first_name + ' ' + this.currentUser.last_name);
        this.changeUserLang(this.currentUser.language);
      }
    })
  );
}

loggedIn(): boolean {
  const token = localStorage.getItem('token');

  //return !this.jwtHelper.isTokenExpired(token);
  return true;
}

logout() {
  localStorage.removeItem('token');
}

roleMatch(allowedRoles): boolean {
  let isMatch = false;
  const userRoles = this.decodedToken.role as Array<string>;
  allowedRoles.forEach(element => {
    if (userRoles.includes(element)) {
      isMatch = true;
      return;
    }
  });
  return isMatch;
}
}
