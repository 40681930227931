// declare const $;

import {SystemSettingsByTypeModel} from '../../private-modules/modules/profile-module/models';
import {AppApi} from '../shared-constants';
import {UserDetailModel} from '../shared-model';

export const isEmpty = (obj): boolean =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

export const appLogger = (value: any) => {
    console.log(value);
};

export function copyObject<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function removeEmptyFields(params: any) {
    for (const field of Object.keys(params)) {
        if (params.hasOwnProperty(field)) {
            if (Array.isArray(params[field])) {
                if (checkEmptyValue(params[field])) {
                    delete params[field];
                } else {
                    params[field].forEach(record => {
                        removeEmptyFields(record);
                    });
                    params[field] = params[field].filter(
                        value => !checkEmptyValue(value)
                    );
                    if (checkEmptyValue(params[field])) {
                        delete params[field];
                    }
                }
            } else if (typeof params[field] === 'object') {
                if (checkEmptyValue(params[field])) {
                    delete params[field];
                } else {
                    removeEmptyFields(params[field]);
                    if (checkEmptyValue(params[field])) {
                        delete params[field];
                    }
                }
            } else {
                if (checkEmptyValue(params[field])) {
                    delete params[field];
                }
            }
        }
    }
    return params;
}

export function checkEmptyValue(value) {
    if (value === null || value === undefined) {
        return true;
    } else if (typeof value === 'string' && value === '') {
        return true;
    } else if (Array.isArray(value) && value.length === 0) {
        return true;
    } else if (
        value.constructor === Object &&
        Object.entries(value).length === 0
    ) {
        return true;
    }
    return false;
}

export function isValidImageType(filename: string): boolean {
    return (
        filename === 'image/png' ||
        filename === 'image/jpeg' ||
        filename === 'image/jpg'
    );
}

export function getNowUTC(_date: Date) {
    const dateObj = new Date(_date);
    const currentDate = new Date();
    console.log(currentDate.getHours());
    const hours = currentDate.getHours();
    console.log(hours);
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), hours, currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
}

export function sendDate(_date: Date) {
    const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    console.log(clientTimezoneOffset);
    const dateObj = new Date(_date);
    dateObj.setHours(0 + (-clientTimezoneOffset), 0, 0, 0);
    return dateObj;
}

export function getSystemSettingsArray(array: SystemSettingsByTypeModel[]) {
    let returnArray = [];
    array.forEach(record => {
        if (record['app_settings'] && record['app_settings'].length > 0) {
            returnArray = [...returnArray, ...record['app_settings']];
        } else {
            returnArray.push(record);
        }
    });
    return returnArray;
}

export function getUserInitialOfName(userDetails: UserDetailModel) {
    const firstNameInitial = userDetails.first_name.charAt(0);
    const lastNameInitial = userDetails.last_name.charAt(0);
    return firstNameInitial + lastNameInitial;
}

export function getUserProfileImage(token): string {
    const params = {
        access_token: token,
        time: new Date().getTime()
    };
    return AppApi.USER_PROFILE_IMAGE + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
}
