import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentService } from 'app/shared/services/comment.service';
import { HttpStatus } from 'app/utility/shared-constants';
import { appLogger } from 'app/utility/shared-functions';
import { IServiceLookup } from 'app/utility/shared-model/serviceLookup.model';
import { SharedService } from 'app/utility/shared-services';
import { ConfirmationDialogComponent, FormBaseComponent } from '..';
import { CommentDetailsComponent } from '../comment-details/comment-details.component';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent extends FormBaseComponent implements OnInit, OnChanges {

    @Input() taskCardDetail;
    commentForm: FormGroup;
    updateComment: FormControl = new FormControl('');
  
    isShowCommentButton = false;
  
    userData = null;
    selectedComment = null;
    commentList = [];

  constructor(private fb: FormBuilder,
    private _sharedService: SharedService,
    private _commentService: CommentService,
    @Inject(MAT_DIALOG_DATA) public data: IServiceLookup,
    private dialog: MatDialog) 
    {
        super(fb);
    }

  ngOnInit(): void {
    // appLogger( "Service ID: " + this.data.service_id );
    this.userData = this._sharedService.getUser();
    this.createCommentForm();
    this.getCommentList();
  }

  ngOnChanges(): void {
    if (this.data) {
      this.commentList = [];
      this.getCommentList();
    }
  }

  createCommentForm = () => {
    this.commentForm = this.createForm({
      comment: ["", [Validators.required]],
    });
  };

  getCommentList = () => {
    this._commentService.getCommentsByServiceId(this.data.service_id).subscribe(response => {
      this.commentList = response && response['data'] ? response['data'] : [];
      this.commentList = this.commentList.filter(elem => elem.deleted == 0);
    })
  }

  onSubmitCommentForm = (form: FormGroup) => {
    if (this.onSubmit(form)) {
      const params = this.prepareRequestParams(form.value);
      this._commentService.addComment(params).subscribe(response => {
        if (response && response['status'] === HttpStatus.SUCCESS) {
          this.isShowCommentButton = false;
          this.getCommentList();
          this.commentForm.reset();
        }
      })
    }
  };

  prepareRequestParams = (formValue) => {
    const params = {
      "userId": this.userData.id,
      "app_id": this.data.app_id,
      "service_id": this.data.service_id,
      "assigned_user_id": this.userData.id,
      "name": this.userData.first_name + ' ' + this.userData.last_name,
      "description": formValue.comment,
      "type": "101",
      "code": this.data.code,
      "color": this.data.color,
      "icon": null,
      "priority": '100',
      "sequence": this.commentList.length + 1,
      "status": 1,
      "access_modifier": '101'
    }

    return params;
  }

  onFocusComment = () => {
    this.isShowCommentButton = true;
  };

  onHideCommentForm = () => {
    this.isShowCommentButton = false;
  };

  getDate = (date) => {
    return date ? new Date(date.toString() + 'z') : '';
  }

  onEditComment = (comment) => {
    this.selectedComment = comment;
    this.updateComment.setValue(this.selectedComment.name);
  }

  onArchiveComment = (comment) => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      panelClass: 'phone-responsive',
      data: {
        message: `Are you sure want to Delete Comment?`,
        title: `Are you sure?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.submit) {
        this._commentService.archive(comment.id).subscribe(response => {
          this.getCommentList();
        })
      }
    });
  }

  onUpdateComment = () => {
    this.selectedComment['name'] = this.updateComment.value;
    this.selectedComment['userId'] = this.userData.id;
    this.selectedComment['assigned_user_id'] = this.userData.id;
    this.selectedComment['description'] = this.userData.first_name + ' ' + this.userData.last_name,
    delete(this.selectedComment['date_entered']);
    delete(this.selectedComment['date_modified']);

    this._commentService.updateComment(this.selectedComment).subscribe(() => {
      this.getCommentList();
      this.selectedComment = null;
      this.updateComment.setValue('');
    })
  }

  onCancelComment = () => {
    this.selectedComment = null;
    this.updateComment.setValue('');
  }

  getNameChar = (user): string => {
    let name = '';
    if (user && user.name) { 
      const names = user.name.split(" ");
      if (names[0] && names[1]) {
        name = names[0].charAt(0) + names[1].charAt(0);
      } else {
          name = names[0].charAt(0)
      }
    } else if (user && user.first_name && user.last_name) {
      name = user.first_name.charAt(0) + user.last_name.charAt(0)
    } else if (user && user.email) {
      name = user.email.substring(0, 2);
    }
    return name;
  }
}
