import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {SharedService} from './shared.service';
import {catchError, finalize, tap} from 'rxjs/operators';
import {isEmpty} from '../shared-functions';
import {HttpMethodsTypeEnum} from '../shared-constants';

@Injectable()
export class HttpHelperService {

    constructor(protected _sharedService: SharedService,
                protected http: HttpClient) {
    }

    protected httpHelperMethod(methodType: HttpMethodsTypeEnum, url: string, params = {}, httpOptions,
                               showToast: boolean, showLoader: boolean, searchParams, filesObj: any) {
        return this.apiCall(methodType, url, params, httpOptions, searchParams)
            .pipe(
                tap((response: any) => {
                    if (response && response.message) {
                        this.setToastMessage(response, showToast);
                    }
                    return response || {};
                }),
                catchError(
                    this.handleError('', [])
                ),
                finalize(() => {
                    if (showLoader) {
                        this._sharedService.setLoader(false);
                    }
                })
            );
    }

    private apiCall(
        methodType: HttpMethodsTypeEnum,
        url: string,
        params = {},
        httpOptions,
        searchParams = {}
    ): Observable<any> {
        switch (methodType) {
            case HttpMethodsTypeEnum.GET:
                return this.http.get<any>(
                    this.prepareEndpoint(url, params, searchParams),
                    httpOptions
                );
                break;
            case HttpMethodsTypeEnum.POST:
            case HttpMethodsTypeEnum.POST_MULTIPART:
                return this.http.post<any>(url, params, httpOptions);
                break;
            case HttpMethodsTypeEnum.PUT:
            case HttpMethodsTypeEnum.PUT_MULTIPART:
                return this.http.put<any>(url, params, httpOptions);
                break;
            case HttpMethodsTypeEnum.DELETE:
                return this.http.delete(this.prepareEndpoint(url, params), httpOptions);
                break;
        }
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            return throwError(error);
        };
    }

    private prepareEndpoint(endPoint: string, params: any, searchParams = {}) {
        if (!isEmpty(searchParams)) {
            params['search'] = JSON.stringify(searchParams);
        }
        if (Object.keys(params).length) {
            if (params) {
                endPoint += '?';
            }
            let count = 0;
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    endPoint += (count > 0) ? `&${key}=${params[key]}` : `${key}=${params[key]}`;
                    count++;
                }
            }
        }
        return endPoint;
    }

    private setToastMessage(res: any, show?: boolean) {
        const msg = res.message;
        if (show && msg) {
            this._sharedService.setSnackBar(msg);
        }
    }
}
