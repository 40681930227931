<ul class="users-in-survey">

    <li *ngFor="let item of teamList?.team_members">
        <img matTooltip="{{item.name}}" [src]="item.icon" alt="" onError="this.src='assets/images/avatars/profile.jpg'"/>
    </li>

    <li>
        <a matTooltip="Add User" (click)="addTeam()">
            <span class="material-icons">
                add
            </span>
        </a>
    </li>
</ul>