<div class="header">
    <div class="header__logo">
        <a [routerLink]="homeUrl">
            <img src="../../../assets/images/auth/logo-tr.png" />
        </a>
    </div>

    <ul class="header__navigation">
        <li>
            <a [routerLink]="homeUrl" routerLinkActive="active">{{
                "SYSTEM_NAVIGATION.HOME" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(taskManagementUrl)">
            <a [routerLink]="projectListUrl" routerLinkActive="active">{{
                "TASK_MANAGEMENT.PROJECTS" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(fixAssetsUrl)">
            <a
                [routerLink]="dashboardFixAssetsUrl"
                routerLinkActive="active"
                *ngIf="router.url === dashboardFixAssetsUrl"
                >{{ "SYSTEM_NAVIGATION.DASHBOARD" | translate }}</a
            >
            <a
                [routerLink]="dashboardFixAssetsUrl"
                *ngIf="router.url !== dashboardFixAssetsUrl"
                >{{ "SYSTEM_NAVIGATION.DASHBOARD" | translate }}</a
            >
        </li>

        <!-- <li *ngIf="router.url.includes(fixAssetsUrl)">
            <a>{{ "HEADER.MY_ASSETS" | translate }}</a>
        </li> -->

        <li *ngIf="router.url.includes(fixAssetsUrl)">
            <a
                [routerLink]="fixAssetsUrl"
                routerLinkActive="active"
                *ngIf="router.url !== dashboardFixAssetsUrl"
                >{{ "SIDEBAR.FIX_ASSETS" | translate }}</a
            >
            <a
                [routerLink]="fixAssetsUrl"
                *ngIf="router.url === dashboardFixAssetsUrl"
                >{{ "SIDEBAR.FIX_ASSETS" | translate }}</a
            >
        </li>

        <li *ngIf="router.url.includes(fixAssetsUrl)">
            <a
                [routerLink]="financialDetailsUrl"
                routerLinkActive="active"
                *ngIf="router.url === financialDetailsUrl"
                >{{ "FIX_ASSET.FINANCIAL_DETAILS" | translate }}</a
            >
            <a
                [routerLink]="financialDetailsUrl"
                *ngIf="router.url !== financialDetailsUrl"
                >{{ "FIX_ASSET.FINANCIAL_DETAILS" | translate }}</a
            >
        </li>

        <li *ngIf="_currentRoute === _routeConstant">
            <a [routerLink]="taskManagementUrl">{{
                "HEADER.MY_TASKS" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(taskManagementUrl)">
            <a [routerLink]="myTaskListUrl" routerLinkActive="active">{{
                "HEADER.MY_TASKS" | translate
            }}</a>
        </li>

        <!-- <li *ngIf="router.url.includes(taskManagementUrl)">
            <a>
                {{ "TASK_MANAGEMENT.TO_DO" | translate }}
            </a>
        </li> -->

        <li *ngIf="router.url.includes(taskManagementUrl)">
            <a [routerLink]="calendarUrl">{{
                "SYSTEM_NAVIGATION.CALENDAR" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(surveyURL)">
            <a [routerLink]="surveyURL">{{
                "SYSTEM_NAVIGATION.MY_SURVEY" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(myFiledUrl)">
            <a [routerLink]="myFiledUrl">{{
                "MY_FILE.MY_FILES" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(ideaManagementUrl)">
            <a [routerLink]="ideaManagementUrl">{{
                "SYSTEM_NAVIGATION.MY_IDEAS" | translate
            }}</a>
        </li>

        <li *ngIf="router.url.includes(appSettingsUrl)">
            <a
                [routerLink]="internalAccountsUrl"
                routerLinkActive="active"
                *ngIf="router.url === internalAccountsUrl"
                >{{ "SYSTEM_NAVIGATION.INTERNAL_ACCOUNTS" | translate }}</a
            >
            <a
                [routerLink]="internalAccountsUrl"
                *ngIf="router.url !== internalAccountsUrl"
                >{{ "SYSTEM_NAVIGATION.INTERNAL_ACCOUNTS" | translate }}</a
            >
        </li>

        <li *ngIf="router.url.includes(appSettingsUrl)">
            <a [routerLink]="powerUserUrl" routerLinkActive="active"
               *ngIf="router.url !== appSettingsUrl">
               {{ "SYSTEM_NAVIGATION.USER_MANAGEMENT" | translate }}
            </a>

        </li>

        <li *ngIf="router.url.includes(accountManagementUrl)">
            <a
                [routerLink]="accountsUrl"
                *ngIf="router.url === accountManagementUrl"
                routerLinkActive="active"
                >{{ "SIDEBAR.ACCOUNTS" | translate }}</a
            >
            <a
                [routerLink]="accountsUrl"
                *ngIf="router.url !== accountManagementUrl"
                >{{ "SIDEBAR.ACCOUNTS" | translate }}</a
            >
        </li>

        <li *ngIf="router.url.includes(accountManagementUrl)">
            <a [routerLink]="ProspectsUrl" routerLinkActive="active">{{
                "APP_SETTINGS.PROSPECTS" | translate
            }}</a>
        </li>

        <!-- <li *ngIf="router.url.includes(appSettingsUrl)">
            <a>{{ "SYSTEM_NAVIGATION.PROSPECT_ACCOUNTS" | translate }}</a>
        </li>

        <li *ngIf="router.url.includes(appSettingsUrl)">
            <a>{{ "SYSTEM_NAVIGATION.CUSTOMER_ACCOUNTS" | translate }}</a>
        </li> -->

        <!-- <li *ngIf="router.url.includes(appSettingsUrl)">
            <a [routerLink]="powerUserUrl" routerLinkActive="active"
               *ngIf="router.url !== appSettingsUrl">{{ "SYSTEM_NAVIGATION.USER_MANAGEMENT" | translate }}</a>
            <a [routerLink]="powerUserUrl"
               *ngIf="router.url === appSettingsUrl">{{ "SYSTEM_NAVIGATION.USER_MANAGEMENT" | translate }}</a>
        </li> -->
    </ul>

    <div class="header__action">
        <!-- Commented out on 2021-10-08. Will be activated on later releases based on the requirements -->
        <!-- <div class="header__user-details header__tenant-menu" [matMenuTriggerFor]="menuTenant">
            <span class="material-icons">
                manage_accounts
            </span>
            <div>{{ "SYSTEM_NAVIGATION.TENANTS" | translate }}</div>
            <span class="material-icons"> arrow_drop_down </span>

            <mat-menu class="action-menu-header" #menuTenant="matMenu">
                <button (click)="onAppSettings()" type="button" mat-menu-item>
                    Pepsico
                </button>
                <button (click)="onAppSettings()" type="button" mat-menu-item>
                    Mac D
                </button>
            </mat-menu>
        </div> -->

        <ul>
            <li>
                <a
                    (click)="onClickActionMenu(headerEnum.REMINDER)"
                    class="header__action__menu-home"
                >
                    <span class="material-icons">event</span>
                    <label *ngIf="reminders.unread$ | async as count">{{
                        count
                    }}</label>
                </a>
            </li>

            <li>
                <a
                    (click)="onClickActionMenu(headerEnum.NOTIFICATION)"
                    class="header__action__menu-home"
                >
                    <span class="material-icons">notifications_none</span>
                    <label *ngIf="notifications.unread$ | async as count">{{
                        count
                    }}</label>
                </a>
            </li>

            <li>
                <a
                    (click)="onClickActionMenu(headerEnum.MESSAGES)"
                    class="header__action__menu-home"
                >
                    <span class="material-icons">chat_bubble_outline</span>
                    <label>2</label>
                </a>
            </li>

            <li>
                <a
                    (click)="onClickActionMenu(headerEnum.NAVIGATION)"
                    class="header__action__menu-home"
                >
                    <span class="material-icons">apps</span>
                </a>
            </li>
        </ul>

        <div
            class="layer-hide-dropdown"
            (click)="onHideActionMenu()"
            *ngIf="
                activeAction === headerEnum.NAVIGATION ||
                activeAction === headerEnum.REMINDER ||
                activeAction === headerEnum.NOTIFICATION ||
                activeAction === headerEnum.MESSAGES
            "
        ></div>

        <div class="header__language">
            <button type="button" mat-button [matMenuTriggerFor]="menu">
                <div
                    class="flag-circle"
                    *ngIf="selectedLanguageObj && selectedLanguageObj?.css_icon"
                >
                    <img
                        [src]="
                            'assets/icons/flags/' +
                            selectedLanguageObj?.css_icon
                        "
                        alt=""
                    />
                </div>
                <span>{{ selectedLanguageObj?.name }}</span>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    type="button"
                    mat-menu-item
                    *ngFor="let language of languageList"
                    [value]="language?.code"
                    (click)="setLanguage(language?.code)"
                >
                    {{ language?.description }}
                </button>
            </mat-menu>
        </div>

        <div class="header__user-details" [matMenuTriggerFor]="menuAction">
            <img
                (error)="showInitialsImage()"
                [src]="imageUrl"
                alt=""
                *ngIf="!showInitials"
            />
            <div class="status-position-profile">
                <span class="material-icons-outlined">
                    {{ currentStatus?.icon }}
                </span>
            </div>
            <div class="initial-letters" *ngIf="showInitials">
                {{ getInitialOfName() }}
            </div>
            <span class="material-icons"> arrow_drop_down </span>

            <mat-menu class="action-menu-header" #menuAction="matMenu">
                <button
                    class="user-status"
                    type="button"
                    mat-menu-item
                    [matMenuTriggerFor]="userStatus"
                >
                    <span class="material-icons-outlined">
                        {{ currentStatus?.icon }}
                    </span>
                    {{ currentStatus?.name }}
                </button>

                <mat-menu #userStatus="matMenu" class="user-status">
                    <button
                        type="button"
                        mat-menu-item
                        type="button"
                        *ngFor="let status of userStatusList"
                        (click)="updateUserStatus(status)"
                    >
                        <span class="material-icons-outlined">
                            {{ status?.icon }}
                        </span>
                        {{ status?.name }}
                    </button>
                </mat-menu>

                <button (click)="onProfileClick()" type="button" mat-menu-item>
                    <span class="material-icons"> portrait </span>
                    {{ "SYSTEM_NAVIGATION.MY_PROFILE" | translate }}
                </button>
                <!-- <button type="button" mat-menu-item>
                    <span class="material-icons"> person_outline </span>
                    {{ "HEADER.MY_ACCOUNTS" | translate }}
                </button> -->
                <!-- <button
                    (click)="onSystemSettingClick()"
                    type="button"
                    mat-menu-item
                >
                    <span class="material-icons"> settings </span>
                    System Settings
                </button> -->
                <button
                    (click)="onInternalAccounts()"
                    type="button"
                    mat-menu-item
                >
                    <span class="material-icons"> settings </span>
                    {{ "APP_SETTINGS.APP_SETTING" | translate }}
                </button>
                <button type="button" mat-menu-item (click)="onLogout()">
                    <span class="material-icons"> logout </span>
                    {{ "SYSTEM_NAVIGATION.LOGOUT" | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
</div>

<div *ngIf="activeAction === headerEnum.REMINDER">
    <app-reminders (hideMenu)="onHideActionMenu()"></app-reminders>
</div>

<div *ngIf="activeAction === headerEnum.NOTIFICATION">
    <app-notification></app-notification>
</div>

<div *ngIf="activeAction === headerEnum.MESSAGES">
    <app-messages></app-messages>
</div>

<div *ngIf="activeAction === headerEnum.NAVIGATION">
    <app-header-navigations
        (hideMenu)="onHideActionMenu()"
    ></app-header-navigations>
</div>
