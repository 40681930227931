<div class="modal-view">
    <div class="row">
        <div class="col-md-12 PLR-0">
            <h2 *ngIf="!surveyStatus.includes('Edit')">{{ "TASK_MANAGEMENT.CREATE_TEAM" | translate }}</h2>
            <h2 *ngIf="surveyStatus.includes('Edit')">{{ "TASK_MANAGEMENT.EDIT_TEAM" | translate }}</h2>
        </div>
    </div>
    
    <form [formGroup]="surveyAddForm" (submit)="onSurveyAddSubmit(surveyAddForm)">
        <div class="row">
            <div class="col-md-12 PLR-0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "TASK_MANAGEMENT.TEAM" | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="name"
                            [(ngModel)]="surveyTeamObj.name"
                            placeholder="{{ 'TASK_MANAGEMENT.ENTER_TEAM_NAME' | translate }}"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 PLR-0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "IDEA.DESCRIPTION" | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="description"
                            [(ngModel)]="surveyTeamObj.description"
                            placeholder="{{'IDEA.DESCRIPTION' | translate}}"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 PLR-0">
                <div class="build-team-container">
                    <div class="build-team">
                        <div class="build-team__body">
                            <div class="row">
                                <div class="col-md-12 PL-0 mb-16">
                                    <h3>{{ "SURVEY_MODULES.MEMBER_LIST" | translate }}</h3>
                                </div>
                            </div>
                            <div class="row MB-2">
                                <div class="col-md-9 PL-0">
                                    <div class="build-team__body__left">
                                        <ng-container *ngFor="let member of surveyTeamMembers">
                                            <div class="build-team__body__left__img">
                                                <img [src]="member.icon" matTooltip="{{member.name}}" onError="this.src='assets/images/avatars/profile.jpg'" (click)="editTeamMember(member)">
                                                <a class="close" (click)="deleteMember(member)">X</a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                
                                <div class="col-md-3 PR-0 text-right footer-menu">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" type="button">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="addTeamMember()">
                                            <mat-icon>add</mat-icon>
                                            <span>{{ "BOTTON.ADD" | translate }}</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 PLR-0">
                <button type="submit" mat-flat-button color="primary">
                    {{ "BOTTON.SAVE" | translate }}
                </button>
                <button (click)="onCloseDialog()" type="button" mat-flat-button color="secondary" class="ML-2">
                    {{ "BOTTON.CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>