import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { PaginatedResult } from 'app/shared/models/pagination';
import { User } from 'app/public-modules/modules/auth/components/shared/model/user.model';
import { AppApi, HttpMethodsTypeEnum } from 'app/utility';
import { APIManager, SharedService } from 'app/utility/shared-services';

@Injectable({
  providedIn: 'root'
})

export class TenantAuthDotCoreService {
  
constructor( 
    private http: HttpClient, 
    protected _sharedService: SharedService,
    private _apiManager: APIManager) {}

    getUserLookupByTenantId(id): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.LOOKUP_USER_BY_TENANT_ID, {tenantId: id},
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    loadTenantDefault(): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.GET, AppApi.TENANT_DETAILS_BY_DOMAIN, { 'name': 'maksellent.com' },
            this._apiManager.Authorised_HttpOptions, false, true
        )
    }

}
