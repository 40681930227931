import {Injectable} from '@angular/core';
import {EncryptionFunctions} from '../shared-functions';
import {APPStorage} from '../shared-constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {SharedUserService} from './shared-user.service';
import {AccessTypeModel} from '../../shared/models/access-type.model';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class SharedService extends SharedUserService {
    private token = '';
    private language: string;
    private _VToken = '';
    private isLoginRequired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loaderCount = 0;
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private initiateSnackBar: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private languageChange: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private profileImageChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private userDetailsChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private userStatusList: BehaviorSubject<AccessTypeModel[]> = new BehaviorSubject<AccessTypeModel[]>([]);

    constructor(private dialogRef: MatDialog) {
        super();
    }

    /*Shared User Token Param */
    isLoggedIn(): boolean {
        return this.getToken() ? this.IsValidToken(this.getToken()) : false;
    }

    IsValidToken(token: string): boolean {
        return !!token;
    }

    /* Store token */
    getToken(): string {
        this.token = EncryptionFunctions.DECRYPT_OBJ(
            sessionStorage.getItem(APPStorage.TOKEN) ? sessionStorage.getItem(APPStorage.TOKEN) : localStorage.getItem(APPStorage.TOKEN)
        );
        return this.token;
    }

    setSessionToken(value: string): void {
        sessionStorage.setItem(
            APPStorage.TOKEN,
            EncryptionFunctions.ENCRYPT_OBJ(value)
        );
        this.token = value;
    }

    setLocalToken(value: string): void {
        localStorage.setItem(
            APPStorage.TOKEN,
            EncryptionFunctions.ENCRYPT_OBJ(value)
        );
        this.token = value;
    }

    /* Store language */
    getLanguageValue(): string {
        this.language = EncryptionFunctions.DECRYPT_OBJ(
            sessionStorage.getItem(APPStorage.LANGUAGE)
        );
        return this.language;
    }

    setLanguageValue(value: string): void {
        sessionStorage.setItem(
            APPStorage.LANGUAGE,
            EncryptionFunctions.ENCRYPT_OBJ(value)
        );
        this.language = value;
    }

    /* Store status list */
    getStatusList(): Observable<AccessTypeModel[]> {
        return this.userStatusList.asObservable();
    }

    setStatusList(val: AccessTypeModel[]): void {
        this.userStatusList.next(val);
    }

    // To get & set profile image change
    getProfileImageChange(): Observable<boolean> {
        return this.profileImageChange.asObservable();
    }

    setProfileImageChange(val: boolean): void {
        this.profileImageChange.next(val);
    }

    // To get & set user details change
    getUserDetailsChange(): Observable<boolean> {
        return this.userDetailsChange.asObservable();
    }

    setUserDetailsChange(val: boolean): void {
        this.userDetailsChange.next(val);
    }

    // To get & set language change
    getLanguageChange(): Observable<string> {
        return this.languageChange.asObservable();
    }

    setLanguageChange(val: string): void {
        this.languageChange.next(val);
    }

    // To get & set logged in status
    getLoginRequired(): Observable<boolean> {
        return this.isLoginRequired.asObservable();
    }

    setLoginRequired(val: boolean): void {
        this.isLoginRequired.next(val);
    }

    // Stored verification token
    getVerificationToken(): string {
        this._VToken = EncryptionFunctions.DECRYPT_OBJ(
            sessionStorage.getItem(APPStorage.VERIFICATION_TOKEN)
        );
        return this._VToken;
    }

    setVerificationToken(value: string): void {
        sessionStorage.setItem(
            APPStorage.VERIFICATION_TOKEN,
            EncryptionFunctions.ENCRYPT_OBJ(value)
        );
        this._VToken = value;
    }

    // return Observable of loading
    getLoader(): Observable<boolean> {
        return this.isLoading.asObservable();
    }

    setLoader(val: boolean): void {
        if (val) {
            this.loaderCount += 1;
        } else {
            this.loaderCount -= 1;
            if (this.loaderCount !== 0) {
                val = true;
            }
        }
        this.isLoading.next(val);
    }

    // Snack bar
    getSnackBar(): Observable<string> {
        return this.initiateSnackBar.asObservable();
    }

    setSnackBar(val: string): void {
        this.initiateSnackBar.next(val);
    }

    // Clear session
    clearSession() {
        this.setLocalToken(null);
        this.setSessionToken(null);
        this.setLocalUser(null);
        this.setSessionUser(null);
        this.setLoginRequired(false);
        /*        this.setSignUpCode(null);
                this.setRefreshToken(null);
                this.setUser(null);
                this.setIsLoggedInUser(false);*/
        const language = this.getLanguageValue();
        localStorage.clear();
        sessionStorage.clear();
        this.setLanguageValue(language);
        this.dialogRef.closeAll();
    }
}
