import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AppApi, ConfirmationDialogComponent, FormBaseComponent, GeneralStatusEnum, HttpMethodsTypeEnum, HttpStatus, MY_FORMATS } from "app/utility";
import { APIManager, SharedService, SharedUserService } from 'app/utility/shared-services';
import { Observable } from 'rxjs';
import { UserDetailModel } from 'app/utility/shared-model/users.model';
import { AssetAccountsDotCoreService } from 'app/private-modules/modules/fix-assets-module/services/fix-assets-account.dotcore.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { FixAssetDotCoreService } from 'app/private-modules/modules/fix-assets-module/services/fix-asset.dotcore.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { SurveyCreateModel } from '../../../models/survey/survey-create.model';
import { SurveyDotCoreService } from '../../../services/survey.dotcore.service';
import { TenantAuthDotCoreService } from '../../../services/user.dotcore.service';
import { copyPropsSurveyTeamsObject } from '../../../util/survey-utils';
import { SurveyListTeamAddMemberComponent } from '../survey-list-team-add-member/survey-list-team-add-member.component';
import { TeamDotCoreService } from '../../../services/team.dotcore.service';
import { SurveyTeamCreateModel } from '../../../models/survey-team/survey-team-create.model';

@Component({
    selector: 'app-survey-list-team-add',
    templateUrl: './survey-list-team-add.component.html',
    styleUrls: ['./survey-list-team-add.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ],
})
export class SurveyListTeamAddComponent extends FormBaseComponent implements OnInit {

    // Form group variables
    surveyAddForm: FormGroup;

    // Const Variable
    surveyStatus = 'New';
    userDetail: UserDetailModel;

    // Dynamic Variables
    surveyTeamObj: any = {};
    surveyTeamMembers: any = [];
    tempSurveyTeamMembers: any = [];
    assignedUserId: any = [];
    srvAccessModifier: any = [];
    tenantId: string;
    userDetails: string;
    
    constructor(fb: FormBuilder,
                public dialogRef: MatDialogRef<SurveyListTeamAddComponent>,
                private modalService: MatDialog,
                private _translateService: TranslateService,
                private _sharedUserService: SharedUserService,
                private _userService: TenantAuthDotCoreService,
                private _apiManager: APIManager,
                private _surveyService: SurveyDotCoreService,
                private _surveyTeamService: TeamDotCoreService,
                public dialog: MatDialog,
                private sharedService: SharedService,
                private notificationService: NotificationService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super(fb);
        console.log(data?.type);
    }

    ngOnInit(): void {
        
        this.userDetail = this.sharedService.getUser();
        if (this.data?.type.includes('Edit')) {
            this.surveyStatus = 'Edit';
            this.surveyTeamObj = this.data?.data;
            this.surveyTeamMembers = this.surveyTeamObj?.team_members;
            this.tempSurveyTeamMembers = this.data?.data?.team_members;
            console.log(this.surveyTeamObj);
        } else {
            this.surveyStatus = 'New';
            this.surveyTeamObj = new SurveyTeamCreateModel();
            this.surveyTeamObj.color = '#ffffff';
            this.surveyTeamObj.service_id = this.data?.serviceId;
            this.surveyTeamObj.assigned_user_id = this.userDetail.id;
            this.surveyTeamObj.userId = this.userDetail.id;
            this.surveyTeamObj.type = '103';
            this.surveyTeamObj.access_modifier = 103;
            this.surveyTeamObj.sequence = 1;
            this.surveyTeamObj.code = '';
            this.surveyTeamObj.icon = '';
            this.surveyTeamObj.priority = 0;
            this.surveyTeamObj.status = 1;
            this.surveyTeamMembers = []
        }
        this.initialize();

        this.loadAccessModifierLookup();
        
        this._userService.loadTenantDefault().subscribe((response) => {
            this.tenantId = response?.data?.id;
            this._userService.getUserLookupByTenantId(this.tenantId).subscribe(responseAssignedUser => {
                this.assignedUserId = responseAssignedUser?.data;
            })
        })
    }

    
    get formControls() {
        return this.surveyAddForm.controls;
    }

    initialize = () => {
        this.createModuleForm();
        this.userDetail = this._sharedUserService.getUser();

        
    };

    loadAssignedUser(tenantId) {
        this._userService.getUserLookupByTenantId(tenantId).subscribe(response => {
            this.assignedUserId = response?.data;
        })
    }

    loadAccessModifierLookup(){
        this._surveyService.getLookupAccessModifier().subscribe((response) => {
            this.srvAccessModifier = response?.data
        })
    }
    
    createModuleForm = () => {
        this.surveyAddForm = this.createForm({
            name: [''],
            description: [''],
        });
        
    };

    onSurveyAddSubmit = (form: FormGroup) => {
        const controls = this.surveyAddForm.controls;
        const user = this._sharedUserService.getUser();
        console.log(user);
        if (this.onSubmit(form)) {
            let params = {};
            params = copyPropsSurveyTeamsObject(this.surveyTeamObj, this.surveyStatus);
            console.log(params);
            this.saveSurvey(params).subscribe((response) => {
                let teamId = '';
                if (this.surveyStatus === 'New'){
                    teamId = response?.data;
                    this.surveyTeamObj.id = response?.data
                } else {
                    teamId = this.surveyTeamObj?.id;
                }
            })
        }
    };

    saveSurvey(param): Observable<any> {
        if (this.surveyStatus === 'Edit') {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.PUT, AppApi.UPDATE_TEAM, param,
                this._apiManager.Authorised_HttpOptions, true, true);
        } else {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.POST, AppApi.ADD_TEAM, param,
                this._apiManager.Authorised_HttpOptions, true, true);
        }
    }

    onCloseDialog = () => {
        this.dialogRef.close();
    };

    addTeamMember() {
        const dialogRef = this.modalService.open(SurveyListTeamAddMemberComponent, {
            width: '500px',
            panelClass: 'dialog-app-survey-basic-details-create',
            data: {type: 'New', data: this.surveyTeamObj, srvTeam: {}}
            
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.ngOnInit();
            this._surveyTeamService.getTeamById(this.surveyTeamObj?.id).subscribe((response) => {
                this.surveyTeamObj = response?.data[0];
                this.surveyTeamMembers = this.surveyTeamObj?.team_members;
                this.tempSurveyTeamMembers = this.surveyTeamObj?.team_members;
            })
        });
    }

    editTeamMember(member) {
        const dialogRef = this.modalService.open(SurveyListTeamAddMemberComponent, {
            width: '500px',
            panelClass: 'dialog-app-survey-basic-details-create',
            data: {type: 'Edit', data: this.surveyTeamObj, srvTeam: member}
            
        });

        dialogRef.afterClosed().subscribe(result => {
            this._surveyTeamService.getTeamById(this.surveyTeamObj?.id).subscribe((response) => {
                this.surveyTeamObj = response?.data[0];
                this.surveyTeamMembers = this.surveyTeamObj?.team_members;
                this.tempSurveyTeamMembers = this.surveyTeamObj?.team_members;
            })
        });
    }

    deleteMember(member){
        this.showConfirmationDialog(member?.name, 'Release Member ' ).afterClosed().subscribe(result => {
            if (result.submit){
                this._surveyTeamService.deleteTeamMember(member?.id).subscribe((response) => {
                    this.notificationService.success('Member Sucessfully Released');
                    this.surveyTeamMembers.splice(this.surveyTeamMembers.indexOf(member, 1));
                });
            }
        });
    }

    showConfirmationDialog(value, title): MatDialogRef<ConfirmationDialogComponent, any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            message: `Are you sure want to ` + title + value + `?`,
            title: `Are you sure?`,
          },
        });
        return dialogRef;
    }

}
