import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthDotCoreService } from 'app/public-modules/modules/auth/components/shared/service/auth-dotcore.service';
import { PaginatedResult } from 'app/shared/models/pagination';
import { MatDialog } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { SurveyAddComponent } from '../survey-add/survey-add.component';
import { SurveyModel } from '../../models/survey/survey.model';
import { Router } from '@angular/router';
import { SurveyDotCoreService } from '../../services/survey.dotcore.service';
import { APIManager } from 'app/utility/shared-services';
import { AppApi, appLogger, CommentUpvoteViewEnum, HttpMethodsTypeEnum } from 'app/utility';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AccessTypeModel } from 'app/shared/models/access-type.model';
import { StorageService } from 'app/shared/helpers/storage.service';


@Component({
    selector: 'app-survey-list',
    templateUrl: './survey-list.component.html',
    styleUrls: ['./survey-list.component.scss'],
})
export class SurveyListComponent implements OnInit {

    // State variables
    isShowView;
    isShowFilter = false;

    // Constant variables
    commentUpvoteViewEnum = CommentUpvoteViewEnum;

    surveyList: SurveyModel[] = [];
    accessModifierList: AccessTypeModel[] = [];
    userParams: any = {};
    page = 0;
    filterCounts = 0;
    closeResult = '';
    @ViewChild('matPaginator', { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;

    dataSource: MatTableDataSource<SurveyModel>;

    currentLanguage = 'en';

    constructor(
        private surveyService: SurveyDotCoreService,
        private authService: AuthDotCoreService,
        private _surveyService: SurveyDotCoreService,
        private _translateService: TranslateService,
        private _storageService: StorageService,
        private modalService: MatDialog,
        public dialogAnimation: NgDialogAnimationService,
        private _apiManager: APIManager,
        private router: Router) { }

    ngOnInit(): void {
        if (this._storageService.getString('currentLanguage'))
            this.currentLanguage = JSON.parse(this._storageService.getString('currentLanguage'));
        else
            this.currentLanguage = this._translateService.currentLang;
        this.userParams.name = '';
        this.userParams.orderBy = '';
        this.userParams.pageNumber = this.paginator?.pageIndex ? this.paginator?.pageIndex : 0;
        this.userParams.pageSize = this.paginator?.pageSize === 0 || this.paginator?.pageSize === undefined ? 30 : this.paginator?.pageSize;
        this.userParams.assigned_user_id = '';
        this.userParams.name = '';
        this.userParams.srvid = '';
        this.userParams.srvStart = '';
        this.userParams.srvEnd = '';
        this.userParams.oid = '';
        this.userParams.aid = '';
        this.userParams.tid = '';
        this.userParams.status = '';

        this.loadSurveys(this.userParams).subscribe((response) => {
            this.surveyList = response?.data;
            // Load Access Modifier List from System Settings
            this.loadAccessModifierLookup();
        }, error => {
            console.log(error)
        });
    }

    resetFilter() {
        this.filterCounts = 0;
        this.ngOnInit();
    }

    applyFilter(filterParams) {
        this.userParams.name = filterParams.name;
        this.userParams.orderBy = filterParams.orderBy;
        this.userParams.pageNumber = 0;
        this.userParams.pageSize = this.paginator.pageSize === 0 || this.paginator.pageSize === undefined ? 30 : this.paginator.pageSize;
        this.userParams.assigned_user_id = filterParams.assigned_user_id;
        this.userParams.srvid = filterParams.srvid;
        this.userParams.srvStart = filterParams.srvStart;
        this.userParams.srvEnd = filterParams.srvEnd;
        this.userParams.oid = filterParams.oid;
        this.userParams.aid = filterParams.aid;
        this.userParams.tid = filterParams.tid;
        this.userParams.status = filterParams.status;

        this.filterCounts = filterParams.countFilter;

        this.loadSurveys(this.userParams).subscribe((response) => {
            this.surveyList = response.data;
            // appLogger(this.currentLanguage);
        }, error => {
            console.log(error)
        });
    }

    loadSurveys(params): Observable<any> {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.POST, AppApi.SURVEY_LIST, params,
            this._apiManager.Authorised_HttpOptions, true, true);

    }

    loadAccessModifierLookup(){
        this._surveyService.getLookupAccessModifier().subscribe((response) => {
            this.accessModifierList = response?.data;
            // appLogger(this.accessModifierList);
        })
    }

    onScroll(): Observable<any> {
        this.userParams.pageNumber = this.paginator.pageIndex++;
        return this.loadSurveys(this.userParams);
    }

    openModal(content) {
        this.dialogAnimation.open(content,
            {
                'position': {
                    'right': '0'
                },
                'height': '100%',
                'width': '60rem',
                'animation': {
                    'to': 'left',
                    'incomingOptions': {
                        'keyframeAnimationOptions': {
                            'duration': 300,
                            'easing': 'steps(7, end)'
                        }
                    }
                }
            }
        )
    }

    onSurveyViewDetails = (view: CommentUpvoteViewEnum) => {
        this.isShowView = view;
    };

    goToDetails(id) {
        this.router.navigate(['/survey/detail/basic-detail'], { queryParams: { id: id } })
    }

    goToPreview(id) {
        this.router.navigate(['/survey/detail/preview'], { queryParams: { id: id } })
    }

    goToResponse(id) {
        this.router.navigate(['/survey/detail/response'], { queryParams: { id: id } })
    }

    deleteSurvey(id) {
        this.saveDeleteSurvey(id).subscribe((response) => {
            this.loadSurveys(this.userParams).subscribe((response) => {
                this.surveyList = response.data;
            }, error => {
                console.log(error)
            });
        })
    }

    saveDeleteSurvey(id) {
        return this._apiManager.httpHelperMethod(
            HttpMethodsTypeEnum.DELETE, AppApi.SURVEY_DELETE, { 'id': id },
            this._apiManager.Authorised_HttpOptions, true, true);
    }

    createNewSurvey = () => {
        const dialogRef = this.modalService.open(SurveyAddComponent, {
            width: '500px',
            panelClass: 'dialog-app-survey-basic-details-create'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();
        });
    }

    closeDialog() {
        this.modalService.closeAll();
    }

    toString(content):string {
        return content.toString();
    }
}
