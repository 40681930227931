
export class SurveyEditModel {
    id: string;
    account_id: string;
    organization_id: string;
    unit_id: string;
    location_id: string;
    zone_id: string;
    srv_type_id: string;
    reported_user_id: string;
    assigned_user_id?: any;
    name: string;
    description: string;
    size: number;
    budget: number;
    date_start: Date;
    date_end: Date;
    allow_user_to_see_result: boolean;
    enable_user_signature: boolean;
    enable_responder_signature: boolean;
    type: string;
    code: string;
    color: string;
    icon: string;
    sequence: number
    status: number;
    access_modifier: number;
    tenant_id: string;
    

    constructor(survey?) {
        survey = survey || {};
        this.id = survey.id;
        this.account_id = survey.account_id;
        this.organization_id = survey.organization_id;
        this.location_id = survey.location_id;
        this.zone_id = survey.zone_id;
        this.srv_type_id = survey.srv_type_id;
        this.reported_user_id = survey.reported_user_id;
        this.assigned_user_id = survey.assigned_user_id;
        this.name = survey.name;
        this.description = survey.description;
        this.size = survey.size;
        this.tenant_id = survey.tenant_id;
        this.budget = survey.budget;
        this.date_start = survey.date_start;
        this.date_end = survey.date_end;
        this.allow_user_to_see_result = survey.allow_user_to_see_result;
        this.enable_user_signature = survey.enable_user_signature;
        this.enable_responder_signature = survey.enable_responder_signature;
        this.type = survey.type;
        this.code = survey.code;
        this.color = survey.color;
        this.access_modifier = survey.access_modifier;
        this.status = survey.status;
    }
}