export const locale = {
    lang: 'tr',
    data: {
        LOGIN: {
            TITLE: 'MAKSELLENT Uygulamalarına Hoşgeldiniz!',
            DESCRIPTION: 'MAKSELLENT Uygulamaları ile artık işlerinizin yönetimi daha kolay',
            HEADER: 'HESABINIZA GİRİŞ YAPIN',
            EMAIL: 'E-posta',
            PASSWORD: 'Şifre',
            PASSCONFIRM: 'Şifre (Teyit)',
            REMEMBERME: 'Beni hatırla',
            FORGOTPASSWORD: 'Şifrenizi mi unuttunuz?',
            DONTHAVEACCOUNT: 'Hesabınız yok mu?',
            CREATEACCOUNT: 'Hesap oluştur',
        },
        REGISTER: {
            TITLE: 'MAKSELLENT Uygulamalarına Hoşgeldiniz!',
            DESCRIPTION: 'MAKSELLENT Uygulamaları ile artık işlerinizin yönetimi daha kolay',
            HEADER: 'YENİ HESAP OLUŞTUR',
            STEPFIRST: 'Sisteme giriş bilgilerinizi doldurunuz',
            STEPPROFILE: 'Profil bilgilerinizi doldurunuz',
            STEPCONTACT: 'İletişim detaylarınızı doldurunuz',
            STEPADDRESS: 'Adres bilgilerinizi doldurunuz',
            STEPDONE: 'Son',
            FIRSTNAME: 'İsim',
            LASTNAME: 'Soyad',
            ORGANIZATION: 'Organizasyon',
            DATEofBIRTH: 'Doğum Tarihi',
            USERNAME: 'Kullanıcı Adı',
            GENDER: 'Cinsiyet',
            FEMALE: 'Kadın',
            MALE: 'Erkek',
            EMAIL: 'E-Posta',
            PHONENUMBER: 'Telefon Numarası',
            PREFEREDLANG: 'Dil Tercihi',
            ADDRESSCOUNTRY: 'Ülke',
            ADDRESSCITY: 'İlçeniz',
            ADDRESSSTREET: 'Sokak',
            HAVEACCOUNT: 'Zaten bir hesabınız var mı?',
            ACCEPT: 'Kabul Et',
            TERMS: 'Şartlar ve Koşullar',            
        },
        RECOVER: {
            TITLE: 'MAKSELLENT Uygulamalarına Hoşgeldiniz!',
            DESCRIPTION: 'MAKSELLENT Uygulamaları ile artık işlerinizin yönetimi daha kolay',
            HEADER: 'Şifrenizi Kurtarın',
            GOBACK: 'Giriş sayfasına geri dön',
        },
        RESETPASS: {
            TITLE: 'MAKSELLENT Uygulamalarına Hoşgeldiniz!',
            DESCRIPTION: 'MAKSELLENT Uygulamaları ile artık işlerinizin yönetimi daha kolay',
            HEADER: 'ŞİFRENİ RESETLE',
        },                  
        BOTTON: {
            CREATE: 'Oluştur',
            SAVE: 'Kaydet',
            EDIT: 'Düzenle',
            DELETE: 'Sil',
            CLONE: 'Çoğalt',
            FILTER: 'Filtre',
            RESET: 'Reset',
            MAIN: 'Asıl',
            LOGIN: 'GİRİŞ YAP',
            LOGINLINK: 'Giriş Yap', 
            PASSWORDRESETLINK: 'RESET LİNKİNİ GÖNDER',
            PASSWORDRESETBUTTON: 'ŞİFREMİ RESETLE',
            CREATEACCOUNT: 'HESAP OLUŞTUR',
            BACK: 'Geri',
            NEXT: 'İleri',
            PREVIOUS: 'Previous',
            SUBMIT: 'Gönder',
        },
        DIALOG: {
            NAMEREQUIRED: 'İsim gerekli alan',
            LASTNAMEREQUIRED: 'Soyad gerekli alan',
            ORGANIZATIONREQUIRED: 'Organization bilgisini gerekli alan',
            EMAILREQUIRED: 'E-posta gerekli alan',
            EMAILWARN: 'Lütfen geçerli bir e-posta adresi girin',
            EMAILMUSTCONTAIN: 'Şunları içermeli (0,9) ve [a,z] ve [A,Z] ve [!@#$%^&\?_-|+>;€~¨] ve en az 6 yada daha fazla karakter olmalı.',
            PASSWORDREQUIRED: 'Şifre gerekli alan',
            PASSWORDCONFIRMREQUIRED: 'Şifre teyit/konfirmasyon gerekli',
            PASSWORDWARN: 'Lütfen geçerli bir şifre girin',
            DATEOFBIRTHREQUIRED: 'Doğum tarihi gerekli alan',
            DELETEWARN: 'Bu kaydı silmek istediğinizden, emin misiniz?',
            DELETESUCCESS: 'Kayıt başarılı şekilde silindi!',
            DELETEFAILURE: 'Kayıt silinemedi!',
            EDITABANDONED: 'Değişiklikleriniz kaydedilmeyecek. Çıkmak istediğinizde emin misiniz?',
            EDITSUCCESS: 'Kayıt başarılı şekilde kaydedildi!',
            CREATESUCCESS: 'Kayıt başarılı şekilde oluşturuldu. Lütfen E-Posta adresinizi doğrulayın!',
        },        
    }
};
