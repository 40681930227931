import {Injectable} from '@angular/core';
import {EncryptionFunctions} from '../shared-functions';
import {APPStorage} from '../shared-constants';
import {UserDetailModel} from '../shared-model';


@Injectable({
    providedIn: 'root'
})
export class SharedUserService {
    private _user: UserDetailModel;

    constructor() {
    }

    getUser() {
        if (!this._user) {
            this._user = EncryptionFunctions.DECRYPT_OBJ(
                sessionStorage.getItem(APPStorage.USER) ? sessionStorage.getItem(APPStorage.USER) : localStorage.getItem(APPStorage.USER),
                APPStorage.USER
            );
        }
        return this._user;
    }

    setSessionUser(value: UserDetailModel): void {
        sessionStorage.setItem(
            APPStorage.USER,
            EncryptionFunctions.ENCRYPT_OBJ(value)
        );
        this._user = value;
    }

    setLocalUser(value: UserDetailModel): void {
        localStorage.setItem(
            APPStorage.USER,
            EncryptionFunctions.ENCRYPT_OBJ(value)
        );
        this._user = value;
    }
}
