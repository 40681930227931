import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AppApi, ConfirmationDialogComponent, FormBaseComponent, GeneralStatusEnum, HttpMethodsTypeEnum, HttpStatus, MY_FORMATS } from "app/utility";
import { APIManager, SharedService, SharedUserService } from 'app/utility/shared-services';
import { Observable } from 'rxjs';
import { UserDetailModel } from 'app/utility/shared-model/users.model';
import { AssetAccountsDotCoreService } from 'app/private-modules/modules/fix-assets-module/services/fix-assets-account.dotcore.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { FixAssetDotCoreService } from 'app/private-modules/modules/fix-assets-module/services/fix-asset.dotcore.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { SurveyCreateModel } from '../../../models/survey/survey-create.model';
import { SurveyDotCoreService } from '../../../services/survey.dotcore.service';
import { TenantAuthDotCoreService } from '../../../services/user.dotcore.service';
import { copyPropsSurveyTeamsMemberObject, copyPropsSurveyTeamsObject } from '../../../util/survey-utils';

@Component({
    selector: 'app-survey-list-team-add-member',
    templateUrl: './survey-list-team-add-member.component.html',
    styleUrls: ['./survey-list-team-add-member.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ],
})
export class SurveyListTeamAddMemberComponent extends FormBaseComponent implements OnInit {

    // Form group variables
    surveyAddForm: FormGroup;

    // Const Variable
    surveyStatus = 'New';
    userDetail: UserDetailModel;

    // Dynamic Variables
    surveyTeamObj: any = {}
    surveyTeamMembers: any = {};
    assignedUserId: any = [];
    srvAccessModifier: any = [];
    tenantId: string;
    selectedUser: any;
    
    constructor(fb: FormBuilder,
                public dialogRef: MatDialogRef<SurveyListTeamAddMemberComponent>,
                private _translateService: TranslateService,
                private _sharedUserService: SharedUserService,
                private _userService: TenantAuthDotCoreService,
                private _apiManager: APIManager,
                private _surveyService: SurveyDotCoreService,
                public dialog: MatDialog,
                private sharedService: SharedService,
                private notificationService: NotificationService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super(fb);
        
    }

    ngOnInit(): void {
        if (this.data?.type.includes('Edit')) {
            this.surveyTeamObj = this.data?.data;
            this.surveyTeamMembers = this.data?.srvTeam;
        } else {
            this.surveyTeamMembers.color = '#ffffff';
            this.surveyTeamObj = this.data?.data;
        }
        this.surveyStatus = this.data?.type;
        console.log(this.surveyTeamObj);
        this.initialize();

        this.loadAccessModifierLookup();
        
        this._userService.loadTenantDefault().subscribe((response) => {
            this.tenantId = response?.data?.id;
            this._userService.getUserLookupByTenantId(this.tenantId).subscribe(responseAssignedUser => {
                this.assignedUserId = responseAssignedUser?.data;
                if (this.surveyStatus.includes('Edit')){
                    this.selectedUser = this.assignedUserId.filter( x => x.userId === this.surveyTeamMembers?.userId)[0];
                    console.log(this.selectedUser);
                    this.formControls.srv_assign_users.setValue(this.selectedUser);
                    this.assignedUserId.forEach(user => {
                        if (user !== this.selectedUser){
                            this.surveyTeamObj?.team_members?.forEach(element => {
                                if (element.userId.includes(user.userId)){
                                    this.assignedUserId.splice(this.assignedUserId.indexOf(user), 1);
                                }                                
                            });
                        }
                    });
                } else {
                    this.assignedUserId.forEach(user => {
                        this.surveyTeamObj?.team_members?.forEach(element => {
                            if (element.userId.includes(user.userId)){
                                this.assignedUserId.splice(this.assignedUserId.indexOf(user), 1);
                            }                                
                        });
                    });
                }
            })
        })
    }
    
    get formControls() {
        return this.surveyAddForm.controls;
    }

    initialize = () => {
        this.createModuleForm();
        this.userDetail = this._sharedUserService.getUser();

        
    };

    loadAccessModifierLookup(){
        this._surveyService.getLookupAccessModifier().subscribe((response) => {
            this.srvAccessModifier = response?.data
        })
    }
    
    createModuleForm = () => {
        this.surveyAddForm = this.createForm({
            srv_assign_users: ['']
        });
        
    };

    onSurveyAddSubmit = (form: FormGroup) => {
        const controls = this.surveyAddForm.controls;
        const user = this._sharedUserService.getUser();
        console.log(user);
        console.log(this.selectedUser)
        if (this.onSubmit(form)) {
            let params = {};
            this.surveyTeamMembers.userId = this.selectedUser?.userId;
            this.surveyTeamMembers.assigned_user_id = this.selectedUser?.userId;
            this.surveyTeamMembers.name = this.selectedUser?.first_name + ' ' + this.selectedUser?.last_name;
            this.surveyTeamMembers.description = this.surveyTeamMembers?.name;
            this.surveyTeamMembers.type = this.surveyTeamObj?.access_modifier.toString();
            this.surveyTeamMembers.access_modifier = this.surveyTeamObj?.access_modifier;
            this.surveyTeamMembers.icon = this.selectedUser?.profile_image_url;
            this.surveyTeamMembers.color = this.surveyTeamObj?.color;
            this.surveyTeamMembers.sequence = this.surveyTeamObj?.length + 1;
            this.surveyTeamMembers.status = this.surveyTeamObj?.status;
            this.surveyTeamMembers.code = this.surveyTeamObj?.code;
            this.surveyTeamMembers.priority = 0;
            this.surveyTeamMembers.team_id = this.surveyTeamObj?.id;
            params = copyPropsSurveyTeamsMemberObject(this.surveyTeamMembers, this.surveyStatus);
            console.log(params);
            this.saveSurvey(params).subscribe((response) => {
                console.log(response);
                this.dialogRef.close();
            })
        }
    };

    onChangeUser(assignUser){
        console.log(assignUser);
        this.selectedUser = this.assignedUserId.filter( x => x.userId === assignUser)[0];
        console.log(this.selectedUser);
    }

    saveSurvey(param): Observable<any> {
        if (this.surveyStatus === 'Edit') {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.PUT, AppApi.UPDATE_TEAM_MEMBER, param,
                this._apiManager.Authorised_HttpOptions, true, true);
        } else {
            return this._apiManager.httpHelperMethod(
                HttpMethodsTypeEnum.POST, AppApi.ADD_TEAM_MEMBER, param,
                this._apiManager.Authorised_HttpOptions, true, true);
        }
    }

    onCloseDialog = () => {
        this.dialogRef.close();
    };

}
