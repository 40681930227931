<div class="comment-form-box modal-view">
    <form [formGroup]="ideaCommentForm" (submit)="onIdeaCommentSubmit(ideaCommentForm)">
        <div class="row">
            <div class="col-md-7 PL-0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "SYSTEM_SETTINGS_MODULES.DESCRIPTION" | translate }}</mat-label>
                        <textarea  formControlName="description" matInput placeholder="{{'SYSTEM_SETTINGS_MODULES.ENTER_DESCRIPTION' | translate}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="col-md-5 PR-0 xs-PL0">
                <button type="submit" mat-flat-button color="primary">
                    {{ "IDEA.SAVE" | translate }}
                </button>
                <button type="button" mat-flat-button color="secondary" class="ML-2">
                    {{ "IDEA.CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>