import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from 'app/utility';
import { UserAuthGuard } from 'app/_guards';
import { SurveyListComponent } from './components';

const routes: Routes = [
    {
      path: '', component: SurveyListComponent,
      canActivate: [UserAuthGuard]
    },
    {
        path: 'detail', 
        loadChildren: () => import('./modules/survey-detail-modules/survey-detail.module').then(m => m.SurveyDetailModule),
        canActivate: [UserAuthGuard]
    },
    {
        path: 'detail/:id', 
        loadChildren: () => import('./modules/survey-detail-modules/survey-detail.module').then(m => m.SurveyDetailModule),
        canActivate: [UserAuthGuard]
    },
  ];

@NgModule({
  imports: [
      RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class SurveyRoutingModule { }
