<div class="modal-view">
    <div class="row">
        <div class="col-md-12 PLR-0">
            <h2 *ngIf="!surveyStatus.includes('Edit')">{{ "SURVEY_MODULES.CREATE_NEW" | translate }}</h2>
            <h2 *ngIf="surveyStatus.includes('Edit')">{{ "SURVEY_MODULES.EDIT" | translate }}</h2>
        </div>
    </div>
    
    <form [formGroup]="surveyAddForm" (submit)="onSurveyAddSubmit(surveyAddForm)">
        <div class="row">
            <div class="col-md-12 PLR-0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.ENTER_SURVEY_NAME" | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="survey_name"
                            [(ngModel)]="surveyObj.name"
                            placeholder="{{'SYSTEM_SETTINGS_MODULES.ENTER_NAME' | translate}}"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 PLR-0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "IDEA.DESCRIPTION" | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="srv_description"
                            [(ngModel)]="surveyObj.description"
                            placeholder="{{'IDEA.DESCRIPTION' | translate}}"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <div class="form-control">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>{{ 'SURVEY_MODULES.PLACEHOLDER.START_DATE' | translate }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            [matDatepicker]="dateStartPicker"
                            formControlName="start_date"
                            [(ngModel)]="surveyObj.date_start"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dateStartPicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dateStartPicker></mat-datepicker>
                    </mat-form-field>                   
                </div>
            </div>
            <div class="col-md-6 col-sm-6 PR-0 xs-PL0">
                <div class="form-control">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>{{ 'SURVEY_MODULES.PLACEHOLDER.END_DATE' | translate }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            [matDatepicker]="dateEndPicker"
                            formControlName="end_date"
                            [(ngModel)]="surveyObj.date_end"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dateEndPicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dateEndPicker></mat-datepicker>
                    </mat-form-field>                 
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <div class="form-control">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>{{ "SURVEY_MODULES.PLACEHOLDER.SELECT_SURVEY_TYPE" | translate }}</mat-label>
                          <mat-select formControlName="srv_type" [(ngModel)]="surveyObj.srv_type_id">
                              <mat-option value="{{item.id}}" *ngFor="let item of surveyType">{{item.name}}</mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-6 col-sm-6 PR-0 xs-PL0">
                <div class="form-control">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>{{ "SURVEY_MODULES.ACCESS_TYPE" | translate }}</mat-label>
                        <mat-select
                            class="form-control"
                            formControlName="accessModifierInput"
                        >
                        <ng-container *ngFor="let accessModifier of accessModifierList">
                            <!-- <ng-container *ngFor="let item of accessModifier?.sys_setting_langs"> -->
                                <mat-option 
                                    (onSelectionChange)="onChangeAccessModifier(accessModifier.code, $event)"
                                    [value]="accessModifier">
                                    {{accessModifier.name}}
                                </mat-option>
                            <!-- </ng-container> -->
                        </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label>Assign Users</mat-label>
                      <mat-select formControlName="srv_assign_users" [(ngModel)]="surveyObj.assigned_user_id">
                          <ng-container *ngFor="let item of assignedUserId">
                              <mat-option value="{{item.userId}}">{{item?.first_name + ' ' + item?.last_name}}</mat-option>
                          </ng-container>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-6 PR-0 xs-PL0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "TASK_MANAGEMENT.COLOR_CAPITAL" | translate }}</mat-label>
                        <input matInput [(colorPicker)]="surveyObj.color" [style.background]="surveyObj.color" formControlName="srv_color" [(ngModel)]="surveyObj.color" />
                        
                    </mat-form-field>
                </div>
            </div> 
        </div> -->

        <!-- <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "SURVEY_MODULES.SIZE" | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="size"
                            [(ngModel)]="surveyObj.size"
                            placeholder='{{ "SURVEY_MODULES.SIZE" | translate }}'
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-6 col-sm-6 PR-0 xs-PL0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "SURVEY_MODULES.BUDGET" | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="budget"
                            [(ngModel)]="surveyObj.budget"
                            placeholder='{{ "SURVEY_MODULES.BUDGET" | translate }}'
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "FIX_ASSET.ACCOUNT" | translate }}</mat-label>
                        <input type="text" aria-label="Account" matInput formControlName="account_id" 
                        placeholder='{{"PROFILE.SELECT_ACCOUNT" | translate }}'
                        [matAutocomplete]="account" 
                        [(ngModel)]="surveyObj.account_id" (change)="clearOrganizations()">
                        <mat-autocomplete autoActiveFirstOption #account="matAutocomplete" [displayWith]="displayAccount" (optionSelected)='onChangeAccount($event.option.value)'>
                            <ng-container *ngFor="let option of filterAccountLookups | async">
                                <mat-option *ngIf="option.type.includes('ADD')" [value]="option">
                                    <mat-icon>add</mat-icon> {{option.name}}
                                </mat-option>
                                <mat-option *ngIf="!option.type.includes('ADD')" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 PR-0 xs-PL0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'PROFILE.ORGANIZATION' | translate}}</mat-label>
                        <input type="text" aria-label="Organization" matInput formControlName="organization_id" 
                            placeholder='{{"PROFILE.SELECT_ORGANIZATION" | translate }}'
                            [matAutocomplete]="organization" 
                            [(ngModel)]="surveyObj.organization_id" (change)="clearUnits()">
                        <mat-autocomplete autoActiveFirstOption #organization="matAutocomplete" [displayWith]="displayOrganization" (optionSelected)='onChangeOrganization($event.option.value)'>
                            <ng-container *ngFor="let option of filterOrganizationLookups | async">
                                <mat-option *ngIf="option.type.includes('ADD')" [value]="option">
                                    <mat-icon>add</mat-icon> {{option.name}}
                                </mat-option>
                                <mat-option *ngIf="!option.type.includes('ADD')" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "FIX_ASSET.UNIT" | translate }}</mat-label>
                        <input type="text" aria-label="Unit" matInput formControlName="unit_id" 
                        placeholder='{{"FIX_ASSET.SELECT_UNIT" | translate }}'
                        [matAutocomplete]="unit" [(ngModel)]="surveyObj.unit_id" (change)="clearLocations()">
                        <mat-autocomplete autoActiveFirstOption #unit="matAutocomplete" [displayWith]="displayUnit" (optionSelected)='onChangeUnit($event.option.value)'>
                            <ng-container *ngFor="let option of filterUnitLookups | async">
                                <mat-option *ngIf="option.type.includes('ADD')" [value]="option">
                                    <mat-icon>add</mat-icon> {{option.name}}
                                </mat-option>
                                <mat-option *ngIf="!option.type.includes('ADD')" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 PR-0 xs-PL0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "APP_SETTINGS.LOCATION" | translate }}</mat-label>
                        <input type="text" aria-label="Location" matInput formControlName="location_id" 
                        placeholder='{{"FIX_ASSET.SELECT_LOCATION" | translate }}'
                        [matAutocomplete]="location" [(ngModel)]="surveyObj.location_id" (change)="clearZones()">
                        <mat-autocomplete autoActiveFirstOption #location="matAutocomplete" [displayWith]="displayLocation" (optionSelected)='onChangeLocation($event.option.value)'>
                            <ng-container *ngFor="let option of filterLocationLookups | async">
                                <mat-option *ngIf="option.type.includes('ADD')" [value]="option">
                                    <mat-icon>add</mat-icon> {{option.name}}
                                </mat-option>
                                <mat-option *ngIf="!option.type.includes('ADD')" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-6 PL-0 xs-PR0">
                <div class="form-control">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "FIX_ASSET.ZONE" | translate }}</mat-label>
                        <input type="text" aria-label="Location" matInput formControlName="zone_id" 
                        placeholder='{{"FIX_ASSET.SELECT_ZONE" | translate }}'
                        [matAutocomplete]="zone" [(ngModel)]="surveyObj.zone_id">
                        <mat-autocomplete autoActiveFirstOption #zone="matAutocomplete" [displayWith]="displayZone" (optionSelected)='onChangeZone($event.option.value)'>
                            <ng-container *ngFor="let option of filterZoneLookups | async">
                                <mat-option *ngIf="option.type.includes('ADD')" [value]="option">
                                    <mat-icon>add</mat-icon> {{option.name}}
                                </mat-option>
                                <mat-option *ngIf="!option.type.includes('ADD')" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 PLR-0">
                <button type="submit" mat-flat-button color="primary">
                    {{ "BOTTON.SAVE" | translate }}
                </button>
                <button (click)="onCloseDialog()" type="button" mat-flat-button color="secondary" class="ML-2">
                    {{ "BOTTON.CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>