import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    APPStorage,
    EncryptionFunctions,
    getUserInitialOfName,
    getUserProfileImage,
    HeaderEnum,
    LANGUAGES,
    RouteConstants,
    UserDetailModel,
} from 'app/utility';
import { SharedService } from 'app/utility/shared-services';
import { LoginService, RegisterService } from '../../public-modules/modules/auth/services';
import { CountryLanguagesModel } from '../../public-modules/modules/auth/models';
import { locale as english } from '../../public-modules/modules/auth/components/i18n/en';
import { locale as turkish } from '../../public-modules/modules/auth/components/i18n/tr';
import { locale as german } from '../../public-modules/modules/auth/components/i18n/de';
import { FuseTranslationLoaderService } from '../../../@fuse/services/translation-loader.service';
import { StorageService } from 'app/shared/helpers/storage.service';
import { NotificationsService } from 'app/utility/shared-component/notifications-alert/notifications.service';
import { Observable, Subscription } from 'rxjs';
import { ChangeProfileService } from '../../private-modules/modules/profile-module/services';
import { AccessTypeModel } from '../../shared/models/access-type.model';
import { RemindersService } from '../reminders/reminders.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    // Data variables
    languageList: CountryLanguagesModel[] = [];
    selectedLanguage: string = LANGUAGES.English;
    userDetails: UserDetailModel;
    selectedLanguageObj: CountryLanguagesModel;
    activeAction;
    headerEnum = HeaderEnum;
    _routeConstant: any;
    _currentRoute: string = RouteConstants.HOME;
    showInitials = false;
    profileImageChangeSubscriber$: any;
    imageUrl: any;
    userStatusList: AccessTypeModel[] = [];
    currentStatus: AccessTypeModel;
    userStatusChangeSubscriber$: Subscription;

    constructor(
        public router: Router,
        private authService: SharedService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private sharedService: SharedService,
        private storageService: StorageService,
        private registerService: RegisterService,
        private changeProfileService: ChangeProfileService,
        public readonly notifications: NotificationsService,
        private loginService: LoginService,
        public readonly reminders: RemindersService
    ) {
        // Set the language defaults
        this._fuseTranslationLoaderService.loadTranslations(
            english,
            turkish,
            german
        );
    }

    ngOnInit() {
        this.userDetails = this.sharedService.getUser();
        this.imageUrl = getUserProfileImage(this.sharedService.getToken());
        this.getLanguagesList();
        this.subscribeProfileImageChange();
        this.getStatusList();
        this.subscribeUserStatusChange();
        this.reminders.getCountsOfReminders({}).subscribe();
    }

    ngOnDestroy() {
        if (this.profileImageChangeSubscriber$) {
            this.profileImageChangeSubscriber$.unsubscribe();
        }
        if (this.userStatusChangeSubscriber$) {
            this.userStatusChangeSubscriber$.unsubscribe();
        }
    }

    // Initialisation methods
    getLanguagesList = () => {
        this.getLanguagesListApiCall().subscribe((response) => {
            this.languageList = response['data'];
            this.selectedLanguage =
                this.userDetails && this.userDetails.lang
                    ? this.userDetails.lang
                    : LANGUAGES.English;
            this.setLanguage(this.selectedLanguage);
        });
    };

    subscribeProfileImageChange = () => {
        this.profileImageChangeSubscriber$ = this.sharedService
            .getProfileImageChange()
            .subscribe((lang) => {
                this.imageUrl = getUserProfileImage(this.sharedService.getToken());
            });
    };

    getStatusList = (): void => {
        this.getStatusListApiCall().subscribe((response) => {
            this.userStatusList = response['data'];
            this.sharedService.setStatusList(this.userStatusList);
            this.currentStatus = this.userDetails.status_user ?
                this.userStatusList.find(rec => rec.code === this.userDetails.status_user) :
                this.userStatusList[0];
        });
    };

    subscribeUserStatusChange = () => {
        this.userStatusChangeSubscriber$ = this.sharedService
            .getUserDetailsChange()
            .subscribe((lang) => {
                this.userDetails = this.sharedService.getUser();
                this.currentStatus = this.userDetails.status_user ?
                    this.userStatusList.find(rec => rec.code === this.userDetails.status_user) :
                    this.userStatusList[0];
            });
    };

    // Api calls
    getLanguagesListApiCall = () => {
        return this.registerService.getLanguageList();
    };

    getStatusListApiCall = (): Observable<unknown> => {
        return this.changeProfileService.getTenantSystemSettingsByType({ type: 'userstatus' });
    };

    updateUserStatusApiCall = (params: { status_user: string }): Observable<unknown> => {
        return this.changeProfileService.updateUserStatus(params);
    };

    /*updateLanguageApiCall = (params: any) => {
        return this.changeProfileService.updateUserPreference(params);
    };*/

    // Page events
    /*updateLanguage = (lang: string) => {
        this.updateLanguageApiCall({
            userId: this.userDetails.id,
            lang,
            timezone_id: this.userDetails.timezone_id
        }).subscribe(() => {
            this.setLanguage(lang);
        });
    };*/

    setLanguage(lang: string): void {
        this.selectedLanguageObj = this.languageList.find(
            (record) => record.code === lang
        );
        this.sharedService.setLanguageChange(lang);
        this.storageService.storeString(
            'currentLanguage',
            JSON.stringify(lang)
        );
    }

    updateUserStatus = (status: AccessTypeModel) => {
        this.updateUserStatusApiCall({ status_user: status.code }).subscribe(() => {
            this.getProfileData();
        });
    };

    getProfileData = () => {
        this.loginService.getProfileData().subscribe((profileData) => {
            const userData = EncryptionFunctions.DECRYPT_OBJ(sessionStorage.getItem(APPStorage.USER));
            if (!!(userData)) {
                this.sharedService.setSessionUser(profileData.data);
            } else {
                this.sharedService.setLocalUser(profileData.data);
            }
            this.userDetails = this.sharedService.getUser();
            this.sharedService.setUserDetailsChange(true);
        });
    };


    onSystemSettingClick = () => {
        this.router.navigate(['/' + RouteConstants.SYSTEM_SETTINGS]);
    };

    onAppSettings = () => {
        this.router.navigate(['/' + RouteConstants.APP_SETTINGS]);
    };

    onProfileClick = () => {
        this.router.navigate(['/' + RouteConstants.MY_PROFILE_ROUTE]);
    };

    onInternalAccounts = () => {
        this.router.navigate(['/' + RouteConstants.INTERNAL_ACCOUNTS_ROUTE]);
    }

    get ideaManagementUrl() {
        return '/' + RouteConstants.IDEA;
    }

    get fixAssetsUrl() {
        return '/' + RouteConstants.FIX_ASSETS;
    }

    get financialDetailsUrl() {
        return '/' + RouteConstants.FINANCIAL_LIST_FIX_ASSETS_ROUTE;
    }

    get myFiledUrl() {
        return '/' + RouteConstants.MY_FILE;
    }

    get taskManagementUrl() {
        return '/' + RouteConstants.TASK_MANAGEMENT;
    }

    get calendarUrl() {
        return "/" + RouteConstants.CALENDAR;
    }

    get myTaskListUrl() {
        return '/' + RouteConstants.MY_TASK_LIST_ROUTE;
    }

    get dashboardFixAssetsUrl() {
        return '/' + RouteConstants.DASHBOARD_FIX_ASSETS_ROUTE;
    }

    get homeUrl() {
        return '/' + RouteConstants.HOME;
    }

    get surveyURL() {
        return '/' + RouteConstants.SURVEY;
    }

    get profileUrl() {
        return '/' + RouteConstants.MY_PROFILE_ROUTE;
    }

    get appSettingsUrl() {
        return '/' + RouteConstants.APP_SETTINGS;
    }

    get accountManagementUrl() {
        return '/' + RouteConstants.ACCOUNT_MANAGEMENT;
    }

    get powerUserUrl() {
        return '/' + RouteConstants.POWER_USER_CONTAINER_ROUTE;
    }

    get projectListUrl() {
        return '/' + RouteConstants.PROJECT_LIST_ROUTE;
    }

    get internalAccountsUrl() {
        return '/' + RouteConstants.INTERNAL_ACCOUNTS_ROUTE;
    }

    get userManagementUrl() {
        return '/' + RouteConstants.USER_MANAGEMENT_ROUTE;
    }

    get accountsUrl() {
        return '/' + RouteConstants.ACCOUNT_MANAGEMENT;
    }

    get ProspectsUrl() {
        return '/' + RouteConstants.PROSPECTS_ROUTE;
    }

    async onLogout() {
        this.authService.clearSession();
        this.router.navigate(['/login']);
    }

    onClickActionMenu = (view: HeaderEnum) => {
        this.activeAction = view;
    };

    onHideActionMenu = () => {
        this.activeAction = null;
    };

    showInitialsImage = () => {
        this.showInitials = true;
    };

    getInitialOfName = () => {
        return getUserInitialOfName(this.userDetails);
    };
}
